import { Controller } from "@hotwired/stimulus"
import GSTC from "gantt-schedule-timeline-calendar/dist/gstc.wasm.esm.min.js"
import { Plugin as TimelinePointer } from 'gantt-schedule-timeline-calendar/dist/plugins/timeline-pointer.esm.min.js'
import { Plugin as Selection } from 'gantt-schedule-timeline-calendar/dist/plugins/selection.esm.min.js'
import { Plugin as ItemMovement } from 'gantt-schedule-timeline-calendar/dist/plugins/item-movement.esm.min.js'
import { Plugin as ItemResizing } from 'gantt-schedule-timeline-calendar/dist/plugins/item-resizing.esm.min.js'
import { Plugin as CalendarScroll } from 'gantt-schedule-timeline-calendar/dist/plugins/calendar-scroll.esm.min.js'
import { Plugin as HighlightWeekends } from 'gantt-schedule-timeline-calendar/dist/plugins/highlight-weekends.esm.min.js'
import { Plugin as DependencyLines } from 'gantt-schedule-timeline-calendar/dist/plugins/dependency-lines.esm.min.js'

import { get, post } from '@rails/request.js'

import weekOfYear from 'dayjs/plugin/weekOfYear'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

GSTC.api.dayjs.extend(weekOfYear)
GSTC.api.dayjs.extend(advancedFormat)
GSTC.api.dayjs.extend(utc)
GSTC.api.dayjs.extend(timezone)
GSTC.api.dayjs.tz.setDefault("Europe/Berlin")

export default class extends Controller {

  timers = {}

  static values = { 
    feedUri: String, 
    startDate: String, 
    endDate: String, 
    mode: String,
    collisionsAllowed: { type: Boolean, default: false },
    rowChangesAllowed: { type: Boolean, default: false },
    localeConfig: { 
      type: Object, default: {
        name: 'en',
        weekdays: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_'),
        weekdaysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
        weekdaysMin: 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
        months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
        monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
        weekStart: 1,
        yearStart: 4,
        relativeTime: {
          future: 'in %s',
          past: '%s ago',
          s: 'a few seconds',
          m: 'a minute',
          mm: '%d minutes',
          h: 'an hour',
          hh: '%d hours',
          d: 'a day',
          dd: '%d days',
          M: 'a month',
          MM: '%d months',
          y: 'a year',
          yy: '%d years',
        },
        formats: {
          LTS: 'HH:mm:ss',
          LT: 'HH:mm',
          L: 'DD.MM.YYYY',
          LL: 'D. MMMM YYYY',
          LLL: 'D. MMMM YYYY HH:mm',
          LLLL: 'dddd, D. MMMM YYYY HH:mm',
        },
        ordinal: (n) => {
          const s = ['th', 'st', 'nd', 'rd']
          const v = n % 100
          return `[${n}${s[(v - 20) % 10] || s[v] || s[0]}]`
        },
      }
    }
  }

  static targets = [
    'searchInput',
    'searchField',
    'currentRange',
    'progress',
    'flyout',
    'filterCount',
    'resetFilter',
    'filterArea',
  ]

  initialize() {
    this.changed_items = null
    this.GSTCID = GSTC.api.GSTCID
    globalThis.GSTC = GSTC
    this.updateFilterCount()
  }

  debounce(f, delay = 500) {
    return (...args) => {
      const function_name = f.name
      if (this.timers[function_name]) clearTimeout(this.timers[function_name])
      this.timers[function_name] = setTimeout(() => f.apply(this, args), delay)
    }
  }

  async connect() {
    this.element[this.identifier] = this // alternative to getControllerForElementAndIdentifier
    let firstDay = new Date(this.startDateValue)
    const lastDay = new Date(this.endDateValue)
    const transactions = await this.getFeed(this.feedUriValue, firstDay, lastDay)
    this.initGSTC(transactions)
    this.progressTarget.style.display = 'none'
  }
  
  date(time) {
    return GSTC.api.date(time, false, this.localeConfigValue)
  }

  gatherSearchParams() {
    const params = new URLSearchParams()

    this.searchFieldTargets.forEach(target => {
      if (target.dataset?.controller?.includes('remote-dropdown') || target.dataset?.controller?.includes('dropdown') || target.classList.contains('dropdown')) {       
        $(target).val().forEach(value => {
          if (value.trim() !== "") {
            params.append(`${target.name}`, value)
          }
        })
      } else if (target.dataset?.controller?.includes('checkbox') || target.classList.contains('checkbox')) {
        params.append(target.name, target.checked)
      } else if (target.type === 'date') {
        if (target.value.trim() !== "") {
          params.append(target.name, new Date(target.value).toISOString())
        }
      } else {
        if (target.value.trim() !== "") {
          params.append(target.name, target.value)
        }
      }
    })

    params.append('start', new Date(this.startDateValue))
    params.append('end', new Date(this.endDateValue))
    
    const updated_url = `${window.location.pathname}?${params.toString()}`
    window.history.replaceState({}, '', updated_url)
    return params
  }

  async getFeed(uri) {
    const params = this.gatherSearchParams()

    try {
      const response = await get(`${uri}?${params.toString()}`)
      if (response.ok) {
        return await response.json
      }
    } catch(error) {
      console.error('Fetch request failed: ', error)
    }
    return {}
  }

  async search() {
    this.debounce(this.updateFeed)()
  }

  updateFilterCount() {
    this.filterCountTarget.textContent = `${this.filterCount()}`
  }

  filterCount() {
    const form = this.filterAreaTarget
    var count = 0

    for (const element of form.elements) {
      var e = $(element)
      if (e.type === 'checkbox' || e.type === 'radio') {
        if (e.checked) count++
        
      } else {
        const v = e.val()
        if (v != [] && v != [''] && v !== '') count++
      }
    }

    if (this.searchInputTarget.value !== '') count++
    return count
  }

  formatFeed(avails) {
    const c = this
    const rows = {}
    const items = {}
    for (let i = 0; i < avails.length; i++) {
      const id = c.GSTCID(String(avails[i].id))
      rows[id] = {
        id,
        type: 'avail',
        label: avails[i].label,
        parentId: avails[i].parent_id ? c.GSTCID(String(avails[i].parent_id)) : null,
        expanded: false,
        title: avails[i].title,
        additionalTitle: avails[i].additional_title,
        avail_type: avails[i].type,
        typeColor: avails[i].type_color,
      }

      let rowId = c.GSTCID(String(avails[i].id))
      items[id] = {
        id,
        model: 'Avail',
        label: avails[i].title,
        style: avails[i].style,
        img: avails[i].artwork,
        time: {
          actual_start: c.date(avails[i].time?.start).valueOf(),
          actual_end: c.date(avails[i].time?.end).valueOf(),
          start: c.date(avails[i].time?.start).startOf('day').valueOf(),
          end: c.date(avails[i].time?.end).endOf('day').valueOf(),
        },
        rowId,
        parentId: avails[i].parent_id ? c.GSTCID(String(avails[i].parent_id)) : null,
        description: avails[i].additional_title,
      }
      
      for (let tra_i = 0; tra_i < avails[i].transactions.length; tra_i++) {
        const tr = avails[i].transactions[tra_i]
        const tra_id = c.GSTCID(String(tr.id))
        rows[tra_id] = {
          id: tra_id,
          type: 'transaction',
          label: tr.transaction_id,
          parentId: c.GSTCID(String(tr.parent_id)),
          expanded: false,
          title: tr.transaction_id,
          additionalTitle: tr.duration,
          formatProfile: tr.format_profile,
          licenseType: tr.license_type,
          territories: tr.territories
        }

        items[tra_id] = {
          id: tra_id,
          model: 'AvailTransaction',
          label: tr.transaction_id,
          style: avails[i].style,
          img: avails[i].artwork,
          time: {
            actual_start: c.date(tr.time.start).valueOf(),
            actual_end: c.date(tr.time.end).valueOf(),
            start: c.date(tr.time.start).startOf('day').valueOf(),
            end: c.date(tr.time.end).endOf('day').valueOf(),
          },
          rowId: tra_id,
          description: avails[i].additional_title,
          parentId: tr.parent_id ? c.GSTCID(String(tr.parent_id)) : null,
        }
      }
    }

    // adding dependants
    for (const id in items) {
      //console.log(items[id])
      const item = items[id]
      if (item.parentId && Object.prototype.hasOwnProperty.call(items, item.parentId)) {
        const parent_item = items[item.parentId]
        if (item.model !== 'Avail') {
          parent_item.dependant = [...new Set([
            ...parent_item.dependant ?? [],
            id
          ])]
        }
        parent_item.children = [...new Set([
          ...parent_item.children || [],
          id
        ])]
      }
      // item.dependant = [...new Set([
      //   ...item.parentId ? [item.parentId] : [],
      // ])]

      if (item.model !== 'Avail') {
        c.recalculateParentTimes(item, items)
      }
    }

    const columns = {
      data: {
        [c.GSTCID('label')]: {
          id: c.GSTCID('label'),
          data: 'label',
          sortable: 'label',
          expander: true,
          isHTML: false,
          width: 415,
          header: {
            content: 'Avail',
          },
        }
      },
    }

    return { rows: rows, items: items, columns: columns }
  }

  initGSTC(avails) {
    const c = this
    const startDate = GSTC.api.date(this.startDateValue).startOf('day')
    const endDate = GSTC.api.date(this.endDateValue).endOf('day')

    const { rows, items, columns } = this.formatFeed(avails)

    const week = [
      {
        zoomTo: 100,
        period: 'week',
        periodIncrement: 1,
        format({ timeStart }) {
          return timeStart.format('DD MMMM YYYY')
        },
      },
    ]
    
    const month = [
      {
        zoomTo: 100,
        period: 'month',
        periodIncrement: 1,
        main: true,
        format({ timeStart, vido }) {
          return vido.html`<div style="text-align:center;">${timeStart.format('MMMM YYYY')}</div>`
        },
      },
    ]

    const year = [
      {
        zoomTo: 100,
        period: 'year',
        periodIncrement: 1,
        main: true,
        format({ timeStart, vido }) {
          return vido.html`<div style="text-align:center;">${timeStart.format('YYYY')}</div>`
        },
      },
    ]

    const config = {
      licenseKey: "GSTC:3:5xYXs83PQEo=:RsengspwJ01pGhrO:dN6EG2pncTHhi3LlzD6UrPJNHnX++S8YFLX5hjEng8pzYc8e5AU0e1AGrKAHVlVt6O/In5qhZ1GJe9AIKBGg+l4DlGS/oXgIL5/7YIEr/yI2XfvxKD+D8Wh0Mwf0ku2OMakao52Dfp49eYYMy6HTul4TJ8toWIEvntAJ0Zuqzzf3a0Y4B67L493iULIvC6dR+K1T9PMBlZdVFqKCBQJ3cFwgf19JyUhe1IBBJbHfrynThx44Qt4emCUVA2NIN9l4v1Np9PyO6p5eQJpXGmKPHMmgrEaua+xDzq8MrVEgyjxdaNjqNPmFich6kYBKfBIvUU1dBEfJvxrWjEpr2pL9N9vDqdZdkCaMzfB0aGEmH5RbGKyIMx8B+SVBx5pUal1Is2Nmh4WAZeZZ7qs37vz14bDZYlfYrbLxUBxEjQ8RD+gXx1x8MA1awqt/lQf9F621Xns8qdAPTaHZ/+XDkNfLl6dOgHdt45yRZNDfeeM+XkU16flsCP8FvzoulGhUeCNzFMy1EnZdbbTO+D8VFUNvkEE/jVuudrDesjxNbyNTunwsxhWEhr9iOYMCMgSQhGSO+phFv6lIabPJdU6uSp66hOh8lJW/QtWq5nlfec5WlbLmMZg0tDzuDv3uzRv/pjJ7kYlRiJ0WZ8ge8CISnOaayNGNE2l8kMsk/U9GBXYg5jzuOLawwXI4BP3k6yRzquQeTHqTgABY8AdsrrQl5oR4lBkB04WgR3x22BE0/lgHCGDiFaSmYOAIoCI99XcIodAikC64rD/AkIpaJG5r/5qQutjspALnPceU+/cSkbRSqCUMFC/PUVvCCI+yoZ6T5HNp2usVTbxqzUSCoz9WfYUZ09COAGjl1K0mFzZt/1oMg6XGaS7ZDseZjenMaouvR1vC/JZkpm8qm/Ms1LbwUX7jMOuRmomsAepzq3as1GfzaFIuu2JnxIHDX49o+W9/0UiNYp1COmfq7h3oqUHrruUd/+o6X2iGdRTnO/MJ6WUJdsj3pxRM/M4p5clii84iFH1r05/tPUzjV8/CAvK1qAj0HWMBCzIVvOxzdm9fKO/sCruGT0rRGdCNmCrqhbQ=",
      debug: false,
      innerHeight: 800,
      plugins: [
        HighlightWeekends(),
        TimelinePointer(), // timeline pointer must go first before selection, resizing and movement
        Selection(),
        ItemResizing({
          snapToTime: {
            start({ startTime, time }) {
              return startTime.startOf(time.period)
            },
            end({ endTime, time }) {
              return endTime.endOf(time.period)
            }
          },
          dependant: false,
          events: {
            onStart({ items, ...info }) {
              return items.after
            },
            onResize({ items, selectedIds, addedDependantIds, ...info }) {
              // const all_items = c.gstc.api.getAllItems()

              // c.gstc.api.getItems(items.after[0].children).filter(i => i.model === 'AvailTransaction')
              c.updateAncestors(selectedIds, items.after)

              return items.after
            },
            onEnd({ items, ...info }) {
              c.changed_items = items
              c.debounce(c.displayAdjustmentModal.bind(c), 500)(items, info)
              return items.after
            },
          },
          // content: ({ item, vido }) => ({
          //   left: vido.html`<div class="resizing-handler-content-left"><i class="grip lines vertical icon"></i></div>`,
          //   right: vido.html`<div class="resizing-handler-content-right"><i class="grip lines vertical icon"></i></div>`,
          // })
        }),
        ItemMovement(
          {
            enabled: true,
            threshold: {
              horizontal: 25,
              vertical: 25,
            },

            events: {
              onMove({ items }) {
                return items.before.map((item_before_movement, index) => {
                  const item_after_movement = items.after[index]
                  const current_item = GSTC.api.merge({}, item_after_movement)
                  if (!c.rowChangesAllowedValue) {
                    current_item.rowId = item_before_movement.rowId
                  }
                  if (!c.collisionsAllowedValue && c.isCollision(current_item)) {
                    current_item.time = { ...item_before_movement.time }
                    current_item.rowId = item_before_movement.rowId
                  }
                  return current_item
                })
              },
              onEnd({ items }) {
                const items_changed = items.after.some((item, i) => 
                  items.initial[i].time.start !== item.time.start || items.initial[i].time.end !== item.time.end
                )
                if (items_changed) {
                  c.changed_items = items
                  c.debounce(c.displayAdjustmentModal.bind(c), 500)(c.changed_items)
                  return items.after
                } else {
                  return items.initial
                }
              }
            },
          }
        ),
        CalendarScroll(),
        // DependencyLines({
        //   onLine: [
        //     (line) => {
        //       line.type = c.GSTC.api.sourceID(line.fromItem.id) === '3' ? 'smooth' : 'square'
        //       return line
        //     },
        //   ],
        // }),
      ],
      locale: {
        name: 'en',
        weekdays: 'Sunday_Monday_Tuesday_Wednesday_Thursday_Friday_Saturday'.split('_'),
        weekdaysShort: 'Sun_Mon_Tue_Wed_Thu_Fri_Sat'.split('_'),
        weekdaysMin: 'Su_Mo_Tu_We_Th_Fr_Sa'.split('_'),
        months: 'January_February_March_April_May_June_July_August_September_October_November_December'.split('_'),
        monthsShort: 'Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec'.split('_'),
        weekStart: 1,
        relativeTime: {
          future: 'in %s',
          past: '%s ago',
          s: 'a few seconds',
          m: 'a minute',
          mm: '%d minutes',
          h: 'an hour',
          hh: '%d hours',
          d: 'a day',
          dd: '%d days',
          M: 'a month',
          MM: '%d months',
          y: 'a year',
          yy: '%d years',
        },
        formats: {
          LT: 'HH:mm',
          LTS: 'HH:mm:ss',
          L: 'DD.MM.YYYY',
          LL: 'D MMMM YYYY',
          LLL: 'D MMMM YYYY HH:mm',
          LLLL: 'dddd, D MMMM YYYY HH:mm',
        },
        ordinal: (n) => {
          const s = ['th', 'st', 'nd', 'rd']
          const v = n % 100
          return `[${n}${s[(v - 20) % 10] || s[v] || s[0]}]`
        },
      },
      list: {
        row: {
          height: 30,
        },
        rows,
        columns,
        toggle: {
          display: false
        },
        expander: {
          icons: {
            child: '<svg xmlns="http://www.w3.org/2000/svg" width="0" height="0"></svg>'
          }
        }
      },
      chart: {
        spacing: { 
          left:0, 
          right:0 
        },
        time: {
          calculatedZoomMode: true,
          from: c.date(startDate).valueOf(),
          to: c.date(endDate).valueOf(),
          calendarLevels: [week, month, year]
        },
        item: {
          height: 30,
        },
        items,
      },
      scroll: {
        vertical: {
          width: 10,
          precise: true
        },
      },
      slots: {
        'chart-timeline-items-row-item': { content: [c.itemSlot.bind(c)] },
        'list-column-row': { content: [c.rowSlot.bind(c)] },
        main: { outer: [c.mainOuterSlot.bind(c)] },
      },
    }

    let state = GSTC.api.stateFromConfig(config)
    const element = document.getElementById('calendar_content')

    // const unsubscribe = state.subscribe("config.plugin.CalendarScroll", (options) => {
    //   if (options.enabled) {
    //     // ...
    //   } else {
    //     // ...
    //   }
    // })
    c.state = state
    c.gstc = GSTC({
      element,
      state,
    })
  }

  toggleList() {
    // state.update(`config.list.columns.data.gstcid-name.hidden`, !ev.target.checked) // change gstcid-name 
  }

  async updateFeed() {
    this.progressTarget.style.display = 'block'
    let firstDay = new Date(this.startDateValue)
    const lastDay = new Date(this.endDateValue)

    const avails = await this.getFeed(this.feedUriValue, firstDay, lastDay)
    const { rows, items, columns } = this.formatFeed(avails)

    this.state.update('config.chart.items', {})
    this.state.update('config.list.rows', rows)
    this.state.update('config.chart.items', items)
    this.progressTarget.style.display = 'none'
    this.updateFilterCount()
  }

  toggleFilter() {
    this.flyoutTarget['flyout']?.toggle()
  }

  switchZoom(event) {
    document.querySelectorAll('.range .button').forEach((button) => button.classList.remove('primary', 'active'))
    event.target.classList.add('primary', 'active')
    this.zoomChange(event.params.period)
  }

  previousView() {
    const c = this

    let date = c.gstc.api.time.date(this.startDateValue)

    let from
    let to

    switch (c.modeValue) {
      case 'week':
        date = date.subtract(1, 'week')
        from = date.startOf('week').valueOf()
        to = date.endOf('week').valueOf()
        c.currentRangeTarget.value = `Week ${date.week()}, ${date.format('MMMM YYYY')}`
        break
      case 'month':
        date = date.subtract(1, 'month')
        from = date.startOf('month').valueOf()
        to = date.endOf('month').valueOf()
        c.currentRangeTarget.value = `${date.format('MMMM YYYY')}`
        break
      case 'year':
        date = date.subtract(1, 'year')
        from = date.startOf('year').valueOf()
        to = date.endOf('year').valueOf()
        c.zoom = 5
        c.currentRangeTarget.value = `${date.format('YYYY')}`
        break
    }

    c.startDateValue = new Date(from).toISOString()
    c.endDateValue = new Date(to).toISOString()

    c.state.update('config.chart.time', (time) => {
      time.zoom = c.zoom
      time.from = from
      time.to = to
      return time
    })
    c.updateFeed()
  }

  nextView() {
    const c = this

    let date = c.gstc.api.time.date(this.startDateValue)

    let from
    let to

    switch (c.modeValue) {
      case 'week':
        date = date.add(1, 'week')
        from = date.startOf('week').valueOf()
        to = date.endOf('week').valueOf()
        c.currentRangeTarget.value = `Week ${date.week()}, ${date.format('MMMM YYYY')}`
        break
      case 'month':
        date = date.add(1, 'month')
        from = date.startOf('month').valueOf()
        to = date.endOf('month').valueOf()
        c.currentRangeTarget.value = `${date.format('MMMM YYYY')}`
        break
      case 'year':
        date = date.add(1, 'year')
        from = date.startOf('year').valueOf()
        to = date.endOf('year').valueOf()
        c.currentRangeTarget.value = `${date.format('YYYY')}`
        break
    }

    c.startDateValue = new Date(from).toISOString()
    c.endDateValue = new Date(to).toISOString()

    c.state.update('config.chart.time', (time) => {
      time.zoom = c.zoom
      time.from = from
      time.to = to
      return time
    })
    c.updateFeed()
  }

  zoomChange(period) {
    const c = this
    let date = c.gstc.api.time.date(new Date())
  
    c.zoom = 20
    let from = date.startOf('day').valueOf()
    let to = date.endOf('day').valueOf()
    c.modeValue = period
    switch (period) {
      case 'week':
        c.zoom = 16
        from = date.startOf('week').valueOf()
        to = date.endOf('week').valueOf()
        c.currentRangeTarget.value = `Week ${date.week()}, ${date.format('MMMM YYYY')}`
        break
      case 'month':
        c.zoom = 20
        from = date.startOf('month').valueOf()
        to = date.endOf('month').valueOf()
        c.currentRangeTarget.value = `${date.format('MMMM YYYY')}`
        break
      case 'year':
        c.zoom = 26
        from = date.startOf('year').valueOf()
        to = date.endOf('year').valueOf()
        c.currentRangeTarget.value = `${date.format('YYYY')}`
        break
    }
    c.startDateValue = new Date(from).toISOString()
    c.endDateValue = new Date(to).toISOString()

    c.state.update('config.chart.time', (time) => {
      time.zoom = c.zoom
      time.from = from
      time.to = to
      return time
    })
    c.updateFeed()
  }

  itemSlot(vido, props) {
    const c = this
    const { html, onChange, update } = vido
    let imageSrc = ''
    let description = ''
   
    onChange((newProps) => {
      props = newProps
      if (!props || !props.item) return
      imageSrc = props.item.img
      description = props.item.description
      update()
    })

    function onDoubleClick() {
      c.editSingleItem(props)
    }

    return (content) => {
      if (!props || !props.item) return content
      return html`
        <div class="item-text" @dblclick=${onDoubleClick} style="width: 100%">
          <div class="item-label"><span style="font-weight: 700">${content}</span> - ${description}</div>
        </div>`
    }
  }

  rowSlot(vido, props) {
    const { html, onChange, update, api } = vido
    onChange((newProps) => {
      props = newProps
      if (!props || !props.row) return
      update()
    })

    return (content) => {
      if (!props || !props.column) return
      let typeLabel = ''
      switch (props.row.type) {
        case 'avail':
          typeLabel = html`<div class='ui ${props.row.typeColor} tiny basic label'>${props.row.avail_type}</div>`
          break
        case 'transaction': {
            let formatLabel = html`<div class='ui tiny basic label'>${props.row.licenseType} ${props.row.formatProfile}</div>`
            let territoryLabels = props.row.territories.map((t) => html`<div class='ui tiny basic label'>${t.territory}</div>`)
            typeLabel = html`${formatLabel} ${territoryLabels}`
          }
          break
      }
      return api.sourceID(props.column.id) === 'label'
        ? html`
        <div class="${props.row.type} row">
          <div class="type">
            ${typeLabel}
          </div>
          <div class="title">
            <div class="main-title">${props.row.title}</div>
            <div class="additional-title">${props.row.additionalTitle}</div>
          </div
        </div>`
        : content
    }
  }

  mainOuterSlot(vido, props) {
    const { onChange, api, update, html, state, getElement } = vido
    onChange((changedProps) => {
      // if current element is reused to display other item data just update your data so when you click you will display right alert
      props = changedProps 
    })
  
    // let year = api.time.date(this.startDateValue).year()
    // let month = api.time.date(this.startDateValue).month()

    // const months = [
    //   'January',
    //   'February',
    //   'March',
    //   'April',
    //   'May',
    //   'June',
    //   'July',
    //   'August',
    //   'September',
    //   'October',
    //   'November',
    //   'December',
    // ]

    // let loading = ''
    // let overlay = ''

    // function updateTime() {
    //   if (loading) return

    //   const startTime = api.time
    //     .date(`${year}-${month + 1}-01`)
    //     .startOf('month')
    //     .valueOf()
    //   const endTime = api.time
    //     .date(`${year}-${month + 1}-01`)
    //     .endOf('month')
    //     .valueOf()
    //   loading = 'LOADING... You can load items from backend now.'
    //   overlay = 'overlay'
    //   setTimeout(() => {
    //     // if you have items you can change view
    //     state.update('config.chart.time', (time) => {
    //       time.from = startTime
    //       time.to = endTime
    //       //console.log(`${year}-${month + 1}-01`, `${year}-${month + 1}-01`)
    //       return time
    //     })
    //     loading = ''
    //     overlay = ''
    //   }, 250)
    // }
    // let listenerAdded = false

    return (content) => html`${content}`
  }

  onLevelDates({ dates, level, format }) {
    if (format.period !== 'day') return dates
    return dates.filter((date) => date.leftGlobalDate.day() !== 0 && date.leftGlobalDate.day() !== 6)
  }

  async displayAdjustmentModal(items, info) {
    const c = this
    try {
      let items_after = items.after.map((item, _i) => {
        return { 
          id: item.id, 
          model: item.model, 
          model_id: GSTC.api.sourceID(item.id),
          start: item.time.start,
          end: item.time.end,
          actual_start: item.time.actual_start,
          actual_end: item.time.actual_end,
        }
      })

      let selected_item_ids = info?.selectedIds?.map(gstc_id => GSTC.api.sourceID(gstc_id))
      let added_dependant_ids = info?.addedDependantIds?.map(gstc_id => GSTC.api.sourceID(gstc_id))

      const response = await post(`/avails/adjust_avails_modal`, {
        responseKind: "turbo-stream",
        body: JSON.stringify({ 
          items: items_after, 
          selected_item_ids: selected_item_ids,
          added_dependant_ids: added_dependant_ids,
         })
      })

      if (response.ok) {
        return items.after
      } else if (response.unprocessableEntity) {
        const toast = await response.text
        Turbo.renderStreamMessage(toast)
      } else {
        throw "should not happen"
      }
    } catch (error) {
      console.log(`adjust modal error ${error}`)
    }
    c.changed_items = null
    return items.initial
  }

  async revert(items = this.changed_items) {
    const c = this
    if (c.changed_items) {
      c.changed_items = null
      c.state.update('config.chart.items', (current_items) => {
        for (const item of items.initial) {
          current_items[item.id] = item
        }
        return current_items
      })
      
      c.updateAncestors()
      c.gstc.state.update('config', (config) => { return config })
    }
  }

  async clearFilter(event) {
    for (const element of this.filterAreaTarget.elements) {
      const e = $(element)
      if (element.type === 'checkbox' || element.type === 'radio') {
        element.checked = false
      } else if (element.tagName === 'INPUT') {
        element.value = ''
      } else if (element.tagName === 'SELECT') {
        const dropdown_module = e.parent('.dropdown').data('module-dropdown')
        if (dropdown_module) {
          dropdown_module.clear()
        } else {
          e.selectedIndex = -1
        }
        e.val('')
      }
      e.trigger('change')
    }
    this.filterCountTarget.textContent = '0'
  }

  isCollision(item) {
    const all_items = this.gstc.api.getAllItems()
    for (const itemId in all_items) {
      if (itemId === item.id) continue
      const currentItem = all_items[itemId]
      if (currentItem.rowId === item.rowId) {
        if (item.time.start >= currentItem.time.start && item.time.start <= currentItem.time.end) return true
        if (item.time.end >= currentItem.time.start && item.time.end <= currentItem.time.end) return true
        if (item.time.start <= currentItem.time.start && item.time.end >= currentItem.time.end) return true
        if (item.time.start >= currentItem.time.start && item.time.end <= currentItem.time.end) return true
      }
    }
    return false
  }

  recalculateParentTimes(item, items) {
    let current_parent_id = item.parentId
    while (current_parent_id && Object.prototype.hasOwnProperty.call(items, current_parent_id)) {
      const parentItem = items[current_parent_id]
      if (parentItem.model === 'Avail') {
        parentItem.time.start = Math.min(parentItem.time.start, item.time.start)
        parentItem.time.end = Math.max(parentItem.time.end, item.time.end)
        parentItem.time.actual_start = Math.min(parentItem.time.actual_start, item.time.actual_start)
        parentItem.time.actual_end = Math.max(parentItem.time.actual_end, item.time.actual_end)
      }
      current_parent_id = parentItem.parentId;
    }
  }

  updateAncestors(selectedIds = [], items_after) {
    const c = this
    const all_items = c.gstc.api.getAllItems()
    for (const item_id in all_items) {
      const item = all_items[item_id]
      if (item.model === 'Avail') {
        if (!selectedIds.includes(item_id)) {
          const { min_start, max_end } = c.findMinMaxTimes(item_id, selectedIds, items_after)          
          if (min_start) item.time.start = min_start
          if (max_end) item.time.end = max_end
        }
      }
    }
  }

  editSingleItem({ _row, item, _itemData }) {

    this.changed_items = { initial: [item], after: [item] }
    this.debounce(this.displayAdjustmentModal.bind(this), 500)(this.changed_items)
    console.log('clicked')
  }

  findMinMaxTimes(item_id, selectedIds, items_after) {
    const c = this
    const item = items_after?.find(i => i.id === item_id) ?? c.gstc.api.getItem(item_id)
    const is_selected = selectedIds.includes(item_id)

    switch (item.model) {
      case 'Avail':
        if (!item.children) {
          if (is_selected) return {
            min_start: item.time.start,
            max_end: item.time.end
          }
        }
        return item.children.reduce((acc, child_id) => {
          let { min_start, max_end } = c.findMinMaxTimes(child_id, selectedIds, items_after)

          min_start = Math.min(acc.min_start ?? min_start, min_start ?? acc.min_start)
          max_end   = Math.max(acc.max_end   ?? max_end, max_end     ?? acc.max_end)

          if (is_selected) {
            min_start = Math.min(min_start, item.time.start)
            max_end   = Math.max(max_end,   item.time.end)
          }

          return {
            min_start: min_start,
            max_end: max_end
          }
        }, {})
      case 'AvailTransaction':
        return { 
          min_start: item.time.start, 
          max_end: item.time.end 
        }
      default:
        return {}
    }
  }

  updateItems(avail_transactions) {
    const c = this
    c.state.update('config.chart.items', (current_items) => {
      for (const tr of avail_transactions) {
        const id = c.GSTCID(String(tr.id))
        const current_item = current_items[id]
        if (current_item && current_item.model === 'AvailTransaction') {
          current_item.time = {
            actual_start: c.date(tr.start).valueOf(),
            actual_end: c.date(tr.end).valueOf(),
            start: c.date(tr.start).startOf('day').valueOf(),
            end: c.date(tr.end).endOf('day').valueOf(),
          }
          current_items[id] = current_item
        }
      }
      return current_items
    })
    c.updateAncestors()
    c.gstc.state.update('config', (config) => { return config })
  }
}
