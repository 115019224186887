import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", "output" ]

  connect() {
    //console.log("summarize")
  }

  send() {
    let button = $(this.inputTarget).find("button.summarize")
    button.addClass("disabled loading")

    let textarea = $($(this.inputTarget).find("textarea")[0]).val()
    let textareas = $(this.outputTargets).length

    $(this.outputTargets).each(async function(i, output) {
      let output_textarea = $($(output).find("textarea")[0])
      let max_length = output_textarea.attr("maxlength")
      let url = '/datamovers/endpoint.json'
      let body = { host: "https://datamover4.imagion.de", path: "text_summarize.json", parameter: { text: textarea, min_length: 60, max_length: max_length } }

      $.ajax({
        url: url,
        type: "post",
        data: body,
      }).done(function(data) {
        output_textarea.val(data.result)
        textareas -= 1

        if (textareas == 0) {
          button.removeClass("disabled loading")
        }
      }).fail(function() {
        console.log("failure to load response")
      })
    })
  }
}
