document.addEventListener("turbo:load", function() {
  if ($("#qc_requests").length > 0) {
    $('#qc_requests .dropdown').dropdown({ placeholder: false, fullTextSearch: true })
    
    search.init("/qc_requests")
    qc_requests.init()
    qc_requests.load_remote_cards()
    qc_requests.init_edit_qc_element_modal()
    search.asset_autocompletion(".ui.search.qc-request-asset");
  }
  $('#qc_element .with-tooltip').popup({
  });
})
    
var qc_requests = {
  init() {
    calendar.datetimepicker($("#qc_requests"))
    $(".playbutton").on('click', function() {
      console.log("play")
      $('.ui.accordion.videoplayer').accordion({
        onOpening() {
          if ($('#player-frame').length > 0) {
            playerTools.insertVideo()
          }
        },
        onClose() {
          if ($('#player-frame').length > 0) {
            playerTools.removeVideo()
          }
        }
      })
      $('.ui.accordion.videoplayer').accordion("toggle", 0)

      $('#qc_requests .with-tooltip').popup({
      });
    }),

    qc_requests.init_dropdowns()
    qc_requests.nested_fields()
  },
  init_dropdowns() {
    $(".search-field.user.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
    $(".priority.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
  },

  nested_fields() {
    $("#qc_requests").off("fields_added.nested_form_fields.nested_qc_request_qc_elements")
    $("#qc_requests").on("fields_added.nested_form_fields.nested_qc_request_qc_elements", function(event, param) {
      qc_requests.set_qc_element_data(event.target, param.additional_data)
    })
    qc_elements.nested_fields()
  },

  init_index_body() {
    $('#qc_requests .quicklook').on("click", function() {
      assets.quicklook_asset($(this).data('asset-id'))
    })
  },

  // remote nested fields
  load_remote_cards() {
    const promises = [];
    $('.remote.qc-element.card:not([data-qc-element-id=""])').each( (_i, card) => 
      promises.push( qc_requests.qc_element_card( $(card).data('qc-element-id'), $(card).data('qc-element-index') ).done( (html) => $(card).html(html) ))
    )
    $.when.apply($, promises).then( () =>
      context_menu.init_menu()
    )
    const a_ID = $('.asset-id-input').val()
    qc_requests.update_asset_card(a_ID)
  },

  qc_element_card(qce_id, index) {
    return $.ajax({
        url: `/qc_requests/qc_element_card`,
        type: "GET",
        data: {
            qce_id: `${qce_id}`,
            nested_field_index: `${index}`,
        }
    });
  },

  // set_qc_element_data(target, data) {
  //   if (typeof(data) !== 'undefined') {
  //     data.nested_field_index = `${$(target).find(".qc-element-id-display").data("qc-element-index")}`
  //     $.ajax({
  //       url: `/qc_requests/qc_element_card`,
  //       data: data
  //     }).done(function(result) {
  //         $(target).find(".qc-element-id-display").append(result)
  //         $(target).find(".qc-elelement-id").val(data.qce_ID)
  //         context_menu.init_menu()
          
  //     }).fail(function(error) {
  //         console.log(error.responseText);
  //     });
  //   }
  // },

  show_qc_element_segment() {
    $("#qc_requests .qc.element.segment").show()
  },

  update_asset_card(asset_ID) {
    if (typeof asset_ID == 'undefined' || asset_ID == "") { return }
    $('.asset-id-input').val(parseInt(asset_ID))
    assets.asset_card(parseInt(asset_ID), "list").done( html => $('.qc-request-asset.card').html(html) )
  },

  init_edit_qc_element_modal() {
    if ( !$('#qc_request_qcr_a_ID')?.val()?.length) {
      $('.qc.element.segment').hide()
    }
    $('.add.qc-element.button').on('click', function() {
      $(this).addClass('disabled')
      $(this).find('i').removeClass('plus').addClass('notched circle loading')
      qc_requests.edit_qc_element()
    })
  },

  edit_qc_element(qce_id) {
    const qcr_id = $('#qc_request_ID').val();
    var DATA = {
      asset_id: $('#qc_request_qcr_a_ID').val()
    }

    let url = `/qc_requests/qc_element`

    if (typeof qce_id !== "undefined") {
      DATA.qce_id = qce_id
    }

    if (qcr_id != undefined) {
      url = `/qc_requests/${qcr_id}/qc_element`
    }
      
    $.ajax({
      url: url,
      data: DATA,
      type: "GET",
    }).done(function(data) {
      $('.add.qc-element.button').find('i').addClass('plus').removeClass('notched circle loading')
      $("body").append(data)
      $('.edit_qc_element.modal').modal({
        observeChanges: true,
        autofocus: false,
        onShow() {
          $('form.edit_qc_element')
            .form({
              fields: {

              },
              onSuccess:function(event){
                  event.preventDefault();
                  let form_data = $(this).serializeObject()
                  let qce_id = form_data['qc_element[qce_ID]']
                  if (typeof(qce_id) === "undefined" || qce_id === "undefined" || qce_id === "") {
                    $('.qc-element .add_nested_fields_link').trigger('click', [form_data])
                  } else {
                    const target = $(`[data-qce-id=${qce_id}]`).parents('fieldset')
                    qc_requests.set_qc_element_data(target, form_data)
                  }

                  $('.edit_qc_element.modal').modal('hide')
              }
            })
          return qc_requests.init_modal_dropdowns()
        },
        onHidden() {
          $('.edit_qc_element.modal').remove()
          $('.add.qc-element.button').removeClass('disabled')
        }
      }).modal('show');
    }).fail(function() {
      $('.add.qc-element.button').removeClass('blue').addClass('red').text("Error")
    })
  },

  // clone_qc_element(qce_id) {
  //   $.ajax({
  //     url: `/qc_elements/${qce_id}/clone`,
  //     type: "POST",
  //   }).done(function(data) {
  //     $('.qc-element .add_nested_fields_link').trigger('click', [{ qce_id: data.qc_element.qce_ID }])
  //   });
  // },

  init_modal_dropdowns() {
    qc_elements.init_dropdowns()
  }, 

  set_qc_element_data(target_element, data) {
    let target = $(target_element)
    if (data !== undefined) {
      data.qce_id = `${data.qce_id}`
      data.nested_field_index = `${target.find(".qc-element-id-display").data("qc-element-index")}`
      $.ajax({
        url: `/qc_requests/qc_element_card`,
        data: data
      }).done(function(result) {
        
        target.find('.qc-element-id-display').attr('data-qc-element-id', data.qce_id)
        target.find('.qc-element-id-display').html(result)
        let hidden_form_fields = target.find('.hidden_form_fields')
        if (typeof(data.qce_id) === 'undefined' || data.qce_id === 'undefined') {
          for (const key in data) {
            let field_name = `${key}`.split('qc_element').pop()
            hidden_form_fields.find(`input[name$='${field_name}']`).val(data[key] === 'undefined' ? undefined : data[key]).removeAttr("disabled")
          }
        }
        $(".remove.qc_element.button").on('click', function(e) {
          $(this).parents('.qc-element.card').siblings('.remove_nested_fields_link').trigger('click')
          console.log(e)
        })
        context_menu.init_menu()
      }).fail(function(error) {
          console.log(error.responseText);
      })
    }
  },

  set_status_modal(qc_request_ids) {
    $.ajax({
      url: `/qc_requests/set_status_modal`,
      data: {
        qc_request_ids: qc_request_ids
      },
    }).done(function(data) {
      $(data).appendTo('body')
      let set_status_modal = $('#qc_request_set_status_modal')
      set_status_modal.modal({
        observeChanges: true,
        autofocus: false,
        closable: false,
        onShow() {
          qc_requests.init_set_status_modal(set_status_modal)
        },
        onHidden() {
          set_status_modal.remove()
        }
      })
      set_status_modal.modal('show')
    }).fail(function(_xhr, _textStatus, errorThrown) {
      if (errorThrown != 'abort') { console.log(errorThrown) }
    })
  },

  init_set_status_modal(set_status_modal) {
    let change_status_dropdown = set_status_modal.find('.ui.change_status.dropdown').not('.remote').dropdown()
    let assign_to_dropdown = set_status_modal.find('.ui.assign_to.dropdown').dropdown({
      minCharacters: 1,
      delay: {search: 300},
      apiSettings: {
        cache: false,
        url: '/user_profile_webs.json?q={/query}&search_input={/query}'
      },
      templates: {
        menu: dropdownTemplates.search_menu
      }
    })
    calendar.datepicker(".due_date.field", { position: 'top left', lastResort: 'top left' } )

    let apply_button = set_status_modal.find('.apply.button')
    apply_button.on('click', function() {

      var data = {}
      let qc_request_ids = set_status_modal.find('.ui.form').data('qcRequestIds')
      let state = change_status_dropdown.dropdown('get value')
      let assign_to = assign_to_dropdown.dropdown('get value')
      let due_date = set_status_modal.find('#due_date').val()
  
      if (qc_request_ids?.length > 0) { data.qc_request_ids = qc_request_ids }
      if (state !== '') { data.status = state }
      if (assign_to !== '') { data.assign_to = assign_to }
      if (due_date !== '') { data.due_date = due_date }
      $.ajax({
        url: `/qc_requests/change_status`,
        type: "POST",
        dataType: "json",
        data: data,
        beforeSend() {
          apply_button.addClass('loading')
        }
      }).done(function(_data, _textStatus, _xhr) {
        set_status_modal.modal('hide')
        $('body').toast({
          title: "Status Change Success!",
          class: 'success',
          displayTime: 3000,
          message: "QC Requests / QC Elements successfuly updated!"
        })
        $(".start-search .primary.button").trigger('click')

      }).fail(function(_jqXHR, _textStatus, errorThrown) {
        let status_modal_error_message = set_status_modal.find('#status_modal_error_message')
        status_modal_error_message.append(`<p>An unknown error occured!</p>`)
        status_modal_error_message.append(`<p>Error: ${errorThrown}</p>`)
        status_modal_error_message.removeClass('hidden')
        status_modal_error_message.addClass('visible')
      }).always(() => apply_button.removeClass('loading'))
    })
  },

  destroy_selected(qc_request_ids) {
    if (confirm("Do you really want to destroy the selected qc requests?")) {
      $.ajax({
        url: `/qc_requests/destroy_selected`,
        type: "POST",
        dataType: "json",
        data: {
          qc_request_ids: qc_request_ids
        },
      }).done(function() {
        $('body').toast({
          title: "Success!",
          class: 'success',
          displayTime: 3000,
          message: "QC Requests successfuly destroyed!"
        })
        $(".start-search .primary.button").trigger('click')
      }).fail(function() {
        $('body').toast({
          title: "Could not destroy selected qc requests!",
          class: 'error',
          displayTime: 0,
          message: "Unassign of qc requests failed"
        })
      })
    }
  },

  unassign_qc_elements(qc_request_ids) {
    if (confirm("Do you really want to completely unassign the qc elements of these qc requests? All entered data and issues will be deleted.")) {
      $.ajax({
        url: `/qc_requests/unassign_qc_elements`,
        type: "POST",
        dataType: "json",
        data: {
          qc_request_ids: qc_request_ids
        },
      }).done(function() {
        $('body').toast({
          title: "QC Elements unassigned!",
          class: 'success',
          displayTime: 3000,
          message: "Unassign of qc elements succeeded"
        })
      }).fail(function() {
        $('body').toast({
          title: "Could not unassign!",
          class: 'error',
          displayTime: 3000,
          message: "Unassign of qc elements failed"
        })
      })
    }
  },

  context_menu() {
    $('.edit.qc_element.item').on('click',  function(_eventData) {
      var qce_id = $(this).data('qc-element-id')
      qc_requests.edit_qc_element(qce_id)
    }),

    $('.clone.qc_element.item').click ( function(_eventData) {
      var qce_id = $(this).data('qc-element-id')
      qc_requests.clone_qc_element(qce_id)
    }),

    $(".delete.qc_element.item").on('click', function() {
      var qce_id = $(this).data('qc-element-id')
      $.ajax({
        url: `/qc_elements/${qce_id}`,
        type: 'post',
        data: {_method: 'delete'},
      })
      .done(function (_data) { 
        console.log('deleted qc element')
        // $(`fieldset:has(div.qc-element.card[data-qc-element-id=${qce_id}]) .remove_nested_fields_link`).trigger('click')
        $(`fieldset:has(div.qc-element.card[data-qc-element-id=${qce_id}])`).remove()
      })
      .fail(function (_jqXHR, textStatus, errorThrown) { 
        console.log('error')
        console.log(errorThrown)
        console.log(textStatus)
      });
    })
  }

}

window.qc_requests = qc_requests
