var workflow_50018 = {
  init(_target, params_id) {
    
    $('.ui.dropdown.noselection').dropdown({
      placeholder: false,
      action: "hide",
      onHide : function() {
        $(this).dropdown('clear')
      },
    })

    $(".ui.search.asset-project").search({
      minCharacters: 3,
      apiSettings: {
        url: '/projects/search_dropdown?q={/query}',
        data: {
          contact_filter: $(`#contact_filter_${params_id}`).prop('checked'),
          contact_id: $(`#asset_a_c_ID_${params_id}`).val(),
          territory_id: $(`#search_project_territory_${params_id}`).dropdown('get value')
        },
        beforeSend(settings) {
          settings.data.contact_filter = $(`#contact_filter_${params_id}`).prop('checked');
          settings.data.contact_id = $(`#asset_a_c_ID_${params_id}`).val();
          settings.data.territory_id = $(`#search_project_territory_${params_id}`).dropdown('get value')
          return settings;
        }
      },
      templates: {
        standard: search.multi_asset_project_search
      },
      onSelect(result, _response) {
        $('.asset-project .add_nested_fields_link').trigger('click', [{ project: result }]);
        $(".ui.search.asset-project").search("set value", "");
        $(".ui.search.asset-project").search("hide results");
        return false;
      },
      onResults(response) {
        return response.results;
      }
    });
    // $(`#contact_filter_${params_id}`).change(() => $(".ui.search.asset-project").search("search remote", $(".ui.search.asset-project").search("get value")));

    $("#multi-asset-projects").off("fields_added.nested_form_fields.nested_asset_asset_projects")
    $("#multi-asset-projects").on("fields_added.nested_form_fields.nested_asset_asset_projects", function(event, param) {
      switch (param.object_class) {
        case "asset_project":
          workflow_50018.field_added(event,param)
          break
        default:
          console.log("Fields were successfully added, callback not handled.")
          break
      }
    });

    $('.search.dropdown.project.territory').dropdown({
      apiSettings: {
        url: '/assets/territories?q={/query}'
      },
      templates: {
        menu: dropdownTemplates.project_territory_search_menu
      }
    })

  },

  field_added(event, param) {
    assets.set_project_data(event.target, param.additional_data)
    workflow_50018.init_nested_whparams($(event.target)) 
  },

  init_nested_whparams(element, _params_id) {
    let nested_whparams = {}
    for (let params of Array.from($(element).find('.params-input').not('.nested_wj_Params'))) {
      var params_value
      if ($(params).is(':checkbox')) {
        params_value = $(params).prop('checked');
      } else {
        params_value = $(params).val();
      }
      nested_whparams[`${$(params).attr('id')}`] = params_value
      $(params).change(function() {
        workflow_50018.update_nested_whparams(element)
      })
    }
    nested_whparams = JSON.stringify(nested_whparams, null, null)
    $(element).find('.nested_wj_Params').val(nested_whparams)
    workflow.update_whparams($(element.closest('fieldset.nested_workflow_workflow_jobs')))
  },

  update_nested_whparams(element) {
    console.log('updated nested')
    let nested_whparams = {}
    for (let params of Array.from($(element).find(".params-input").not('.nested_wj_Params'))) {
      var hash, params_value;
      if ($(params).is(':checkbox')) {
        params_value = $(params).prop('checked');
      } else if ($(params).hasClass('dropdown')) {
        params_value = $(params).find("select").val();
      } else {
        params_value = $(params).val();
      }
      if ($(params).hasClass('dropdown')) {
        hash = { id: $(params).find("select").attr('id'), uuid: $(params).find("select").data('uuid') , value: params_value };
      } else {
        hash = { id: $(params).attr('id'), uuid: $(params).data('uuid') , value: params_value };
      }
      nested_whparams[hash.id] = hash.value
    }
    nested_whparams = JSON.stringify(nested_whparams, null, null)
    $(element).find('.nested_wj_Params').val(nested_whparams)
    workflow.update_whparams($(element.closest('fieldset.nested_workflow_workflow_jobs')))
  }
};

window.workflow_50018 = workflow_50018;
