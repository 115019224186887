document.addEventListener("turbo:load", function() {
  if ($("#asset_storage_search").length > 0) {
    $('.with-tooltip').popup({
      delay: {
        show: 800,
        hide: 300
      }
    });
        
    search.init("/asset_storage");

    $('#asset_storage_search .dropdown').not('.additional').dropdown({fullTextSearch: true});

    $('#asset-search .additional.dropdown').dropdown({
      allowAdditions: true,
      fullTextSearch: true
    });
    calendar.datepicker("#asset_storage_search")
  }
});
