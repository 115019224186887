document.addEventListener("turbo:load", function() {
  if ($("#file_locations.index").length > 0) {
    search.init("file_locations")
  }

  if ($("#file_locations.edit").length > 0) {
    $("#file_locations .dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
    general.hover_link("asset_transfer_accounts")
  }
});
