document.addEventListener("turbo:load", () => api.init());

var api = {
  init() {
    return $('.api.dropdown').dropdown({
      apiSettings: {
        cache: false,
        beforeSend(settings) {
          const value = $(this).data('value')
          const name = $(this).data('name')
          if ($(this).data('params')) {
            settings.onResponse = function(JsonResponse) {
              const response = {
                success: true,
                results: []
              }
              $.each(JsonResponse, function(index, item) {
                if (typeof item === 'object') { return response.results.push({ value: item[value], name: item[name] }); }
              })
              return response;
            }
            $.extend(settings.urlData, $(this).data('params'))
          }
          return settings;
        }
      }
    })
  }
}

window.api = api;

$.fn.api.settings.cache = false;
$.fn.api.settings.api = {
  'get assets': '/assets.json?search_input={query}&sort={/sort}',
  'get users': '/users.json?search_input={query}&sort={/sort}',
  'get clients': '/contacts.json?search_input={query}&sort={/sort}&client=true&active=true',
  'get all clients': '/contacts.json?search_input={query}&sort={/sort}',
  'get titles': '/titles.json?search_input={query}&sort={/sort}&direction=asc',
  'get content_partners': '/contacts.json?search_input={query}&sort={/sort}&content_partner=true',
  'get fulfillment_partners': '/contacts.json?search_input={query}&sort={/sort}&fulfillment_partner=true',
  'get distribution_channels': '/distribution_channels.json?search_input={query}&sort={/sort}',
  'get distribution_channel_types': '/distribution_channel_types/channel_types?q={/query}',
  'get suppliers': '/contacts/suppliers?q={/query}',
  'get asset_tags': '/asset_tags/asset_tags?search_input={query}&sort={/sort}&search=true',
  'get codecs': '/assets/codecs?search_input={query}&sort={/sort}&search=true',
  'get formats': '/assets/formats?search_input={query}&sort={/sort}',
  'get all formats': '/assets/formats?search_input={query}&sort={/sort}&type=all',
  'get media_portals': '/media_portals/filter_portals?search_input={query}&sort={/sort}&direction=asc&checked={/checked}&client_id={client_id}&asset_id={asset_id}',
  'get asset_spec_type_ids': '/asset_spec_types.json?search_input={query}&sort={/sort}&direction=asc&checked={/checked}&search_asset_types={asset_type_id}',
  'get user profile webs': '/user_profile_webs.json?search_input={query}&sort={/sort}',
  'get vidchecker_alert_types': '/assets/events/vidchecker_alert_types',
  'get vidchecker_alert_levels': '/assets/events/vidchecker_alert_levels',
  'get language_codes': '/languagecode?q={query}',
  'get metadata_mapping_contact': '/metadata_mapping_contacts/dropdown_search.json?search_input={query}&uniq_by_channel=true&sort={/sort}&direction=asc',
  'save qc event': '/qc_elements/{id}/save_qc_event',
  'save qc element': '/qc_requests/{id}/save_qc_element',
  'generate asset requests': '/asset_requests/generate'
};
