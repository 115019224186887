let Utils = function() {};

Utils.prototype = {
  constructor: Utils,
  isElementInView(element, fullyInView) {
    const pageTop = $(window).scrollTop();
    const pageBottom = pageTop + $(window).height();
    const elementTop = $(element).offset().top;
    const elementBottom = elementTop + $(element).height();
    if (fullyInView === true) {
      return (pageTop < elementTop) && (pageBottom > elementBottom);
    } else {
      return (elementTop <= pageBottom) && (elementBottom >= pageTop);
    }
  },
  
  uuidv4() {
    return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  }
  
};
Utils = new Utils;

window.utils = Utils;
