document.addEventListener("turbo:load", function() {
  if ($("#assets-import.import").length > 0) {
    asset_import.init()
  }
})

var asset_import = {
  init() {
    let location_id = 0
    if ($(".import.storagelocation.dropdown").length > 0) {
      location_id = $(".import.storagelocation.dropdown").dropdown("get value")
    }
    
    asset_import.get_folder({path: "", location_id: location_id, element: $("#assets-import.import .filelist")})

    $('#assets-import .with-tooltip').popup({
      inline: true,
      exclusive: true,
      delay: {
        show: 500,
        hide: 0
      }
    })
  },

  sort_file_tree(sort_by, direction) {
    $(".asset.selection.list,.asset.import.list").each(function(){
      $(this).html($(this).children('.asset.import.item').sort(function(a, b){
        if (direction === "desc") {
          return ($(b).data(sort_by)) > ($(a).data(sort_by)) ? 1 : -1
        } else {
          return ($(b).data(sort_by)) < ($(a).data(sort_by)) ? 1 : -1
        }
      }))
    })
    asset_import.init_file_tree_actions()
  },

  init_file_tree() {
    $('.sort_by.dropdown').dropdown({
      onChange(value, _text, _$choice) {
        asset_import.sort_file_tree(value, $('#sort_direction_indicator').is('.down') ? 'desc' : 'asc')
      }
    })
    asset_import.init_file_tree_actions()

    $(".import.storagelocation.dropdown").dropdown({
      onChange(_value, _text, _$selectedItem) {
        asset_import.get_folder({path: "", location_id: $(".import.storagelocation.dropdown").dropdown("get value"), element: $("#assets-import.import .filelist")})
      }
    })
    $("#assets-import.import .location").on('click', function() {
      asset_import.load_folder($(this))
    })
  },

  init_file_tree_actions() {
    $('#sort_direction_indicator').off('click')
    $('#sort_direction_indicator').on('click', function(_e) {
      $(this).toggleClass("down up")
      asset_import.sort_file_tree($('.sort_by.dropdown').dropdown('get value'), $('#sort_direction_indicator').is('.down') ? 'desc' : 'asc')
    })

    $('.confirm.asset.import.button').off('click')
    $('.confirm.asset.import.button').on('click', function() {

      if ($('.asset.import.with-tabs').find(".active.tab").data("tab") == "external_import") {
        let list_of_urls = $('.asset.url.list').val().split(/[\s,\r\n]+/).filter(String)
        if (list_of_urls.length > 0) {
          
          asset_import.import_asset(list_of_urls)
        } else {
          $('body').toast({
            title: 'No URLs added!',
            class: 'error',
            displayTime: 'auto',
            message: "Please enter some URLs first."
          })
        }
      } else {
        let import_paths = $('.asset.import.item.selected').toArray().filter(function(x) {
          return !($(x).parents('.asset.import.folder.item').first().is('.selected'))
        }).map(x => $(x).data('path')).unique()
        if (import_paths.length > 0) {
          asset_import.import_asset(import_paths)
        } else {
          $('body').toast({
            title: 'No Files Selected!',
            class: 'error',
            displayTime: 'auto',
            message: "Please make a selection first."
          })
        }
      }
    })
    
    $('.asset.import.file.item').off('click')
    $('.asset.import.file.item').on('click', function(e) {
      var x = e.pageX - $(this).offset().left
      if (x >= 69) {
        $(this).toggleClass('selected')
        // eslint-disable-next-line no-empty
        if ($(this).hasClass('selected')) { } else {
          $(this).parents('.asset.import.folder.item[class~="selected"]').removeClass('selected')
        }
        e.stopPropagation()
      }
    })
    $('.asset.import.folder.item').off('click')
    $('.asset.import.folder.item').on('click', function(e) {
      const target = $(e.target)
      var x = e.pageX - $(this).offset().left
      if (target.is('.caret.icon') || (x >= 45 && x <= 68)) {
        let folder_contents = $(this).find('.asset.import.list').first()
        
        if (!folder_contents.is('.loaded')) {
          asset_import.get_folder({path: $(this).data('folder'), location_id: $(".import.storagelocation.dropdown").dropdown("get value"), element: folder_contents, is_subfolder: true})
        }

        if (folder_contents.css('max-height') !== "0px") {
          folder_contents.css('max-height', null)
        } else {
          folder_contents.css('max-height', 'none')
        } 
        
        folder_contents.toggle()
        if (folder_contents.is(':visible')) {
          target.removeClass('right')
          target.addClass('down')
        } else {
          target.removeClass('down')
          target.addClass('right')
        }

      } else if (x > 68 && $(this).data("import-allowed") ) {
        $(this).toggleClass('selected')
        if ($(this).hasClass('selected')) {
          $(this).find('.asset.import.item:not([class~="selected"])').addClass('selected')
        } else {
          $(this).find('.asset.import.item[class~="selected"]').removeClass('selected')
          $(this).parents('.asset.import.folder.item[class~="selected"]').removeClass('selected')
        }
      }
      e.stopPropagation()
    })

    $(".importelement").off('click')
    $(".importelement").on('click', function() {
      asset_import.import_asset([$(this).parent().data("path")])
    })

    $('.asset.import.folder.item').off('dblclick')
    $('.asset.import.folder.item').on('dblclick', function(e) {
      var x = e.pageX - $(this).offset().left
      // eslint-disable-next-line no-empty
      if ($(e.target).is('.caret') || x <= 27 ) {
      } else {
        asset_import.load_folder($(this))
        e.stopPropagation()
        e.preventDefault()
      }
    })
  },

  get_folder({path: path, location_id: location_id, element: element, is_subfolder: is_subfolder = false, loader: loader}) {
    let directory_dimmer = $(element).find('.ui.directory.dimmer')
    let sort_by_value = $('.sort_by.dropdown').dropdown('get value')
    let sort_by = sort_by_value.length > 0 ? sort_by_value : 'name'
    $.ajax({
      url: "/assets/import/folder",
      type: "GET",
      data: {
        path: path,
        location_id: location_id, 
        sort: sort_by,
        direction: ($('#sort_direction_indicator').is('.down') ? 'desc' : 'asc'),
        is_subfolder: is_subfolder
      },
      beforeSend() {
        directory_dimmer.addClass('active')
      }
    }).done(function(data, _textStatus, _xhr) {
      element.html(data)
      if (is_subfolder) {
        asset_import.init_file_tree_actions()
      } else {
        asset_import.init_file_tree()
      }
    }).fail(function(jqXHR, _textStatus, errorThrown) {
      console.log(errorThrown)
      if (errorThrown == "Unprocessable Entity") {
        $('filelist .ui.asset.selection.list').html("<div class='ui red basic label'>" + jqXHR.responseJSON.message + "</div>")
      } else {
        $('.filelist .ui.asset.selection.list').html("<div class='ui red basic label'>Loading Error</div>")
      }
      return 
    }).always(function() {
      directory_dimmer.removeClass('active')
      $(loader).remove()
    })
  },

  load_subfolder(element) {
    var loader = element.find('.active.loader')
    if (!loader.length)
      loader = $('<div class="ui active inline mini loader" style="margin-left: 30px;"></div><br>').appendTo(element)

    asset_import.get_folder({path: element.data("folder"), location_id: $(".import.storagelocation.dropdown").dropdown("get value"), element: $("#assets-import.import .filelist"), is_subfolder: true, loader: loader})
  },

  load_folder(element, _is_subfolder = false) {
    var loader = element.find('.active.loader')
    if (!loader.length)
      loader = $('<div class="ui active inline mini loader" style="margin-left: 30px;"></div><br>').appendTo(element)

    asset_import.get_folder({path: element.data("folder"), location_id: $(".import.storagelocation.dropdown").dropdown("get value"), element: $("#assets-import.import .filelist"), loader: loader})
  },

  import_asset(filenames) {

    let import_button = $('.confirm.asset.import.button')
    let breadcrump_dimmer = $('<div class="ui active inverted dimmer"></div>').appendTo("#assets-import.import .filelist .import.breadcrumb")
    let import_dimmer = $('<div class="ui active inverted dimmer"><div class="ui text loader">Processing</div></div>').appendTo('.asset.import.segment.with-tabs')
    
    return $.ajax({
      url: "/buildjobfromimport",
      type: "post",
      dataType: "json",
      data: {
        filenames
      },
      beforeSend(_jqXHR, _settings) {
        import_button.addClass("disabled")
      },
    }).done(function(data, _textStatus, _xhr) {
      let retrieve = $('.ui.retrieve.checkbox').checkbox('is checked')
      let new_asset_url = `/assets/new?jobid=${data.id}`
      if (retrieve) {
        new_asset_url += `&retrieve=${retrieve}` 
      }
      window.location.replace(new_asset_url)
    }).fail(function(jqXHR, _textStatus, errorThrown) {
      console.log(jqXHR.status)
      console.log(`Import Error: ${errorThrown}`)

      let error_msg = errorThrown
      if (jqXHR.responseText) {
        error_msg = `${errorThrown}: ${jqXHR.responseText}`
      }

      $.ajax({
        url: "/message",
        type: "get",
        data: {
          body: error_msg,
          type: 'error'
        },
      }).done(function(data, _textStatus, _xhr) {
        $(".body.segment").prepend(data)
        general.message_closable()
      }).fail(function(jqXHR, _textStatus, _errorThrown) {
        console.log(jqXHR.status)
      })
    }).always(function() {
      import_button.removeClass("disabled")
      breadcrump_dimmer.remove()
      import_dimmer.remove()
      $("#assets-import.import .filelist").find(".dimmer").remove()
    })
  }
}

window.asset_import = asset_import
