import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'modal',
    'artworkColumn',
  ]

  static values = {
    distributionChannelId: Number
  }

  connect() {
  }

  displaySpecConform() {
    this.artworkColumnTargets.forEach(column => {
      if (column.dataset.specConform === 'true') {
        column.style.display = ''
        column.disabled = false
      } else {
        column.disabled = true
        column.style.display = 'none'
      }
    })
  }
  
  displayAll() {
    this.artworkColumnTargets.forEach(column => {
      column.style.display = ''
      column.disabled = false
    })
  }

  close(event) {
    if (event.detail.success && !(this.hasCreateMultipleArtworksTarget && this.createMultipleArtworksTarget.checked)) {
      $(this.modalTarget).modal('hide')
    }
  }
}