const search_settings = {
  init(class_name) {
    $(".search.settings.quickmenu").on('click', function() {
      search_settings.open_quickmenu(class_name)
    })
  },

  open_quickmenu(class_name) {
    $('body').dimmer({
      dimmerName: "quickmenu",
      onShow() {
        $(".ui.dimmer.quickmenu").append("<div id='quickmenu' class='open animate__animated animate__slideInRight'><div class='quickmenu header'></div><div class='quickmenu body'><span class='center'><br><br><br><div class='fa-3x'><i class='far fa-circle-notch fa-spin'></i></div></span></div></div>");
        $("#quickmenu .quickmenu.header").append("<h3 class='ui header'><i class='fas fa-history fa-fw fa-sm icon' aria-hidden='true'></i> Search History</h3>");

        $(".context.bar .search.bar.filter .remote.dropdown").each(function() { 
          $(this).trigger('click')
        })
        $(".context.bar .search.bar.filter .segment")[0].scrollTop = 0

        search_settings.load_index(class_name);
      },
      onHide() {
        $("#quickmenu").removeClass("animate__animated animate__slideInRight")
        $("#quickmenu").addClass("animate__animated animate__slideOutRight")
        setTimeout(function() {
          $(".ui.dimmer.quickmenu").remove();
          $("body").removeClass("dimmable dimmed")
        }, 800)
      }
    }).dimmer('show')
  },

  load_index(class_name) {
    $.ajax({
      url: "/search_settings/quickmenu",
      type: "GET",
      data: {
        filter_key: class_name
      },
      success(data, _textStatus, _xhr) {
        $("#quickmenu .quickmenu.body").html(data);
        if (Object.keys(search.collect_search()).length > 0) {
          $(".name.search.filter").on('click', function() {
            search_settings.name_current_search(class_name)
          })
        } else {
          $(".name.search.filter").hide()
        }
        context_menu.init_menu();
        $(".search-setting.list.item .clicker").on('click', function() {
          $(".filter.settings").data("settings", $(this).data("settings"))
          search.init_search_form()
          $(".start-search .primary.button").trigger('click')
          $('body').dimmer("hide")
          $(".context.bar .search.bar.filter #search_input").focus()
        })
      },
      error(_jqXHR, _textStatus, errorThrown) {
        console.log(errorThrown);
        $("#quickmenu .quickmenu.body").html("<br><br><span class='center'><div class='ui red basic label'>Loading Error</div></span>");
      }
    });
  },

  name_current_search(class_name) {
    $.ajax({
      url: "/search_settings/name",
      type: "GET",
      success(data, _textStatus, _xhr) {
        $("#quickmenu .quickmenu.body").html(data);
        $("#quickmenu .search.name").focus();

        $("#quickmenu .save.search.filter").on('click', function () {
          search_settings.save_current_search_filter(class_name, $("#quickmenu .search.name").val(), false, false)
        })
      },
      error(_jqXHR, _textStatus, errorThrown) {
        console.log(errorThrown);
        $("#quickmenu .new.basket").removeClass("loading")
        $("#quickmenu .quickmenu.body").html("<br><br><span class='center'><div class='ui red basic label'>Loading Error</div></span>");
      }
    });
  },

  context_menu() {

    $(".item.share.search-setting").on('click', function() {
      const target_id = $(this).attr("data-target-id")
      const filter_key = $("#search_setting_" + target_id).attr("data-filter-key")

      $.ajax({
        url: `/search_settings/${target_id}/share`,
        type: "POST",
        success(_data, _textStatus, _xhr) {
          search_settings.load_index(filter_key)
        },
        error(_jqXHR, _textStatus, errorThrown) {
          console.log(errorThrown);
        }
      });
    })

    $(".item.delete.search-setting").on('click', function() {
      const target_id = $(this).attr("data-target-id")

      $.ajax({
        url: `/search_settings/${target_id}/delete`,
        type: "POST",
        success(_data, _textStatus, _xhr) {
          $("#search_setting_" + target_id).addClass("animate__animated animate__fadeOutRight")
          setTimeout(function() {
            $("#search_setting_" + target_id).remove();
          }, 800)
        },
        error(_jqXHR, _textStatus, errorThrown) {
          console.log(errorThrown);
        }
      });
    })
  },

  save_current_search_filter(class_name, name, hidden = false, shared = false, open_menu = true) {
    const settings = search.collect_search()

    if (Object.keys(settings).length > 0) {

      if (settings != null || settings == "") {
        $.ajax({
          url: "/search_settings/add",
          type: "POST",
          data: {
            filter_key: class_name,
            settings: settings,
            name: name,
            hidden: hidden,
            shared: shared
          },
          success(_data, _textStatus, _xhr) {
            if (open_menu) {
              search_settings.load_index(class_name);
            }
          },
          error(_jqXHR, _textStatus, errorThrown) {
            console.log(errorThrown);
            $("#quickmenu .quickmenu.body").html("<br><br><span class='center'><div class='ui red basic label'>Loading Error</div></span>");
          }
        });
      }
      
    }
  }
}

window.search_settings = search_settings;
