document.addEventListener("turbo:load", function() {
  document.querySelector('#avails.index') && avails.index()
  //document.querySelector('#avails.edit') && avails.edit()
  document.querySelector('#avails_queue.import') && avails.import()
});

var delay = (function() {
  var timer
  timer = 0
  return function(callback, ms) {
    clearTimeout(timer)
    timer = setTimeout(callback, ms)
  }
})()

var avails = {
  index() {
    search.init("/avails");
    window.color_array = ["#83d3fb","#ffca80","#ffe680","#abeb93","#ff80a6"];

    $("#avails .dropdown").not('.remote').dropdown({ placeholder: false, fullTextSearch: true })
    
    calendar.datepicker(".modified_date_start", { position: 'top left', lastResort: 'top left' } )
    calendar.datepicker(".modified_date_end", { position: 'top right', lastResort: 'top right' } )

    calendar.datepicker(".creation_date_start", { position: 'top left', lastResort: 'top left' } )
    calendar.datepicker(".creation_date_end", { position: 'top right', lastResort: 'top right' } )
  },

  init_index_body() {
    $('#avails .with-tooltip').popup({
      inline: true,
      exclusive: true,
      delay: {
        show: 500,
        hide: 0
      }
    });
  },

  edit() {
    let tab_menu = $(".segment.custom.with-tabs")
    general.tab_with_url(tab_menu)

    calendar.datetimepicker($("#avails"))
    calendar.datepicker($("#avails"))
    avails.init_dropdowns($("#avails"))
    avails.init_distribution_channel_dropdowns()
    avails.init_distribution_channel_type_dropdowns()
    avails.init_distribution_subchannel_dropdowns()

    avails.init_territory_dropdowns($("#avails"))
    avails.init_language_dropdowns($("#avails"))
    avails.init_hovers($("#avails"))
    avails.nested_fields()

    avails.breadcrumb()

    avails.init_avail_asset($("#avails").find(".nested_fields.nested_avail_avail_assets"))
  },

  import() {
    $(".import.file").on("click", function() {
      $(".import.file").addClass("loading")
      avails.import_modal()
    })
  },

  import_modal() {
    const url = `/avails/file_selection_modal`
    $.ajax({
        url
      }).done(function(data) {
      $(".import.file").removeClass("loading")
      $("body").append(data)

      let modal = $('.import.modal')
      modal.modal({
        transition: "fade up",
        autofocus: false,
        closable: false,
        onShow() {
          modal.find(".info-popup").popup()
          $(".reload.button").on("click", function() {
            $(".reload.button").addClass("loading")
            avails.files_list()  
          })
          avails.files_list()
        },
        onHidden() {
          modal.remove()
        },
        onApprove: function() {
          avails.import_trigger(modal)

          return false
        },
        observeChanges: true
      }).modal("show")
    })
  },

  files_list() {
    const url = `/avails/file_selection`
    $.ajax({
      url
    }).done(function(data) {
      const files_list = $("#files-list")
      files_list.html(data)

      files_list.find(".ui.checkbox").checkbox()
      files_list.find(".info-popup").popup()

      avails.calc_trigger()
      avails.init_selectors()
      avails.init_upload()

      $(".reload.button").removeClass("loading")
    })
  },

  init_upload() {
    var counter = 0;

    let dropzone = $(".modal.import")
    let drop_target = '.modal.import .dropzone'
    dropzone.off('drag dragstart dragend dragover dragenter dragleave drop') //.off('dragenter').off('dragleave').off('drop')
    dropzone.on('drag dragstart dragend dragover dragenter dragleave drop', function(e) {
      e.preventDefault();
      e.stopPropagation();
    }).on('dragenter', function(e) {
      //console.log("enter")

      counter++;
      $(dropzone).addClass('dropactive')
      $(drop_target).show()
    }).on('dragleave', function(e) {
      //console.log("leave")

      counter--;
      if (counter === 0) { 
        $(drop_target).hide()
      }
    }).on('drop', function(e) {
      counter = 0;
      $(drop_target).hide()
      //console.log("drop")

      $('#upload-list .error.message').remove()
      
      const files = e.originalEvent.dataTransfer.files;

      //console.log(files)

      for(var i = 0, len = files.length; i < len; i++) {
        let ext = files[i].name.split('.').pop()

        if (ext == 'xlsx') {
          const formData = new FormData()
          formData.append('avails[file]', files[i])
          formData.append('avails[name]', files[i].name)

          const upload_id = general.makeid() 

          let upload_element = `<div id="${upload_id}" class='ui segment xyz-in' xyz="fade up"> ${files[i].name} <div class="ui tiny bottom attached progress"><div class="bar"></div></div></div>`
          $('#upload-list').append(upload_element)

          //console.log(upload_element)
          let progress = $("#" + upload_id).find(".ui.progress")
          progress.progress()

          $.ajax({
            url: '/avails/upload_files',
            method: 'POST',
            type: 'POST',
            data: formData,
            contentType: false,
            processData: false,
            xhr: function () {
                var xhr = new window.XMLHttpRequest();
                xhr.upload.addEventListener("progress", function(event) {
                  var percent = (event.loaded / event.total) * 100;
                  //console.log(percent)

                  progress.progress({ percent: percent })
                }, false);

                return xhr;
            }
          }).done(function(data) {
            $("#" + upload_id).remove()
            avails.files_list()
          }).fail(function(xhr, _text, _errorThrown) {
            console.log(xhr)
          })

        } else {
          $('#upload-list').append('<div class="ui error message">Incorrect file type. Only XLSX uploads are allowed.</div><br>');
        }
      }
    });
  },

  init_selectors() {
    $('.import.modal .symbol').hide()
    $('.import.modal .selector').show()
    $(".import.modal .selector").off('click')
    $(".import.modal .selector").on('click', function() {
      if ($(this).find(".selected").length > 0) {
        index_select.unselect(this)
      } else {
        index_select.select(this)
      }
      avails.check_import_button()
    })
  },

  check_import_button() {
    if ($('.import.modal .selector .selected').length > 0) {
      $('.approve.import.button').removeClass('disabled')
    } else {
     $('.approve.import.button').addClass('disabled')
    }
  },

  init_transactions(element) {
    avails.init_dropdowns(element)
    $(element).find(".item[data-tab]").tab()
  },

  init_avail_asset(element) {
    $(element).each(function(_i, avail_asset) {
      avails.init_worktype_dropdown(avail_asset)
      avails.init_dropdowns(avail_asset)
    })
  },

  breadcrumb() {
    let avail_id = $("#avail_av_ID").val()
    if (avail_id != undefined)
    $.ajax({
      url: "/avails/" + avail_id + "/breadcrumb",
    }).done(function(data) {
      $(".breadcrumb.area").html(data)
    })
  },

  import_trigger(modal) {
    $('.approve.import.button').addClass('loading')
    $(".import.modal .filelist").append('<div class="ui active inverted dimmer"><div class="ui text loader">Processing</div></div>')

    const promises = $('.import.modal .selector .selected').map((_, item) => {
      const item_row = $(item).parent()
      const file_path = item_row.parent().data("file-path")
      const file_name = item_row.parent().data("file-name")

      const skip = item_row.closest("tr").find('.skip.existing.checkbox').checkbox('is checked')

      return $.ajax({
        url: "/avails/import_file",
        type: "POST",
        dataType: "json",
        data: {
          filenames: [file_path],
          skip_existing: skip
        },
        complete() {
        },
        success(_data, _textStatus, _xhr) {
          $('body').toast({
            title: 'Started Avail Import',
            class: 'black',
            message: file_name,
            displayTime: 2000,
            newestOnTop: true
          })
        },
        error(jqXHR, _textStatus, errorThrown) {
          $(".body.segment").find(".message").remove()
          $(".body.segment").prepend('<div class="ui negative message"><i class="close icon"></i><div class="header">' + errorThrown + '</div> <p>' + jqXHR.responseJSON.message + '</p></div>');
          general.message_closable()
        }
      })

    })

    Promise.allSettled(promises)
    .then((results) => {
      const index_body = document.getElementById("index_body")
      index_body.reload()
      modal.modal("hide")
    })
    .catch((err) => {
      console.log(err)
      $('.import.modal .import.button').removeClass('green loading').addClass('red').text("Import failed!")
    })
  },

  calc_trigger() {
    $('.file.import.selection').each(function(_i, item) {
      if ($(item).data("file-path").includes(".xlsx")) {
        $(item).parent().find(".avail.count").on('click', function() {
          avails.fill_avail_count($(item))
        })
      } else {
        $(this).parent().find('.active.loader').removeClass('active')
      }
    })
  },

  fill_avail_count(target) {
    $(target).parent().find('.avail.count .label').html('<span class="ui active mini inline loader"></span>')
    $.ajax({
      url: "/avails/avail_count",
      type: "GET",
      dataType: "json",
      data: {
        path: $(target).data("file-path"),
      }
    }).done(function(data) {
      $(target).parent().find('.active.loader').removeClass('active')
      $(target).parent().find('.avail.count .label').text(data)
    })
  },

  init_territory_dropdowns(target) {
    $(target).find(".territory.dropdown").dropdown({
      apiSettings: {
        cache: false,
        url: '/avails/territories?q={/query}'
      },
      templates: {
        menu: dropdownTemplates.territory_search_menu
      }
    });
  },

  init_dropdowns(element) {
    $(element).find(".dropdown").not('.remote').not('.custom').dropdown({ placeholder: false, fullTextSearch: true })
    avails.contact_dropdown()
    avails.distribution_fulfillment_partner_dropdown()
  },

  contact_dropdown() {
    $('.contact_id').dropdown({
      apiSettings: {
        //minCharacters: 2,
        cache: false,
        url: '/contacts.json?q={/query}&search_input={/query}'
      },
      templates: {
        menu: dropdownTemplates.search_menu
      },
      onChange(value, _text, _$selectedItem) {
        return $('.contact_id').val(value);
      }
    }).dropdown('set selected', $('.contact_id').val());
  },

  distribution_fulfillment_partner_dropdown() {
    $('.distribution_fulfillment_partner_id').dropdown({
      apiSettings: {
        //minCharacters: 2,
        cache: false,
        url: '/contacts.json?search_input={query}&sort={/sort}&distribution_fulfillment_partner=true',
        onResponse: (data) => {
          return { results: data.map((item) => ({ name: item.name, value: item.id })) }
        },
      },
      templates: {
        menu: dropdownTemplates.search_menu
      },
      onChange(value, _text, _$selectedItem) {
        return $('.distribution_fulfillment_partner_id').val(value);
      }
    }).dropdown('set selected', $('.distribution_fulfillment_partner_id').val());
  },

  init_hovers(element) {
    avails.alid_hover('.alid.delivery', element)
    avails.content_id_hover('.avail.asset.content-id', element)
  },

  alid_hover(namespace, element) {
    let namespace_field = $(element).find(namespace)
    let namespace_field_label = namespace_field.find('label')

    namespace_field.off("mouseenter")
    namespace_field.off("mouseleave")
    namespace_field
    .on("mouseenter", function() {
      const delivery_order_id_field = $(this)
      if ($(delivery_order_id_field).data("delivery-order-id") != "") {
        if (!($(delivery_order_id_field).find('label a').length > 0)) {
          namespace_field_label.removeClass("required")
          namespace_field_label.append("<a target='_blank' href='/delivery_orders/" + $(delivery_order_id_field).data("delivery-order-id") + "/edit'>&nbsp;&nbsp;<i class='fas fa-external-link'></i></a>");
        }
      }
    })
    .on("mouseleave", function() {
      namespace_field_label.addClass("required")
      namespace_field_label.find("a").remove()
    })
  },

  content_id_hover(namespace, element) {
    let namespace_field = $(element).find(namespace)
    let namespace_field_label = namespace_field.find('label')

    namespace_field.off("mouseenter")
    namespace_field.off("mouseleave")
    namespace_field
    .on("mouseenter", function() {
      const content_id_field = $(this)
      const content_id = $(content_id_field).find('input:text').val()
      const licensor_id = $('#avail_av_c_LicensorID').val()
      const worktype = $(element).find('.worktype').dropdown('get value')

      if (!($(content_id_field).find('label a').length > 0) && content_id !== "") {
        fetch_metadata_ref_link(worktype, licensor_id, content_id).then(link => {
          if (link) {
            $(content_id_field).find('label').append(link);
          } else {
            $(content_id_field).find('label').append("<a>&nbsp;&nbsp;Metadata not found!</a>")
          }
        })
      }

    })
    .on("mouseleave", function() {
      namespace_field_label.find('a').remove()
    })
  },

  init_language_dropdowns(element) {
    $(element).find(".allowed.search.dropdown").dropdown({
      apiSettings: {
        cache: false,
        url: '/avails/languages?q={/query}'
      },
      templates: {
        menu: dropdownTemplates.language_search_menu
      }
    })

    $(element).find(".holdback.search.dropdown").dropdown({
      apiSettings: {
        cache: false,
        url: '/avails/languages?q={/query}'
      },
      templates: {
        menu: dropdownTemplates.language_search_menu
      }
    })

    $(element).find(".asset-language.search.dropdown").dropdown({
      apiSettings: {
        cache: false,
        url: '/avails/languages?q={/query}'
      },
      templates: {
        menu: dropdownTemplates.language_search_menu
      }
    })
  },

  init_worktype_dropdown(element) {
    $(element).find(".custom.worktype.dropdown").each(function(_i, item) {
      $(item).dropdown({
        onChange: function() {
          avails.fetch_avail_metadata_form(element)
        },
      }).dropdown('set selected', $(item).val())
      avails.fetch_avail_metadata_form(element)
    });
  },

  fetch_avail_metadata_form(element) {
    $.ajax({
      url: "/avails/metadata",
      data: {
        worktype: $(element).find(".worktype.dropdown").dropdown("get value"),
        avail_id: $("#avail_av_ID").val(),
        avail_asset_id: $(element).find(".avail-asset-id").data("avail-asset-id"),
        avail_asset_index: $(element).find('.avail-asset-index').data("avail-asset-index"),
      }
    }).done(function(data) {
      $(element).find(".asset.metadata").html(data);
      avails.init_language_dropdowns(element)
      avails.init_territory_dropdowns(element)
      calendar.datetimepicker(element)
    }).fail(() => console.log("error"));
  },

  init_distribution_channel_dropdowns() {
    var distribution_channel_dropdown = $('.remote.dropdown.distribution-channel')
    distribution_channel_dropdown.dropdown({
      apiSettings: {
        cache: false,
        url: `/avails/distribution_channels.json`,
        beforeSend: function(settings) {
          settings.data.av_c_LicensorID = $('#avail_av_c_LicensorID').dropdown('get value')
          return settings;
        }
      },
      onChange: function() {
        $('.remote.dropdown.distribution-channel-type').dropdown('clear')
        $('.remote.dropdown.distribution-subchannel').dropdown('clear')
      },
      templates: {
        menu: dropdownTemplates.distribution_channel_search
      }
    }).dropdown('set selected', $('#avail_av_dc_ID').val());


    $("#avails .licensor.dropdown").dropdown({ 
      // placeholder: false, fullTextSearch: true,
      onChange(_value, _text, _$choice) {
        $('.remote.dropdown.distribution-channel').dropdown('clear')
        $('.remote.dropdown.distribution-channel-type').dropdown('clear')
        $('.remote.dropdown.distribution-subchannel').dropdown('clear')
      }
    })
  },

  init_distribution_channel_type_dropdowns() {
    var distribution_channel_type_dropdown = $('.remote.dropdown.distribution-channel-type')
    distribution_channel_type_dropdown.dropdown({
      apiSettings: {
        cache: false,
        url: `/avails/distribution_channel_types.json`,
        data: {
          distribution_channel: $('.remote.dropdown.distribution-channel').dropdown('get value')
        },
        beforeSend: function(settings) {
          settings.data.distribution_channel = $('.remote.dropdown.distribution-channel').dropdown('get value')
          return settings;
        }
      },
      templates: {
        menu: dropdownTemplates.distribution_channel_type_search
      }
    }).dropdown('set selected', $('#avail_av_dct_ID').val());
  },

  init_distribution_subchannel_dropdowns() {
    var distribution_subchannel_dropdown = $('.remote.dropdown.distribution-subchannel')
    distribution_subchannel_dropdown.dropdown({
      apiSettings: {
        cache: false,
        url: `/avails/distribution_subchannels.json`,
        beforeSend: function(settings) {
          settings
          settings.data.licensor = $('#avail_av_c_LicensorID').dropdown('get value')
          settings.data.distribution_channel = $('.remote.dropdown.distribution-channel').dropdown('get value')
          return settings
        }
      },
      templates: {
        menu: dropdownTemplates.distribution_subchannel_search
      }
    }).dropdown('set selected', $('#avail_av_mmcs_ID').val());
  },

  nested_fields() {
    let transaction_sidebar_name = "avail_transaction"
    general.element_sidebar(transaction_sidebar_name)

    let asset_sidebar_name = "avail_asset"
    general.element_sidebar(asset_sidebar_name)

    $("#avails.edit").off("fields_added.nested_form_fields")
    $("#avails.edit").on("fields_added.nested_form_fields", function(event, param) {
      switch (param.object_class) {
        case "avail_transaction": 

          avails.init_dropdowns(event.target)
          calendar.datetimepicker(event.target)
          calendar.datepicker(event.target)
          avails.init_transactions(event.target)
          avails.init_avail_asset(event.target)
          avails.init_hovers(event.target)
          avails.init_language_dropdowns(event.target)
          avails.init_territory_dropdowns(event.target)

          general.add_sidebar_item($(event.target).find(".nested-fields"), transaction_sidebar_name)
          return
        case "avail_asset":

          avails.init_dropdowns(event.target)
          calendar.datetimepicker(event.target)
          calendar.datepicker(event.target)
          avails.init_transactions(event.target)
          avails.init_avail_asset(event.target)
          avails.init_hovers(event.target)
          avails.init_language_dropdowns(event.target)
          avails.init_territory_dropdowns(event.target)

          general.add_sidebar_item($(event.target).find(".nested-fields"), asset_sidebar_name)
          return
        case "avail_asset_language":
          avails.init_dropdowns(event.target)
          avails.init_language_dropdowns(event.target)
          return
        case "avail_allowed_language":
          avails.init_dropdowns(event.target)
          avails.init_language_dropdowns(event.target)
          return
        case "avail_holdback_language":
          avails.init_dropdowns(event.target)
          avails.init_language_dropdowns(event.target)
          return
        case "avail_alt_identifier":
          return
        case "avail_title_display_unlimited":
          avails.init_language_dropdowns(event.target)
          return
        case "avail_title_internal_alias":
          avails.init_territory_dropdowns(event.target)
          return
        case "md_company_display_credit":
          avails.init_territory_dropdowns(event.target)
          avails.init_language_dropdowns(event.target)
          return
        case "display_string":
          avails.init_language_dropdowns(event.target)
          return
        case "md_rating":
          avails.init_territory_dropdowns(event.target)
          return
        case "season_title_display_unlimited":
          avails.init_language_dropdowns(event.target)
          return
        case "season_title_internal_alias":
          avails.init_territory_dropdowns(event.target)
          return
        case "avail_release_history":
          calendar.datetimepicker(event.target)
          calendar.datepicker(event.target)
          return
        case "series_title_internal_alias":
          avails.init_territory_dropdowns(event.target)
          return
        case "series_title_display_unlimited":
          avails.init_language_dropdowns(event.target)
          return
        case "local_series_title":
          avails.init_language_dropdowns(event.target)
          return
      default:
        return console.log(`Fields were successfully added, callback not handled for ${param.object_class}.`)
      }
    })

    $("#avails.edit").off("fields_removed.nested_form_fields")
    $("#avails.edit").on("fields_removed.nested_form_fields", function(event, param) {
      switch (param.object_class) {
        case "avail_transaction": 
          general.remove_sidebar_item($(event.target).find(".nested-fields").attr("data-model-id"), transaction_sidebar_name)
          return
        case "avail_asset": 
          general.remove_sidebar_item($(event.target).find(".nested-fields").attr("data-model-id"), asset_sidebar_name)
          return
      default:
        return console.log(`Fields were successfully added, callback not handled for ${param.object_class}.`)
      }
    })

  }
}

async function fetch_metadata_ref_link(worktype, licensor_id, content_id) {
  let url = "/metadata_titles"
  let id = "mt_ID"

  switch (worktype) {
    case "5": //season
    url = "/metadata_seasons"
    id = "ms_ID"
    break;
    case "6": //series
    url = "/metadata_brands"
    id = "mb_ID"
    break;
  }
  
  let response = await $.ajax({
    url: url,
    type: "GET",
    dataType: "json",
    data: {
      search_client: licensor_id,
      catalog_no: content_id
    }
  })

  if (response.length == 1) {
    return "<a target='_blank' href='" + url + '/' + response[0][id] + "/edit'>&nbsp;&nbsp;Metadata <i class='fas fa-external-link'></i></a>"
  }
}

window.avails = avails
