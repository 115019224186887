document.addEventListener("turbo:load", function() {
  if ($("#genre_subs").length > 0) {
    search.init("/genre_subs");
    return init_dropdowns();
  }
});


var init_dropdowns = function() {
  $(".search-field.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
  return $("#genre_subs .default-options.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
};
