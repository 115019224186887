document.addEventListener("turbo:load", function() {
  if ($("#metadata").length > 0) {
    metadata.init()
  }
});

var metadata = {
  init() {
    metadata.import()

    $("#metadata .ui.dropdown").dropdown({
      onChange(_value, _text, _$choice) {
        metadata.load($(this).attr("data-namespace"))
      }
    })
    const metadata_elements = ["brands", "seasons", "titles"]

    $.each(metadata_elements, function( _index, value ) {
      metadata.load(value)
      $("#metadata .input." + value + " input").keyup(function(event) {
        if (event.keyCode === 13) {
          if ($(this).is(":focus")) {
            metadata.load(value)
          }
        }
      });

    })
  },

  load(namespace) {
    const search_term = $("#metadata .input." + namespace + " input").val()
    const status = $("#metadata .dropdown.status." + namespace).dropdown("get values")
    const type = $("#metadata .dropdown.type").dropdown("get value")
    $("#metadata .input." + namespace).addClass("loading")
    $.ajax({
      url: "/metadata_" + namespace + "?type=mini&search_input=" + search_term + "&search_status=" + status + "&search_type=" + type,
      dataType: "script"
    }).done(function(_data) {
      $("#metadata .input." + namespace).removeClass("loading")
      
    });
  },

  import() {
    $(".import.file").on("click", function() {
      $(".import.file").addClass("loading")
      metadata.import_modal()
    })
  },

  files_list() {
    const url = `/metadata/files_list`
    $.ajax({
      url
    }).done(function(data) {
      const files_list = $("#files-list")
      files_list.html(data)

      files_list.find(".ui.checkbox").checkbox()
      files_list.find(".info-popup").popup()

      metadata.init_download()
      metadata.init_upload()

      $(".reload.button").removeClass("loading")
    })
  },

  import_modal() {
    const url = `/metadata/upload_files_modal`
    $.ajax({
        url
      }).done(function(data) {
      $(".import.file").removeClass("loading")
      $("body").append(data)

      let modal = $('.import.modal')
      modal.modal({
        transition: "fade up",
        autofocus: false,
        closable: false,
        onShow() {
          modal.find(".info-popup").popup()
          $(".reload.button").on("click", function() {
            $(".reload.button").addClass("loading")
            metadata.files_list()  
          })
          metadata.files_list()
        },
        onHidden() {
          modal.remove()
        },
        observeChanges: true
      }).modal("show")
    })
  },

  init_download() {
    $('.download.trigger').each(function(_i, item) {
      $(item).on("click", function() {
        console.log($(this).find(".button_to"))
        $(this).find(".button_to").submit()
      })
    })
  },

  init_upload() {
    var counter = 0;

    let dropzone = $(".modal.import")
    let drop_target = '.modal.import .dropzone'
    dropzone.off('drag dragstart dragend dragover dragenter dragleave drop') //.off('dragenter').off('dragleave').off('drop')
    dropzone.on('drag dragstart dragend dragover dragenter dragleave drop', function(e) {
      e.preventDefault();
      e.stopPropagation();
    }).on('dragenter', function(e) {
      //console.log("enter")

      counter++;
      $(dropzone).addClass('dropactive')
      $(drop_target).show()
    }).on('dragleave', function(e) {
      //console.log("leave")

      counter--;
      if (counter === 0) { 
        $(drop_target).hide()
      }
    }).on('drop', function(e) {
      counter = 0;
      $(drop_target).hide()
      //console.log("drop")

      $('#upload-list .error.message').remove()
      
      const files = e.originalEvent.dataTransfer.files;

      //console.log(files)

      for(var i = 0, len = files.length; i < len; i++) {
        let ext = files[i].name.split('.').pop()

        if (ext == 'xlsx' || ext == 'xls' || ext == 'xml' || ext == 'json') {
          const formData = new FormData()
          formData.append('metadata[file]', files[i])
          formData.append('metadata[name]', files[i].name)

          const upload_id = general.makeid() 

          let upload_element = `<div id="${upload_id}" class='ui segment xyz-in' xyz="fade up"> ${files[i].name} <div class="ui tiny bottom attached progress"><div class="bar"></div></div></div>`
          $('#upload-list').append(upload_element)

          //console.log(upload_element)
          let progress = $("#" + upload_id).find(".ui.progress")
          progress.progress()

          $.ajax({
            url: '/metadata/upload_files',
            method: 'POST',
            type: 'POST',
            data: formData,
            contentType: false,
            processData: false,
            xhr: function () {
                var xhr = new window.XMLHttpRequest();
                xhr.upload.addEventListener("progress", function(event) {
                  var percent = (event.loaded / event.total) * 100;
                  //console.log(percent)

                  progress.progress({ percent: percent })
                }, false);

                return xhr;
            }
          }).done(function(data) {
            $("#" + upload_id).remove()
            metadata.files_list()
          }).fail(function(xhr, _text, _errorThrown) {
            console.log(xhr)
          })

        } else {
          $('#upload-list').append('<div class="ui error message">Incorrect file type. Only XLSX uploads are allowed.</div><br>');
        }
      }
    });
  },

  update_sidebar(sidebar_name) {
    let element_sidebar = $(`.element-sidebar-container.${sidebar_name}`)
    let fields = element_sidebar.find(`.nested-fields.${sidebar_name}`)
    let sidebar = element_sidebar.find('.element-sidebar')

    $.each(fields, function( _index, field ) {
      let model_id = $(field).attr("data-model-id")
      let header_text = $(field).find(".header-name")

      let update_sidebar_trigger = $(field).find(".update-sidebar")
      
      let menu_item = sidebar.find(`.menu-item[data-model-id=${model_id}]`)
      update_sidebar_trigger.off("change")
      update_sidebar_trigger.on("change", function() {
        let name = $(field).find(".first-name").val() + " " + $(field).find(".middle-name").val() + " " + $(field).find(".last-name").val()
        name = name.replace(/\s+/g,' ')
        if (name == " ") { 
          name = "New " + $(field).find(".member-type").dropdown("get text")
        }
        let role = $(field).find(".metadata-role-id").dropdown("get text")
        if (role == "") { role = "-" }
        menu_item.find("span").text(name)
        header_text.text(name)
        menu_item.find(".label .role-name").text(role)

        if ($(field).find(".billing-top").checkbox("is checked")) {
          menu_item.find(".label .billing").addClass("blue check")
        } else {
          menu_item.find(".label .billing").removeClass("blue check")
        }
        
      })
      
    })
  },

  contact_dropdown() {
    $('.contact_id').dropdown({
      apiSettings: {
        //minCharacters: 2,
        cache: false,
        url: '/contacts.json?type=metadata&q={/query}&search_input={/query}'
      },
      templates: {
        menu: dropdownTemplates.search_menu
      },
      onChange(value, _text, _$selectedItem) {
        $('.add-image.button').each((_, button) => {
          const url = new URL(button.getAttribute('href'))
          url.searchParams.set('client_id', value)
          button.setAttribute('href', url.href)
        })
        return $('.contact_id').val(value);
      }
    }).dropdown('set selected', $('.contact_id').val())
  },

  language_dropdown() {
    $(".dropdown.language-search").dropdown({
      apiSettings: {
        cache: false,
        url: '/languagecode?q={/query}',
      },
      templates: {
        menu: dropdownTemplates.language_search_menu
      },
    })
  },

  territory_dropdown() {
    $(".dropdown.territory-search").dropdown({
      apiSettings: {
        cache: false,
        url: '/territorycode?q={/query}',
      },
      templates: {
        menu: dropdownTemplates.territory_search_menu
      },
    })
  },

  member_type_dropdown(node) {
    $(node).find(".dropdown.member-type").dropdown({
      onChange(value, _text, _$choice) {
        metadata.view_by_member_type(node, value)
      }
    })
  },

  view_by_member_type(node, value) {
    let cast = $(node).find(".cast-options")
    let crew = $(node).find(".crew-options")

    if (value == "Cast") {
      cast.show()
      crew.hide()
      $(node).find(".metadata-role-id").dropdown("set selected", 1)
    }
    if (value == "Crew") {
      cast.hide()
      crew.show()
      if ($(node).find(".metadata-role-id").dropdown("get value") == 1) {
        $(node).find(".metadata-role-id").dropdown("clear")
      }
    }
  },

  casts(node, namespace) {
    let nodes = $(node).find(`.nested-fields.${namespace}`)
    
    $.each($(nodes), function(_index, field) {
      metadata.member_type_dropdown(field)
      metadata.view_by_member_type(field, $(field).find(".dropdown.member-type").dropdown("get value"))
    })
  },

  approval_steps() {
    $(".metadata.approval.steps .step").off("click")
    $(".metadata.approval.steps .step").on("click", function(event) {
      let target = $(event.currentTarget)
      let new_level = target.data("level")
      $(".approval_status_id").val(new_level)

      //target.addClass("completed")

      $(`.metadata.approval.steps .step`).removeClass("completed")
      for(var i = 0; i < new_level; i++) {
        $(`.metadata.approval.steps .step[data-level=${i+1}]`).addClass("completed")
      }

      $('body').toast({
        title: `${new_level}: ${target.find(".title").text()}`,
        message: `Save to set new approval step`,
        displayTime: 5000,
        showProgress: 'bottom',
        className: {
          toast: 'ui message'
        }
      })
    })
  }

};
    
window.metadata = metadata;
