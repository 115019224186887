import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSpring, animated, easings } from 'react-spring'

export default (props) => {
  let icon = {prefix: 'far', name: props.icon};
  if (props.modalIsLoading) {
    icon.prefix = 'fad'
    icon.name = 'spinner-third'
  } else {
    icon.name = props.showQuicklookIcon ? "eye" : props.icon
  }
  const rotateZ = useSpring({
    from: {
      rotateZ: 0,
    },
    to: {
      rotateZ: 360,
    },
    config: {
      duration: 1000,
      easing: easings.easeInOutCirc,
    },
    loop: true
  })

  return(
    props.modalIsLoading ? (
      <div className='icon'>
        <animated.div style={rotateZ}>
          <FontAwesomeIcon icon={[icon.prefix, icon.name]} size="2x"/>
        </animated.div>
      </div>
    ) : (
      <div className='icon'>
        <FontAwesomeIcon icon={[icon.prefix, icon.name]} size="2x"/>
      </div>
    )
  )
};
