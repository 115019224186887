document.addEventListener("turbo:load", function() {
  document.querySelector('#user_profile_webs.index') && user_profile_webs.index()
  //document.querySelector('#user_profile_webs.edit') && user_profile_webs.edit()
});

var user_profile_webs = {
  index() {
    search.init("/user_profile_webs")

    $(".to-be-disabled.button").on("click", () =>
      $.ajax({
        url: "/user_profile_webs/to_be_disabled",
        type: "GET",
        success(data, _textStatus, _xhr) {
          $("body").append(data);
          $('.to_be_disabled.modal').modal({
            onShow() {
              $.ajax({
                url: "/user_profile_webs/to_be_disabled_users",
                type: "GET",
                data: {
                  with_qc: $("#search_qc_notification").prop("checked").toString(),
                  portals: $("#search_portals").val(),
                  contacts: $("#search_clients").val(),
                  active: $("#search_active").val()
                },
                success(data, _textStatus, _xhr) {
                  $('.to_be_disabled.modal .content').html(data);
                  return $('.to_be_disabled.modal').modal("refresh");
                }
              });
            },
            onHidden() {
              $(".to_be_disabled.modal").remove();
            }
          });
          return $('.to_be_disabled.modal').modal("show");
        },
        error(_jqXHR, _textStatus, errorThrown) {
          return console.log(errorThrown);
        }
      })
    );
  },

  edit() {
    $('#user_profile_webs.edit .item[data-tab]').not(".custom").tab()
    $('#user_profile_webs .dropdown').not('.remote').dropdown({ placeholder: false });
    
    user_profile_webs.profile_image()

    $('#user_profile_web_upw_upw_ReportToID').dropdown({
      apiSettings: {
        cache: false,
        url: '/user_profile_webs.json?q={/query}&search_input={/query}'
      },
      templates: {
        menu: dropdownTemplates.search_menu
      },
      onChange(value, _text, _$selectedItem) {
        return $('#user_profile_web_upw_upw_ReportToID').val(value);
      }
    }).dropdown('set selected', $('#user_profile_web_upw_upw_ReportToID').val());

    $('#user_profile_web_upw_c_ClientContactID').dropdown({
      apiSettings: {
        //minCharacters: 2,
        cache: false,
        url: '/contacts.json?q={/query}&search_input={/query}'
      },
      templates: {
        menu: dropdownTemplates.search_menu
      },
      onChange(value, _text, _$selectedItem) {
        return $('#user_profile_web_upw_c_ClientContactID').val(value);
      }
    }).dropdown('set selected', $('#user_profile_web_upw_c_ClientContactID').val());

    $('.client.field').hover((function() {
      if (!($('.client.field label a').length > 0)) {
        $('.client.field label').append("<a target='_blank' href='/contacts/" + $(".client.dropdown").dropdown("get value") + "'>&nbsp;&nbsp;<i class='fas fa-external-link'></i> (" + $(".client.dropdown").dropdown("get value") + ")</a>"); 
      }
    }), function() {
      $('.client.field label a').remove();
    });

    $(".default.settings").on("click", function() { 
      $("#user_profile_web_upw_IsAllQCLanguagesEnabled").prop( "checked", true );
      $("#user_profile_web_language_code_ids").parent().dropdown("set exactly", ["140", "141", "22", "25", "27", "31", "34", "52", "54"]);
      $("#user_profile_web_qc_element_spec_ids").parent().dropdown("set exactly", ["1", "2", "4", "5"]);
      $("#user_profile_web_qc_element_type_ids").parent().dropdown("set exactly", ["1", "2", "3", "4"]);
      $("#user_profile_web_upw_IsQCEmailNotificationEnabled").prop( "checked", true );
      $("#user_profile_web_upw_upw_ReportToID").parent().dropdown("set selected", 31);
      $("#user_profile_web_upw_c_ClientContactID").parent().dropdown("set value", 3342).dropdown("set text", "Imagion AG").dropdown("set selected", 3342);
      $("#user_profile_web_upw_CompanyName").val("Imagion AG");
      $("#user_profile_web_upw_JobTitle").val("Quality Control Operator");
      $("#user_profile_web_upw_PreferredPortal").parent().dropdown("set exactly", 3);
    });
      
    $('#user_profile_web_upw_c_ClientContactID').on("change", function() {
      const contact_name = $(this).dropdown('get text');
      return $('#user_profile_web_upw_CompanyName').val(contact_name);
    });

    $('#user_profile_web_upw_FirstName').on("change", function() {
      user_profile_webs.set_appellation();
      user_profile_webs.set_fullname();
    });

    $('#user_profile_web_upw_LastName').on("change", function() {
      user_profile_webs.set_fullname()
    })

    $('#user_profile_web_upw_FullName').on("change", function() {
      const first_name = $('#user_profile_web_upw_FirstName').val()
      const last_name = $('#user_profile_web_upw_LastName').val()

      if (first_name.length == 0 || last_name.length == 0 ) {
        const full_name = $('#user_profile_web_upw_FullName').val();
        const full_name_split = full_name.split(" ");
        const last_name_from_split = full_name_split[full_name_split.length - 1]

        $('#user_profile_web_upw_FirstName').val(full_name.replace(' ' + last_name_from_split, ''));
        if (full_name_split.length > 1) {
          $('#user_profile_web_upw_LastName').val(last_name_from_split);
        }

        user_profile_webs.set_appellation();
      }
    });

    user_profile_webs.history()
  },

  history () {
    if ($("#user_profile_webs .history.empty").length > 0) {
      $("#user_profile_webs .history.empty").removeClass("empty")
      if ($("#user_profile_webs.edit").data("model-id") != undefined){
        $.ajax({
          url: `/user_profile_webs/${$("#user_profile_webs.edit").data("model-id")}/histories`,
          type: "GET",
          success(data, _textStatus, _xhr) {
            $("#user_profile_webs .history").html(data)
          },
          error(jqXHR, _textStatus, errorThrown) {
    
          }
        })
      }
    }
  },

  set_appellation() {
    if ($('#user_profile_web_upw_Appellation').val().length == 0) {
      $('#user_profile_web_upw_Appellation').val(`Hallo ${$('#user_profile_web_upw_FirstName').val()},`)
    }
  },

  set_fullname() {
    const full_name = $('#user_profile_web_upw_FullName')
    const first_name = $('#user_profile_web_upw_FirstName')
    const last_name = $('#user_profile_web_upw_LastName')

    if (full_name.val().length == 0 || full_name.val() == first_name.val()) {
      last_name_checked = last_name.val().length == 0 ? "" : ` ${last_name.val()}`
      full_name.val(`${first_name.val()}${last_name_checked}`)
    }
  },

  profile_image() {
    $('.card .image').dimmer({on: 'hover'})

    $(".remove-img").on("click", function() {
      $("#remove-img").trigger("click");
      $(".remove-img").hide();
      $(".profile-image").attr('src', $(".fallback-image-path").data("path"));
    });

    $(".img-button").on("click", () =>
      // Click hidden input field for Image File upload
      $("#user_profile_webs.edit .profile-file").trigger("click")
    );

    const preview = $(".profile-image")
    $("#user_profile_webs.edit .profile-file").on("change", function(event) {
      const input = $(event.currentTarget)
      const file = input[0].files[0]
      const reader = new FileReader()
      reader.onload = function(e) {
        const image_base64 = e.target.result
        preview.attr("src", image_base64)
      }
      reader.readAsDataURL(file)
    });
  }
};

window.user_profile_webs = user_profile_webs;
