import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['context_menu']


  connect() {
    this.eventHandler = async function(event) {
      if (event.target.closest('.context.menu .vertical.menu')) return
      context_menu.menu_trigger($(this))
    }
    this.context_menuTarget.addEventListener('click', this.eventHandler)
  }

  disconnect() {
    this.context_menuTarget.removeEventListener('click', this.eventHandler)
  }
}
