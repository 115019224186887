import React, { useState, useEffect } from 'react'
import ReactFlow, { ReactFlowProvider, Background, MiniMap, Controls, getIncomers, getOutgoers, isEdge, isNode } from 'react-flow-renderer'
import CatalogBaseObjectNode from './CatalogBaseObjectNode'
import layoutedNodes from './CatalogBaseObjectLayout'

const nodeTypes = {
  catalogBaseObjectNode: CatalogBaseObjectNode,
}

const CatalogBaseObject = props => {
  const [nodes, setNodes] = useState(props.nodes)
  const [edges, setEdges] = useState(props.edges)
  
  const [hideChilds, setHideChilds] = useState(false)
  const [targetIds, setTargetIds] = useState([])

  const onInit = (reactFlowInstance) => {
    setNodes(layoutedNodes(nodes, edges))
    layoutEdges()
    // reactFlowInstance.fitView({ padding: 0.2 })
    window.CatalogBaseObject_reactFlowInstance = reactFlowInstance
    $(".react-flow__attribution.bottom.right").remove()
  }

  const layoutEdges = () => {
    nodes.forEach((node) => {
      node.data.childless = getOutgoers(node, nodes, edges).length == 0
      node.data.parentless = getIncomers(node, nodes, edges).length == 0
    })
  }

  const onNodeDoubleClick = (mouseEvent, node) => {
    const mapChildren = (children) => children.flatMap((node) => [node.id, ...mapChildren(getOutgoers(node,nodes, edges))])
    setTargetIds([...targetIds, ...mapChildren(getOutgoers(node, nodes, edges))])
    setHideChilds(!node.data.collapsed)
    node.data.collapsed = !node.data.collapsed 
  }

  useEffect(() => {
    setNodes((nodes) => nodes.map((node) => {
      if (targetIds.includes(node.id)) {
        node.hidden = hideChilds
        node.data.collapsed = hideChilds
      }
      return node
    }))

    setEdges((edges) => edges.map((edge) => {
      if (targetIds.includes(edge.source) || targetIds.includes(edge.target)) {
        edge.hidden = hideChilds
      }
      return edge
    }))

    setTargetIds([])
    setHideChilds(null)
    setNodes(layoutedNodes(nodes, edges))
  }, [hideChilds])

  return (
    <div style={{height: '75vh'}}>
      <ReactFlowProvider>
        <ReactFlow nodes={nodes} edges={edges} onInit={onInit} className='CatalogBaseObject' nodeTypes={nodeTypes} minZoom={0.1} nodesConnectable={false} onNodeDoubleClick={onNodeDoubleClick} fitView={true}>
          <MiniMap
            nodeStrokeColor={(n) => {
              if (n.data?.style?.borderColor) return n.data.style.borderColor
              if (n.type === 'input') return '#0041d0'
              if (n.type === 'output') return '#ff0072'
              if (n.type === 'default') return '#1a192b'

              return '#eee'
            }}
            nodeColor={(n) => {
              if (n.data?.style?.color) return n.data.style.color

              return '#fff'
            }}
            nodeBorderRadius={2}
          />
          <Controls />
          <Background variant="dots"/>
        </ReactFlow>
      </ReactFlowProvider>
    </div>
  )
}

export default CatalogBaseObject
