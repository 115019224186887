var loading_asset_drop = false
var asset_drop = {
  
  init() {
    if ($("#assets").length > 0 || $("#assets-import").length > 0 || $("#dashboard").length > 0) {
      asset_drop.init_dnd()
    }
   },

   init_dnd() {
    if (window.File && window.FileReader && window.FileList && window.Blob) {
      let dropZone;
      if ($("#asset-drop").length == 0) {
        dropZone = $(".main.body")
        dropZone.on('drag dragstart dragend dragover dragenter dragleave drop', function(e) {
          e.preventDefault();
          e.stopPropagation();
        }).on('dragover dragenter', function() {
          if (loading_asset_drop == false) {
            if ($("#asset-drop").length == 0) {
              loading_asset_drop = true
              asset_drop.load_asset_drop()
            }
          }
        }).on('dragleave dragend drop', function() {
        });
      }
      
    } else {
      return console.log("The File APIs are not fully supported in this browser.");
    }
  },

  init_dropzone() {
    const dropZone = $(".dropzone");

    dropZone.on('drop', function(e) {
      dropZone.find(".header").text("Processing...");
      $(".dashboard-stats").removeClass('animate__animated animate__fadeInUp');
      $(".dashboard-stats").addClass('animate__animated fadeOutDown');
      dropZone.addClass('info animate__animated animate__bounceIn');
      dropZone.find(".icon").removeClass('download');
      dropZone.find(".icon").addClass('notched circle loading');

      const { files } = e.originalEvent.dataTransfer;

      const filenames = [];
      let i = 0;
      let f = undefined;

      while ((f = files[i])) {
        console.log(f.name);
        filenames.push(f.name);
        i++;
      }
      let importInProgress = false;
      let error = null;
      $.ajax({
        url: "/jobs/import",
        type: "post",
        dataType: "json",
        data: {
          filenames,
          dnd: true
        },
        complete() {
          if (!importInProgress) {
            return asset_drop.dnd_cleanup(dropZone, error);
          }
        },
        success(data, _textStatus, _xhr) {
          importInProgress = true;
          console.log(data);
          dropZone.find(".header").text("Importing...");
          dropZone.find("p").remove();
          dropZone.find(".content").append("<ul></ul>");
          dropZone.find("ul").addClass('list');
          
          for (let filename of Array.from(filenames)) {
            dropZone.find("ul").append(`<li>${filename}</li>`);
          }
          if (data.existing_asset_id === "") {
            return window.location.replace(`/assets/new?jobid=${data.id}`);
          } else {
            return window.location.replace(`/assets/${data.existing_asset_id}/edit?update=${data.id}`);
          }
        },

        error(jqXHR, _textStatus, errorThrown) {
          console.log(jqXHR.status);
          error = jqXHR.status;
          return console.log(`Drag and Drop Import Error: ${errorThrown}`);
        }
      });
    });
  },

  load_asset_drop() {
    return $.ajax({
      url: "/assets/drop",
    }).done(function(data) {
      loading_asset_drop = false
      $(".main.body>.main.content>.body").prepend(data)
      $("#asset-drop").show();
      $("#asset-drop").addClass('animate__bounceInDown');

      asset_drop.init_dropzone()
    }).fail(function() {
      loading_asset_drop = false
    });
  },

  dnd_cleanup(dropZone, error) {
    dropZone.removeClass('info animate__bounceIn animate__animated');
    dropZone.addClass('animate__shake animate__animated');
    dropZone.find(".icon").removeClass('notched circle loading');
    dropZone.find(".icon").addClass('download');
    dropZone.find(".header").text("Add Assets");
    if (error === 409) {
      dropZone.find(".header").text("Filename is not unique in Import Folder");
    }
    if (error === 412) {
      dropZone.find(".header").text("Unprocessable entity");
      //dropZone.find(".header").find("p").text("Just Drag n Drop a Single File or Multiple Files here to start the Asset Create Process");
    }
    dropZone.find("p").text("Just Drag n Drop a Single File or Multiple Files here to start the Asset Create Process");
    $(".dashboard-stats").removeClass('animate__animated animate__fadeOutDown');
    return $(".dashboard-stats").addClass('animate__animated animate__fadeInUp');
  },

};

window.asset_drop = asset_drop;
    