var awaited_asset = {
  init(target, params_id, url_params = {}) {
    if (target.find(`#awaited_asset_${params_id}`).length > 0) {
      if (target.find('.import.awaited.asset.field').parents('.workflow_7352').length == 0) {
        target.find('.import.awaited.asset.field').show()
      }
      let data = url_params
      data.awaited_assets_only = true
      $(`#awaited_asset_${params_id}`).dropdown({
        clearable: true,
        apiSettings: {
          cache: false,
          url: '/assets/search_dropdown?q={/query}',
          data: data,
        },
        templates: {
          menu: dropdownTemplates.asset_search_menu
        },
        onChange(value, _text, _$selectedItem) {
          $(`#awaited_asset_id_${params_id}`).val(value).trigger('change')
          awaited_asset.validate(target, params_id)
        }
      }).dropdown('queryRemote', '', function() {
        let awaited_asset_id = $('#import_into_awaited')?.val()?.padStart(8, '0')
        if (typeof awaited_asset_id !== 'undefined' && awaited_asset_id !== "" ) {
          $(`#awaited_asset_${params_id}`).dropdown('set exactly', [awaited_asset_id])
        }
      })

      $(`:checkbox#import_into_awaited_asset_${params_id}`).change(function() {
        if ($(this).prop("checked")) {
          target.find('.awaited.asset.field.select').show()
          target.find('.title-component').hide()
          target.find('.workflow_50008 .create.asset.checkbox').checkbox('uncheck')
        } else {
          target.find('.awaited.asset.field.select').hide()
          target.find('.title-component').show()
          target.find(`#awaited_asset_${params_id}`).dropdown("clear") 
        }
      })
    }
  },

  validate(target, params_id) {
    if ($(target).find('.asset-spec-type.remote.search.dropdown').dropdown("get values").length > 1 && $(`:checkbox#import_into_awaited_asset_${params_id}`).prop("checked")) {
      $(':input[type=submit]').prop('disabled', true);
      $(target).find('.asset-spec-type.remote.search.dropdown').parent().append("<div class='ui mini red label'>Only one Spec possible for a Awaited Asset</div>")

    } else {
      $(':input[type=submit]').prop('disabled', false);
      $(target).find('.asset-spec-type.remote.search.dropdown').parent().find(".ui.red.label").remove()
    }
  }
};
    
window.awaited_asset = awaited_asset;
