import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';

export default memo((props, { data, isConnectable }) => {
  return (
    <>
    <Handle
      data-collapsed={props.collapsed}
      data-childless={props.childless}
      type="source"
      position="bottom"
      isConnectable={isConnectable}
      style={props.style}
      onClick={() => catalog_base_objects.add_base_object_relation(props.model_id)}
    />
    </>
  );
});
