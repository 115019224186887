import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {

  static targets = [
    'deploymentSpec',
    'defaultPreset',
  ]
  static values = {}

  async applyDeploymentSpecPresets({ detail: { value: deployment_spec_id } }) {
    let c = this
    const url = `/workflow_job_presets/deployment_spec_presets?deployment_spec_id=${deployment_spec_id}`
    const response = await get(url)
    if (response.ok) {
      const data = await response.json
      const presets = data.presets
      if (presets.length > 0) c.applyPresets(presets, 'Deployment Spec Presets')
    } else {
      console.error('Error:', response)
    }
  }

  async init({detail: { wjt_id }}) {
    let c = this
    if (!wjt_id) return
    try { await this.waitForDropdownInitialization()
    } finally {
      c.applyDefaultPresets(wjt_id)
    }
  }

  waitForDropdownInitialization(ms = 5000) {
    const c = this
    let p = new Promise((resolve, reject) => {
      if (c.checkDropdowns()) { return resolve(true) }
      const observer = new MutationObserver(_mutations => {
        if (c.checkDropdowns()) {
          observer.disconnect()
          clearTimeout( timeout )
          resolve(true)
        }
      })
      observer.observe(c.element, {
          childList: true,
          subtree: true
      })
      
      let timeout = setTimeout(() => {
        observer.disconnect()
        reject('dropdown check timed out')
      }, ms)
    })
    return p
  }

  async applyDefaultPresets(wjt_id) {
    let c = this
    const url = `/workflow_job_templates/${wjt_id}/default_preset`
    const response = await get(url)

    if (response.ok) {
      const data = await response.json
      const presets = data.presets
      if (presets.length > 0) c.applyPresets(presets, 'Default Presets')
    } else {
      console.error('Error:', response)
    }
  }

  checkDropdowns() {
    const dropdowns = this.element.querySelectorAll('.ui.dropdown select')
    return Array.from(dropdowns).every(select => $(select).closest('.ui.dropdown').data('module-dropdown') !== undefined)
  }

  applyPresets(presets, title = 'Presets', class_name = 'info') {
    var toast = $.toast({
      title: title,
      message: 'Filling in presets...',
      showProgress: 'bottom',
      closeIcon: true,
      class: class_name,
      displayTime: 0
    })
    let r = workflow.fill_preset_values($(this.element).closest('fieldset'), presets)
    if (r == true || r.length < presets.length) {
      this.updateToast(toast, { message: 'Sucessfully Filled in Presets', classList: 'success', timeout: 1500 })
    } else {
      this.updateToast(toast, { message: 'Failed to fill in Presets', classList: 'error' })
    }
  }

  updateToast(toast, { title, message, classList, timeout }) {
    if (title) toast.find('.title').text(title)
    if (message) toast.find('.message').text(message)
    if (classList) toast.attr('class', `ui ${classList} toast`)
    if (timeout) setTimeout(() => toast.toast('close'), timeout)
  }
}
