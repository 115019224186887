import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {

  static outlets = [
    'remote-dropdown',
    'nested-fields',
  ]

  static targets = [
    'submitButton',
    'contactPersonDropdown',
    'statusDropdown',
    'departmentDropdown',
    'employeeDropdown',
    'supplierDropdown',

    'costTotal',
    'supplierCostTotal',

    'regularCost',
    'regularQuantity',
    'regularTotal',

    'supplierCost',
    'supplierQuantity',
    'supplierTotal',

    'capturingCheckbox',
    'title',
    'internalDescription',
    'projectId',
    'dueDate',
  ]

  static values = {
    currentUserLogin: String
  }
  connect() {
    window.ctrl = this
  }

  updateContactPersonQueryParams({ detail: { value, _text, _selectedItem, _selectedOptions }}) {
    const c = this
    const contact_person_dropdown = c.remoteDropdownOutlets.find(outlet => outlet.element == c.contactPersonDropdownTarget)
    if (value.length > 0) {
      contact_person_dropdown.setQueryParam('contact_id', value)
    } else {
      contact_person_dropdown.clear()
      contact_person_dropdown.unsetQueryParam('contact_id')
    }
  }

  addSourceAsset({ detail: { result: asset }}) {
    const c = this
    const source_asset_list = c.nestedFieldsOutlets.find(o => o.element.dataset.nestedFieldsIdentifierValue == 'source_assets')
    source_asset_list.remoteAdd({}, (template) => {
      let frame = template.querySelector('fieldset>turbo-frame')
      frame.id = `${Date.now().toString(36)}_card_frame`
      frame.src = `/assets/${asset.a_ID}/card?turbo_frame_id=${frame.id}&type=list&include_link=false`

      template.querySelector('fieldset').dataset.model = 'Asset'
      template.querySelector('fieldset').dataset.model_id = asset.a_ID
      template.querySelector('.asset-id').value = asset.a_ID

      return template
    })
  }

  addDestinationAsset({ detail: { result: asset }}) {
    const c = this
    const destination_asset_list = c.nestedFieldsOutlets.find(o => o.element.dataset.nestedFieldsIdentifierValue == 'destination_assets')
    destination_asset_list.remoteAdd({}, (template) => {
      let frame = template.querySelector('fieldset>turbo-frame')
      frame.id = `${Date.now().toString(36)}_card_frame`
      frame.src = `/assets/${asset.a_ID}/card?turbo_frame_id=${frame.id}&type=list&include_link=false`

      template.querySelector('fieldset').dataset.model = 'Asset'
      template.querySelector('fieldset').dataset.model_id = asset.a_ID
      template.querySelector('.asset-id').value = asset.a_ID

      return template
    })
  }

  updateTotal(e) {
    const c = this
    const parent = e.target.closest('tr')
    const regular_cost_target = c.regularCostTargets.find(t => parent.contains(t))
    const regular_quantity_target = c.regularQuantityTargets.find(t => parent.contains(t))
    const regular_total_target = c.regularTotalTargets.find(t => parent.contains(t))

    const cost = parseFloat(regular_cost_target.value)
    const quantity = parseFloat(regular_quantity_target.value)
    
    if (!isNaN(cost) && !isNaN(quantity)) {
      regular_total_target.value = cost * quantity
    } else {
      regular_total_target.value = ''
    }

    c.updateCostTotal()
  }

  updateCostTotal() {
    const c = this
    const total = c.regularTotalTargets.map(t => parseFloat(t.value)).filter(v => !isNaN(v)).reduce((sum, v) => sum + v, 0)
    c.costTotalTarget.value = total
  }

  
  updateSupplierTotal(e) {
    const c = this
    const parent = e.target.closest('tr')
    const supplier_cost_target = c.supplierCostTargets.find(t => parent.contains(t))
    const supplier_quantity_target = c.supplierQuantityTargets.find(t => parent.contains(t))
    const supplier_total_target = c.supplierTotalTargets.find(t => parent.contains(t))

    const cost = parseFloat(supplier_cost_target.value)
    const quantity = parseFloat(supplier_quantity_target.value)
    
    if (!isNaN(cost) && !isNaN(quantity)) {
      supplier_total_target.value = cost * quantity
    } else {
      supplier_total_target.value = ''
    }

    c.updateSupplierCostTotal()
  }

  updateSupplierCostTotal() {
    const c = this
    const total = c.supplierTotalTargets.map(t => parseFloat(t.value)).filter(v => !isNaN(v)).reduce((sum, v) => sum + v, 0)
    c.supplierCostTotalTarget.value = total
  }

  async applyPreset({ detail: { _value, _text, _selectedItem, selectedOptions }}) {
    const c = this
    let { id, title, internalDescription, capturing } = selectedOptions[0].dataset
    if (parseInt(id) == 62) {

      const response = await get(`/projects/${this.projectIdTarget.value}/contact`, { 
        query: {
          'fields[]': 'c_Name',
        }
      })
      if (response.ok) {
        const contact = await response.json
        c.setTitle(`${contact.c_Name} Package`)
      }

      c.setDueDate(new Date())
      c.setEmployee(c.currentUserLoginValue)
      c.setStatus('finished')

    } else {
      c.setCapturingCheckbox(capturing)
      c.setTitle(title)
      c.setInternalDescription(internalDescription)
    }
  }

  setStatus(status) {
    $(this.statusDropdownTargets).val(status).trigger('change')
  }

  setEmployee(login) {
    $(this.departmentDropdownTarget).dropdown('clear')
    $(this.employeeDropdownTarget).dropdown('set exactly', login)
    $(this.supplierDropdownTarget).dropdown('clear')
  }

  setDueDate(date) {
    if (this.hasDueDateTarget) {
      const d = date.toLocaleDateString("de-DE")
      const t = date.toLocaleTimeString("de-DE", { hour: "2-digit", minute: "2-digit", hour12: false })
      this.dueDateTarget.value = `${d} ${t}`
    }
  }

  setCapturingCheckbox(capturing) {
    if (this.hasCapturingCheckboxTarget) this.capturingCheckboxTarget.checked = capturing === "true"
  }

  setTitle(title) {
    if (this.hasTitleTarget) this.titleTarget.value = title
  }

  setInternalDescription(description) {
    if (this.hasInternalDescriptionTarget) this.internalDescriptionTarget.value = description
  }

  close(event) {
    if (event.detail.success) {
      $(this.element).modal('hide')
    }
  }
}
