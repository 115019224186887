import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    delivery_orders.edit()
  }

  openAsset(event) {
    window.open(`/assets/${event.params.id}/edit?tab=additional`, '_blank')
  }

  addAsset(event) {
    delivery_orders.select_package_component_asset($(event.target), event.params.clone)
  }

  openPackageFileModal(event) {
    delivery_orders.delivery_package_file_modal($(event.target).closest('.show-dpf-modal'))
  }
}
