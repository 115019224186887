import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    if (this.element?.parentNode?.nodeName == 'TURBO-FRAME') {
      if (this.element.dataset?.modalType === "basket") {
        $('body').find(".quicklook.modal").remove()
        $('body').find(".dimmer").remove()
        $('body').removeClass("dimmable dimmed scrolling")
      }
      this.show($(this.element))
    }
  }
  
  fill_asset_list(modal, asset_ids) {
    const add_transfer_asset_link = modal.find('.add_nested_fields_link[data-object-class="transfer_asset"]')    
    for (const a_id of asset_ids) { 
      add_transfer_asset_link.trigger('click', [{ asset: {a_ID: `${a_id}` } }])
    }
    $(".transfer_asset_count").text(asset_ids.length)
  }

  show(modal) {
    let c = this
    modal.off('turbo:frame-load')
    modal.on('turbo:frame-load', function(_e) {
    })

    modal.modal({
      autofocus: false,
      onShow() {
        transfers.init_form(modal)
      },
      onHidden() {
        modal.remove()
      },
      onApprove: function() {        
      },
      onDeny: function() {
        modal.modal('hide')
      }
    })

    modal.modal("show")
    if (this.element.dataset?.modalType === "index_select") {
      c.fill_asset_list(modal, index_select.selected_ids())
    }
  }
}