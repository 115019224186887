document.addEventListener("turbo:load", function() {
  if ($("#metadata_mapping_contacts").length > 0) {
    search.init("/metadata_mapping_contacts")
    
    $('#metadata_mapping_contacts .contact.field').on('mouseenter', function() {
      $(this).find('label').append("<a target='_blank' href='/contacts/" + $("#metadata_mapping_contact_mmc_c_ID").dropdown("get value") + "'>&nbsp;&nbsp;<i class='fas fa-external-link'></i></a>")
    }).on('mouseleave', function() {
      $(this).find('label a').remove()
    })

    $('#metadata_mapping_contacts .distribution-channel.field').on('mouseenter', function() {
      $(this).find('label').append("<a target='_blank' href='/distribution_channels/" + $("#metadata_mapping_contact_mmc_dc_ID").dropdown("get value") + "/edit'>&nbsp;&nbsp;<i class='fas fa-external-link'></i></a>")
    }).on('mouseleave', function() {
      $(this).find('label a').remove()
    })

    init_dropdowns()
  }
});

var init_dropdowns = function() {
  $(".search-field.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true})

  $('.remote.contact-person.dropdown').dropdown({
    fullTextSearch: true,
    delay: {search: 300},
    apiSettings: {
      cache: false,
      url: '/metadata_mapping_contacts/user_profile_webs.json?q={/query}&search_input={/query}',
      beforeSend: function(settings) {
        settings.data.contact_id = $('#metadata_mapping_contact_mmc_c_ID').val()
        return settings
      }
    },
    templates: {
      menu: dropdownTemplates.search_menu
    }
  }).dropdown('queryRemote', '', function() {})

  $('.remote.platform-contact-person.dropdown').dropdown({
    delay: {search: 300},
    apiSettings: {
      cache: false,
      url: '/metadata_mapping_contacts/user_profile_webs.json?q={/query}&search_input={/query}',
      beforeSend: function(settings) {
        settings.data.contact_id = $('#metadata_mapping_contact_mmc_c_ID').val()
        return settings
      }
    },
    templates: {
      menu: dropdownTemplates.search_menu
    }
  }).dropdown('queryRemote', '', function() {})

  $("#metadata_mapping_contacts .default-options.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true})

  $("#metadata_mapping_contact_mmc_c_ID").dropdown({
    apiSettings: {
      cache: false,
      url: '/contacts/clients?q={/query}'
    },
    templates: {
      menu: dropdownTemplates.search_menu
    },
    onChange(_value, _text, _$choice) {
    }
  }).dropdown('set selected', $('#metadata_mapping_contact_mmc_c_ID').val());

  $('#metadata_mapping_contact_mmc_dc_ID').dropdown({
    apiSettings: {
      cache: false,
      url: '/delivery_orders/channels?q={/query}',
      data: {
        c_ID: $("#metadata_mapping_contact_mmc_c_ID").val()
      },
      beforeSend(settings) {
        settings.data.c_ID = $("#metadata_mapping_contact_mmc_c_ID").val()
        return settings;
      }
    },
    templates: {
      menu: dropdownTemplates.search_menu
    },
    onChange(_value, _text, _$choice) {
    }
  }).dropdown('set selected', $('#metadata_mapping_contact_mmc_dc_ID').val())
};
