import { StreamActions } from "@hotwired/turbo"
StreamActions.modal = function() {  
  const getAllAttributes = el => el
  .getAttributeNames()
  .reduce((obj, name) => ({
    ...obj,
    [name]: el.getAttribute(name)
  }), {})
  let { initializer, delay, options } = getAllAttributes(this)

  let initializer_function = initializer?.split('.')?.reduce( (acc, current) => acc && acc[current], window)
  let modal_target = this.templateContent?.firstChild
  if (typeof initializer_function === "function") {
    initializer_function.apply(null, [modal_target])
  } else {
    let modal = $(modal_target)
    let modal_initializer = {
      ...JSON.parse(options),
      onHidden() {
        modal.remove()
      }
    }
    modal.modal(modal_initializer)

    if (delay) {
      setTimeout(function() {
        modal.modal("show")
      }, parseInt(delay) || 0)
    } else {
      modal.modal("show")
    }
  }
}