import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    if (this.element?.parentNode?.nodeName == 'TURBO-FRAME') {
      if (this.element.dataset?.modalType === "basket") {
        $('body').find(".quicklook.modal").remove()
        $('body').find(".dimmer").remove()
        $('body').removeClass("dimmable dimmed scrolling")
      }
      this.show($(this.element))
    }
  }

  show(modal) {
    modal.off('turbo:frame-load')
    modal.on('turbo:frame-load', function(_e) {})

    modal.modal({
      autofocus: false,
      onShow() {
        baskets.init_form(modal)
      },
      onHidden() {
        modal.remove()
      },
      onApprove: function() {        
      },
      onDeny: function() {
        modal.modal('hide')
      }
    })

    modal.modal("show")
  }
}