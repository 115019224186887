import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {

  static targets = [
    'clientDropdown',
    'storagePolicyDropdown',
    'ghostInput',
    'additionalDescriptionLabel',
    'internalNotesLabel',
    'additionalDescription',
  ]

  static values = {
    paramsId: String,
  }

  connect() {
    $(this.ghostInputTargets).ghostInput()
  }

  async updateStoragePolicy({ detail: { value, _text, _selectedItem }}) {
    let c = this
    if (value.length > 0) {
      try {
        const response = await get('/assets/storage_policy', {
          query: {
            a_c_ID: [value].flat()[0],
            a_IsExplicitContent: false
          },
          responseKind: 'json'
        })
    
        if (response.ok) {
          let data = await response.json 
          if (data.client && c.hasStoragePolicyDropdownTarget) {
            $(c.storagePolicyDropdownTarget).dropdown('set exactly', data.client)
          }
        }
      } catch (error) {
        console.error('storage policy request failed', error)
      }
    }
  }

  updateGhostText({ currentTarget: { value }, params: { ghostSelector } }) {
    let element = $(this.ghostInputTargets).filter(ghostSelector)
    element.data('ghost-text', value)
    element.trigger('change')
  }

  appendAdditionalDescription() {
    this.additionalDescriptionLabelTarget.textContent = "Append To"
    if (this.hasAdditionalDescriptionTarget) {
      this.additionalDescriptionTarget.disabled = false
    }
  }

  replaceAdditionalDescription() {
    this.additionalDescriptionLabelTarget.textContent = "Replace"
    if (this.hasAdditionalDescriptionTarget) {
      this.additionalDescriptionTarget.disabled = true
    }
  }

  appendInternalNotes() {
    this.internalNotesLabelTarget.textContent = "Append To"
  }

  replaceInternalNotes() {
    this.internalNotesLabelTarget.textContent = "Replace"
  }

}

$.fn.ghostInput = function(options) {
  return this.each(function(_i, el){
    var o = $.extend({
      ghostText: "",
      ghostPlaceholder: "",
      ghostTextClass: "ghost-text",
      ghostHiddenClass: ""
    }, options)
    var element = $(el)
    if(element.ghostInputValidate) { return true }
    element.ghostInputValidate = true;
    var r = element.attr("id") || "";
    o.ghostPlaceholder = element.attr("placeholder") || o.ghostPlaceholder
    element.attr("placeholder") ? element.removeAttr("placeholder") : ""
    o.ghostText = element.attr("data-ghost-text") || o.ghostText
    o.ghosttextspan = $("<span />").text(o.ghostPlaceholder)
    o.ghostHiddenLabel = $("<span />").css({"visibility":"hidden"}).addClass(o.ghostHiddenClass)
    o.ghostBox = $("<span />").attr("for", r).addClass(o.ghostTextClass).append(o.ghostHiddenLabel).append(o.ghosttextspan)

    element.parent().prepend(o.ghostBox)
    o.ghostBox.click(function() {
      element.focus()
    })
    element.off(".ghost_input")
    element.on("keyup.ghost_input keydown.ghost_input keypress.ghost_input change.ghost_input",function() {
      setTimeout(function() {
        var t = "" == $.trim(element.val()) ? o.ghostPlaceholder: $(element).data("ghost-text")//o.ghostText
        o.ghostHiddenLabel.text(element.val())
        o.ghosttextspan.text(t)
      }, 0)
    })
    return true
  })
}