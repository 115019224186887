document.addEventListener("turbo:load", function() {
  if ($("#roles").length > 0) {
    roles.init();
  }
});

var roles = {
  init() {
    search.init("/roles");
    roles.init_dropdowns();
  },

  init_dropdowns() {
    $("#roles .dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
  }
};

window.roles = roles;
