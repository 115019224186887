import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    modelId: Number
  }

  connect() {
  }
}
