import { StreamActions } from "@hotwired/turbo"
StreamActions.update_fields = function() {  
  const getAllAttributes = el => el
  .getAttributeNames()
  .reduce((obj, name) => ({
    ...obj,
    [name]: el.getAttribute(name)
  }), {})
  let { target, targets, fields } = getAllAttributes(this)

  console.log("filling fields")
  fields = JSON.parse(fields)

  const setValue = (element, selector, value) => {
    const field = element.querySelector(`${selector}`)
    if (field) {
      console.log(`filling ${selector} with ${value}`)
      $(field).val(value)
      field.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }
  const setFields = (element, fields) => {
    if (!element) return
    for (const [selector, value] of Object.entries(fields)) {
      setValue(element, selector, value)
    }
  }

  if (target) {
    const target_element = document.getElementById(target)
    setFields(target_element, fields)
  } else if (targets) {
    const target_elements = document.querySelectorAll(targets)
    target_elements.forEach(el => {
      setFields(el, fields)
    })
  } else {
    setFields(document, fields)
  }
}