var workflow_50008 = {
  init(target, params_id) {
    target.find('.format.config.selection').dropdown('setting', 'onChange', (_value, _text, _$choice) => workflow_50008.set_on_change(target, params_id));
    target.find('.framerate.config.selection').dropdown('setting', 'onChange', (_value, _text, _$choice) => workflow_50008.set_on_change(target, params_id));
    target.find('.tc.config').change(function() {
      assets.clean_framerate($(this))
      workflow_50008.set_on_change(target)
    });
    target.find('.tc.config').parent().find('.dropdown.button .params-input').change(function() {
      workflow_50008.set_on_change(target)
    });
    workflow_50008.init_asset_spec_dropdown(target, params_id)
    awaited_asset.init(target, params_id, { asset_type: 11 })
    $('.workflow_50008 .create.asset.checkbox').change(function() {
      if ($(this).checkbox("is checked")) {
        target.find('.awaited.asset .checkbox').checkbox('uncheck')
      }
    })

    workflow_50008.check_framerate(target)
  },

  check_framerate(target) {
    var framerate = $(target).find('.framerate.config.selection').dropdown('get value')

    if (framerate == 9) {
      $(target).find('.tc.fields').removeClass("two").addClass("three")
      $(target).find('.timecode_format.field').show()
      
    } else {
      $(target).find('.tc.fields').addClass("two").removeClass("three")
      $(target).find('.timecode_format.field').hide()
    }
  },

  set_on_change(target, params_id) {
    
    var itt_message = $(`
    <div class="ui timecode visible warning message">
      <i class="close icon"></i>
      <div class="header">
        Timecode deviation detected!
      </div>
      <p>The Timecode offset differs from the sync slave start timecode.<br>Please check whether this is intened for the selected specs with an <b>ITT</b> output format.</p>
    </div>
    `).hide()

    var specs = $(target).find('.asset-spec-type.remote.search.dropdown').dropdown('get value')

    $.ajax({
      url: '/asset_spec_types?q={/query}&search_input={/query}',
      contentType: "application/json",
      data: { could_be_itt: specs },
      dataType: 'json',
      success: function(response){
          if (response.result) {
            const sync_tc = $(target).find('.sync.group.tc').val()
            var current_tc = $(target).find('.params-input.tc.config').val()
            var plusminus = $(target).find('.params-input.tc.config').parent().find('.dropdown.button .params-input').val()
            
            $(target).find('.params-input.tc.config').closest('.nested-fields').find('.timecode.warning.message').hide('normal', function(){ $(this).remove() })

            if (sync_tc != current_tc || (sync_tc == current_tc && plusminus > 0)) {
              $(target).find('.params-input.tc.config').closest('.field').addClass('warning')
              $(target).find('.params-input.tc.config').closest('.nested-fields').prepend(itt_message)
              $('.timecode.warning.message .close').on('click', function() { $(this).closest('.message').transition('fade') })
              itt_message.show()
            } else {
              $(target).find('.params-input.tc.config').closest('.field').removeClass('warning')
              $(target).find('.params-input.tc.config').closest('.nested-fields').find('.timecode.warning.message').hide('normal', function(){ $(this).remove() })
            }
          } else {
            $(target).find('.params-input.tc.config').closest('.field').removeClass('warning')
            $(target).find('.params-input.tc.config').closest('.nested-fields').find('.timecode.warning.message').hide('normal', function(){ $(this).remove() })
          }
      }
    })

    workflow_50008.check_framerate(target)

    workflow.update_whparams($(target))
    awaited_asset.validate(target, params_id)
  },
  
  init_asset_spec_dropdown(target, params_id) {
    const asset_spec_type_dropdown = $(target).find('.asset-spec-type.remote.search.dropdown')
    asset_spec_type_dropdown.dropdown({
      apiSettings: {
        cache: false,
        url: '/asset_spec_types?q={/query}&search_input={/query}',
        data: {
          search_distribution_channel_types: $(`#distribution_channel_types_${params_id}`).parent().dropdown('get value'),
          search_asset_types: 11,
          search_subtitle_config: true
        },
        beforeSend(settings) {
          settings.data.search_distribution_channel_types = $(`#distribution_channel_types_${params_id}`).parent().dropdown('get value')
          return settings
        }
      },
      onChange() {
        workflow_50008.set_on_change(target, params_id)
      }
    })
  }
};

window.workflow_50008 = workflow_50008;
