const workflow_50019 = {
  init(target, params_id) {
    $(target).find('.download-parameter').on("change", function() {
      const selected_files = $(target).find('.download.checkbox.checked')
      const pluralized = selected_files.length === 1 ? "file" : "files"
      $(target).find('.filecount.header').text(`${selected_files.length} ${pluralized} to retrieve`)
      
      const downloads = []

      for (let file of Array.from(selected_files)) {
        const location = $(file).data('location')
        const asset_transfer_account_id = $(file).data('assetTransferAccountId')
        
        downloads.push({ 
          source: location, 
          asset_transfer_account_id: asset_transfer_account_id
        })
      }
      $(`#downloads_${params_id}`).val(JSON.stringify(downloads))
      workflow.update_whparams($(target))
    })
    $(target).find('.download.checkbox :first').trigger("change")
  }
};

window.workflow_50019 = workflow_50019;
