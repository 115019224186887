import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
const humanFormat = require("human-format")

// Connects to data-controller="filesum"
export default class extends Controller {
  
  static values = {
    assetIds: Array,
    filesize: Number,
    showHuman: Boolean
  }

  static targets = [ "label" ] 

  connect() {
    this.refresh()
  }

  async refresh() {
    this.filesizeValue = await this.fetchFileSum()
  }

  filesizeValueChanged() {
    this.updateLabel()
  }

  async fetchFileSum() {
    const response = await post('/assets/filesize', { 
      body: {
        asset_ids: this.assetIdsValue,
        summarize: true
      }
    })

    if (response.ok) {
      const result = await response.json
      return result
    }
  }

  updateLabel() {
    const labelText = this.showHumanValue ? humanFormat.bytes(this.filesizeValue) : this.filesizeValue
    this.labelTarget.innerText = labelText
  }

  toggleShowHuman() {
    this.showHumanValue = !this.showHumanValue
    this.updateLabel()
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.filesizeValue)
    $('body').toast({ message: `Copied Filesize to Clipboard!`, class: 'success' })
  }
}
