document.addEventListener("turbo:load", function() {

  notifications.menu_button();

  if ($("#notifications").length > 0) {
    search.init("/notifications");

    notifications.init_dropdowns();
    notifications.init_overview();
    notifications.init_charts();
  }
});

var notification_preview_request = null

var notifications = { 
  init_dropdowns() {
    $(".search-field.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
    $("#notifications .default-options.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
  },

  init_overview() {
    calendar.datepicker(".scheduled_date_start", { position: 'top left', lastResort: 'top left' } );
    calendar.datepicker(".scheduled_date_end", { position: 'top right', lastResort: 'top right' } );
    calendar.datepicker(".sent_at_date_start", { position: 'top left', lastResort: 'top left' } );
    calendar.datepicker(".sent_at_date_end", { position: 'top right', lastResort: 'top right' } );

    $('.notification.overview .over.due.item').on('click', function() {
      $('#search_overdue').val(function(_index, value) { return value == "" ? "true" : ""; } );
      notifications.update_search_results();
    });

    $('.notification.overview .scheduled.item').on('click', function() {
      $('#search_scheduled').val(function(_index, value) { return value == "" ? "true" : ""; } );
      notifications.update_search_results();
    });

    $('.notification.overview .cancelled.item').on('click', function() {
      $('#search_cancelled').trigger('click');
      // $(".start-search .primary.button").trigger('click');
      notifications.update_search_results();
    });

    $('.notification.overview .sent.item').on('click', function() {
      $('#search_sent').val(function(_index, value) { return value == "" ? "true" : ""; } );
      notifications.update_search_results();
    });

    $('.notification.overview .upcoming.next.week.item').on('click', function() {
      // $("#search_asset_request_status").dropdown('set exactly',['2','3']);
      const today = new Date();
      const strToday = today.getDate() + "." + (today.getMonth()+1) + "." + today.getFullYear();
      today.setDate(today.getDate() + 7);
      const strWeek = today.getDate() + "." + (today.getMonth()+1) + "." + today.getFullYear();

      $(".scheduled_date_start .calendar.datepicker").calendar("set date", strToday);
      $(".scheduled_date_end .calendar.datepicker").calendar("set date", strWeek);
      notifications.update_search_results();
    });

    $('.notification.overview .upcoming.next.two.weeks.item').on('click', function() {
      // $("#search_asset_request_status").dropdown('set exactly',['2','3']);
      const today = new Date();
      const strToday = today.getDate() + "." + (today.getMonth()+1) + "." + today.getFullYear();
      today.setDate(today.getDate() + 14);
      const strWeek = today.getDate() + "." + (today.getMonth()+1) + "." + today.getFullYear();

      $(".scheduled_date_start .calendar.datepicker").calendar("set date", strToday);
      $(".scheduled_date_end .calendar.datepicker").calendar("set date", strWeek);
      notifications.update_search_results();
    });
  },
  update_search_results() {
    $(".start-search .primary.button").trigger('click');
  },

  init_index_body() {
    notifications.init_charts()

    general.quicklook_icon('#notifications .quicklook')
    $('#notifications .quicklook').on('click', function() {
      const preview_button = $(this)
      let target_type = preview_button.data('target-type')
      let target_id = preview_button.data('target-id')
      if(notification_preview_request) { notification_preview_request.abort() }
      notification_preview_request = $.ajax({
        url: `/notification_types/preview_modal`,
        type: "GET",
        data: {
          model_name: target_type,
          model_id: target_id,
        },
        beforeSend() {
          preview_button.addClass('loading')
        }
      }).done(function(data) {
        $(data).appendTo('body')
        let preview_modal = $('#notification_preview_modal')
        preview_modal.modal({
          observeChanges: true,
          autofocus: false,
          closable: false,
          allowMultiple: true,
          onShow() {
            notification_types.init_notification_preview_modal(preview_modal)
          },
          onHidden() {
            preview_modal.remove()
          }
        }).modal('show')
      }).fail(function(_xhr, _textStatus, errorThrown) {
        if (errorThrown != 'abort') { console.log(errorThrown) }
      }).always(() => preview_button.removeClass('loading'))

    })
  },

  init_charts() {
    // charts.init();
    if ($("#notification-chart").length > 0) {
      notifications.fill_notification_chart();
    }
  },
  fill_notification_chart() {
    console.log("called notification chart");
    return $.ajax({
      // data: {
      //   projects_filter: $('#projects_filter').val(),
      //   platform_filter: $('#platform_filter').val()
      // },
      data: search.collect_search(),
      url: "/charts/notification_history",
      type: "GET",
      dataType: "json",
      success(data, _textStatus, _xhr) {
        charts.notification_chart('notification-chart', data);
      }
    });
  },

  menu_button() {
    $(".main.menu .user-notifications.item").on('click', function () {
      notifications.open_quickmenu()
    });
  },

  open_quickmenu(_selection_only = false, _target_model = "", _target_id = "") {
    $('body').dimmer({
      dimmerName: "quickmenu",
      onShow() {
        $(".ui.dimmer.quickmenu").append("<div id='quickmenu' class='open animate__animated animate__slideInRight'><div class='quickmenu header'></div><div class='quickmenu body'><span class='center'><br><br><br><div class='fa-3x'><i class='far fa-circle-notch fa-spin'></i></div></span></div></div>");
        $("#quickmenu .quickmenu.header").append("<a href='/notifications'><h3 class='ui header'><i class='fas fa-bell fa-fw fa-sm icon' aria-hidden='true'></i> <span>Notifications</span></h3></a>");
        notifications.load_index();
      },
      onHide() {
        $("#quickmenu").removeClass("animate__animated animate__slideInRight")
        $("#quickmenu").addClass("animate__animated animate__slideOutRight")
        setTimeout(function() {
          $(".ui.dimmer.quickmenu").remove();
          $("body").removeClass("dimmable dimmed")
        }, 800)
      }
    }).dimmer('show')
  },

  load_index() {
    $.ajax({
      url: "/notifications/quickmenu",
      success(data, _textStatus, _xhr) {
        $("#quickmenu .quickmenu.body").html(data);
        $("#quickmenu .quickmenu.body .menu .item").tab()

        $('.mark-all-mam-notifications-read').on('click', function() {
          $.ajax({
            url: this.dataset.path,
            method: 'POST',
            dataType: 'json',
          }).done(function() {
            $('#notification_counter').html('')
            $("#quickmenu").removeClass("animate__animated animate__slideInRight")
            $("#quickmenu").addClass("animate__animated animate__slideOutRight").one('animationend', function() {
              $(".ui.dimmer.quickmenu").remove();
              $("body").removeClass("dimmable dimmed")
              console.log('Animation ended!');
            });
          }).fail(function() {
            $.toast({
              title: 'Mark All as Read',
              message: 'An unknown error occurred!',
              showIcon: 'ban',
              class: 'error',
              displayTime: 2000,
            })
          })
        })
        context_menu.init_menu();
      },
      error(_jqXHR, _textStatus, errorThrown) {
        console.log(errorThrown);
        $("#quickmenu .quickmenu.body").html("<br><br><span class='center'><div class='ui red basic label'>Loading Error</div></span>");
      }
    });
  },
};

window.notifications = notifications;
