var workflow_50012 = {
  init(target, params_id) {
    const contact_search = target.find('.search.contacts');

    return contact_search.search({
      minCharacters: 3,
      apiSettings: {
        url: '/users/contacts?q={/query}'
      },
      onSelect(result, _response) {
        $("#contact-list").append(`<li class="list-group-item quicklink-contact-id" data-id=${result.upc_id} style='height: 30px;'>${result.upc_name} - ${result.upc_email}<div class="ui mini icon basic button pull-right remove-quicklink-contact" href="#"><i class="fas fa-times icon"></i></div></li>`);
        $('.remove-quicklink-contact').on('click', function() {
          $(this).parent().remove();
          return $(`#recipients_${params_id}`).val(workflow_50012.contact_field("#contact-list")).change();
        });

        contact_search.search("set value", "");
        contact_search.search("hide results");
        $(`#recipients_${params_id}`).val(workflow_50012.contact_field("#contact-list")).change();

        return false;
      }
    });
  },

  contact_field(contact_list) {
    const contact_ids = [];
    $.each($(contact_list).find("li"), (i, val) => contact_ids.push($(val).attr("data-id")));
    return contact_ids;
  }
};

window.workflow_50012 = workflow_50012;
