import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  diff = null
  filtering = false
  timers = {}

  static targets = [
    'assetCount',
    'assetCountFilterIcon',
    'assetCountLabel',
    'assetIds',
    'assetList',
    'controls',
    'filterDropdown',
    'filterArea',
    'filterButton',
    'removeUnfilteredButton',
    'removeFilteredButton',
    'warningLabel',
    'warningLabelIcon',
    'warningLabelText',
    'warningLabelDetail',
    'errorLabel',
    'errorLabelIcon',
    'errorLabelText',
    'errorLabelDetail',
  ]

  static values = {
    assetIds: { type: Array, default: [] },
  }

  initialize() {
    
  }
  connect() {
    this.assetListObserver = new MutationObserver(() => this.debounce(this.updateNFPLabel)())
    this.assetListObserver.observe(this.assetListTarget, { childList: true, subtree: true })

    this.debounce(this.initFilterDropdown)()
  }
  disconnect() {
    this.assetListObserver.disconnect()
  }

  debounce(f, delay = 200) {
    return (...args) => {
      const function_name = f.name;
      if (this.timers[function_name]) clearTimeout(this.timers[function_name])
      this.timers[function_name] = setTimeout(() => f.apply(this, args), delay)
    }
  }

  updateNFPLabel() {
    let nfp_count = this.assetListTarget?.querySelectorAll('.not_for_production.label')?.length
    this.errorLabelTextTarget.innerText = nfp_count

    if (nfp_count > 0) {
      $(this.controlsTarget).show()
    } else {
      if (!$(this.warningLabelTarget).is(':visible')) $(this.controlsTarget).hide()
    }

    if (nfp_count > 0) {
      $(this.errorLabelTarget).show()

      let error_tooltip = `<span>${nfp_count}</span> Assets Not for Production!`
      this.errorLabelTarget.setAttribute('data-html', error_tooltip)
      this.errorLabelTarget.setAttribute('data-position', 'bottom right')
    } else {
      $(this.errorLabelTarget).hide()
    }
    $(this.errorLabelTarget).popup({ hoverable: true, inline: true, position: 'top right', prefer: 'opposite', delay: { show: 500, hide: 0 } })
  }

  async loadDiff() {
    try {
      const params = new URLSearchParams()
      this.assetIdsValue.forEach(id => params.append('asset_ids[]', id))

      const response = await get(`/workflows/asset_diff?${params.toString()}`)
      if (response.ok) {
        this.diff = await response.json
      } else {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
    } catch (error) {
      console.error('Fetch request failed:', error)
    }
  }

  async initFilterDropdown() {
    await this.loadDiff()
    let categories = {}
    Object.values(this.diff).forEach(asset => { 
      Object.entries(asset).forEach(([attr, { value, score, human_name, human_value }]) => {
        if (score != 0.0) {
          if (!categories[attr]) {
            categories[attr] = []
          }
          let item = { value, score, human_name, human_value }
          
          let exists = categories[attr].some(existingItem => 
            existingItem.value === item.value &&
            existingItem.score === item.score &&
            existingItem.human_name === item.human_name &&
            existingItem.human_value === item.human_value
          )
          if (!exists) { categories[attr].push(item) }
        }
      })
    })

    let dropdown_values = []
    Object.entries(categories).forEach(([category, items]) => {
      dropdown_values.push({
        name     : items[0].human_name,
        type     : 'header'
      })
      items.forEach(item => {

        let v = item.value === null ? `${category}:::__NULL__` : `${category}:::${item.value}`
        let dv = ["", null].includes(item.value) ? '-' : item.human_value ?? item.value//item.value
        dropdown_values.push({ 
          name: `<span"><a class="ui empty circular label" style="background-color: hsl(24, ${item.score * 83}%, 53%)"></a>&nbsp;${dv}</span>`, 
          value: v,
          text: `<span><span class="ui small text" style="color: darkgray">${item.human_name}</span> ${dv}</span>`,
          type: 'item',
        })
      })
    })

    window.diff = this.diff
    window.dropdown_values = dropdown_values
    this.dropdown_values = dropdown_values

    let c = this

    $(this.filterDropdownTarget).dropdown({
      placeholder: 'Select Filters',
      forceSelection: false,
      clearable: true,
      delay: 300,
      values: dropdown_values,
      onChange(_value, _text, _choice) {
        c.debounce(c.filterWorkflowAssets)()
      }
    }).dropdown('clear')
    $(this.filterDropdownTarget).find('.header').addClass('ui small dividing')

    this.showControls()
  }

  toggleFiltering() {
    $(this.filterAreaTarget).toggle()
    if ($(this.filterAreaTarget).is(':visible')) {
      this.filtering = true
    } else {
      this.filtering = false
    }
  }

  async filterWorkflowAssets() {
    let c = this
    let dropdown_values = $(this.filterDropdownTarget).dropdown('get values')
    if (this.filtering && dropdown_values.length > 0) {

      let filter_params = {}
      let dropdown_values = $(this.filterDropdownTarget).dropdown('get values')

      dropdown_values.forEach(dropdown_value => {
        let [column_name, value] = dropdown_value.split(':::')
        value = value === '__NULL__' ? null : value
        if (filter_params[column_name]) {
          if (Array.isArray(filter_params[column_name])) {
            filter_params[column_name].push(value)
          } else {
            filter_params[column_name] = [filter_params[column_name], value]
          }
        } else {
          filter_params[column_name] = value
        }
      })
      try {
        let response = await $.ajax({
          url: `/workflows/filter_workflow_assets`,
          type: 'GET',
          dataType: 'json',
          data: {
            asset_ids: this.assetIdsValue, 
            filter_params: filter_params
          },
        })
        let show_asset_ids = response.results
        $(c.assetListTarget).find('.asset-id-display.card').each(function(i, card) {
          if (show_asset_ids.some(id => id == card.dataset.modelId)) {
            $(card).show()
          } else {
            $(card).hide()
          }
        })
      } catch(error) {
        $(c.assetListTarget).find('.asset-id-display.card').show()
      }
    } else {
      $(c.assetListTarget).find('.asset-id-display.card').show()
    }

    this.enableDisableDropdownItems()
    this.updateAssetCount()
  }

  showControls() {
    let problematic_asset_count = Object.entries(this.diff).filter(([_key, subitems]) => Object.values(subitems).some(item => item.score !== 0)).length
    
    this.warningLabelTextTarget.innerText = problematic_asset_count

    if (problematic_asset_count > 0) {
      $(this.controlsTarget).show()
    } else {
      if (!$(this.errorLabelTarget).is(':visible')) $(this.controlsTarget).hide()
    }

    if (problematic_asset_count > 0) {
      $(this.warningLabelTarget).show()
      $(this.filterButtonTarget).show()

      let warning_tooltip = `<span>${problematic_asset_count}</span> Assets with Potential Issues!`
      this.warningLabelTarget.setAttribute('data-html', warning_tooltip)
      this.warningLabelTarget.setAttribute('data-position', 'bottom right')
    } else {
      $(this.warningLabelTarget).hide()
      $(this.filterButtonTarget).hide()
      $(this.filterAreaTarget).hide()
    }

    $(this.warningLabelTarget).popup({ position: 'top center', hoverable: false, inline: true, variation: 'fluid', delay: { show: 500, hide: 0 } })
  }

  updateAssetCount() {
    let visible_asset_count = $(this.assetListTarget).find('.asset-id-display.card:visible').length
    let overall_asset_count = this.assetIdsValue.length
    
    $(this.removeUnfilteredButtonTarget).toggleClass('disabled', visible_asset_count === overall_asset_count);
    $(this.removeFilteredButtonTarget).toggleClass('disabled', visible_asset_count <= 0)

    if (visible_asset_count == overall_asset_count) {
      $(this.assetCountFilterIconTarget).hide()
      $(this.assetCountTarget).text(overall_asset_count)
    } else {
      $(this.assetCountFilterIconTarget).show()
      $(this.assetCountTarget).text(`${visible_asset_count} / ${overall_asset_count}`)
    }

    this.removeUnfilteredButtonTarget.textContent = `Discard ${overall_asset_count - visible_asset_count} Unfiltered`
    this.removeFilteredButtonTarget.textContent = `Discard ${visible_asset_count} Filtered`
  }

  updateAssets(event) {
    $(this.assetListTarget).find('.asset-id-display.card').show()
    let asset_ids = $(this.assetListTarget).find('.asset-id-display.card').map((_i,x) => x.dataset.modelId).get().map(Number)
    if (event?.target?.id != 'workflow_asset_ids') {
      $(this.assetIdsTarget).val(asset_ids.join(' '))
      $("#workflow_search").data("asset-ids", asset_ids)
    }
    this.assetIdsValue = asset_ids
    this.updateAssetCount()
    this.debounce(this.initFilterDropdown)()
  }

  enableDisableDropdownItems() {
    $(this.filterDropdownTarget).find('.item,.header').show().removeClass('disabled')

    let displayed_asset_ids = $(this.assetListTarget).find('.asset-id-display.card:visible').map((_i,x) => x.dataset.modelId).get().map(Number)
    let displayed_asset_attributes = displayed_asset_ids.flatMap(id => {
      let record_attributes = this.diff[id]
      return Object.keys(record_attributes).map(attr => ({attr: attr, val: record_attributes[attr].value}))
    })
    $(this.filterDropdownTarget).find('.item').each(function(_i, item){
      let [attr, value] = $(item).data('value').split(':::')
      let should_be_displayed = displayed_asset_attributes.some(
        obj => obj.attr == attr && obj.val?.toString() == value
      )
      if(should_be_displayed)
        $(item).removeClass('not_applicable')
      else
        $(item).addClass('not_applicable')
    })
  }

  removeUnfilteredAssets() {
    console.log('remove unfiltered')
    $(this.assetListTarget).find('.asset-id-display.card').not(':visible').remove()
    this.updateAssets()
  }

  removeFilteredAssets() {
    $(this.assetListTarget).find('.asset-id-display.card:visible').remove()
    this.updateAssets()
  }

}