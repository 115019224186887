const title = {
  init(target, params_id) {
    assets.language_buttons(target)
    if ($(`#workflow_id_${params_id}`).val() === "6902") {
      return $(`#additional_description_${params_id}`).text(`This audio asset was created by extracting the audio embedded in the video with the asset ID ${parseInt($(target).find('.workflow-job-asset-id').val().toString())}.`);
    }
  }
};

window.title = title;
