const { element } = require("prop-types");

document.addEventListener("turbo:load", function() {
  if ($("#delivery_orders.index").length > 0) {
    delivery_orders.index()
  }
  
  if ($("#delivery_orders.edit").length > 0) {
    //delivery_orders.edit()
  }

  if ($("#delivery_orders.show").length > 0) { 
    delivery_orders.show()
  }

  if ($("#delivery_orders.clone").length > 0) { 
    delivery_orders.clone()
  }
});

let delivery_orders_drag_element
let asset_browser_request = null
let notification_modal_request = null
let publish_wizard_request = null
let notification_preview_request = null

const delivery_orders = {
  index() {
    general.init_common()
    search.init("/delivery_orders").then(function() {
      delivery_orders.init_index_body()
      delivery_orders.init_import_project_modal()
  
      calendar.datepicker(".modified_date_start", { position: 'top left', lastResort: 'top left' } )
      calendar.datepicker(".modified_date_end", { position: 'top right', lastResort: 'top right' } )
  
      calendar.datepicker(".creation_date_start", { position: 'top left', lastResort: 'top left' } )
      calendar.datepicker(".creation_date_end", { position: 'top right', lastResort: 'top right' } )
    })
  },

  edit() {
    (function (original) {
      jQuery.fn.clone = function () {
        const result           = original.apply(this, arguments),
            my_textareas     = this.find('textarea').add(this.filter('textarea')),
            result_textareas = result.find('textarea').add(result.filter('textarea')),
            my_selects       = this.find('select').add(this.filter('select')),
            result_selects   = result.find('select').add(result.filter('select'));

        for (let i = 0, l = my_textareas.length; i < l; ++i) $(result_textareas[i]).val($(my_textareas[i]).val());
        for (let i = 0, l = my_selects.length;   i < l; ++i) {
          for (let j = 0, m = my_selects[i].options.length; j < m; ++j) {
            if (my_selects[i].options[j].selected === true) {
              result_selects[i].options[j].selected = true;
            }
          }
        }
        return result;
      };
    }) (jQuery.fn.clone);
    general.init_common()

    // find including root node
    $.fn.findAll = function(selector) {
      return this.find(selector).add(this.filter(selector));
    };

    // sort elements
    $.fn.sortElements = (function(){
      const sort = [].sort
      return function(comparator, getSortable) {
          getSortable = getSortable || function(){return this}
          const placements = this.map(function(){
              const sortElement = getSortable.call(this),
                  parentNode = sortElement.parentNode,
                  // Since the element itself will change position, we have
                  // to have some way of storing it's original position in
                  // the DOM. The easiest way is to have a 'flag' node:
                  nextSibling = parentNode.insertBefore(
                      document.createTextNode(''),
                      sortElement.nextSibling
                  )
              return function() {
                  if (parentNode === this) {
                      throw new Error(
                          "You can't sort elements if any one is a descendant of another."
                      );
                  }
                  // Insert before flag:
                  parentNode.insertBefore(this, nextSibling);
                  // Remove flag:
                  parentNode.removeChild(nextSibling);
              }
          })
          return sort.call(this, comparator).each(function(i){
              placements[i].call(getSortable.call(this))
          })
      }
    })()

    delivery_orders.load_action_bar()

    delivery_orders.view_init()

    delivery_orders.init_package_button()
    delivery_orders.init_form()

    delivery_orders.key_handler()

    // validations
    delivery_orders.check_validation_status()
    delivery_orders.init_validations()

    delivery_orders.check_destination_path()
  },

  show() {
    general.init_common()
    $('#delivery_orders.show .menu .item').tab()
    
  },

  clone() {
    $('form[id*="edit_delivery_order"]').on('submit', function(e) {
      if ($("#delivery_order_do_c_ClientContactID").val() != "" && $("#delivery_order_do_dc_ID").val() != "" && $("#delivery_order_do_dct_ID").val() != "" && $("#delivery_order_do_fnc_ID").val() != "") {
        $(".body.segment").find(".message.validation").remove()
        return true
      } else {
        if ($(".body.segment").find(".message.validation").length == 0) {
          $(".body.segment").prepend(`<div class="ui warning message validation"><i class="close icon"></i><div class="content"><div class="header">Please fill all required fields</div></div>`);
          general.message_closable()
        }
        e.preventDefault()
        return false
      }
    })
  },

  load_action_bar() {
    if ($(".delivery_order_ID").val() == "") { return }

    let action_bar_content = $('#delivery_orders.edit .action-bar-content')
    $.ajax({
      url: `/delivery_orders/${$(".delivery_order_ID").val()}/action_bar`,
    }).done(function(data) {
      action_bar_content.html(data)

      popup.init()
      delivery_orders.init_modals()
    }).fail(function(_error){
    })
  },

  key_handler() {
    document.addEventListener("keydown",function(event){
      var key = event.key
      if (key == "Escape"){
        if ($(".assign.artwork.modal").length > 0) {
          $(".assign.artwork.modal").modal("hide")
        } else if ($(".create.delivery-order-artwork.modal").length > 0) {
          if (!$("#send-create-artwork").hasClass("loading")) {
            $(".create.delivery-order-artwork.modal").modal("hide")
          }
        } else if ($(".send.notification.modal").length > 0) {
          if (!$(".send.notification.button").hasClass("loading")) {
            $(".send.notification.modal").modal("hide")
          }
        }
      }
    });
  },

  adjust_form_body() {
    delivery_orders.load_action_bar()
  },

  count_packages() {
    const packages_count = $(".delivery-order-content-package-menu").length
    $(".packages-count .index.label").text(packages_count)
    $(".packages-count .count-text").text((packages_count == 1 ? "Package" : "Packages"))

    let no_elements = $(".no-elements-found")
    let content_packages = $(".overview.content-package")

    if (packages_count == 0) {
      no_elements.show()
      content_packages.hide()
    } else {
      no_elements.hide()
      content_packages.show()
    }

    return packages_count
  },

  check_delivery_order_state() {
    if ($("#delivery-order-state").val() === "7") {
      $("#delivery_orders .ui.grid .nested-fields-container .remove_nested_fields_link").addClass("disabled");
    }
  },

  studio_title_dropdown(element) {
    const package_id = $(element).findAll(".delivery_order_content_package.tab").data("package-id")
    $(element).find('.ui.mt_StudioTitle.dropdown').dropdown({
      fullTextSearch: true,
      delay: {search: 300},
      preserverHTML: false,
      apiSettings: {
        cache: false,
        url: '/delivery_orders/metadata_titles?q={/query}',
        data: {
          c_ID: $("#delivery_order_do_c_ClientContactID").val(),
          s_ID: $('#delivery_order_do_ms_ID').val(),
          exclude_ids: delivery_orders.mt_ids()
        },
        beforeXHR(xhr) { // minCharacters = 1 for features
          if (!$('#delivery_order_do_ms_ID').val() && this.urlData.query.length < 1 ) {
            xhr.abort()
          }
        },
        beforeSend(settings) {
          settings.data.c_ID = $("#delivery_order_do_c_ClientContactID").val();
          settings.data.s_ID = $('#delivery_order_do_ms_ID').val();
          settings.data.exclude_ids = delivery_orders.mt_ids();
          return settings;
        }
      },
      templates: {
        menu: dropdownTemplates.metadata_title_content_package_component_menu
      },
      onChange(value, text, choice) {
        if (choice != undefined){
          delivery_orders.set_studio_title(package_id, value, text, choice.data("episode-number"))
        }
      }
    }).dropdown('set selected', $('.ui.mt_StudioTitle.dropdown').val());
  },

  set_studio_title(package_id, value, text, episode_number) {
    $(".delivery_order_content_package.tab[data-package-id=" + package_id + "]").find("h3.header .title").text(text)
    $(".delivery-order-content-package-menu[data-item-id=" + package_id + "] .content").data("package-id", value)
    $(".delivery-order-content-package-menu[data-item-id=" + package_id + "] .title").data("title", text)
    $(".delivery-order-content-package-menu[data-item-id=" + package_id + "] .title .title-text").data("studio-title-id", value)
    $(".delivery-order-content-package-menu[data-item-id=" + package_id + "] .title .title-text").text(text)
    if (episode_number != "" && episode_number != "null" && episode_number != null && episode_number != undefined) {
      $(".delivery-order-content-package-menu[data-item-id=" + package_id + "] .title .index.label").text(episode_number)
      $(".delivery-order-content-package-menu[data-item-id=" + package_id + "] .title .index.label").css("display","")
      //$('.delivery-order-content-package-menu').sortElements(function(a,b) { return $(a).find('.index.label').text() > $(b).find('.index.label').text() ? 1 : -1 } )
    }
    $(`.delivery_order_content_package[data-package-id="${package_id}"]`).find('.distribution_channel_cat_id input').val('').trigger('change')

  },

  linked_order_dropdown(element) {
    $(element).find('.ui.linked-order.dropdown').dropdown({
      minCharacters: 2,
      apiSettings: {
        cache: false,
        url: '/delivery_orders/linked_orders?q={/query}',
        data: {
          c_ID: $("#delivery_order_do_c_ClientContactID").val()
        },
        beforeSend(settings) {
          settings.data.c_ID = $("#delivery_order_do_c_ClientContactID").val();
          return settings;
        }
      },
      templates: {
        menu: dropdownTemplates.delivery_order_linked_order_menu
      },
      onChange(_value, _text, _choice) {
        delivery_orders.check_import_from_order_button(element)
      }
    })

    delivery_orders.check_import_from_order_button(element)
  },

  check_import_from_order_button(element) {
    const import_button = $(element).find('.button.add-assets-from-order')
    const order_id = $(element).find('.ui.linked-order.dropdown').dropdown("get value")
    if (order_id != "") {
      import_button.show()
      delivery_orders.import_from_order(order_id, import_button, element)
    } else {
      import_button.hide()
    }

    delivery_orders.check_first_import_from_order()
  },

  check_first_import_from_order() {
    if ($("#import-options-loader").length > 0) { return }

    const first_order_id = $(".field.material-order.first .linked-order.dropdown").dropdown("get value")
    if (first_order_id != "") {
      
      $(".add_orders.import-option").removeClass("disabled")
    } else {
      $(".add_orders.import-option").addClass("disabled")
    }
  },

  import_from_order(order_id, import_button, element) {
    $(import_button).off("click")
    $(import_button).on("click", function() {
      $(import_button).addClass("loading")
      $.ajax({
        url: '/orders/' + order_id + '/assets_for_import',
        data: {
          dc_id: $("#delivery_order_do_dc_ID").val()
        },
        complete() {
          $(import_button).removeClass("loading")
        },
        success(data, _textStatus, _xhr) {
          const existing_asset_ids = $($(element).find(".assets-list").find('[data-asset-id]').toArray().filter(x => $(x).parents('[style*="display: none"]').length == 0)).toArray().map(x => $(x).data('asset-id') )
          $.each(data.assets, function(_index, asset) {
            if (!existing_asset_ids.includes(asset.a_ID)) {
              $(import_button).parent().parent().find('.component-asset .add_nested_fields_link').trigger('click', [{asset: asset}])
            }
          });
        },
        error(_jqXHR, _textStatus, errorThrown) {
          console.log(errorThrown);
        }
      });
    })
  },

  init_hdr_metadata_card_export() {
    const hdr_asset_ids = Array.from($('.delivery_order_content_package_component .asset.card.item .client .label')).filter((label) => $(label).text().includes("HDR")).map((label) => $(label).closest('.asset.card').data('assetId'))
    const docp_ids = delivery_orders.docp_ids()

    if (hdr_asset_ids.length > 0) {
      const export_menu_button = $('.dropdown.export.button')
      const export_hdr_metadata_button = $('.hdr-metadata-export.item')
      export_menu_button.dropdown()
      export_menu_button.show()
      export_hdr_metadata_button.show()
      export_hdr_metadata_button.on('click', function() {
        export_menu_button.addClass('loading')
        $.ajax({
          url: "/api/packager/export_hdr_metadata_card",
          type: "POST",
          data: {
            docp_ids: docp_ids,
            asset_ids: hdr_asset_ids
          }
        }).done((data) => {
          export_menu_button.removeClass('loading')
          export_hdr_metadata_button.addClass('disabled')
          $('body').toast({
            title: 'HDR metadata card export started.',
            class: 'success',
            message: '<ul>' + data.file_paths.map((fp) => `<li>${fp}</li>`).join(' ') + '</ul>',
            displayTime: 'auto'
          })
          setTimeout(() => {
            export_hdr_metadata_button.removeClass('disabled')
            export_hdr_metadata_button.html('<i class="table icon"></i> Export HDR Metadata Card')
          }, 800)
        }).fail((error) => {
          export_menu_button.removeClass('loading')
          export_hdr_metadata_button.addClass('disabled')
          export_hdr_metadata_button.html('<i class="exclamation triangle icon"></i> Error on HDR metadata card export')
          $('body').toast({
            title: 'Error on HDR metadata card export',
            class: 'error',
            message: `${error.responseJSON?.error}`,
            displayTime: 'auto',
          })
        })
      })
    }
  },

  asset_browser() {
    const search_clients_dropdown = $('.asset-browser-filter #search_clients')
    search_clients_dropdown.dropdown('queryRemote', '', function() { 
      search_clients_dropdown.dropdown('restore defaults')
      search_clients_dropdown.dropdown('set exactly', $("#delivery_order_do_c_ClientContactID").val())
    })

    $('.asset-browser-filter #search_titles').dropdown({
      apiSettings: {
        cache: false,
        url: '/titles/dropdown_search?q={/query}',
        data: {
          contact_id: search_clients_dropdown.dropdown('get value')
        },
        beforeSend(settings) {
          settings.data.contact_id = search_clients_dropdown.dropdown('get value')
          return settings;
        }
      },
      templates: {
        menu: dropdownTemplates.title_search_menu
      }
    })

    $('.asset-browser-filter').find('.search.dropdown, .search#search_arrival_date_start, .search#search_arrival_date_end, .filter_by_linked_project.checkbox .search, .filter_by_asset_spec_types.checkbox .search').one("change", function(_e) {
      $('.asset-browser-filter').find('.search.dropdown, .search#search_arrival_date_start, .search#search_arrival_date_end, .filter_by_linked_project.checkbox .search, .filter_by_asset_spec_types.checkbox .search').not("").off('change')

      const promises = $("#delivery_orders .nested-fields-container").find('.delivery_order_content_package.tab').toArray().map( x => delivery_orders.load_package($(x)) )
      Promise.allSettled(promises).then((_result) => {
        delivery_orders.asset_browser_search()
      })

      $('.asset-browser-filter').find('.search.dropdown, .search#search_arrival_date_start, .search#search_arrival_date_end, .filter_by_linked_project.checkbox .search, .filter_by_asset_spec_types.checkbox .search').not("").on("change", function(_e) {
        delivery_orders.asset_browser_search()
      })
    })
  },

  asset_browser_search() {
    if (asset_browser_request) {
      asset_browser_request.abort()
    }

    asset_browser_request = $.ajax({
      url: "/delivery_orders/asset_browser",
      data: {
        c_ID: $('.asset-browser-filter #search_clients').dropdown('get values'),
        t_ID: $('.asset-browser-filter #search_titles').dropdown('get value'),
        p_ID: $('.asset-browser-filter .checkbox.filter_by_linked_project').checkbox('is checked') ? $("#delivery_order_do_p_ID").val() : undefined,
        dct_ID: $('.asset-browser-filter .checkbox.filter_by_asset_spec_types').checkbox('is checked') ? $("#delivery_order_do_dct_ID").val() : undefined,
        afo_ID: $('.asset-browser-filter #search_container').dropdown('get values'),
        arrival_date_start: $('#search_arrival_date_start').val(),
        arrival_date_end: $('#search_arrival_date_end').val(),
        s_ID: $('#delivery_order_do_ms_ID').val(),
        already_assigned: $($(".assets-list").find('[data-asset-id]').toArray().filter(x => $(x).parents('[style*="display: none"]').length == 0)).toArray().map(x => $(x).data('asset-id') )
      },
      beforeSend: function() {
        
        $('.tab .asset_browser').html('<br><br><br><br><br><div class="ui active centered inline loader"></div>')
      },
    }).done(function(data) {
      $(".tab .asset_browser").html(data)

      // remove links and make asset cards draggable
      $('.asset-browser-list').find('.asset.card').find('a').removeAttr('href')
      $('.asset-browser-list').find('.asset.card').attr("draggable", "true");
      $('.asset-browser-list').find('.asset.card').on({
        dragstart: function(e) {
          // create drag "image"
          let d = $(`<div class="ui blue mini label dragging">${$(e.target).data('asset-id')}</div>`);
          d.css({
            "width": "auto",
            "height": "auto",
            'font-size': '12px',
          })
          d.addClass('dragging')
          delivery_orders_drag_element = d
          $('body').append(delivery_orders_drag_element)

          const offsetX    = d.width() - 10
          const offsetY    = d.height() + 5
          e.originalEvent.dataTransfer.setData('asset-id', $(this).data('asset-id'))
          e.originalEvent.dataTransfer.setData('content-type', $(this).find('.client .label[data-content-type]').data('content-type') )
          e.originalEvent.dataTransfer.setDragImage(delivery_orders_drag_element[0], offsetX, offsetY)
          $(this).addClass('drag-start')
        },
        dragenter: function(_e) {
          $(this).removeClass('drag-start')
          $(this).addClass('drag-enter')
        },
        dragexit: function(_e) {
          $(this)[0].className = 'drag'
          $(this).removeClass('drag-start drag-enter')
        },
        dragend: function(_e) {
          $(delivery_orders_drag_element).remove()
        }
      })

    }).fail(function(_xhr, _textStatus, errorThrown) {
      if (errorThrown != 'abort') {
        console.log("error loading asset_browser")
      }
    })  
  },

  perform_close_tabs(active_tabs) {
    $(active_tabs).each((_i, item) => $(item).removeClass('active').removeClass('blue'))
  },

  init_dropdowns(namespace) {
    $(namespace).find(".default-options.dropdown").not('.remote').dropdown({ fullTextSearch: true });

    $(namespace).find('.import.dropdown').dropdown({ 
      action: "hide", 
      forceSelection: false,
      onShow: function() {
        if ($("#import-options-loader").length > 0) {
          $(".import-option").addClass("disabled")

          const promises = $("#delivery_orders .nested-fields-container").find('.delivery_order_content_package.tab').toArray().map( x => delivery_orders.load_package($(x)) )
          Promise.allSettled(promises).then((_result) => {
            $(".import-option").removeClass("disabled")
            $("#import-options-loader").remove()
            delivery_orders.check_first_import_from_order()
          })
        }
      },
      onHide: function() {
        $(this).dropdown('clear');
      },
    })

    $(namespace).find(".dropdown.external.comment").dropdown({
      action: "hide",
      apiSettings: {
        cache: false,
        url: '/delivery_orders/external_comment_templates?q={/query}&search_input={/query}',
      },
      onChange(value, _text, _selectedItem) {
        const textarea = $(this).parent().parent().find("textarea")
        let textarea_text = textarea.val()

        if (textarea_text != "") { textarea_text = textarea_text + "\n" }
        if (value == "null") {value = ""}
        textarea.val(textarea_text + value)
        $(this).parent().dropdown("clear")

        if (textarea.length) {
          textarea.scrollTop(textarea[0].scrollHeight - textarea.height());
        }
          
        return;
      }
    })

    $('.ui.dropdown.noselection').dropdown({
      placeholder: false,
      action: "hide",
      onHide : function() {
        $(this).dropdown('clear');
      },
    })

    $('.alternate-id.dropdown').dropdown()
  },

  init_modals() {
    
    delivery_orders.init_send_notification_modal()
    delivery_orders.publish_wizard.init()

    const do_id = $(".delivery_order_ID").val()

    const asset_package_all_button = $(".action-bar-content .button.asset_package_all")
    asset_package_all_button.on("click", function() {
      let button = $(this)
      if (!button.is('.loading') && confirm("Are your sure you want to package all not started assets?")) {
        button.addClass('loading')
        let docp_ids = $('.delivery-order-content-package-menu').map((_i,package_element) => package_element.dataset?.itemId).toArray()
        if (docp_ids.length == 0) { button.data('isLoading', false); return }

        const component_requests = []
      
        let toast = $('body').toast({
          displayTime: 0,
          title: 'Asset Packager',
          message: `Loading Components...`,
          showIcon: 'box open',
          showProgress: 'bottom',
          pauseOnHover: false,
          class: "initalizer",
        })

        let toast_header = toast.find('.content .header')
        let toast_message = toast.find('.content .message')

        let progress = 0
        let progress_bar = $('<div class="ui attached active progress bottom compact indicating" data-percent="1"><div class="bar up progressing " style="animation-duration: 0.5s; transition-duration: 300ms; display: block; width: 99%;"></div></div>').appendTo(toast.parent('.toast-box'))
        progress_bar.progress({ percent: 1 })
        let step = (100.0 / docp_ids.length) || 0.0

        let	promiseAjax = (settings) => new Promise((resolve, reject) => {
          settings.timeout = 60000
          $.ajax(settings)
          .done( (data,textStatus,jqXHR) => resolve({data: data, textStatus: textStatus, jqXHR: jqXHR, settings: settings}) )
          .fail( (jqXHR, textStatus, errorThrown) => reject({jqXHR: jqXHR, textStatus: textStatus, errorThrown: errorThrown, settings: settings}) )
        })      

        docp_ids.forEach(docp_id => {
          let components_request = promiseAjax({
            url: `/delivery_orders/${do_id}/package/${docp_id}/components`,
            dataType: 'json',
            data: {
              only_ids: true
            },
            method: "GET",
          })
          components_request.then(_value => {
            progress += step
            progress_bar.progress({ percent: progress })
          }).catch(reason => { console.log(reason?.errorThrown) })
          component_requests.push(components_request)
        })
        Promise.allSettled(component_requests).then( component_request_results => {
          progress = 1
          progress_bar.progress({ percent: 1 })
          toast_message.html('Initializing...')
           
          const fulfilled_component_requests = component_request_results.filter(r => r.status == 'fulfilled')
          const failed_component_requests    = component_request_results.filter(r => r.status != 'fulfilled')
          const components_count = fulfilled_component_requests.reduce((a, r) => { return a += r.value?.data?.length }, 0)
          step = (100.0 / components_count) || 0.0

          const asset_packager_requests = []
          fulfilled_component_requests.forEach(r => {
              r.value?.data?.forEach(docpc_id => {

                let asset_packager_request = promiseAjax({
                  url: `/delivery_orders/asset_packager`,
                  dataType: 'json',
                  data: {
                    docpc_id: docpc_id
                  },
                  method: "POST",
                })
                asset_packager_request.then(_value => {
                  progress += step
                  progress_bar.progress({ percent: progress })
                }).catch(reason => {
                  console.log(reason?.errorThrown)
                })
                asset_packager_requests.push(asset_packager_request)
              })

          })
          Promise.allSettled(asset_packager_requests).then( packaging_request_results => {

            // const fulfilled_packaging_requests = packaging_request_results.filter(r => r.status == 'fulfilled')
            const failed_packaging_requests    = packaging_request_results.filter(r => r.status != 'fulfilled')

            if (failed_packaging_requests?.length == 0 && failed_component_requests?.length == 0) {
              progress_bar.progress({ percent: 99 })
              toast_message.html('Successfully started')
              progress_bar.removeClass('sliding blue indicating active').addClass('green indeterminate')
              toast.removeClass('neutral').addClass('green')
              toast.toast('show')
              setTimeout(() => toast.toast('close'), 1500)
            } else {
              toast.find('.box.open').remove()
              toast_header.html('<i class="ban icon"></i>Asset Packager Failed!')
              toast_header.addClass('asset_packager_all_errors_header')
              toast_message.html('')
              toast_header.css("margin-bottom", "7px")
              toast.removeClass('neutral').addClass('red')

              const list = $(`<div class="ui list celled asset_packager_all_errors"></div>`).appendTo(toast_message)
              
              for (let failed_request of failed_component_requests) {
                let error = failed_request?.reason?.jqXHR?.responseJSON?.error || "An Unknown Error Occured"
                let docp_id = failed_request.reason?.settings?.url?.split('/')?.reverse()?.[1] || "unknown"
                
                let list_item = `
                <div class="ui item">
                  <i class="large open box middle aligned icon"></i>
                  <div class="content">
                    <div class="header">Package ID: ${docp_id || "unknown" }</div>
                    <div class="description">${error}</div>
                  </div>
                </div>
                `
                list.append(list_item)
              }

              for (let failed_request of failed_packaging_requests) {
                let responseJSON = failed_request?.reason?.jqXHR?.responseJSON
                let error = responseJSON.error || "An Unknown Error Occured"
                console.log(responseJSON)

                let header_description = "Unknown Component"
                if (responseJSON?.component) {
                  header_description = `${responseJSON?.package} > ${responseJSON?.component}`
                }
                
                let list_item = `
                <div class="ui item">
                  <i class="large file outline middle aligned icon"></i>
                  <div class="content">
                    <div class="header">${header_description}</div>
                    <div class="description">${error}</div>
                  </div>
                </div>
                `
                list.append(list_item)
              }

              progress_bar.progress({ percent: 99 })
              progress_bar.removeClass('sliding blue green indicating active').addClass('red indeterminate')
              toast.toast('show')
              setTimeout(() => progress_bar.remove(), 1500)
              setTimeout(() => toast.toast('close'), 60000)
            }
            
            button.removeClass('loading')
          })
         
        })

      }
    })
  },

  autofill_remote_dropdown(dropdown) {
    dropdown.dropdown('queryRemote', '', function() {
      const options = dropdown.find('.item').toArray().map((obj) => $(obj).data('value'))
      if (options.length == 1) {
        dropdown.dropdown('set selected', options[0])
      } else if (!options.includes(parseInt(dropdown.dropdown('get value')))) {
        dropdown.dropdown('clear')
      }
    })
  },

  init_artwork_modal(modal_target) {
    let modal = $(modal_target)
    modal.modal({
      observeChanges: true,
      autofocus: false,
      closable: false,
      allowMultiple: true,
      onShow() {
      },
      onHidden() {
        modal.remove()
      }
    })
    modal.modal("show")
  },

  init_create_metadata_modal(modal_target) {
    let modal = $(modal_target)
    let docps = $(".delivery-order-content-package-menu").toArray().map((e) => e.dataset?.itemId)
    modal.modal({
      observeChanges: true,
      autofocus: false,
      closable: false,
      allowMultiple: true,
      transition: {
        showMethod: 'fade',
        showDuration: 1000,
        hideMethod: 'fade',
        hideDuration: 500,
      },
      onShow() {
        modal.find("#metadata-docps").data("docps", docps)
        
        modal.find('.dropdown.metadata-select').dropdown({
          apiSettings: {
            cache: false,
            url: '/delivery_order_api_endpoints?q={/query}',
            data: {
              dc_ID: $('#delivery_order_do_dc_ID').val()
            },
            beforeSend(settings) {
              settings.data.dc_ID = $('#delivery_order_do_dc_ID').val()
              return settings;
            }
          },
          onAdd(_addedValue, _addedText, _$addedChoice) {
            modal.find('.send-create-metadata.button').removeClass('disabled')
          },
          onRemove(_removedValue, _removedText, _$removedChoice) {
            if (modal.find('.dropdown.metadata-select').dropdown('get value').length == 0) {
              modal.find('.send-create-metadata.button').addClass('disabled')
            }
          }
        })
      },
      onHidden() {
        modal.remove()
      }
    }).modal("show")
  },

  init_metadata_xml_modal(modal_target) {
    let modal = $(modal_target)
    const do_id = $(".delivery_order_ID").val()
    const docp_ids = delivery_orders.docp_ids()

    modal.modal({
      observeChanges: true,
      autofocus: false,
      onShow() {
        $.ajax({
          url: `/delivery_orders/${do_id}/metadata/${docp_ids}.xml`,
          dataType: 'text'
        }).done(function(data) {
          modal.find('.metadata.xml.details').text(data)
          modal.find('.metadata.xml.details').each((_i, block) => hljs.highlightElement(block))
          modal.find('.approve.button').removeClass('disabled')
          modal.find('.copy_to_clipboard.button').removeClass('disabled')
          modal.find('.copy_to_clipboard.button').on('click', async function(_e) {
            try {
              await navigator.clipboard.writeText(data)
              $('body').toast({ message: `Copied Text to Clipboard!`, class: 'success' })
            } catch (error) {
              modal.find('.copy_to_clipboard.button').addClass('red disabled')
              $('body').toast({ message: `Failed to copy text: ${error}`, class: 'error' })
            }
            
          })
        }).fail(function(jqXHR, _textStatus, _errorThrown) {
          modal.find('.metadata.xml.details').text(jqXHR.responseText)
        })

        $.ajax({
          url: `/delivery_orders/${do_id}/metadata/${docp_ids}/export_problems`,
        }).done(function(data) {
          modal.find('.export.problems').html(data)
        }).fail(function(jqXHR, _textStatus, _errorThrown) {
          modal.find('.metadata.xml.details').text(jqXHR.responseText)
        })
      },
      onHidden() {
        $('.metadata-xml.modal').remove();
      },
      onApprove: function() {
        let approve_button = $('.metadata-xml.modal .approve.button')
        approve_button.addClass('loading')
        $.ajax({
          url: `/api/packager/export_metadata_xml`,
          method: 'POST',
          data: {
            docp_ids: docp_ids,
            xml_payload: $('.metadata.xml.details').text()
          }
        }).done(function(data) {
          console.log(data)
          $('.metadata-xml.modal').modal("hide")
        }).fail(function(jqXHR, _textStatus) {
          approve_button.removeClass('loading').addClass('disabled negative').html(`<i class="exclamation triangle icon"></i> ${jqXHR.responseJSON.error}`)
        }).always(function() {
          approve_button.removeClass('loading')
        })
        return false
      }
    }).modal("show")
  },

  metadata_image_preview(metadata_item, docp_id, metadata_type, dct_ids) {
    return $.ajax({
      url: "/api/packager/metadata_image_preview",
      type: "GET",
      data: {
        metadata_item,
        docp_id,
        metadata_type,
        'dct_ids[]': dct_ids
      }
    })
  },

  change_dos(state, button, text, class_name) {
    $(button).addClass('loading');
    const button_group = $(button).parent();
    $.ajax({
      url: '/delivery_orders/change_state',
      type: "POST",
      dataType: "json",
      data: {
        refresh_component: $(button_group).data('docpca-id'),
        state
      },
      complete() {
        $(button).removeClass('loading');
      },
      success(data, _textStatus, _xhr) {
        $(".nested-fields-container .active.tab .delivery_order_content_package_component_assets").find(`.status[data-docpca-identifier='${$(button_group).data('docpca-identifier')}']`).text(text).removeClass('green red blue');
        $(".nested-fields-container .active.tab .delivery_order_content_package_component_assets").find(`.last-status-modification-date[data-docpca-identifier='${$(button_group).data('docpca-identifier')}']`).text(data.date_for_label).removeClass('green red blue');
      },
      error(_jqXHR, _textStatus, errorThrown) {
        $(button).addClass('red').find('i').removeClass(class_name).addClass('warning');
        console.log(errorThrown);
      }
    });
  },

  docp_ids() {
    let docp_ids = []
    $('#delivery_orders .delivery-order-content-package-menu').each(function(_i) {
      if ($(this).data("item-id") != "") {
        docp_ids.push($(this).data("item-id"))
      }
    })
    return docp_ids
  },
  
  mt_ids() {
    let studio_title_ids = []
    $('#delivery_orders .overview.content-package .title-text').each(function(_i) {
      if ($(this).data("studio-title-id") != "") {
        studio_title_ids.push($(this).data("studio-title-id"))
      }
    })
    return studio_title_ids
  },

  nested_fields() {

    $("#do_alt_ids").off("fields_added.nested_form_fields")
    $("#do_alt_ids").on("fields_added.nested_form_fields", function(event, param) {
      //console.log(param.object_class)
      switch (param.object_class) {
        case "delivery_order_alternate_id": {
          $('.alternate-id.dropdown').dropdown()
        }
      }
    })

    $("#content_package_form").off("fields_added.nested_form_fields.nested_delivery_order_content_packages");
    $("#content_package_form").on("fields_added.nested_form_fields.nested_delivery_order_content_packages", function(event, param) {
      let data_list_item_id, menu
      switch (param.object_class) {
        case "delivery_order_content_package": {
          const tab = event.target
          $(".delivery_order_content_package.tab").removeClass('active')
          $(tab).find(".delivery_order_content_package.tab").addClass('active')
          $(tab).find(".ui.secondary.segment .item[data-tab]").tab()
          $(tab).find(".menu").menu({ overflowMethod: 'dropdown', })

          const main_tab = event.delegateTarget;

          //Set Menu Tab ID
          const menu_data_tab = $(".delivery-order-content-package-menu").last().find(".content").attr("data-tab")
          const menu_data_item_id = $(".delivery-order-content-package-menu").last().attr("data-item-id")
          const menu_data_package_id = $(".delivery-order-content-package-menu").last().find('.content').data('package-id')
          
          $(main_tab).find(".delivery_order_content_package.tab").last().attr("data-tab", menu_data_tab)
          $(main_tab).find(".delivery_order_content_package.tab").last().attr('data-package-id', menu_data_package_id)
          $(main_tab).find(".delivery_order_content_package.tab").find(".delivery-order-content-package-nested-field").attr("data-item-id", menu_data_item_id)

          const component_list_id = general.makeid();
          $(event.target).find(".add_nested_fields_link.component").attr("data-insert-into", "component-list-" + component_list_id)
          $(event.target).find(".component-list").attr("id", "component-list-" + component_list_id)
          $(event.target).find(".component-list").attr("data-package-id", menu_data_package_id)

          const avails_list_id = general.makeid();
          $(event.target).find(".add_nested_fields_link.avails").attr("data-insert-into", "avails-list-" + avails_list_id)
          $(event.target).find(".avails-list").attr("id", "avails-list-" + avails_list_id)

          delivery_orders.init_package(event.target)
          
          $(event.target).find(".add_nested_fields_link.component").trigger("click")

          break;
        }
        case "delivery_order_content_package_component": {
          data_list_item_id = general.makeid();
          menu = event.delegateTarget;
          const package_id = $(event.target).parents('[data-package-id]').data('package-id')
          const menu_component_list = $(`.delivery-order-content-package-menu .content[data-package-id="${package_id}"] .components.list`)

          $(event.target).parent().parent().find("center .components").parent().remove()
          
          $(menu_component_list).append(`<div class="item with-margin" data-list-item-id=${data_list_item_id}><span class="component_title">No Title</span>
            <span class="ui compact mini basic label pull-right"><div class="ui icon mini pull-right" data-color="orange" data-status-code="1"><i class="orange hourglass start icon"></i>not started</div></span>
          </div>`)
          $(event.target).find('.nested-fields').first().attr('data-component-item-id', data_list_item_id)

          const assets_list_id = general.makeid();
          $(event.target).find(".add_nested_fields_link").attr("data-insert-into", "assets-list-" + assets_list_id)
          $(event.target).find(".assets-list").attr("id", "assets-list-" + assets_list_id)

          const tab_id = general.makeid();
          $(event.target).find(".item.assets").attr("data-tab", "assets-" + tab_id)
          $(event.target).find(".tab.assets").attr("data-tab", "assets-" + tab_id)
          $(event.target).find(".item.events").attr("data-tab", "events-" + tab_id)
          $(event.target).find(".tab.events").attr("data-tab", "events-" + tab_id)
          $(event.target).find(".item.content_packages").attr("data-tab", "content_packages-" + tab_id)
          $(event.target).find(".tab.content_packages").attr("data-tab", "content_packages-" + tab_id)
          $(event.target).find(".item.component_asset_requests").attr("data-tab", "component_asset_requests-" + tab_id)
          $(event.target).find(".tab.component_asset_requests").attr("data-tab", "component_asset_requests-" + tab_id)

          delivery_orders.init_component($(event.target))

          const menu_content = $(`.delivery-order-content-package-menu .content[data-package-id="${package_id}"]`);
          const component_count = $(menu_content).find('.components.list .item').length;
          $(menu_content).find('.components-count').text(component_count);

          delivery_orders.check_import_from_order_button($('#delivery_orders .nested-fields-container .ui.active.delivery_order_content_package.tab'))

          $(`.delivery_order_content_package.tab[data-package-id="${package_id}"] .item.active .count.label`).text(component_count)
          if (typeof(param.additional_data?.asset) !== "undefined") {
            $(event.target).find('.add_nested_fields_link[data-object-class="delivery_order_content_package_component_asset"]').trigger('click', [param.additional_data])
          }
          break;
        }
        case "delivery_order_content_package_component_asset": {
          $(event.target).find(".remote.card").data("model-id", param.additional_data.asset.a_ID)
          $(event.target).find(".asset-id").val(param.additional_data.asset.a_ID)

          delivery_orders.package_components_assets($(`.nested_${param.association_path}`))
          const remove_field_button = event.target;
          $(remove_field_button).find(".asset-packager-publish").addClass('disabled');
          $(remove_field_button).find(".asset-packager-refresh").addClass('disabled');
          $(remove_field_button).find(".asset-packager-remove").addClass('disabled');

          // set content type
          let asset = param.additional_data.asset
          let content_type_dropdown = $(event.target).parents('.nested-fields[data-component-item-id]').find('.asset-content-type.dropdown')

          let dropdown_content_types = content_type_dropdown.find('.item[data-text]').toArray().map(x => $(x).data('text'))
          if (dropdown_content_types.includes('Trailer')) {
            dropdown_content_types.push('Teaser')
          }

          let content_type_info = asset && asset.asset_content_types && asset.asset_content_types.filter(x => x && dropdown_content_types.includes(x.act_ContentType) )[0]
          let content_type = (content_type_info && content_type_info.act_ContentType) || (asset && asset.content_type)
          if (content_type == 'Teaser') {
            content_type = 'Trailer'
          }

          if (typeof(content_type) !== 'undefined') {
            let value = content_type_dropdown.find($(`.item[data-text*='${content_type}' i]`)).data('value')
            if (typeof(value) !== 'undefined') {
              let current_content_type = content_type_dropdown.dropdown('get value')
              if (!current_content_type) {
                content_type_dropdown.dropdown('set exactly', value)
              }
            }
          }

          let current_asset_count = Number($(".asset.count").text())
          $(".asset.count").text(current_asset_count + 1)
          break;
        }
        case "avail_schedule": {
          data_list_item_id = general.makeid()

          let avails_list = $(event.target).parent('.avails-list')
          const avails_count = avails_list.children('fieldset:visible').length

          $(event.target).find('.nested-fields').first().attr('data-avail-item-id', data_list_item_id)
          
          delivery_order_avails.clone_avail(param, event)
          
          let tab = avails_list.parent('.avails.tab')
          let menu_id = tab.attr("data-tab")
          menu = $(".delivery-order-content-package-menu .content[data-tab=" + menu_id + "]")
          delivery_order_avails.build_avail_list(menu, data_list_item_id)

          menu.find('.avail-count').text(avails_count)
          tab.find('.tabular.menu .item[data-tab^=avails] .count').first().text(avails_count)
          break;
        }
        case "delivery_order_alternate_id": {
          $('.alternate-id.dropdown').dropdown()
        }
      }
      
      if (typeof param.additional_data?.callback === 'function') {
        param.additional_data.callback(event)
      }
      
    });

    $("#content_package_form").off("fields_removed.nested_form_fields.nested_delivery_order_content_packages");
    $("#content_package_form").on("fields_removed.nested_form_fields.nested_delivery_order_content_packages", function(event, param) {
      const package_id = $(event.target).parents('[data-package-id]').data('package-id')
      const menu_content = $(`.delivery-order-content-package-menu .content[data-package-id="${package_id}"]`)
      switch (param.object_class) {
        case "delivery_order_content_package": {
          const menu_item = $(`.delivery-order-content-package-menu[data-item-id='${$(event.target).find(".ui.tab").data("package-id")}']`)
          $(menu_item).remove()
          $(event.target).find('.asset.card.item').addClass("hidden")

          delivery_orders.count_packages()
          delivery_orders.perform_menu_components_count()
          delivery_orders.perform_menu_avails_count()
          delivery_orders.perform_asset_count()
          $(".general.information").trigger("click")
          
          delivery_orders.asset_browser_search()
          
          break
        }
        case "delivery_order_content_package_component": {
          // delivery_orders.perform_menu_components_count();
          
          const component_id = $(event.target).find('[data-component-item-id]').data('component-item-id')
          $(menu_content).find(`.components.list .item[data-list-item-id="${component_id}"]`).remove()
          
          // update count
          let component_count = $(menu_content).find('.components.list .item').length
          $(menu_content).find('.components-count').text(component_count)
          $(`.delivery_order_content_package.tab[data-package-id="${package_id}"] .item.components .count.label`).text(component_count)
          delivery_orders.asset_browser_search()
          $(event.target).find('.asset.card.item').addClass("hidden")
          delivery_orders.perform_asset_count()
          break
        }
        case "avail_schedule": {
          const avail_id = $(event.target).find('[data-avail-item-id]').data('avail-item-id')
          $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).remove()
          
          // update count
          const avail_count = $(menu_content).find('.avail.list .item').length
          $(menu_content).find('.avail-count').text(avail_count)
          $(`.delivery_order_content_package.tab[data-package-id="${package_id}"] .item.avails .count.label`).text(avail_count)
          
          break
        }
        case "delivery_order_content_package_component_asset": {
          $(event.target).find('.asset.card.item').addClass("hidden")
          delivery_orders.perform_asset_count()

          let current_asset_count = Number($(".asset.count").text())
          $(".asset.count").text(current_asset_count - 1)

          delivery_orders.asset_browser_search()
          break
        }
      }
    })
  },

  nested_fields_for_modal(modal) {
    modal.off("fields_added.nested_form_fields.nested_delivery_order_content_packages");
    modal.on("fields_added.nested_form_fields.nested_delivery_order_content_packages", function(event, param) {
      switch (param.object_class) {
        case "avail_schedule": {
          delivery_order_avails.clone_avail(param, event)
          delivery_order_avails.init_package_avail_schedules(event.target)
          break
        }
      }
    })
  },

  init_fetch_asset_request_modal(modal_target) {
    let modal = $(modal_target)
    modal.modal({
      observeChanges: true,
      autofocus: false,
      onShow() {
        modal.find('.checkbox').checkbox()
        modal.find('.dropdown').dropdown({
          onChange(_value, _text, _choice) {
            delivery_orders.check_for_modal_value(modal)
          }
        })
        modal.find('#due_date').on("change", function() {
          delivery_orders.check_for_modal_value(modal)
        })
        modal.find('#territory').parent().dropdown('set exactly', $('#delivery_order_territory_code_ids').dropdown('get value'))
        modal.find('#language').parent().dropdown('set exactly', $('#delivery_order_language_code_ids').dropdown('get value'))
        calendar.datepicker('.metadata-asset-request')

        delivery_orders.check_for_modal_value(modal)
        delivery_orders.init_send_asset_request_create(modal)
      },
      onHidden() {
        modal.remove()
      },
    }).modal("show")
  },

  init_clone_modal(modal_target) {
    //console.log("init_clone_modal")
    let modal = $(modal_target)
    modal.modal({
      observeChanges: true,
      autofocus: false,
      onShow() {
        delivery_orders.init_clone_form(modal)
      },
      onHidden() {
        modal.remove()
      },
      onApprove() {
        modal.find(".green.ok.button").addClass("loading disabled")
        Turbo.navigator.submitForm(document.querySelector('#delivery_orders_clone_modal form'))
        return false
      }
    }).modal("show")
  },

  init_clone_form(modal) {
    //console.log("init_clone_form")
    delivery_orders.init_dropdowns('#delivery_orders_clone_modal')
    $("#clone_delivery_order_do_c_ClientContactID").dropdown({
      apiSettings: {
        cache: false,
        url: '/contacts/clients?q={/query}'
      },
      templates: {
        menu: dropdownTemplates.search_menu
      },
      onChange(_value, _text, _choice) {
        $('#clone_delivery_order_do_dc_ID').dropdown('clear')
        $('#clone_delivery_order_do_dct_ID').dropdown('clear')
        $('#clone_delivery_order_do_mmc_ID').dropdown('clear')
        $('#clone_delivery_order_do_p_ID').dropdown('clear')
      }
    }).dropdown('set selected', $('#clone_delivery_order_do_c_ClientContactID').val());

    $("#clone_delivery_order_do_c_ClientContactID").change(function() {
      if ($(this).val().length) {
        $(modal).find('.contact.disabled').removeClass('disabled')
      }
    });
    $("#clone_delivery_order_do_c_ClientContactID").trigger('change');

    let dc_dropdown = $('#clone_delivery_order_do_dc_ID').dropdown({
      apiSettings: {
        cache: false,
        url: '/delivery_orders/channels?q={/query}',
        data: {
          c_ID: $("#clone_delivery_order_do_c_ClientContactID").val()
        },
        beforeSend(settings) {
          settings.data.c_ID = $("#clone_delivery_order_do_c_ClientContactID").val();
          return settings;
        }
      },
      templates: {
        menu: dropdownTemplates.search_menu
      },
      onChange(_value, _text, _choice) {
        $('#clone_delivery_order_do_dct_ID').dropdown('clear');
        $('#clone_delivery_order_do_mmc_ID').dropdown('clear');
      }
    })
    dc_dropdown.dropdown('set selected', $('#clone_delivery_order_do_dc_ID').val())

    let dct_dropdown = $('#clone_delivery_order_do_dct_ID').dropdown({
      allowReselection: true,
      apiSettings: {
        cache: false,
        url: '/distribution_channel_types/channel_types?q={/query}',
        data: {
          dc_ID: $("#clone_delivery_order_do_dc_ID").val()
        },
        beforeSend(settings) {
          settings.data.dc_ID = $("#clone_delivery_order_do_dc_ID").val();
          return settings;
        },
      },
      templates: {
        menu: dropdownTemplates.search_menu
      },
    })
    dct_dropdown.dropdown('set selected', $('#clone_delivery_order_do_dct_ID').val())

    $(modal).find('.ui.subchannel.dropdown').dropdown({
      apiSettings: {
        cache: false,
        url: '/delivery_orders/subchannels?q={/query}',
        data: {
          mmc_ID: $('#clone_delivery_order_do_mmc_ID').val()
        },
        beforeSend(settings) {
          settings.data.mmc_ID = $('#clone_delivery_order_do_mmc_ID').val();
          return settings;
        }
      },
      templates: {
        menu: dropdownTemplates.subchannel_search_menu
      },
      onChange(_value, _text, choice) {
        if ($(choice).data('carousel-value')) {
          $(modal).find('.subchannel.label').text($(choice).data('carousel-value')).show()
        } else {
          $(modal).find('.subchannel.label').hide()
        }
      }
    }).dropdown('set selected', $(modal).find('.ui.subchannel.dropdown').val())

    let mmc_dropdown = $('#clone_delivery_order_do_mmc_ID').dropdown({
      apiSettings: {
        cache: false,
        url: '/delivery_orders/contacts?q={/query}',
        data: {
          dc_ID: $("#clone_delivery_order_do_dc_ID").val(),
          c_ID: $("#clone_delivery_order_do_c_ClientContactID").val()
        },
        beforeSend(settings) {
          settings.data.dc_ID = $("#clone_delivery_order_do_dc_ID").val()
          settings.data.c_ID = $("#clone_delivery_order_do_c_ClientContactID").val()
          return settings;
        }
      },
      templates: {
        menu: dropdownTemplates.search_menu
      },
      onChange(_value, _text, _choice) {
      }
    })
    mmc_dropdown.dropdown('set selected', $('#clone_delivery_order_do_mmc_ID').val())

    $('#clone_delivery_order_do_p_ID').dropdown({
      minCharacters: 2,
      apiSettings: {
        cache: false,
        url: '/projects/search_dropdown?q={/query}',
        data: {
          c_ID: $("#clone_delivery_order_do_c_ClientContactID").val()
        },
        beforeSend(settings) {
          settings.data.c_ID = $("#clone_delivery_order_do_c_ClientContactID").val();
          return settings;
        }
      },
      templates: {
        menu: dropdownTemplates.projects_search_menu
      }
    }).dropdown('set selected', $('#clone_delivery_order_do_p_ID').val());

    $("#clone_delivery_order_do_p_ID").change(function() {
      if ($(this).val().length) {
        $(modal).find('.project.disabled').removeClass('disabled');
      }
    }).change()

    let fl_dropdown = $('#clone_delivery_order_do_fl_ID').dropdown({
      onChange(_value, _text, choice) {
        if (choice === null) {
          $('#clone_delivery_order_do_ContentPackageDestinationPath').val("")
          $('#clone_delivery_order_do_ContentPackageDestinationPublishPath').val("")
          $('#clone_delivery_order_do_ContentPackageDestinationArchivePath').val("")
          $('#clone_delivery_order_do_EnforceFlatRoot').val("false")
          $(modal).find('.file-hierarchy-attributes .label').addClass('hidden')
        } else {
          const flat_root = $(choice).data('enforceFlatRoot')
          if (flat_root) {
            $(modal).find('.flat-root.label').removeClass('hidden')
          } else {
            $(modal).find('.flat-root.label').addClass('hidden')
          }
          $('#clone_delivery_order_do_ContentPackageDestinationPath').val($(choice).data('location'))
          $('#clone_delivery_order_do_ContentPackageDestinationPublishPath').val($(choice).data('locationPublish'))
          $('#clone_delivery_order_do_ContentPackageDestinationArchivePath').val($(choice).data('locationArchive'))
          $('#clone_delivery_order_do_EnforceFlatRoot').val(flat_root)
          if ($(choice).data('assetTransferAccount')) {
            $(modal).find('.transfer-account.label').html($(choice).data('assetTransferAccount'))
            $(modal).find('.transfer-account.label').removeClass('hidden')
          } else {
            $(modal).find('.transfer-account.label').addClass('hidden')
          }
          if ($(choice).data('m2mAccount')) {
            $(modal).find('.m2m-account.label').removeClass('hidden')
          } else {
            $(modal).find('.m2m-account.label').addClass('hidden')
          }
        }
        //delivery_orders.check_destination_path()
      },
      apiSettings: {
        cache: false,
        url: '/file_locations?q={/query}',
        data: { 
          type: 'delivery_order',
          distribution_channel: $('#clone_delivery_order_do_dc_ID').val(),
          content_partner: $('#clone_delivery_order_do_c_ClientContactID').val()
        },
        beforeSend(settings) {
          settings.data.distribution_channel = $('#clone_delivery_order_do_dc_ID').val()
          settings.data.content_partner = $('#clone_delivery_order_do_c_ClientContactID').val()
          return settings
        },
      },
      templates: {
        menu: dropdownTemplates.filelocation_search
      }
    })

    let fnc_dropdown = $('#clone_delivery_order_do_fnc_ID').dropdown({
      minCharacters: 0,
      apiSettings: {
        cache: false,
        url: '/file_naming_conventions?q={/query}',
        data: {
          distribution_channel: $('#clone_delivery_order_do_dc_ID').val()
        },
        beforeSend(settings) {
          settings.data.distribution_channel = $('#clone_delivery_order_do_dc_ID').val();
          return settings;
        }
      },
      templates: {
        menu: dropdownTemplates.filelocation_search
      }
    })
    fnc_dropdown.dropdown('set selected', $('#clone_delivery_order_do_fnc_ID').val())

    dc_dropdown.dropdown('setting', 'onChange', (_value, _text, _choice) => {
      dct_dropdown.dropdown('clear')
      mmc_dropdown.dropdown('clear')

      dct_dropdown.dropdown('queryRemote', '', function() {
        delivery_orders.autofill_remote_dropdown(dct_dropdown)
      })
    })

    dct_dropdown.dropdown('setting', 'onChange', (_value, _text, _choice) => {
      delivery_orders.autofill_remote_dropdown(mmc_dropdown)
      delivery_orders.autofill_remote_dropdown(fl_dropdown)
      delivery_orders.autofill_remote_dropdown(fnc_dropdown)
    })


    let import_options_dropdown = $('#clone_delivery_order_import_options').dropdown({
      minCharacters: 0,
      onChange: function(_value, _text, _selectedItem) {
        const selected_options = $(this).val()
        
        const packages_selected = selected_options.includes('import_packages')
        const items = import_options_dropdown.find('.item')

        items.each((i,item) => {
          const v = item.dataset.value
          if (v !== 'import_packages') {
            if (packages_selected) {
              item.classList.remove('disabled')
            } else {
              item.classList.add('disabled')
            }
          }
        })

        if (!packages_selected) { $(import_options_dropdown).dropdown('remove selected', ['import_assets', 'import_asset_requests', 'import_avails']) }
      }
    })    
  },

  check_for_modal_value(modal) {
    const territory = modal.find('#territory').dropdown('get value')
    const language = modal.find('#language').dropdown('get value')
    const due_date = modal.find('#due_date').val()
    const approval = modal.find('#approval_workflow').val() != ""
    
    if (territory && language && due_date && approval) {
      modal.find('.asset_request.create').removeClass("disabled")
    } else {
      modal.find('.asset_request.create').addClass("disabled")
    }
  },

  init_index_body() {
    delivery_orders.load_status_labels();
    $(".search-field.dropdown").not('.remote').dropdown({ placeholder: false, fullTextSearch: true });

    delivery_orders.load_cover('#delivery_orders .quicklook.cover')

    general.quicklook_icon('#delivery_orders .quicklook')
    $('#delivery_orders .quicklook').on("click", function() {
      delivery_orders.quicklook($(this).data('model-id'));
    });
  },

  quicklook(model_id) {
    $.ajax({
      url: `/delivery_orders/${model_id}/quicklook`,
      data:{
        modal: true
      },
      success(data, _textStatus, _xhr) {
        $('body').append(data);
        
        $('.ui.quicklook.modal').modal({
          duration: 200,
          onShow() {
            delivery_orders.show()
          },
          onHidden() {
            $('body').find(".quicklook.modal").remove()
          }
        }).modal('show');
      },

      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown);
      }
    });
  },

  init_form() {
    delivery_orders.init_dropdowns("#delivery_orders")
    delivery_orders.check_delivery_order_state()
    delivery_orders.move_to()
    delivery_orders.import_menu()

    delivery_orders.nested_fields()

    if ($(".overview.content-package .active.loader").length > 0) {
      if ($("#delivery_orders.new").length > 0) {
        $('.ui.styled.fluid.accordion.content-package').html("")
        delivery_orders.count_packages()
      } else {
        delivery_orders.menu_package()  
      }
    }

    general.hover_link("contacts")
    general.hover_link("distribution_channels")
    general.hover_link("distribution_channel_types")
    general.hover_link("file_locations")
    general.hover_link("file_naming_conventions")
    general.hover_link("projects")
    general.hover_link("metadata_seasons")

    $('.with-tooltip').popup({ inline: true })

    $('.general.information .item[data-tab]').tab({
      evaluateScripts: false,
      onVisible(path) {
        //console.log(path)
        if (path == "asset_browser") {
          if ($(".general.information .asset_browser .inline.loader").length > 0) {
            delivery_orders.asset_browser()
          }
        }

        if (path == "browser") {
          if ($(".general.information .filelist .inline.loader").length > 0) {
            delivery_orders.get_folder("", $("#delivery_orders.edit .filelist"))
          }
        }
      }
    })

    $("#delivery_order_do_DistributionChannelSeasonID").on('dblclick', function() {
      if ($(this).val().length === 0) {
        $.ajax({
          url: "/metadata_mapping_contacts/pre_and_suffix/",
          type: "GET",
          dataType: "json",
          data: {
            c_id: $("#delivery_order_do_c_ClientContactID").val(),
            dc_id: $("#delivery_order_do_dc_ID").val()
          }
        }).done(function(data) {
            $("#delivery_order_do_DistributionChannelSeasonID").val(data.metadata_mapping_contact.mmc_DistributionChannelCatIDPrefix + $("#ms_CatalogNo").val() + data.metadata_mapping_contact.mmc_DistributionChannelCatIDSuffix).transition('glow')
        }).fail(() => console.log("error"))
      }
    })

    $("#delivery_order_do_DistributionChannelBrandID").on('dblclick', function() { 
      if ($(this).val().length === 0) {
        $.ajax({
          url: "/metadata_mapping_contacts/pre_and_suffix/",
          type: "GET",
          dataType: "json",
          data: {
            c_id: $("#delivery_order_do_c_ClientContactID").val(),
            dc_id: $("#delivery_order_do_dc_ID").val()
          }
        }).done(function(data) {
          $("#delivery_order_do_DistributionChannelBrandID").val(data.metadata_mapping_contact.mmc_DistributionChannelCatIDPrefix + $("#mb_CatalogNo").val() + data.metadata_mapping_contact.mmc_DistributionChannelCatIDSuffix).transition('glow')
        }).fail(() => console.log("error"))
      }
    })

    $("#delivery_order_do_c_ClientContactID").dropdown({
      apiSettings: {
        cache: false,
        url: '/contacts/clients?q={/query}'
      },
      templates: {
        menu: dropdownTemplates.search_menu
      },
      onChange(_value, _text, _choice) {
        $('#delivery_order_do_dc_ID').dropdown('clear')
        $('#delivery_order_do_dct_ID').dropdown('clear')
        $('#delivery_order_do_mmc_ID').dropdown('clear')
        $('#delivery_order_do_p_ID').dropdown('clear')
        $('#delivery_order_territory_code_ids').dropdown('clear')
        $('#delivery_order_language_code_ids').dropdown('clear')
        $('#delivery_order_do_ms_ID').dropdown('clear')
        $("#ms_CatalogNo").val("")
        $("#mb_CatalogNo").val("")
      }
    }).dropdown('set selected', $('#delivery_order_do_c_ClientContactID').val());

    $("#delivery_order_do_c_ClientContactID").change(function() {
      if ($(this).val().length) {
        $('.contact.disabled').removeClass('disabled')
      }
    });

    $("#delivery_order_do_c_ClientContactID").change();

    let dc_dropdown = $('#delivery_order_do_dc_ID').dropdown({
      apiSettings: {
        cache: false,
        url: '/delivery_orders/channels?q={/query}',
        data: {
          c_ID: $("#delivery_order_do_c_ClientContactID").val(),
          active: true
        },
        beforeSend(settings) {
          settings.data.c_ID = $("#delivery_order_do_c_ClientContactID").val();
          return settings;
        }
      },
      templates: {
        menu: dropdownTemplates.search_menu
      },
      onChange(_value, _text, _choice) {
        $('#delivery_order_do_dct_ID').dropdown('clear');
        $('#delivery_order_do_mmc_ID').dropdown('clear');
        $('#delivery_order_territory_code_ids').dropdown('clear');
        $('#delivery_order_language_code_ids').dropdown('clear');
      }
    })
    dc_dropdown.dropdown('set selected', $('#delivery_order_do_dc_ID').val())

    let dct_dropdown = $('#delivery_order_do_dct_ID').dropdown({
      allowReselection: true,
      apiSettings: {
        cache: false,
        url: '/distribution_channel_types/channel_types?q={/query}',
        data: {
          dc_ID: $("#delivery_order_do_dc_ID").val()
        },
        beforeSend(settings) {
          settings.data.dc_ID = $("#delivery_order_do_dc_ID").val();
          return settings;
        },
      },
      templates: {
        menu: dropdownTemplates.search_menu
      },
    })
    dct_dropdown.dropdown('set selected', $('#delivery_order_do_dct_ID').val())

    let territory_codes_dropdown = $('#delivery_order_territory_code_ids').dropdown({
      onLabelSelect(selectedLabels) {
        if($(selectedLabels).hasClass('territory-selected')) {
          $('#delivery_order_default_territory_id').val("")
          $('.ui.label.territory-selected').removeClass('territory-selected')
        } else {
          $('.ui.label.territory-selected').removeClass('territory-selected')
          $(selectedLabels).addClass('territory-selected')
          $('#delivery_order_default_territory_id').val($(selectedLabels).data("value"))
        }
        $(selectedLabels).removeClass('active');
      },
      apiSettings: {
        cache: false,
        url: '/delivery_orders/territories?q={/query}',
        data: {
          c_ID: $("#delivery_order_do_c_ClientContactID").val(),
          dct_ID: $("#delivery_order_do_dct_ID").val()
        },
        beforeSend(settings) {
          settings.data.c_ID = $("#delivery_order_do_c_ClientContactID").val();
          settings.data.dct_ID = $("#delivery_order_do_dct_ID").val();
          return settings;
        }
      },
      templates: {
        menu: dropdownTemplates.territory_search_menu
      }
    })
    territory_codes_dropdown.dropdown('set selected', $('#delivery_order_territory_code_ids').val())

    $('#delivery_orders .ui.subchannel.dropdown').dropdown({
      apiSettings: {
        cache: false,
        url: '/delivery_orders/subchannels?q={/query}',
        data: {
          mmc_ID: $('#delivery_order_do_mmc_ID').val()
        },
        beforeSend(settings) {
          settings.data.mmc_ID = $('#delivery_order_do_mmc_ID').val();
          return settings;
        }
      },
      templates: {
        menu: dropdownTemplates.subchannel_search_menu
      },
      onChange(_value, _text, choice) {
        if ($(choice).data('carousel-value')) {
          $('#delivery_orders .subchannel.label').text($(choice).data('carousel-value')).show()
        } else {
          $('#delivery_orders .subchannel.label').hide()
        }
      }
    }).dropdown('set selected', $('#delivery_orders .ui.subchannel.dropdown').val())

    if ($('#delivery_order_territory_code_ids').length > 0) {
      if ($("#delivery_order_default_territory_id").val() !== "") {
        $('#delivery_order_territory_code_ids').parent().find(`a[data-value='${$("#delivery_order_default_territory_id").val()}']`).addClass('territory-selected')
      }
    }

    let language_codes_dropdown = $('#delivery_order_language_code_ids').dropdown({
      onLabelSelect(selectedLabels) {
        if($(selectedLabels).hasClass('language-selected')) {
          $('#delivery_order_default_language_id').val("")
          $('.ui.label.language-selected').removeClass('language-selected')
        } else {
          $('.ui.label.language-selected').removeClass('language-selected')
          $(selectedLabels).addClass('language-selected')
          $('#delivery_order_default_language_id').val($(selectedLabels).data("value"))
        }
        $(selectedLabels).removeClass('active');
      },
      apiSettings: {
        cache: false,
        url: '/delivery_orders/languages?q={/query}',
        data: {
          c_ID: $("#delivery_order_do_c_ClientContactID").val(),
          dct_ID: $("#delivery_order_do_dct_ID").val()
        },
        beforeSend(settings) {
          settings.data.c_ID = $("#delivery_order_do_c_ClientContactID").val();
          settings.data.dct_ID = $("#delivery_order_do_dct_ID").val();
          return settings;
        }
      },
      templates: {
        menu: dropdownTemplates.language_search_menu
      }
    })
    language_codes_dropdown.dropdown('set selected', $('#delivery_order_language_code_ids').val())

    if ($('#delivery_order_language_code_ids').length > 0) {
      if ($("#delivery_order_default_language_id").val() !== "") {
        $('#delivery_order_language_code_ids').parent().find(`a[data-value='${$("#delivery_order_default_language_id").val()}']`).addClass('language-selected')
      }
    }

    if ($('.action[href*="/delivery_orders/new"]').length > 0) {
      territory_codes_dropdown.dropdown('setting', 'apiSettings',  {
        onSuccess(response, _element, _xhr) {
          let results = response.results
          if (results.length == 1 && $('#delivery_order_territory_code_ids').val()?.length < 1) {
            $('#delivery_order_territory_code_ids')
            territory_codes_dropdown.dropdown('set exactly', results[0].value)
          }
        }
      })
      
      language_codes_dropdown.dropdown('setting', 'apiSettings',  {
        onSuccess(response, _element, _xhr) {
          let results = response.results
          if (results.length == 1 && $('#delivery_order_language_code_ids').val()?.length < 1) {
            language_codes_dropdown.dropdown('set exactly', results[0].value)
          }        
        }
      })
    }

    $('#delivery_order_do_ms_ID').dropdown({
      apiSettings: {
        cache: false,
        url: '/metadata_seasons/seasons?q={/query}',
        data: {
          c_ID: $("#delivery_order_do_c_ClientContactID").val()
        },
        beforeSend(settings) {
          settings.data.c_ID = $("#delivery_order_do_c_ClientContactID").val()
          return settings
        }
      },
      templates: {
        menu: dropdownTemplates.season_id_catalog_no_search_menu
      },
      onChange(_value, _text, choice) {
        if (choice != null) {
          $("#ms_CatalogNo").val(choice.data("seasoncatalogno"))
          $("#mb_CatalogNo").val(choice.data("brandcatalogno"))
      }
        $("#delivery_order_do_DistributionChannelSeasonID").val("")
        $("#delivery_order_do_DistributionChannelBrandID").val("")
        delivery_orders.check_import_buttons()
      }
    }).dropdown('set selected', $('#delivery_order_do_ms_ID').val())

    delivery_orders.check_import_buttons()

    let mmc_dropdown = $('#delivery_order_do_mmc_ID').dropdown({
      apiSettings: {
        cache: false,
        url: '/delivery_orders/contacts?q={/query}',
        data: {
          dc_ID: $("#delivery_order_do_dc_ID").val(),
          c_ID: $("#delivery_order_do_c_ClientContactID").val()
        },
        beforeSend(settings) {
          settings.data.dc_ID = $("#delivery_order_do_dc_ID").val()
          settings.data.c_ID = $("#delivery_order_do_c_ClientContactID").val()
          return settings;
        }
      },
      templates: {
        menu: dropdownTemplates.search_menu
      },
      onChange(_value, _text, _choice) {
        delivery_order_avails.display_avail_schedule_fields()
      }
    })
    mmc_dropdown.dropdown('set selected', $('#delivery_order_do_mmc_ID').val())

    $('#delivery_order_do_p_ID').dropdown({
      minCharacters: 2,
      apiSettings: {
        cache: false,
        url: '/projects/search_dropdown?q={/query}',
        data: {
          c_ID: $("#delivery_order_do_c_ClientContactID").val()
        },
        beforeSend(settings) {
          settings.data.c_ID = $("#delivery_order_do_c_ClientContactID").val();
          return settings;
        }
      },
      templates: {
        menu: dropdownTemplates.projects_search_menu
      }
    }).dropdown('set selected', $('#delivery_order_do_p_ID').val());

    $("#delivery_order_do_p_ID").change(function() {
      if ($(this).val().length) {
        $('.project.disabled').removeClass('disabled');
      }
    }).change();

    calendar.datepicker('#delivery_orders')

    let fl_dropdown = $('#delivery_order_do_fl_ID').dropdown({
      onChange(_value, _text, choice) {
        if (choice === null) {
          $('#delivery_order_do_ContentPackageDestinationPath').val("")
          $('#delivery_order_do_ContentPackageDestinationPublishPath').val("")
          $('#delivery_order_do_ContentPackageDestinationArchivePath').val("")
          $('#delivery_order_do_EnforceFlatRoot').val("false")
          $('.file-hierarchy-attributes .label').addClass('hidden')
        } else {
          const flat_root = $(choice).data('enforceFlatRoot')
          if (flat_root) {
            $('.flat-root.label').removeClass('hidden')
          } else {
            $('.flat-root.label').addClass('hidden')
          }
          $('#delivery_order_do_ContentPackageDestinationPath').val($(choice).data('location'))
          $('#delivery_order_do_ContentPackageDestinationPublishPath').val($(choice).data('locationPublish'))
          $('#delivery_order_do_ContentPackageDestinationArchivePath').val($(choice).data('locationArchive'))
          $('#delivery_order_do_EnforceFlatRoot').val(flat_root)
          if ($(choice).data('assetTransferAccount')) {
            $('.transfer-account.label').html($(choice).data('assetTransferAccount'))
            $('.transfer-account.label').removeClass('hidden')
          } else {
            $('.transfer-account.label').addClass('hidden')
          }
          if ($(choice).data('m2mAccount')) {
            $('.m2m-account.label').removeClass('hidden')
          } else {
            $('.m2m-account.label').addClass('hidden')
          }
        }
        delivery_orders.check_destination_path()
      },
      apiSettings: {
        cache: false,
        url: '/file_locations?q={/query}',
        data: { 
          type: 'delivery_order',
          distribution_channel: $('#delivery_order_do_dc_ID').val(),
          content_partner: $('#delivery_order_do_c_ClientContactID').val()
        },
        beforeSend(settings) {
          settings.data.distribution_channel = $('#delivery_order_do_dc_ID').val()
          settings.data.content_partner = $('#delivery_order_do_c_ClientContactID').val()
          return settings
        },
      },
      templates: {
        menu: dropdownTemplates.filelocation_search
      }
    })

    let fnc_dropdown = $('#delivery_order_do_fnc_ID').dropdown({
      minCharacters: 0,
      apiSettings: {
        cache: false,
        url: '/file_naming_conventions?q={/query}',
        data: {
          distribution_channel: $('#delivery_order_do_dc_ID').val()
        },
        beforeSend(settings) {
          settings.data.distribution_channel = $('#delivery_order_do_dc_ID').val();
          return settings;
        }
      },
      templates: {
        menu: dropdownTemplates.filelocation_search
      }
    })
    fnc_dropdown.dropdown('set selected', $('#delivery_order_do_fnc_ID').val())

    dc_dropdown.dropdown('setting', 'onChange', (_value, _text, _choice) => {
      dct_dropdown.dropdown('clear')
      mmc_dropdown.dropdown('clear')
      $('#delivery_order_territory_code_ids').dropdown('clear')
      $('#delivery_order_language_code_ids').dropdown('clear')

      dct_dropdown.dropdown('queryRemote', '', function() {
        delivery_orders.autofill_remote_dropdown(dct_dropdown)
      })
    })

    dct_dropdown.dropdown('setting', 'onChange', (_value, _text, _choice) => {
      delivery_order_avails.display_avail_schedule_fields()
      delivery_orders.autofill_remote_dropdown(mmc_dropdown)
      delivery_orders.autofill_remote_dropdown(fl_dropdown)
      delivery_orders.autofill_remote_dropdown(fnc_dropdown)

      if ($('.action[href*="/delivery_orders/new"]').length > 0) {
        language_codes_dropdown.dropdown('queryRemote', '', () => {})
        territory_codes_dropdown.dropdown('queryRemote', '', () => {})
      }
    })
    
  },

  check_destination_path() {
    if ($("#delivery_orders.clone").length > 0 ) { return }

    if ($(".delivery_order_ID").val() != "") {
      $("#delivery_order_do_ContentPackageDestinationPath").on("change", function(_event) {
        if ($("#delivery_order_do_ContentPackageDestinationPath").val() != "") {
          $(".body.segment").find(".message.destination_path").remove()
          $(".destination_path.button").removeClass("disabled")
        } else {
          if ($(".body.segment").find(".message.destination_path").length == 0) {
            $(".body.segment").prepend(`<div class="ui warning message destination_path"><i class="close icon"></i><div class="content"><div class="header">Content Package Destination Path is empty</div> This will lead to problems on any publish or file based actions</div></div>`);
            general.message_closable()
            $(".destination_path.button").addClass("disabled")
          }
        }
      }).trigger("change")
    }
  },

  // Publish Wizard
  publish_wizard: {
    init() {
      const start_publish_button = $('.start.publish.wizard')
      if (start_publish_button.length > 0) {
        const do_id = $(".delivery_order_ID").val()

        start_publish_button.on('click', function() {

          if(publish_wizard_request) { publish_wizard_request.abort() }
          publish_wizard_request = $.ajax({
            url: `/delivery_orders/${do_id}/publish_wizard`,
            beforeSend() {
              start_publish_button.addClass('loading')
            }
          }).done(function(data) {
            $(data).appendTo('body')
            let publish_wizard_modal = $('#delivery_order_publish_wizard_modal')
            publish_wizard_modal.modal({
              observeChanges: true,
              autofocus: false,
              closable: false,
              allowMultiple: true,
              onShow() {
                delivery_orders.publish_wizard.advance_to_step(publish_wizard_modal, 'publish')
                delivery_orders.publish_wizard.display_existing_destination_files(publish_wizard_modal)
                delivery_orders.publish_wizard.display_validation_warnings(publish_wizard_modal)
              },
              onHidden() {
                if (publish_wizard_modal.data('published')) {
                  window.location.reload()
                }
                publish_wizard_modal.remove()
              }
            }).modal('show')
          }).fail(function(_xhr, _textStatus, errorThrown) {
            if (errorThrown != 'abort') { console.log(errorThrown) }
          }).always(() => start_publish_button.removeClass('loading'))
        })
      }
    },

    display_existing_destination_files(publish_modal) {
      const do_id = $(".delivery_order_ID").val()
      fetch(`/delivery_orders/${do_id}/existing_destination_files`)
        .then(r => r.text())
        .then(html => {
          publish_modal.find('.file.warnings.display').removeClass('ui active centered inline text loader')
          publish_modal.find('.file.warnings.display').html(html)
        }).catch(e => console.log(e))
    },

    display_validation_warnings(publish_modal) {
      const do_id = $(".delivery_order_ID").val()
      fetch(`/delivery_orders/${do_id}/validation_warnings`)
        .then(r => r.text())
        .then(html => {
          publish_modal.find('.validation.warnings.display').removeClass('ui active centered inline text loader')
          publish_modal.find('.validation.warnings.display').html(html)
          validations.init_show_validation_details()
        }).catch(e => console.log(e))
    },

    handle_potential_problems(response) {
      let type = response.type
      let segment = $(`.ui.general.information.segment[data-variant="${type}"]`)
      let error_message = $(`.ui.error.message[data-variant="${type}"]`)
      let potential_problems = response.potential_problems

      if (potential_problems?.length > 0) {
        const list = error_message.find('.list')
        list.html('')
        $.each(potential_problems, function(_i, problem) {
          list.append($("<li>").text(problem))
        })
        segment.addClass('top attached')
        error_message.removeClass('hidden').addClass('visible')
      } else {
        segment.removeClass('top attached')
        error_message.removeClass('visible').addClass('hidden')
      }
    },

    reset_actions_and_errors(publish_wizard_modal) {
      let primary_button = publish_wizard_modal.find('#publish_wizard_primary_button')
      let secondary_button = publish_wizard_modal.find('#publish_wizard_secondary_button')
      let error_message = publish_wizard_modal.find('.ui.error.message')
      primary_button.off('click.publish')
      secondary_button.off('click.publish')
      error_message.removeClass('visible')
      error_message.addClass('hidden')
      error_message.find('.header').html('')
      error_message.find('.list').html('')
      primary_button.removeClass('red')
      primary_button.addClass('primary')
      primary_button.data('confirm', null)
      primary_button.data('force', null)
      primary_button.html('Next <i class="right arrow icon"></i>')
    },

    publish_step(publish_wizard_modal) {
      const do_id = $(".delivery_order_ID").val()

      // let primary_button = publish_wizard_modal.find('#publish_wizard_primary_button')
      let secondary_button = publish_wizard_modal.find('#publish_wizard_secondary_button')
      publish_wizard_modal.find('#publish_wizard_quit_button').html("Close")
      // let error_message = publish_wizard_modal.find('.ui.error.message')
      let steps = publish_wizard_modal.find('.ui.steps')
      secondary_button.removeClass("black")
      secondary_button.addClass("teal")
      secondary_button.html("Skip")

      secondary_button.off('click')
      secondary_button.on('click', _e => {
        delivery_orders.publish_wizard.advance_to_step(publish_wizard_modal, 'move')
      })
      $.ajax
      steps.find('.publish.step').addClass('active').removeClass('disabled')
      steps.find('.move.step').addClass('disabled').removeClass('active')
      steps.find('.notify.step').addClass('disabled').removeClass('active')

      // delivery_orders.publish_wizard.reset_actions_and_errors(publish_wizard_modal)

      publish_wizard_modal.find(".ui.checkbox").not('#update_linked_projects_orders').checkbox()
      let update_linked_projects_checkbox = publish_wizard_modal.find('.ui.update_linked_projects_orders.checkbox').checkbox({
        fireOnInit: true,
      })
      publish_wizard_modal.find('#publish_wizard_primary_button').on('click.publish', function(_e) {

        let primary_button = publish_wizard_modal.find('#publish_wizard_primary_button')
        let error_message = publish_wizard_modal.find('.ui.error.message')
        $.ajax({
          url: `/delivery_orders/${do_id}/publish`,
          dataType: 'json',
          contentType: 'application/json',
          method: 'GET',
          data: {
            force: primary_button.data('force'),
            update_linked_projects_orders: update_linked_projects_checkbox.checkbox('is checked'),
            add_project_to_client_delivery_note: false,
            add_project_to_platform_delivery_note: false,
          },
          beforeSend() {
            primary_button.addClass('loading')
          }
        }).done(function(_data) {
          primary_button.off('click.publish')
          publish_wizard_modal.data('published', true)
          delivery_orders.publish_wizard.advance_to_step(publish_wizard_modal, 'move')
        }).fail(function(xhr, _textStatus, _errorThrown) {
          error_message.find('.header').html(xhr.responseJSON?.error)
          let error_list = xhr.responseJSON?.error_list
          if (error_list.length > 0) {
            error_message.find('ul.list').html(error_list.map(err => `<li>${err}</li>`)?.join(''))
          } else {
            error_message.find('ul.list').html(`<li>An Unknown Error Occured!</li>`)
          }
          
          error_message.removeClass('hidden')
          error_message.addClass('visible')
          primary_button.removeClass('primary')
          primary_button.addClass('red')

          if (xhr.responseJSON?.prevent_publish) {
            primary_button.addClass('disabled')
            primary_button.html(`Unable to Publish! <i class="right arrow icon"></i>`)
          } else {
            primary_button.data('confirm', 'Are You Really Sure?')
            primary_button.html(`Publish Anyway! <i class="right arrow icon"></i>`)
            primary_button.data('force', true)
          }
        }).always(_x => {
          primary_button.removeClass('loading')
        })
      })
    },

    move_step(publish_wizard_modal) {
      const do_id = $(".delivery_order_ID").val()
      let primary_button = publish_wizard_modal.find('#publish_wizard_primary_button')
      let secondary_button = publish_wizard_modal.find('#publish_wizard_secondary_button')
      publish_wizard_modal.find('#publish_wizard_quit_button').html("Close")
      // let error_message = publish_wizard_modal.find('.ui.error.message')
      let steps = publish_wizard_modal.find('.ui.steps')
      delivery_orders.publish_wizard.reset_actions_and_errors(publish_wizard_modal)
      secondary_button.removeClass("black")
      secondary_button.addClass("teal")
      secondary_button.html("Skip")

      secondary_button.off('click')
      secondary_button.on('click', _e => {
        delivery_orders.publish_wizard.advance_to_step(publish_wizard_modal, 'notify')
      })

      $.ajax({
        url: `/delivery_orders/${do_id}/publish_wizard_move`,
        method: 'GET',
        data: {
          move_to: 'delivery'
        },
        beforeSend() {
          publish_wizard_modal.find('.form').html(`<div class="ui active loader"></div>`)
          primary_button.addClass('loading')
        }
      }).done(function(data) {
        publish_wizard_modal.find('.form').fadeOut("slow", function(){
          publish_wizard_modal.find('.form').html(data)
          publish_wizard_modal.find('.form').fadeIn("slow")
          steps.find('.publish.step').removeClass('active').removeClass('disabled')
          steps.find('.move.step').addClass('active').removeClass('disabled')
          steps.find('.notify.step').removeClass('active').addClass('disabled')

          primary_button.off("click.publish")

          if ($("#delivery_order_do_ContentPackageDestinationPath").val() == "") {
            primary_button.addClass("disabled red")
            primary_button.text("Publish Path Missing")
          } else {
            primary_button.html(`<i class="truck moving icon"></i> Move`)

            primary_button.on("click.publish", _e => {
              primary_button.addClass("loading")
              $.ajax({
                cache: false,
                url: `/delivery_orders/${do_id}/move_to/delivery`
              }).done(function(_data) {
                primary_button.removeClass("loading")
                primary_button.removeClass('primary red').addClass('green')
                primary_button.addClass('disabled')
                primary_button.off('click.publish')
                primary_button.html('Initiated!')
                delivery_orders.publish_wizard.advance_to_step(publish_wizard_modal, 'notify')

              }).fail(function(_xhr, _textStatus, _errorThrown) {
                primary_button.removeClass("loading")
                primary_button.removeClass('primary').addClass('red')
                primary_button.html('Failed To Move Files!')
                console.log("Failed To Move Files!")
              })
            })
          }
        })

      }).fail(function(_xhr, _textStatus, _errorThrown) {
        console.log('error loading move step')
      }).always(() => { primary_button.removeClass('loading') })
    },

    notify_step(publish_wizard_modal) {
      const do_id = $(".delivery_order_ID").val()
      delivery_orders.publish_wizard.reset_actions_and_errors(publish_wizard_modal)
      let primary_button = publish_wizard_modal.find('#publish_wizard_primary_button')
      let secondary_button = publish_wizard_modal.find('#publish_wizard_secondary_button')
      publish_wizard_modal.find('#publish_wizard_quit_button').hide()
      let steps = publish_wizard_modal.find('.ui.steps')

      secondary_button.removeClass("black")
      secondary_button.addClass("grey")
      secondary_button.html("Close")
      secondary_button.off('click')
      secondary_button.on('click', _e => {
        delivery_orders.publish_wizard.advance_to_step(publish_wizard_modal, 'finish')
      })

      $.ajax({
        url: `/delivery_orders/${do_id}/publish_wizard_notify`,
        method: 'GET',
        beforeSend() {
          publish_wizard_modal.find('.form').html(`<div class="ui active loader"></div>`)
          primary_button.addClass('loading')
          primary_button.addClass('disabled')
        }
      }).done(function(data) {
        publish_wizard_modal.find('.form').fadeOut("slow", function(){
          publish_wizard_modal.find('.form').html(data)
          steps.find('.publish.step').removeClass('active').removeClass('disabled')
          steps.find('.move.step').removeClass('active').removeClass('disabled')
          steps.find('.notify.step').addClass('active').removeClass('disabled')
          let send_notification_form = publish_wizard_modal.find('.form')

          delivery_orders.init_send_notification_form(send_notification_form).then(() => primary_button.removeClass('disabled loading'))
          
          publish_wizard_modal.find('.form').fadeIn("slow")

          delivery_orders.init_send_notification_button(primary_button, publish_wizard_modal, () => delivery_orders.publish_wizard.advance_to_step(publish_wizard_modal, 'finish'))
        })
      }).fail(function(_xhr, _textStatus, _errorThrown) {
        console.log('error loading notify step')
        primary_button.removeClass('loading')
      })
    },

    finish(publish_wizard_modal) {
      delivery_orders.publish_wizard.reset_actions_and_errors(publish_wizard_modal)
      publish_wizard_modal.modal('hide')
    },

    advance_to_step(publish_wizard_modal, to_step) {
      switch (to_step) {
        case 'publish':
          delivery_orders.publish_wizard.publish_step(publish_wizard_modal)
          break
        case 'move':
          delivery_orders.publish_wizard.move_step(publish_wizard_modal)
          break
        case 'notify':  
          $('.file.warnings.display').hide()
          delivery_orders.publish_wizard.notify_step(publish_wizard_modal)
          break
        case 'finish':
          delivery_orders.publish_wizard.finish(publish_wizard_modal)
          break
        default:
          console.log('error unknown previous step')
          break
      }
    },

    update_primary_notification_button(_element) {
      let primary_button = $('.send.notification.ok.button,#publish_wizard_primary_button')

      let checks = $('#delivery_order_notification_form .ui.queue.checkbox').toArray().map(x => $(x).checkbox('is checked'))
      let str = "Send Notifications"
      if (checks.every( x => x == false )) {
        str = "Don't send Notifications"
        primary_button.removeClass('primary')
        primary_button.addClass('black')
      }
      if (checks.some( x => x == true )) {
        str = "Send some Notifications"
        primary_button.removeClass('black')
        primary_button.addClass('primary')
      }
      if (checks.every( x => x == true )) {
        str = "Send Notifications"
        primary_button.removeClass('black')
        primary_button.addClass('primary')
      }

      primary_button.html(`<i class="envelope icon"></i> ${str}`)
    }
  },

  init_import_project_modal() {
    $('.modal_import_project').on("click", function() {
      $.ajax({
        url: "/delivery_orders/import_project_modal",
        type: "GET",
      }).done(function(data) {
        $("body").append(data);
        $('.import.project.modal').modal({
          observeChanges: true,
          autofocus: false,
          onShow() {
            $(".ui.search.dropdown").dropdown()
            const url = '/projects/search_dropdown?q={/query}';
            $(".import.project.modal .search.import-project").search({
              minCharacters: 3,
              apiSettings: {
                url,
                data: {
                  contact_filter: delivery_orders.client_filter(),
                  contact_id: $("#import_client").val(),
                  p_c_BroadcastPlatformID: "7052"
                },
                beforeSend(settings) {
                  settings.data.contact_filter = delivery_orders.client_filter();
                  settings.data.contact_id = $("#import_client").val();
                  return settings;
                }
              },
              onSelect(result, _response) {
                $('.ui.green.send').removeClass("disabled")
                $(".import.project.modal .search.import-project").val(result.p_ID)
                return true;
              },
              templates: {
                standard: search.project_search
              }
            });
            delivery_orders.init_send_import_project()
          }, 
          onHidden() {
            $('.import.project.modal').remove();
          }
        }).modal("show");

      }).fail(() => console.log("error"));
    });
  },

  client_filter() {
    if ($('#import_client').val() == "") {
      return false
    } else {
      return true
    }
  },

  init_send_import_project() {
    $('.ui.green.send').on("click", function() {
      $.ajax({
        url: "/delivery_orders/import_project",
        type: "POST",
        data: {
          p_id: $(".import.project.modal .search.import-project").val()
        }
      }).done(_data => $('.import.project.modal').modal('hide')).fail(() => console.log("error"));
    });
  },

  init_send_asset_request_create(modal) {
    let mt_ids
    let docp_IDs
    if ($(".metadata-asset-request.modal").data("docp-id") == "") {
      mt_ids = delivery_orders.mt_ids()
      docp_IDs = delivery_orders.docp_ids()
    } else {
      mt_ids = [$('.active.delivery_order_content_package.tab .content_package_ID').parent().find(".mt_StudioTitle").dropdown("get value")]
      docp_IDs = [$('.active.delivery_order_content_package.tab .content_package_ID').val()]
    }

    $(modal).find('.asset_request.create').on("click", function() {
      $.ajax({
        url: "/delivery_orders/send_asset_request",
        type: "POST",
        data: {
          mt_IDs: mt_ids,
          dct_ID: $('#delivery_order_do_dct_ID').val(),
          docp_IDs: docp_IDs,
          arq_DueDate: $('#due_date').val(), 
          arq_FulfillmentPartnerID: $('#fulfillment_partner').dropdown('get value'),
          arq_tc_ID: $('#territory').dropdown('get value'), 
          arq_lc_ID: $('#language').dropdown('get value'),
          MandatoryImageOnly: $(this).parent().parent().find('.ui.mandatory.checkbox').checkbox('is checked'),
          IsHighPriority: $(this).parent().parent().find('.ui.priority.checkbox').checkbox('is checked'),
          approval_workflow: $('#approval_workflow').dropdown('get value'),
          ms_ID: $('#delivery_order_do_ms_ID').val()
        }
      }).done(_data => $('.metadata-asset-request.modal').modal('hide')).fail(() => console.log("error"))
    });
  },

  move_to() {
    $('#delivery_orders .move_to .cancel.button').on("click", function() { $('#delivery_orders .move_to').remove() });

    const move_button = $('#delivery_orders .move_to .move.button')
    if ($("#delivery_order_do_ContentPackageDestinationPath").val() == "") {
      move_button.addClass("disabled red")
      move_button.text("Publish Path Missing")
    } else {
      move_button.on("click", function() {
        move_button.addClass("loading")
        $.ajax({
          url: "/delivery_orders/" + $("#delivery_order_ID").val() + "/move_to/" + move_button.attr("data-type")
        }).done(_data => $('#delivery_orders .move_to').remove()).fail(() => {
          move_button.removeClass("loading")
          console.log("error")
        });
      });
    }
  },

  set_asset_data(target, data) {
    if (data !== undefined) {
      const { asset } = data;
      $.ajax({
        data: {
          type: $(target).find(".asset-id-display").attr("data-type"),
          fields: $(target).find(".asset-id-display").attr("data-fields")
        },
        url: `/assets/${asset.a_ID}/card`
      }).done(function(result) {
        $(target).find(".asset-id-display").append(result)

      }).fail(function() {
        console.log('error');
      });
      $(target).find(".asset-id").val(asset.a_ID);
      $(target).find('.sync-start-tc').val(asset.a_FileStartTimecode);
      $(target).find('.sync-start-tc').change(function() {
        assets.clean_framerate($(this));
      }).change();
    }
  },

  menu_package() {
    const menu = $('.ui.fluid.accordion.content-package');
    $.ajax({
      url: '/delivery_orders/' + $('#delivery_order_ID').val() + '/menu_package',
      type: "GET",
      success(data, _textStatus, _xhr) {
        $(menu).html(data)
        delivery_orders.init_sidebar_droptargets()
        delivery_orders.count_packages()
      },
      error(_jqXHR, _textStatus, errorThrown) {
        console.log(errorThrown)
      }
    })
  },

  load_menu(open_menu = true) {
    const menu = $('.ui.styled.fluid.accordion.content-package');

    return new Promise(function (resolve, reject) {
      $.ajax({
        cache: false,
        url: '/delivery_orders/menu_package',
      }).done(function(data) {
        $(menu).append(data)
        
        if (open_menu) {
          $(menu).find(".delivery-order-content-package-menu").last().find(".title").trigger('click')
        }
        $('.delivery-order-content-packages-link-container .add_nested_fields_link').trigger('click')
        delivery_orders.count_packages()

        delivery_orders.init_sidebar_droptargets()

        resolve(data)
      }).fail(reject)
    })
  },

  init_sidebar_droptargets() {
    $('.delivery-order-content-package-menu').on({
      dragenter: function(e) {
        e.preventDefault();
        $(this).addClass('drag-entered')
      },
      dragover: function(e) {
        e.preventDefault();
        $(this).addClass('drag-over')
        e.originalEvent.dataTransfer.dropEffect = 'copy'
      },
      dragleave: function(_e) {
        $(this).removeClass('drag-entered drag-over')
      },
      drop: function(e) {
        const dropped_asset_id = e.originalEvent.dataTransfer.getData('asset-id')
        const dropped_asset_content_type = e.originalEvent.dataTransfer.getData('content-type')
        // load content package if not loaded

        const pkg = $("#delivery_orders .nested-fields-container").find(`[data-tab='${$(this).find('.content').attr("data-tab")}']`)
        delivery_orders.load_package(pkg).then(function(_data) {
          const components = $(pkg).find('.component-list > fieldset[style!="display: none;"]')
          
          // all components
          const all_component_assets = components.find('.assets-list')
          const existing_assets = all_component_assets.find('> .nested_fields .asset-id').toArray().map(x => $(x).val())

          if (!existing_assets.includes(dropped_asset_id)) {
            const component = $(components.toArray().filter(x => $(x).find('.assets-list').find('> .nested_fields').length == 0 )).last()

            if (components.length < 1 || component.length == 0) {
              $(pkg).find('.component.add_nested_fields_link').trigger('click', [{ asset: {a_ID: `${dropped_asset_id}`, content_type: dropped_asset_content_type  } }])        
            } else {
              component.find('.add_nested_fields_link[data-object-class="delivery_order_content_package_component_asset"]').trigger('click', [{ asset: {a_ID: `${dropped_asset_id}`, content_type: `${dropped_asset_content_type}` } }])
            }
            
          }
        })
        
        $(this).removeClass('drag-entered drag-over')
        $(this).on("animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd", function(){
          $(this).removeClass("drop-confirmation-animation")
          $('.asset-browser-list').find(`.asset.card[data-asset-id="${dropped_asset_id}"]`).hide()
        }).addClass("drop-confirmation-animation")
      }
    })
  },

  init_package_button() {
    $(".ui.add-new-delivery-order-content-package.button").on("click", function() {
      $("#delivery_orders .general.information.form").hide()
      $(".general.information.segment").removeClass("blue").addClass("grey")
      const active_tabs = $('.nested-fields-container .ui.active.delivery_order_content_package.tab');
      active_tabs.push($('.delivery-order-content-package-menu .active.title'));
      active_tabs.push($('.delivery-order-content-package-menu .active.content'));
      delivery_orders.perform_close_tabs(active_tabs);
      active_tabs.addClass("blue")

      return delivery_orders.load_menu()
    });

    $(".status-versions").on("click", function() {
      const do_id = $('#delivery_order_ID').val()

      $.ajax({
        url: "/delivery_orders/" + do_id + "/history_modal",
      }).done(function(data) {
        $("body").append(data)
        
        const modal = $('.status-versions.modal')
        modal.modal({
          autofocus: false,
          onShow() {
            
          },
          onHidden() {
            modal.remove()
          }
        }).modal("show");

      }).fail(function() {
        console.log("error")
      })   
    })

    $("#delivery_order_do_p_ID").trigger('change')
  },

  view_init() {
    delivery_orders.init_modals()

    $(".general.information.segment").on("click", function() {
      $(".general.information.segment").addClass("blue").removeClass("grey")
      $("#delivery_orders .general.information.form").show()
      const active_tabs = $('.nested-fields-container .ui.active.delivery_order_content_package.tab')
      active_tabs.push($('.delivery-order-content-package-menu .active.title'))
      active_tabs.push($('.delivery-order-content-package-menu .active.content'))
      delivery_orders.perform_close_tabs(active_tabs)
    })

    delivery_orders.menu_accordion()
  },

  menu_accordion() {
    $('#delivery_orders .ui.accordion.content-package').accordion({
      animateChildren: false,
      onOpening() {
        $("#delivery_orders .general.information.form").hide()
        $("#delivery_orders .general.information.segment").removeClass("blue").addClass("grey")
        $('#delivery_orders .nested-fields-container .ui.active.delivery_order_content_package.tab').removeClass('active')
        $("#delivery_orders .nested-fields-container").find(`[data-tab='${$(this).attr("data-tab")}']`).addClass('active')

        delivery_orders.load_package($("#delivery_orders .nested-fields-container").find(`[data-tab='${$(this).attr("data-tab")}']`))
      },
      onClose() {
        if ($('#delivery_orders .delivery-order-content-package-menu .title.active').length == 0) {
          $("#delivery_orders .general.information.segment").trigger("click")
        }
      }
    });
  },

  delivery_package_file_modal(element) {
      const invoke_row = $(element).parent().parent();
      const count_label = $(element).parents('.delivery-order-content-package-nested-field').find('.item[data-tab=images] .count.label')
      const model = $(element).data('dpf-model');
      const model_id = $(element).data('dpf-id');
      
      $.ajax({
        url: "/delivery_orders/delivery_package_file_modal",
        type: "GET",
        data: {
          model,
          model_id
        }
      }).done( function(html) {
        $('body').append(html);
        $('.ui.delivery.package.file.modal').modal({
          autofocus: false,
          onHidden() {
            let model_tab = null
            if (model == "dpfi") {
              model_tab = document.getElementById($(element).closest(".package-images.tab turbo-frame").attr("id"))
              model_tab.reload()
            }

            $('.ui.delivery.package.file.modal').remove();
          },
          onApprove() {
            const filename = $('.ui.delivery.package.file.modal').find('#filename').val();
            invoke_row.find('.filename').text(filename);
            $.ajax({
              url: "/delivery_orders/update_delivery_package_file",
              type: "PATCH",
              data: {
                model,
                model_id,
                filename
              }
            });
          },
          onDeny() {
            if (confirm('Are you sure you want to delete this?')) {
              invoke_row.remove();
              const count = parseInt(count_label.text()) 
              if (count > 0) {
                count_label.text(count - 1)
              }
              $.ajax({
                url: "/delivery_orders/delete_delivery_package_file",
                type: "DELETE",
                data: {
                  model,
                  model_id
                }});
            }
          }
        }).modal('show');
      });
  },

  package_components(component) {
    //console.log("package_components")
    delivery_orders.init_dropdowns(component);

    delivery_orders.package_components_assets(component);

    $(component).find('.with-tooltip').popup({ inline: true });

    $(component).find(".ui.components-territory-code.dropdown").each(function(_i, item) {
      $(item).dropdown({
        apiSettings: {
          cache: false,
          url: '/delivery_orders/territories?q={/query}',
          data: {
            c_ID: $("#delivery_order_do_c_ClientContactID").val(),
            dct_ID: $("#delivery_order_do_dct_ID").val()
          },
        },
        templates: {
          menu: dropdownTemplates.territory_search_menu
        }
      }).dropdown('set selected', $(item).val())
    });

    $(component).find(".package-component").each(function(_i, item) {
      const component_accordion = $(item).find('.ui.accordion.components')
      const component_body = $(item).find('.ui.secondary.segment')
      const package_element = $(item).find(".open-package")

      component_accordion.accordion({ 
        animateChildren: false,
        onOpening() {
          component_body.show()
          package_element.addClass("active")
        },
        onClose() {
          component_body.hide()
          package_element.removeClass("active")
        }
      })

      $(item).find(".open-package").on("click", function() {
        component_accordion.accordion("toggle", 0)
      })
    })

    $(component).find(".ui.asset-content-type.dropdown").each(function(_i, item) {
      if ($(item).find("select").val() === "4") {
        $(item).parent().next().show();
        $(item).parent().next().next().show();
      } else {
        $(item).parent().next().hide();
        $(item).parent().next().next().hide();
      }

      $(item).on('change', function() {
        const selected_name = $(item).find('option:selected').text();
        const title = $(item).closest('fieldset').find('.ui.tab');
        $(".components.list").find(`[data-list-item-id='${title.data("list-item-id")}']`).text(selected_name);
        $(item).closest('fieldset').find(".component-title").text(selected_name)

        const component_id = $(this).parents('.nested-fields[data-component-item-id]').data('component-item-id')
        const package_id = $(this).parents('.delivery_order_content_package.tab').data('package-id')
        const menu_content = $(`.delivery-order-content-package-menu .content[data-package-id="${package_id}"]`)
        const component_title = $(this).dropdown('get text')

        $(menu_content).find(`.components.list .item[data-list-item-id="${component_id}"]`).find('.component_title').text(component_title)

        if ($(item).find("select").val() === "4") {
          $(item).parent().next().show();
          $(item).parent().next().next().show();
        } else {
          $(item).parent().next().hide();
          $(item).parent().next().next().hide();
        }
      });
    });

    $(component).find(".package").each(function(_i, component_package) {
      $(component_package).find(".send-asset-packager").on("click", function() {
        const asset_package_all_button = $(".action-bar-content .button.asset_package_all")
        if (asset_package_all_button.is('.loading')) {
          alert("Asset Packager is already running!")
          return
        }

        let button = $(this)
        if (!button.is('.loading') && confirm("Are your sure?")) {
          button.addClass('loading')
          let docpc_id = $(component_package).find(".delivery_order_content_package_component").val()

          let toast = $('body').toast({
            displayTime: 0,
            title: 'Asset Packager',
            message: `Initializing...`,
            showIcon: 'file outline',
            showProgress: 'bottom',
            pauseOnHover: false,
            class: "initalizer",
          })

          let toast_header = toast.find('.content .header')
          let toast_message = toast.find('.content .message')

          let progress_bar = $('<div class="ui attached active progress blue bottom compact sliding indeterminate" data-percent="99"><div class="bar up progressing " style="animation-duration: 0.5s; transition-duration: 300ms; display: block; width: 99%;"></div></div>').appendTo(toast.parent('.toast-box'))

          $.ajax({
            url: '/delivery_orders/asset_packager',
            type: "POST",
            dataType: "json",
            data: {
              docpc_id: docpc_id,
            },
            beforeSend: function() {
              progress_bar.progress({ percent: 99 })
            }
          }).done(function(_data, _textStatus, _xhr) {
            progress_bar.removeClass('sliding blue').addClass('green')
            toast.removeClass('neutral').addClass('green')
            toast_header.html('Successfully started')
            toast_message.html('')
            toast.toast('show')
            setTimeout(() => toast.toast('close'), 1500)
          }).fail(function(jqXHR, _textStatus, _errorThrown) {
            console.log(jqXHR.responseText)

            let error = jqXHR.responseJSON?.error || "An Unknown Error Occured"
            toast.find('.file.outline').remove()
            toast_header.html('<i class="ban icon"></i>Asset Packager Error')
            toast_header.addClass('asset_packager_all_errors_header')
            toast_message.html('')
            toast_header.css("margin-bottom", "7px")
            toast.removeClass('neutral').addClass('red')

            let list = $(`<div class="ui list celled asset_packager_all_errors"></div>`).appendTo(toast_message)
            
            let header_description = "Unknown Component"
            if (jqXHR.responseJSON?.component) {
              header_description = `${jqXHR.responseJSON?.package} > ${jqXHR.responseJSON?.component}`
            }
            
            let list_item = `
            <div class="ui item">
              <i class="large file outline middle aligned icon"></i>
              <div class="content">
                <div class="header">${header_description}</div>
                <div class="description">${error}</div>
              </div>
            </div>
            `
            list.append(list_item)

            progress_bar.removeClass('sliding blue').addClass('red')
            toast.removeClass('neutral').addClass('red')
            toast.toast('show')
            setTimeout(() => progress_bar.remove(), 1500)
            setTimeout(() => toast.toast('close'), 60000)
          }).always(() => { 
            button.removeClass('loading')
            button.data('isLoading', false)
          })
        }
      })

      $(component_package).find(".send-chapter-packager").on("click", function() {
        const button = $(this)
        if (!button.is('.loading')) {
          if (confirm("Are you sure?") === true) {
            const docpc_id = $(component_package).find(".delivery_order_content_package_component").val()
            button.addClass('loading')
            let toast = $('body').toast({
              displayTime: 0,
              title: `Initializing Chapter Packager`,
              message: `Package ID: ${docpc_id}`,
              showIcon: 'list',
              showProgress: 'bottom',
              pauseOnHover: false,
              class: "initalizer",
            })

            let toast_header = toast.find('.content .header')
            let toast_message = toast.find('.content .message')

            let progress_bar = $('<div class="ui attached active progress blue bottom compact sliding indeterminate" data-percent="99"><div class="bar up progressing " style="animation-duration: 0.5s; transition-duration: 300ms; display: block; width: 99%;"></div></div>').appendTo(toast.parent('.toast-box'))
            
            $.ajax({
              url: '/delivery_orders/chapter_packager',
              type: "POST",
              dataType: "json",
              data: {
                docpc_id: docpc_id,
              },
              beforeSend: function() {
                progress_bar.progress({ percent: 99 })
              }
            }).done(function(_data, _textStatus, _xhr) {
              progress_bar.removeClass('sliding blue').addClass('green')
              toast.removeClass('neutral').addClass('green')
              toast.find('.content .header').html('Successfully started')
              toast.toast('show')
              setTimeout(() => toast.toast('close'), 1500)
            }).fail(function(jqXHR, _textStatus, _errorThrown) {
              console.log(jqXHR.responseText)

              let error = jqXHR.responseJSON?.error || "An Unknown Error Occured"
              toast.find('.list.icon').remove()
              toast_header.html('<i class="ban icon"></i>Chapter Packager Error')
              toast_header.addClass('chapter_packager_errors_header')
              toast_message.html('')
              toast_header.css("margin-bottom", "7px")
              toast.removeClass('neutral').addClass('red')

              const list = $(`<div class="ui list celled chapter_packager_errors"></div>`).appendTo(toast_message)

              let header_description = "Unknown Component"
              if (jqXHR.responseJSON?.component) {
                header_description = `${jqXHR.responseJSON?.package} > ${jqXHR.responseJSON?.component}`
              }

              let list_item = `
              <div class="ui item">
                <i class="large list middle aligned icon"></i>
                <div class="content">
                  <div class="header">${header_description}</div>
                  <div class="description">${error}</div>
                </div>
              </div>
              `
              list.append(list_item)

              progress_bar.removeClass('sliding blue').addClass('red')
              toast.removeClass('neutral').addClass('red')
              toast.toast('show')
              setTimeout(() => progress_bar.remove(), 1500)
              setTimeout(() => toast.toast('close'), 60000)

            }).always(() => { 
              button.removeClass('loading')
            })
          }
        }
      })
    })
  },

  package_components_assets(component) {
    //console.log("package_components_assets")
    delivery_orders.init_dropdowns(component);

    const promises = Array.from($(component).find('.remote.asset.card')).map((asset_card) => general.fetch_view(asset_card))
    Promise.all(promises).then((_results) => {
      
      const fill_awaited_buttons = $(component).find('.fill-awaited.button')
      fill_awaited_buttons.each(function(_i,b) { 
        const fill_awaited_button = $(b)
        const asset_card = fill_awaited_button.parents(':eq(2)').find('.asset-id-display [data-asset-status]')
        const is_awaited = asset_card?.data('asset-status') === "awaited"
        if (is_awaited) {
          fill_awaited_button.on('click', function() {
            window.open(`/workflows/edit?asset_ids[]=${asset_card?.data('cloned-from')}&import_into_awaited=${asset_card?.data('asset-id')}&delivery_order_id=${$('#delivery_order_ID').val()}`, '_blank')
          })
          fill_awaited_button.show()
        } else {
          fill_awaited_button.hide()
        }
      })
      delivery_orders.perform_asset_count()

    })
    
    $(component).find('.asset-packager-refresh').on("click", function() {
      delivery_orders.change_dos(2, this, "refresh file", "refresh");
    });

    $(component).find('.asset-packager-remove').on("click", function() {
      delivery_orders.change_dos(8, this, "remove", "remove circle outline");
    });

    $(component).find('.asset-packager-publish').on("click", function() {
      delivery_orders.change_dos(7, this, "publish", "checkmark");
    });

    $(component).find(".ui.checkbox").checkbox()

    delivery_orders.asset_autocompletion_with_cloning($(component).find(".search.component-asset"), { delivery_order: true }, component);

    //delivery_orders.import_from_order(component)
  },

  perform_menu_components_count() {
    const menu = $('#delivery_orders .content-package .active.content');
    const count = $(menu).find('.components.list .item').length;
    $(menu).find('.components-count').text(count);

    $(".delivery_order_content_package.tab.active .item.active .count.label").text(count)
  },

  perform_menu_avails_count() {
    const menu = $('#delivery_orders .content-package .active.content');
    const count = $(menu).find('.avail.list .item').length;
    $(menu).find('.avail-count').text(count);

    $(".delivery_order_content_package.tab.active .item.active .count.label").text(count)
  },

  perform_asset_count() {
    // let asset_count = parseInt($('.delivery_order_content_package_component.component-list .asset.card.item:not(.hidden)').length)
    // $(".packages-count .asset.count.label").text(asset_count)    
    // $(".packages-count .asset-count-text").text((asset_count == 1 ? "Asset" : "Assets"))
  },

  load_package(do_package) {
    const package_id = $(do_package).data("package-id")
    const package_index = $(do_package).data("package-index")

    return new Promise(function (resolve, reject) {
      if ($(do_package).data("package-id") != undefined) {
        if ($(do_package).find(".ui.empty.segments").length > 0) {
          $.ajax({
            cache: false,
            url: `/delivery_orders/${$(".delivery_order_ID").val()}/package/${package_id}`,
            data: {
              package_index: package_index || 0,
              package_id: package_id
            },
          }).done(function(data) {
            //console.log(package_id)
            $(do_package).html($(data).children())
            //$(".delivery-order-content-package-menu .content[data-tab=delivery_order_content_package-" + package_id + "]").html($(do_package).find(".package-menu-details"))

            delivery_orders.init_dropdowns($(do_package))
            delivery_orders.studio_title_dropdown($(do_package))
            delivery_orders.linked_order_dropdown($(do_package));

            delivery_orders.load_component($(do_package).find(".delivery_order_content_package_component"), package_index).done(function() {
              delivery_orders.init_package(do_package)
              resolve(data)
            })
          }).fail(reject)
        } else { resolve('could not find empty ui segments or package already loaded') }
      } else { resolve('package id is undefined') }
    })
  },

  init_package(do_package) {
    $(do_package).find(".item[data-tab]").tab()
    $(do_package).find(".menu").menu({ overflowMethod: 'dropdown', })

    $(do_package).find('.with-tooltip').popup({ inline: true });

    delivery_orders.init_dropdowns($(do_package))
    delivery_orders.studio_title_dropdown($(do_package))
    delivery_orders.linked_order_dropdown($(do_package));

    delivery_order_avails.init_package_avail_schedules(do_package);

    $(do_package).find('.studiotitle.field').hover((function() {
      if (!($(do_package).find('.studiotitle.field label a').length > 0)) {
        if ($(this).find("select").val() != "") {
          $(do_package).find('.studiotitle.field label').append("<a target='_blank' href='/metadata_titles/" + $(this).find("select").val() + "/edit'>&nbsp;&nbsp;<i class='fas fa-external-link'></i></a>");
        }
      }
    }), function() {
      $(do_package).find('.studiotitle.field label a').remove();
    });

    delivery_orders.first_material_order()
    
    $(do_package).find(".distribution_channel_cat_id input").on("dblclick", function() {
      if ($(this).val().length === 0) {
        delivery_orders.load_cat_id($(this))
      }
    })

    delivery_orders.init_validations(do_package)
  },

  first_package() {
    return $($(".nested_fields.nested_delivery_order_delivery_order_content_packages[style!='display: none;']")[0]).find(".delivery_order_content_package")
  },

  first_material_order() {
    const first_package = delivery_orders.first_package()
    const first_order_field = $(first_package).find('.material-order.field')

    if (!first_order_field.hasClass("first")) {

      first_order_field.addClass("first")
      
      first_order_field.off("mouseover")
      first_order_field.off("mouseleave")

      first_order_field.on({
        mouseenter: function () {
          if (!(first_package.find('.studiotitle.field label span').length > 0)) {
            if ($(this).find("select").val() != "" && $("#delivery_order_do_c_ClientContactID").val() == "8759" && !$(".generate_components .button").hasClass("disabled")) {
              first_package.find('.material-order.field label').append("<span style='color: #919191; font-size: 11px;' >&nbsp;&nbsp;&nbsp;&nbsp;<i class='fad fa-clone' style='color: #FE9A76;'></i> <i>Copy sequential Order IDs into Content Packages</i></span>");
              first_package.find('.material-order.field label span').on("click", function() {
                delivery_orders.fill_material_orders_modal(first_package)
              })
            }
          }
        },
        mouseleave: function () {
          first_package.find('.material-order.field label span').remove();
        }
      });

    }
  },

  fill_material_orders_modal(first_package) {
    $.ajax({
      url: "/delivery_orders/fill_material_order_modal",
    }).done(function(data) {
      $("body").append(data);

      $('.ui.material-order.modal .fill-orders.button').addClass("disabled")

      $('.ui.material-order.modal').modal({
        autofocus: false,
        onShow() {
          $(".ui.dimmer.modals").addClass("dark")
          
          delivery_orders.load_first_order_data(first_package)
        },
        onHidden() {
          $('.ui.material-order.modal').remove();
          $(".item.add_orders").removeClass("disabled")
        }
      }).modal("show");

    }).fail(function() {
      console.log("error")
      $(".item.add_orders").removeClass("disabled")
    })    
  },

  load_first_order_data(first_package, with_modal = true) {
    const first_order_id = $(first_package).find(".linked-order.dropdown").dropdown("get value")
    const order_count = delivery_orders.count_packages()

    return $.ajax({
      url: "/delivery_orders/linked_orders",
      data: {
        first_order_id: first_order_id,
        order_count: order_count,
        c_ID: $("#delivery_order_do_c_ClientContactID").val()
      }
    }).done(function(data) {
      if(with_modal) {
        $.each(data.results,function(index, item){
          $('.ui.material-order.modal .content').append(`<div class="ui vertical segment"><p><span class="ui basic tiny circular label" style="margin-right: 20px;">${index+1}</span>${item.name}</p></div>`)
        });
  
        $('.ui.material-order.modal .fill-orders.button').removeClass("disabled")
        $('.ui.material-order.modal .fill-orders.button').on("click", () => delivery_orders.fill_material_orders(data.results))
      } else {
        delivery_orders.fill_material_orders(data.results)
      }

      $(".item.add_orders").removeClass("disabled")

    }).fail(() => console.log("error"));
  },

  import_menu() {
    delivery_orders.metadata_season_change()

    delivery_orders.generate_components_button()
    delivery_orders.add_order_assets_button()
    delivery_orders.add_orders_button()
    delivery_orders.trigger_all_import_options()
  },

  fill_material_orders(results) {
    $('.ui.material-order.modal .fill-orders.button').addClass("disabled loading")
    let orders_count = 0

    $($(".nested_fields.nested_delivery_order_delivery_order_content_packages[style!='display: none;']")).each(function(i, item) {
      if ((i + 1) > results.length) { return }
      $(item).find('.material-order.field .dropdown').dropdown("set value", results[i].value)
      $(item).find('.material-order.field .dropdown').dropdown("set text", results[i].name)

      orders_count += 1
    })

    $('body').toast({
      title: `${orders_count} Orders filled`,
      class: 'success',
      displayTime: 3000,
    })

    $('.ui.material-order.modal').modal("hide")
  },

  load_cat_id(element) {
    const input_field = $(element)
      $.ajax({
        url: "/metadata_titles/" + $(input_field).closest(".fields").find(".studiotitle select").val() + "/catalog_id",
        type: "GET",
        dataType: "json",
        data: {
          dc_id: $("#delivery_order_do_dc_ID").val(),
          do_mmc_ID: $("#delivery_order_do_mmc_ID").val()
        }
      }).done(function(data) {
        input_field.val(data.metadata_mapping_contact.mmc_DistributionChannelCatIDPrefix + data.mt_CatalogNo + data.metadata_mapping_contact.mmc_DistributionChannelCatIDSuffix).transition('glow')
        input_field.trigger("change")
      }).fail(() => console.log("error"));
  },

  load_component(component, package_index = 0) {
    //console.log("load_component: " + package_index)
    const package_id = $(component).data("package-id")
    const existing_package_ids = $(`.delivery_order_content_package.tab[data-package-id="${package_id}"]`).find('.package_menu_helpers').data('package-component-ids')

    return $.ajax({
      url: `/delivery_orders/${$(".delivery_order_ID").val()}/package/${package_id}/components`,
      data: {
        package_index: package_index,
        package_component_ids: existing_package_ids
      },
      type: "GET",
      success(data, _textStatus, _xhr) {
        $(component).html($(data).children());
        delivery_orders.init_component(component)
      }
    })
  },

  init_component(component) {
    //console.log("init_component")
    $(component).find(".item[data-tab]").tab()
    //$(component).find(".menu").menu({ overflowMethod: 'dropdown', })
    delivery_orders.package_components(component);
  },

  load_import_delivery_sources(turbo_frame, only_video, hide_imported) {
    let currentUrl = turbo_frame.attr('src')
    let url = new URL(currentUrl)
    url.searchParams.set('only_video', only_video)
    url.searchParams.set('hide_imported', hide_imported)
    if (url.toString() != currentUrl) {
      turbo_frame.html('<div class="ui active centered inline loader"></div>')
      turbo_frame.attr('src', url.toString())
    }
  },

  init_import_delivery_sources_modal(modal_target) {
    let modal = $(modal_target)
    modal.modal({
      onShow() {
        modal.on('formdata', (e) => {
          const formData = e.originalEvent.formData
          let asset_ids = modal.find('.asset.item').has('svg.selected').toArray().map( a => $(a).data("asset-id") )
          for (let a_id of asset_ids) {
            formData.append('asset_ids[]', a_id)
          }
        })
        modal.find('form').addBack('form').on('turbo:submit-end', function(e) {
          if (e.detail?.formSubmission?.result?.success) {
            modal.modal('hide')
            location.reload()
          }
        })
        let frame = modal.find('turbo-frame#import_delivery_sources_frame')

        modal.find(".ui.checkbox").checkbox({
          fireOnInit: true,
          onChange() {
            delivery_orders.load_import_delivery_sources(frame, modal.find(".checkbox.only-video").checkbox("is checked"), modal.find(".checkbox.hide-imported").checkbox("is checked"))
          }
        })

        frame.off('turbo:frame-load')
        frame.on('turbo:frame-load', function(_e) {
          if (modal.find(".imported.label").length > 0) {
            modal.find(".imported-target").html(modal.find(".scrolling.content .imported.label"))
            modal.find(".checkbox.hide-imported").show()
          } else {
            modal.find(".checkbox.hide-imported").hide()
          }
          assets.load_cover('.import.delivery.sources.modal .quicklook.cover')

          let selected_label = $(".import.delivery.sources.modal").find(".import-assets-selected")
          modal.find(".selector").show()
          modal.find(".selector").on('click', function() {
            if ($(this).find(".selected").length > 0) {
              $(this).parent().removeClass("selected")
              index_select.unselect(this)
            } else {
              $(this).parent().addClass("selected")
              index_select.select(this)
            }
            modal.find(".list.item").addClass("selected")
            selected_label.text($(".import.delivery.sources.modal").find('.asset.item').has('.selected').length)
          })

          modal.find(".list.item").each(function() {
            index_select.select($(this).find(".selector"))
          })

          $(".import.delivery.sources.modal").find(".import-assets-selected").text(modal.find(".list.item").length)
          $(".import.delivery.sources.modal").find(".import-assets-count").text(modal.find(".list.item").length)
        })

      },
      onHidden() {
        modal.remove()
      },
    })
    modal.modal('show')
  },

  load_status_labels(node = $('.status.label')) {
    node.each(function(_i, item) {
      const do_id = $(item).parents('.delivery-order').data('delivery-order-id');
      $.ajax({
        url: `/delivery_orders/${do_id}/status_label`,
        type: "GET"
      }).done( function(html) {
        $(item).html(html);
        $(item).parents('.delivery-order').find('.delimiter .line').css('background-color', $(item).find('.icon').data('color'));
        if ($(item).find('.icon').data('status-code') < 7) {
          let due_date = $(item).parents('.delivery-order').find('.content-type').data('iso-date');
          if (due_date !== undefined) {
            due_date = Date.parse(due_date);
            const today_date = new Date();
            if (due_date < today_date) {
              $(item).parents('.delivery-order').find('.content-type').addClass('overdue');
            }
          }
        }
      });
    });
  },

  load_cover(node) {
    $(node).each((_i, cover) =>
      $.ajax({
        url: `/delivery_orders/${$(cover).data('model-id')}/cover`
      }).done(function(result) {
        $(cover).find('.ui.placeholder').replaceWith(`<img src="${result}" class="ui cover image">`)
        delivery_orders.load_cover_warning_sign(cover)
      })
    )
  },

  load_cover_warning_sign(cover) {
    $(cover).find('.warning.sign[data-type="error"]').show()
    fetch(`/delivery_orders/${$(cover).data('model-id')}/check_validation_status`)
      .then(response => response.json())
      .then(data => {
        if (data.validation_status == 'failed') {
          $(cover).find('.warning.sign[data-type="warning"]').show()
        }
      })
      .catch((error) => { console.log(error)})
  },

  get_folder(path, element) {
    if ($(".delivery_order_ID").val() == "") { return }
    $.ajax({
      url: "/delivery_orders/" + $("#delivery_order_ID").val() + "/package_folder",
      type: "GET",
      data: {
        path
      },
      success(data, _textStatus, _xhr) {
        element.html(data);

        delivery_orders.init_package_elements()
      },

      error(_jqXHR, _textStatus, errorThrown) {
        console.log(errorThrown);
        $("#delivery_orders.edit .filelist").html("<div class='ui red basic label'>Loading Error</div>");
      }
    });
  },

  load_folder(element) {
    $(element).append('<div class="ui active inline mini loader" style="margin-left: 30px;"></div><br>');
    delivery_orders.get_folder($(element).data("folder"), $("#delivery_orders.edit .filelist"));
  },

  init_package_elements() {
    $("#delivery_orders.edit .location").on("click", function() {
      delivery_orders.load_folder($(this));
    });

    $("#delivery_orders.edit .xml").on("click", function() {
      delivery_orders.file_preview($(this));
    });
  },

  asset_autocompletion_with_cloning(nodes, params, namespace) {
    let url = '/assets/search_dropdown?q={/query}';
    if (params) { url = search.add_params_to_url(url, params); }
    $(nodes).search({
      minCharacters: 3,
      apiSettings: {
        url,
        data: delivery_orders.asset_search_data(this),
        beforeSend(settings) {
          settings.data = delivery_orders.asset_search_data(this)
          return settings;
        }
      },
      templates: {
        standard: search.asset_search_with_cloning
      },
      onSelect(result, response, test) {
        $(this).search("set value", "")
        $(this).search("hide results") 
        return false
      },
    })
  },

  asset_search_data(target) {
    return {
          client_id: delivery_orders.filter_by_client(target),
          filter_by_asset_spec_types: $(target).find(".checkbox .filter_by_asset_spec_types").prop("checked"),
          distribution_channel_type_id: delivery_orders.filter_by_distribution_channel_type(target),
          filter_by_content_type: delivery_orders.filter_by_content_type(target),
        }
  },

  select_package_component_asset(element, clone = false) {  
    const result = element.closest('.result')
    const search_field = element.parents('.search.component-asset')
    const namespace = element.parents('.delivery_order_content_package_component_assets')

    if (result) {
      let data = result.data('result')
      if (clone) {
        const component_id = search_field.find('input[id^="asset-search-"').attr('id').split('-').pop()
        const asset_list = namespace.find(`#assets-list-${component_id}`)

        $.ajax({
          url: "/assets/" + data.a_ID + "/clone",
          type: "POST",
          dataType: "json",
          beforeSend() {
            asset_list.append(`
              <div class='ui raised segment clone-placeholder'>
                <div class='ui placeholder'>
                  <div class='paragraph'>
                    <div class='short line'></div>
                    <div class='medium line'></div>
                  </div>
                </div>
              </div>
            `)
          }
        }).done(function(data) {
          data.original_asset_id = data.a_ID
          data.a_ID = data.asset_id
          data.is_awaited = true
          search_field.find('.asset-autocomplete-id').val(parseInt(data.a_ID))

          asset_list.find('.clone-placeholder').first().remove()

          $(namespace).find('.component-asset.focus .add_nested_fields_link').trigger('click', [{ asset: data }])
        }).fail(function(_xhr, _textStatus, errorThrown) {
          asset_list.find('.clone-placeholder').first().remove()
          console.log(`failed to clone into awaited asset ${errorThrown}`)
        }) 
      } else {
        data.is_awaited = false
        search_field.find('.asset-autocomplete-id').val(parseInt(data.a_ID))
        $(namespace).find('.component-asset.focus .add_nested_fields_link').trigger('click', [{ asset: data }])
      }
    }
  },

  filter_by_client(context) {
    if ($(context).find(".checkbox .filter_by_client").prop("checked")) {
      return $("#delivery_order_do_c_ClientContactID").val()
    }
    return ""
  },

  filter_by_distribution_channel_type(context) {
    if ($(context).find(".checkbox .filter_by_asset_spec_types").prop("checked")) {
      return $("#delivery_order_do_dct_ID").val()
    }
    return ""
  },

  filter_by_content_type(context) {
    if ($(context).find(".checkbox .filter_by_content_type").prop("checked")) {
      return $(context).closest(".package-component").find(".asset-content-type").dropdown("get value")
    }
    return ""
  },

  isNormalInteger(str) {
    return /^\+?(0|[1-9]\d*)$/.test(str);
  },

  file_preview(element) {
    const folder = $(element).data("folder")
    const path = $(element).data("path")
    $.ajax({
      url: "/delivery_orders/" + $("#delivery_order_ID").val() + "/file_preview",
      type: "GET",
      data: {
        path,
        folder
      },
      success(data, _textStatus, _xhr) {
        $("#delivery_orders.edit .filelist").html(data);

        delivery_orders.init_package_elements()

        $("#delivery_orders.edit .xmldata").each((_i, block) => hljs.highlightElement(block))
      },

      error(_jqXHR, _textStatus, errorThrown) {
        console.log(errorThrown);
        $("#delivery_orders.edit .filelist").html("<div class='ui red basic label'>Loading Error</div>");
      }
    });
  },

  init_send_notification_form(send_notification_form) {
    return new Promise((resolve, reject) => {
        let init_promises = []

        const do_id = $(".delivery_order_ID").val()
        $('#delivery_order_notification_form .ui.accordion').accordion({ 
          selector: { trigger: '.header' }
        })

        $('#delivery_order_notification_form .delivery_order_confirmation_preview.button').on('click', function() {
          const preview_button = $(this)
          let notification_type_id = preview_button.data('notification-type-id')

          if(notification_preview_request) { notification_preview_request.abort() }
          notification_preview_request = $.ajax({
            url: `/notification_types/preview_modal`,
            type: "GET",
            data: {
              notification_type_id: notification_type_id,
              model_name: "DeliveryOrder",
              model_id: do_id,
            },
            beforeSend() {
              preview_button.addClass('loading')
            }
          }).done(function(data) {
            $(data).appendTo('body')
            let preview_modal = $('#notification_preview_modal')
            preview_modal.modal({
              observeChanges: true,
              autofocus: false,
              closable: false,
              allowMultiple: true,
              onShow() {
                notification_types.init_notification_preview_modal(preview_modal)
              },
              onHidden() {
                preview_modal.remove()
              }
            }).modal('show')
          }).fail(function(_xhr, _textStatus, errorThrown) {
            if (errorThrown != 'abort') { console.log(errorThrown) }
          }).always(() => preview_button.removeClass('loading'))
        })

        $('#delivery_order_api_call_form .send_api_callback.checkbox').checkbox({
          onChecked: function() {
            $(this).closest('.list').find('.list .item .send_api_callback.checkbox').checkbox('set enabled')
            $(this).closest('.list').find('.list .item .send_api_callback.checkbox').each((_, trigger) => {
              if ($(trigger).data('is-default')) {
                $(this).closest('.list').find('.list .item .send_api_callback.checkbox').checkbox('set checked')
              }
            })
          },
          onUnchecked: function() {
            $(this).closest('.list').find('.list .item .send_api_callback.checkbox').checkbox('set disabled')
            $(this).closest('.list').find('.list .item .send_api_callback.checkbox').checkbox('set unchecked')
          }
        })
        $('#delivery_order_notification_form .ui.checkbox.add_quicklink').checkbox()

        $('#delivery_order_api_call_form .with-tooltip').popup({
          position: 'left center',
          delay: {
            show: 500,
            hide: 0
          }
        })

        $('#delivery_order_notification_form .ui.queue.checkbox').checkbox({
          fireOnInit: true,
          onChecked() {
            $(this).parents('.ui.accordion').removeClass('disabled')
          },
          onUnchecked() {
            $(this).parents('.ui.accordion').addClass('disabled')
          },
          onChange() {
            delivery_orders.publish_wizard.update_primary_notification_button(send_notification_form)
          }
        })

        $('.ui.dropdown.recipient_filter.noselection').dropdown({
          placeholder: false,
          action: "hide",
          direction: 'upward',
          onHide : function() {
            $(this).dropdown('clear');
          },
        })

        $('#client_recipient_filter').parent('.checkbox').checkbox({ fireOnInit: true })
        $('#distribution_channel_recipient_filter').parent('.checkbox').checkbox({ fireOnInit: true })
        $('#fulfillment_partner_recipient_filter').parent('.checkbox').checkbox({ fireOnInit: true })

        const update_preview_count = (dropdown_element, value, add_or_remove) => {
          let parent_accordion = $(dropdown_element).parentsUntil('.ui.accordion').last().parent('.ui.accordion')
          let contact_person_count_preview = parent_accordion.find('.title .segment[id$="_preview"] .preview.text')

          let selected_values = null
          switch(add_or_remove) {
            case 'add': {
              let existing = $(dropdown_element).find('option').filter(':selected').map((_i,x) => $(x).val())
              selected_values = [...new Set([...existing, ...[value]])]
              break
            }
            case 'remove': {
              selected_values = $(dropdown_element).find('option').filter(':selected').map((_i,x) => $(x).val()).filter(item => item !== value)
              break
            }
            case 'default':
              break
          }
          
          contact_person_count_preview.html(`${selected_values.length} Contact Person${selected_values.length == 1 ? "" : "s"}`)
          
          if (selected_values.length != 0) {
            contact_person_count_preview.removeClass('orange')
          } else {
            contact_person_count_preview.addClass('orange')
          }
        }

        send_notification_form.find('.notification_recipients_client_template.dropdown').dropdown({
          allowReselection: true,
          placeholder: "Select a Predefined Notice Template",
          clearable: true,
          apiSettings: {
            url: `/notification_types/notification_templates`,
            dataType: "json",
            cache: false,
            data: {
              type: "delivery_order_confirmation"
            }
          },
          onChange(value, _text, _selectedItem) {
            $('#notification_recipients_client_template_content').val(value.replace(/<br>/g, '\n'))
          }
        }).dropdown('queryRemote', '', function() {})

        send_notification_form.find('.cc_client.dropdown,.cc_distribution_channel.dropdown,.cc_fulfillment_partner.dropdown').dropdown({
          minCharacters: 1,
          delay: {search: 300},
          apiSettings: {
            cache: false,
            url: '/user_profile_webs.json?q={/query}&search_input={/query}&include_email=true'
          },
          templates: {
            menu: dropdownTemplates.search_menu
          }
        }).dropdown('queryRemote', '', function() {})

        send_notification_form.find('.notification_recipients_distribution_channel_template.dropdown').dropdown({
          allowReselection: true,
          placeholder: "Select a Predefined Notice Template",
          clearable: true,
          apiSettings: {
            url: `/notification_types/notification_templates`,
            dataType: "json",
            cache: false,
            data: {
              type: "delivery_order_confirmation_platform"
            }
          },
          onChange(value, _text, _selectedItem) {
            $('#notification_recipients_distribution_channel_template_content').val(value.replace(/<br>/g, '\n'))
          }
        }).dropdown('queryRemote', '', function() {})

        send_notification_form.find('.notification_recipients_fulfillment_partner_template.dropdown').dropdown({
          allowReselection: true,
          placeholder: "Select a Predefined Notice Template",
          clearable: true,
          apiSettings: {
            url: `/notification_types/notification_templates`,
            dataType: "json",
            cache: false,
            data: {
              type: "delivery_order_confirmation_fulfillment_partner"
            }
          },
          onChange(value, _text, _selectedItem) {
            $('#notification_recipients_fulfillment_partner_template_content').val(value.replace(/<br>/g, '\n'))
          }
        }).dropdown('queryRemote', '', function() {})

        const deferred_client = new $.Deferred()
        let recipient_dropdown = send_notification_form.find('.notification_recipients_client.dropdown').dropdown({
          fullTextSearch: true,
          cache: false,
          apiSettings: {
            url: `/delivery_orders/${do_id}/notification_recipients?q={/query}&search_input={/query}`,
            dataType: "json",
            cache: false,
            data: {
              type: 'client',
              contact: $("#delivery_order_do_c_ClientContactID").val()
            },
            beforeSend: function(settings) {
              settings.data.contact = $("#delivery_order_do_c_ClientContactID").val()
              settings.data.type = $('#client_recipient_filter').parent('.checkbox').checkbox('is checked') ? 'client' : 'all'
              init_promises.push(deferred_client)
              return settings
            },
            onResponse: function(response) {
              delivery_orders.publish_wizard.handle_potential_problems(response)
            }
          },
          onAdd(value, _text, _choice) {
            update_preview_count(this, value, 'add')
          },
          onRemove(removedValue, _removedText, _$removedChoice) {
            update_preview_count(this, removedValue, 'remove')
          }
        })
        recipient_dropdown.dropdown('queryRemote', '', function() { 
          recipient_dropdown.dropdown('restore defaults')
          let preselected_recipient = recipient_dropdown.find('.item').toArray().map(x => $(x).data('value'))
          if (typeof(preselected_recipient) !== 'undefined') {
            recipient_dropdown.dropdown('set exactly', preselected_recipient)
          }
        })

        const deferred_distribution_channel = new $.Deferred()
        let recipients_distribution_channel_dropdown = send_notification_form.find('.notification_recipients_distribution_channel.dropdown').dropdown({
          fullTextSearch: true,
          apiSettings: {
            url: `/delivery_orders/${do_id}/notification_recipients?q={/query}&search_input={/query}`,
            dataType: "json",
            cache: false,
            data: {
              type: 'distribution_channel',
              distribution_channel_id: $('#delivery_order_do_dc_ID').val(),
              project_id: $('#delivery_order_do_p_ID').val()
            },
            beforeSend(settings) {
              settings.data.distribution_channel_id = $('#delivery_order_do_dc_ID').val()
              settings.data.project_id = $('#delivery_order_do_p_ID').val()
              settings.data.type = $('#distribution_channel_recipient_filter').parent('.checkbox').checkbox('is checked') ? 'distribution_channel' : 'all'
              init_promises.push(deferred_distribution_channel)
              return settings
            },
            onResponse: function(response) {
              delivery_orders.publish_wizard.handle_potential_problems(response)
            }
          },
          onAdd(value, _text, _choice) {
            update_preview_count(this, value, 'add')
          },
          onRemove(removedValue, _removedText, _$removedChoice) {
            update_preview_count(this, removedValue, 'remove')
          }
        })
        recipients_distribution_channel_dropdown.dropdown('queryRemote', '', function() { 
          recipients_distribution_channel_dropdown.dropdown('restore defaults')
          let preselected_recipients_distribution_channel = recipients_distribution_channel_dropdown.find('.item').toArray().map(x => $(x).data('value'))
          if (typeof(preselected_recipients_distribution_channel) !== 'undefined') {
            recipients_distribution_channel_dropdown.dropdown('set exactly', preselected_recipients_distribution_channel)
          }
          deferred_distribution_channel.resolve()
        })

        recipient_dropdown.dropdown('queryRemote', '', function() { 
          recipient_dropdown.dropdown('restore defaults')
          let preselected_recipient = recipient_dropdown.find('.item').toArray().map(x => $(x).data('value'))
          if (typeof(preselected_recipient) !== 'undefined') {
            recipient_dropdown.dropdown('set exactly', preselected_recipient)
          }
          deferred_client.resolve()
        })

        const deferred_fulfillment_partner = new $.Deferred()
        let recipients_fulfillment_partner_dropdown = send_notification_form.find('.notification_recipients_fulfillment_partner.dropdown').dropdown({
          fullTextSearch: true,
          apiSettings: {
            url: `/delivery_orders/${do_id}/notification_recipients?q={/query}&search_input={/query}`,
            dataType: "json",
            cache: false,
            data: {
              type: 'fulfillment_partner',
              file_location_id: $('#delivery_order_do_fl_ID').val()
            },
            beforeSend(settings) {
              settings.data.file_location_id = $('#delivery_order_do_fl_ID').val()
              settings.data.type = $('#fulfillment_partner_recipient_filter').parent('.checkbox').checkbox('is checked') ? 'fulfillment_partner' : 'all'
              init_promises.push(deferred_fulfillment_partner)
              return settings
            },
            onResponse: function(response) {
              delivery_orders.publish_wizard.handle_potential_problems(response)
            }
          },
          onAdd(value, _text, _choice) {
            update_preview_count(this, value, 'add')
          },
          onRemove(removedValue, _removedText, _$removedChoice) {
            update_preview_count(this, removedValue, 'remove')
          }
        })

        recipients_fulfillment_partner_dropdown.dropdown('queryRemote', '', function() { 
          recipients_fulfillment_partner_dropdown.dropdown('restore defaults')
          let preselected_recipients_fulfillment_partner = recipients_fulfillment_partner_dropdown.find('.item').toArray().map(x => $(x).data('value'))
          if (typeof(preselected_recipients_fulfillment_partner) !== 'undefined') {
            recipients_fulfillment_partner_dropdown.dropdown('set exactly', preselected_recipients_fulfillment_partner)
          }
          deferred_fulfillment_partner.resolve()
        })

        $.when.apply($, init_promises).then( function() {
          resolve()
        }).catch(function () {
          reject()
        })
    }).catch(() => {})

  },

  init_send_notification_button(primary_button, context, then_action) {
    const do_id = $(".delivery_order_ID").val()
    let error_message = context.find('.ui.error.message')
    // let warning_message = context.find('.ui.warning.message')

    primary_button.on('click.publish', function(_e) {
      primary_button.addClass('loading')

      const c_template = context.find('#notification_recipients_client_template_content').val()
      const p_template = context.find('#notification_recipients_distribution_channel_template_content').val()
      const f_template = context.find('#notification_recipients_fulfillment_partner_template_content').val()
      let data_params = {}
      data_params['notification_recipients_client'] = context.find('.notification_recipients_client.dropdown').dropdown('get value')
      data_params['notification_recipients_distribution_channel'] = context.find('.notification_recipients_distribution_channel.dropdown').dropdown('get value')
      data_params['notification_recipients_fulfillment_partner'] = context.find('.notification_recipients_fulfillment_partner').dropdown('get value')
      
      data_params['cc_client'] = context.find('.cc_client.dropdown').dropdown('get value')
      data_params['cc_distribution_channel'] = context.find('.cc_distribution_channel.dropdown').dropdown('get value')
      data_params['cc_fulfillment_partner'] = context.find('.cc_fulfillment_partner.dropdown').dropdown('get value')
      
      if (c_template.length > 0) {
        data_params['notification_recipients_client_template_content'] = c_template
      }
      if (p_template.length > 0) {
        data_params['notification_recipients_distribution_channel_template_content'] = p_template
      }
      if (f_template.length > 0) {
        data_params['notification_recipients_fulfillment_partner_template_content'] = f_template
      }

      $(context.find('.notify_client.checkbox').checkbox('is checked')).r_try(function(x) {
        data_params['notify_client'] = x
      })
      $(context.find('.notify_distribution_channel.checkbox').checkbox('is checked')).r_try(function(x) {
        data_params['notify_distribution_channel'] = x
      })
      $(context.find('.notify_fulfillment_partner.checkbox').checkbox('is checked')).r_try(function(x) {
        data_params['notify_fulfillment_partner'] = x
      })

      $(context.find('.add_quicklink.checkbox').checkbox('is checked')).r_try(function(x) {
        data_params['add_quicklink'] = x
      })

      $(context.find('.notify_project_manager.checkbox').checkbox('is checked')).r_try(function(x) {
        data_params['notify_project_manager'] = x
      })

      const enabled_api_callbacks = Array.from(document.querySelectorAll('.send_api_callback.checkbox.checked')).map((callback) => callback.dataset.id)
      if (enabled_api_callbacks.length > 0) {
        data_params['send_api_callback'] = true
        data_params['api_callback_ids'] = enabled_api_callbacks
      }

      if (data_params['notification_recipients_client']?.length == 0 && data_params['notification_recipients_distribution_channel']?.length == 0 && data_params['notification_recipients_fulfillment_partner']?.length == 0) {
        if ([data_params['notify_client'], data_params['notify_distribution_channel'], data_params['notify_fulfillment_partner']].some(v => v === true)) {
          context.find('.notification_recipients_client.dropdown').parent('.field').addClass('error')
          context.find('.notification_recipients_distribution_channel.dropdown').parent('.field').addClass('error')
          context.find('.notification_recipients_fulfillment_partner.dropdown').parent('.field').addClass('error')
          
          error_message.text("Please select at least one recipient for either client, distribution channel or fulfillment_partner! Alternatively chose to queue at least one notification!")
          error_message.find('.header').html("Please select at least one recipient for either client or distribution channel!")
          error_message.removeClass('hidden')
          error_message.addClass('visible')


          primary_button.removeClass('loading')
          context.find('.scrolling.content').scrollTop(0)
          return false
        }
      }
      
      $.ajax({
        url: `/delivery_orders/${do_id}/send_notification`,
        type: 'POST',
        dataType: 'json',
        data: data_params,
      }).done(function(data, _textStatus, _jqXHR) {
        if (data.succeeded.length > 0) {
          $('body').toast({
            title: `Notifications Sent!`,
            class: 'success',
            displayTime: 3000,
          })
          console.log(`${data.succeeded}`)
        }
        if (data.failed?.length > 0 || data.other?.length > 0) {
          $('body').toast({
            title: 'Some Notifications Failed!',
            class: 'warning',
            displayTime: 3000,
            message: `${data.warning}`
          })
          console.log(`${data.warning}`)
        }
        if (data.warning?.length > 0) {
          $('body').toast({
            title: 'Warning!',
            class: 'warning',
            displayTime: 3000,
            message: `${data.warning}`
          })
          console.log(`${data.warning}`)
        }
        
        then_action()
        context.modal('hide')
      }).fail(function(_jqXHR, _textStatus, errorThrown) {
        error_message.find('.header').html("An Unknown Error Occured")
        error_message.removeClass('hidden')
        error_message.addClass('visible')
        console.log(errorThrown)
      }).always(function() {
        primary_button.removeClass('loading')
      })

    })
  },

  init_send_notification_modal() {
    $('.send_notification_modal').on("click", function() {
      const do_id = $(".delivery_order_ID").val()
      if(notification_modal_request) { notification_modal_request.abort() }
      notification_modal_request = $.ajax({
        url: "/delivery_orders/" + do_id + "/send_notification_modal",
        type: "GET",
        beforeSend() {
          $('.send_notification_modal').addClass('loading')
        }
      }).done(function(data) {
        $(data).appendTo('body')
        let notification_modal = $('#send_notification_modal')

        let primary_button = notification_modal.find('.send.notification.button')
        primary_button.addClass('disabled loading')
        
        notification_modal.modal({
          observeChanges: true,
          autofocus: false,
          closable: false,
          allowMultiple: true,
          onShow() {
            delivery_orders.init_send_notification_form(notification_modal.find('.form')).then(() => primary_button.removeClass('disabled loading'))
            primary_button.html(`<i class="envelope icon"></i> Send Notification`)
            delivery_orders.init_send_notification_button(primary_button, notification_modal, () => notification_modal.modal('hide'))
          },
          onHidden() {
            notification_modal.remove()
          }
        }).modal("show");

      }).fail(function(_jqXHR, _textStatus, errorThrown) {
        if (errorThrown != 'abort') {
          console.log(errorThrown)
          console.log("send_notification_error")
        }
      }).always(() => $('.send_notification_modal').removeClass('loading'))
    })
  },

  check_import_buttons() {
    let ms = $("#delivery_order_do_ms_ID").val()
    if (ms == "" || ms == null || ms == undefined){
      $(".metadata-season-notice").show()
      $(".import-option").addClass("disabled")
    } else {
      $(".metadata-season-notice").hide()
      $(".import-option").removeClass("disabled")
    }
  },

  metadata_season_change() {
    let button = $("#delivery_order_do_ms_ID")
    button.off("change")
    button.on("change", function() {
      delivery_orders.check_import_buttons()
    })
  },

  generate_components_button() {
    let button = $(".generate_components.item")
    button.on( "click", function() {
      button.addClass("disabled")
      delivery_orders.load_season()
    })
  },

  add_orders_button() {
    let button = $(".item.add_orders")
    button.off("click")
    button.on("click", function() {
      button.addClass("disabled")
      delivery_orders.fill_material_orders_modal(delivery_orders.first_package())
    })
  },

  add_order_assets_button() {
    let button = $(".item.add_order_assets")
    button.off("click")
    button.on("click", function() {
      button.addClass("disabled")
      delivery_orders.add_order_assets()
    })
  },

  trigger_all_import_options() {
    let button = $(".item.trigger_all_import_options")
    button.off("click")
    button.on("click", function() {
      button.addClass("disabled")

      delivery_orders.load_season()
      delivery_orders.load_first_order_data(delivery_orders.first_package(), false)
      delivery_orders.add_order_assets()
    })
  },

  add_order_assets() {
    let packages = $("#delivery_orders .nested-fields-container").find('.delivery_order_content_package.tab')
    let promises = packages.toArray().map( x => delivery_orders.load_package($(x)) )
    let assets_count = 0
    Promise.allSettled(promises).then((_result) => {
      $(packages.find('.add-assets-from-order')).each(function(_, button) {
        $(button).trigger('click')
        assets_count += 1
      })
      
      let packages_without_components = packages.toArray().filter (x => $(x).find('.component-list').find('> fieldset[style!="display: none;"]').length == 0 )
      $(packages_without_components).each(function(_, pkg) {
        $(pkg).find(".add_nested_fields_link.component").trigger('click', [{callback: function(e) {
          delivery_orders.check_import_from_order_button(pkg)
          $(e.target).find('.add-assets-from-order').first().trigger('click')
          assets_count += 1
        }}])
      })

      $(".item.trigger_all_import_options").removeClass("disabled")
      setTimeout(function() {
        $(".add_order_assets.item").removeClass("disabled")
        $('body').toast({
          title: `${assets_count} Assets added`,
          class: 'success',
          displayTime: 3000,
        })
      }, 1200)
      
    })
  },

  load_season() {
    return $.ajax({
      url: "/delivery_orders/metadata_titles",
      dataType: 'json',
      data: {
        s_ID: $('#delivery_order_do_ms_ID').val(),
      },
      success(data, _textStatus) {
        delivery_orders.generate_packages(data.results)
      },
      error(_jqXHR, _textStatus, errorThrown) {
        $(".generate_components.item").removeClass("disabled")
        console.log(errorThrown);
      }
    });
  },

  generate_packages(results) {
    const menu_items = $(".delivery-order-content-package-menu").length

    const rounds = results.length - menu_items
    let do_promises = []

    $(".delivery_order_content_package").each((_i, do_package) => do_promises.push(delivery_orders.load_package(do_package)))

    return $.when.apply($, do_promises).then( function() {
      
    }).then( function() {
      let promises = []

      for (let i = 0; i < rounds; i++) {
        promises.push(delivery_orders.load_menu(false))
      }
  
      $.when.apply($, promises).then(function() {
        $(".general.information.segment").trigger("click")
        delivery_orders.load_components(results)

        $('body').toast({
          title: `${rounds} Components added`,
          class: 'success',
          displayTime: 3000,
        })
        
        $(".generate_components.item").removeClass("disabled")
      })
    })
  },

  load_components(results) {
    $('.component.add_nested_fields_link').each(function() {
      if ($(this).parent().parent().find(".component-list fieldset[style!='display: none;']").length == 0) {
        try {
          $(this).trigger('click')
        }
        catch (e) {
          console.log(e)
        }
      }
    })
  
    $(".asset-content-type.dropdown").each(function(_index, _element) {
      if ($(this).dropdown("get value") == ""){
        $(this).dropdown("set selected", 2)
      }
    })

    const results_cleaned = Array.from(results)

    $(".nested_fields.nested_delivery_order_delivery_order_content_packages[style!='display: none;']").each(function(_index, element) {
      const package_id = $(element).find(".delivery_order_content_package.tab").data("package-id")
      const mt_id = $(".delivery_order_content_package.tab[data-package-id=" + package_id + "]").find(".mt_StudioTitle.dropdown").dropdown("get value")
      $.each(results, function (_r_index, result) {
        if (result.value == mt_id) {
          const remove_index = results_cleaned.findIndex(function(item, _i){
            return item.value === result.value
          });
          results_cleaned.splice(remove_index, 1);
        }
      });
    })

    let set_new_index = 0
    $(".nested_fields.nested_delivery_order_delivery_order_content_packages[style!='display: none;']").each(function(_index, element) {

      const package_id = $(element).find(".delivery_order_content_package.tab").data("package-id")
      if ($(".delivery_order_content_package.tab[data-package-id=" + package_id + "]").find(".mt_StudioTitle.dropdown").dropdown("get value") == "") {
        delivery_orders.set_content_package_title(package_id, results_cleaned[set_new_index])
        set_new_index = set_new_index + 1
      }
    })
  },

  set_content_package_title(package_id, result) {
    if (result == undefined) { return }
    if ($(".delivery_order_content_package.tab[data-package-id=" + package_id + "]").find(".mt_StudioTitle.dropdown").dropdown("get value").toString() != result.value.toString()) {
      $(".delivery_order_content_package.tab[data-package-id=" + package_id + "]").find(".mt_StudioTitle.dropdown").dropdown("set value", result.value)
      $(".delivery_order_content_package.tab[data-package-id=" + package_id + "]").find(".mt_StudioTitle.dropdown").dropdown("set text", result.name)
      delivery_orders.set_studio_title(package_id, result.value, result.name, result.episode_number)
      delivery_orders.load_cat_id($(".delivery_order_content_package.tab[data-package-id=" + package_id + "]").find(".distribution_channel_cat_id input"))
    }
  },

  check_element_validity(element) {
    let valid = element?.validity?.valid
    element?.setCustomValidity('')
    if (valid) { 
      element?.parentNode?.classList.remove('error')
    } else {
      element?.parentNode?.classList.add('error')
    }
    delivery_orders.show_validation_results()
    return valid
  },

  init_validations(element = document) {
    const input_elements = $(element).find('.validate.required')
    input_elements.each(function(_i, input_element) {
      $(input_element).attr('required', true)
      $(input_element).on('invalid', function(e) {
        e.target.setCustomValidity(' ')
        if (!e.target.validity.valid) {
          e.target.setCustomValidity(' ')
        }
      })
      $(input_element).on('change', function(e) { delivery_orders.check_element_validity(e.target) })
    })
    //input_elements.each((_i, e) => { delivery_orders.check_element_validity(e) })
  },

  check_validation_status() {
    let badge = $('.show_validation_results_modal')
    if (badge.length > 0) {
      const do_id = badge.data('id')
      fetch(`/delivery_orders/${do_id}/check_validation_status`)
        .then(response => response.json())
        .then(data => {
          if (data.validation_status == 'failed') {
            badge.removeClass('hidden')
          }
        }).catch(() => {})
        .finally(() => {
          badge.siblings('.validation.loading.indicator').remove()
        })
    }
  },

  generate_descriptive_validation_error(element) {
    let element_name = $(element).attr('name')?.match(/\w+/g)?.map(s => s?.replace(/_attributes/gm, '')?.replace(/_/gm, ' ')?.replace(/attributes/gm, '')?.replace(/^(?<prefix>(?:do|docp|docpc|docpca)) /, `$1_`)  )?.join(' ') || $(element).attr('id')
    return `${element_name} can't be blank!`
  },

  show_validation_results() {
    let error_message = $('.ui.negative.validation.message')
    error_message.addClass('hidden').find('.list').html("")
    
    let invalid_elements = $('.validate.required:invalid')
    if (invalid_elements.length != 0) {
      invalid_elements.each(function(_i, invalid_element) {
        $(invalid_element).parent().addClass('error')
        error_message.find('.list').append(`<li>${delivery_orders.generate_descriptive_validation_error(invalid_element)}</li>`)
      })
      error_message.removeClass('hidden').show()
      console.log(error_message)
    }
    let valid_elements = $('.validate.required:valid')
    valid_elements.each(function(_i, valid_element) {
      $(valid_element).parent().removeClass('error')
    })
  },

  stop_delete_modal() {
    $(".tiny.modal").removeClass("tiny").addClass("small")
    $(".modal .red.button").addClass("disabled")
  }
};

window.delivery_orders = delivery_orders;
