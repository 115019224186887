document.addEventListener("turbo:load", function() {

  if ($("#statistics_content_partner.index").length > 0) {
    statistics_content_partner.index()
  }
})

var statistics_content_partner = { 

  index() {
  },

  adjust_form_body() {
  },

  init_index_body() {
    $("#statistics_content_partner.index")
  },
}

window.statistics_content_partner = statistics_content_partner
