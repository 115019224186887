import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['checkbox']
  static values = {
    initializer: String,
    settings: { type: Object, default: {} },
  }

  initialize() {
    const c = this
    let settings = {
      onChange() { c.dispatch('change') },
      onChecked() { c.dispatch('checked') },
      onUnchecked() { c.dispatch('unchecked') },
      ...this.settingsValue,
    }
    $(this.element).checkbox(settings)
  }
}
