document.addEventListener("turbo:load", function() {
  if ($("#storage_overviews").length > 0) {
    storage_overviews.init()
  }
})

var storage_overviews = {
  init() {
    search.init("/storage_overviews")
    calendar.datepicker(".search.bar")
  }
}
      
window.storage_overviews = storage_overviews;
