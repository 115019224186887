document.addEventListener("turbo:load", function() {
  document.querySelector('#asset_share_orders.index') && asset_share_orders.index()
  document.querySelector('#asset_share_orders.edit') && asset_share_orders.edit()
});

var asset_share_orders = {
  index() {
    search.init("/asset_share_orders");
    calendar.datepicker(".search.bar")
  },

  edit() {
    $("#asset_share_orders .dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
    calendar.datepicker("#asset_share_orders")
  },
};

window.asset_share_orders = asset_share_orders
