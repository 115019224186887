document.addEventListener("turbo:load", function() {
  if ($("#comments").length > 0) {
    comments.init();
  }
});

var comments = {
  init() {
    $("#comments .box .button").on('click', function () {
      if ($("#comments input").val() != "") {
        comments_channel.perform("post", { comment: $("#comments input").val(), upw_id: $("#upw_ID").val(), a_id: $("#asset_a_ID").val() })
        $("#comments input").val("")        
      }
    })
  },
}

window.comments = comments
