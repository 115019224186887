document.addEventListener("turbo:load", function() {});

const aws = {
  list_buckets() {
    return $.ajax({
      url: "/aws_metrics/list_buckets",
      type: "GET"
    });
  },

  load_aws_storage_metrics(bucket) {
    return $.ajax({
      url: "/aws_metrics/storage_metrics",
      type: "GET",
      data: {
        bucket: bucket
      }
    });
  }
};


window.aws = aws;
