import { Controller } from "@hotwired/stimulus"
export default class extends Controller {

  static outlets = [
    'modal--create-metadata'
  ]

  static targets = [
    'modal',
    'submitButton',
    'mode',
    'output',
    'errors',
  ]

  static values = {
    runUrl: String,
  }

  connect() {
  }

  disconnect() {
  }

  async run() {
    let toast = $('body').toast({
      class: 'info',
      displayTime: 2000,
      message: `Running mddf tools to validate delivery order metadata`,
      showProgress: 'bottom'
    })
  }
}