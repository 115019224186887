var workflow_50013 = {
  init(target, params_id) {

    target.find(".dropdown.contact.watermark").dropdown()
    target.find(".dropdown.user.contact").dropdown({
      apiSettings: {
        url: '/users/' + $("#current_user_id").val() + '/contacts?q={/query}&dropdown=true',
      },
      onChange: function() {
        quicklinks.mail_options();
      }
    })

    target.find('.validate').change(() => workflow_50013.validate(params_id));
    
    return $(':input[type=submit]').on('click', function(event) {
      workflow_50013.validate(params_id);
      return event.preventDefault();
    });
  },

  validate(params_id) {
    const validator = { is_valid: true, messages: [] };

    const error_message = $(`#error_message_${params_id}`);
    error_message.addClass('hidden').html("");
    $(':input[type=submit]').prop('disabled', false);

    workflow_50013.validate_quicklink(params_id, validator);
    if (!validator.is_valid) {
      for (let message of Array.from(validator.messages)) {
        error_message.append(`<p>${message}</p>`);
      }
      error_message.removeClass('hidden');
      $(':input[type=submit]').prop('disabled', true);
    }
    return validator.is_valid;
  },
    
  validate_quicklink(params_id, validator) {
    if ($(`#subject_${params_id}`).val().length < 1 && $(".mail_options").css('display') !== 'none') {
      validator.is_valid = false;
      return validator.messages.push("Please add a Subject!");
    }
  }
};

window.workflow_50013 = workflow_50013;
