import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['chart']
  static values = { 
    chart: String,
    url: String,
  }
  
  connect() {
    if (this.hasUrlValue) {
      fetch(this.urlValue)
        .then(response => response.json())
        .then(data => charts[this.chartValue](this.chartTarget, data))
    } else {
      charts[this.chartValue](this.chartTarget)
    }
  }
}
