document.addEventListener("turbo:load", function() {
  if ($("#metadata_images.index").length > 0) {
    metadata_images.init()
  }
})

var metadata_images = {

  init() {
    search.init("/metadata_images")
  },

  edit() {
    metadata_images.init_form()
  },

  init_form(namespace = "#metadata_images") {
    metadata_images.init_dropdowns(namespace)
    metadata_images.init_upload_image(namespace)
    metadata_images.dropzone(namespace)

    let tab_menu = $(namespace + " .segment.custom.with-tabs")
    general.tab_with_url(tab_menu)

    $(namespace + ' .special.card .image').dimmer({on: 'hover'})

    $(namespace + ' #metadata_image_mi_IsApprovedForProduction').checkbox()

    $(namespace + ' #metadata_image_mi_c_ID').dropdown({
      apiSettings: {
        cache: false,
        url: '/metadata_images/clients?q={/query}'
      },
      templates: {
        menu: dropdownTemplates.search_menu
      }
    }).dropdown('set selected', $(namespace + ' #metadata_image_mi_c_ID').val())
  },

  init_dropdowns(namespace) {
    $(namespace + " .default-options.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true})
  },

  dropzone(namespace) {
    let dropzone = $(namespace + ' .upload-dropzone')
    if (dropzone.length > 0) {
      $(namespace + " #image-preview").hide()

      dropzone.on("click", function() {
        $(namespace + " .metadata_image_upload").trigger("click")
      })

      var counter = 0;

      dropzone.off('drag dragstart dragend dragover dragenter dragleave drop') //.off('dragenter').off('dragleave').off('drop')
      dropzone.on('drag dragstart dragend dragover dragenter dragleave drop', function(e) {
        e.preventDefault();
        e.stopPropagation();
      }).on('dragenter', function(e) {
        counter++;
        $(dropzone).addClass('active')
      }).on('dragleave', function(e) {
        counter--;
        if (counter === 0) { 
          $(dropzone).removeClass('active')
        }
      }).on('drop', function(e) {
        const files = e.originalEvent.dataTransfer.files;
        document.querySelector(namespace + ' .metadata_image_upload').files = files;
        $(namespace + ' .metadata_image_upload').trigger("change")
      })
    }
  },

  init_upload_image(namespace) {
    let dropzone = $(namespace + " .upload-dropzone")
    let preview_container = $(namespace + " #image-preview")
    $(namespace + ' .metadata_image_upload').on('change', function(event) {
      dropzone.hide()
      preview_container.show()
      preview_container.attr("xyz", "fade small-5 ease-out").addClass("xyz-in")

      const preview = $(namespace + " #image-preview")
      const input = $(event.currentTarget)
      let file = input[0].files[0]
      var _URL = window.URL || window.webkitURL
      
      let img = new Image();
      var objectUrl = _URL.createObjectURL(file)
      img.onload = function () {
        preview.find('.image').attr("href", objectUrl)
        preview.find('img').attr('src', objectUrl)
      
        preview.find('.width').text(this.width)
        preview.find('.height').text(this.height)
        const [filename, extension] = file.name.split(/\.(?=[^\.]+$)/)
        preview.find('.file_extension').text(extension)
        preview.find('.header').text(file.name)

        $(namespace + ' #metadata_image_mi_Width').val(this.width)
        $(namespace + ' #metadata_image_mi_Height').val(this.height)
        $(namespace + ' #metadata_image_mi_FileExtension').val(extension)
        $(namespace + ' #metadata_image_mi_OriginalName').val(file.name)

        _URL.revokeObjectURL(objectUrl)
      }
      img.src = objectUrl
    })
  },

  init_upload_modal(modal_target) {
    //console.log("init_upload_modal")
    let modal = $(modal_target)
    modal.modal({
      observeChanges: true,
      autofocus: false,
      onShow() {
        
      },
      onHidden() {
        modal.remove()
      },
      onApprove() {
        modal.find(".green.ok.button").addClass("loading disabled")
        Turbo.navigator.submitForm(document.querySelector('#metadata_image_form form'))
        return false
      }
    }).modal("show")
  }
}

window.metadata_images = metadata_images
