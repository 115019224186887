import React from 'react'
import ReactFlow, { isNode } from 'react-flow-renderer'
import dagre from 'dagre'

const dagreGraph = new dagre.graphlib.Graph()
dagreGraph.setDefaultEdgeLabel(() => ({}))

// In order to keep this example simple the node width and height are hardcoded.
// In a real world app you would use the correct width and height values of
// const nodes = useStoreState(state => state.nodes) and then node.__rf.width, node.__rf.height
const nodeWidth = 330
const nodeHeight = 66

const layoutedNodes = (nodes, edges, direction = 'TB') => {
  const isHorizontal = direction === 'LR'
  dagreGraph.setGraph({ rankdir: direction, ranksep: 200, nodesep: 140 })

  nodes.forEach((node) => {
    const width = node.hidden ? 0 : nodeWidth
    const heigth = node.hidden ? 0 : nodeHeight
    dagreGraph.setNode(node.id, { width: width, height: heigth })
  })

  edges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target)
  })

  dagre.layout(dagreGraph)

  return nodes.map((el) => {
    const nodeWithPosition = dagreGraph.node(el.id)
    el.targetPosition = isHorizontal ? 'left' : 'top'
    el.sourcePosition = isHorizontal ? 'right' : 'bottom'

    // unfortunately we need this little hack to pass a slightly different position
    // to notify react flow about the change. Moreover we are shifting the dagre node position
    // (anchor=center center) to the top left so it matches the react flow node anchor point (top left).
    el.position = {
      x: nodeWithPosition.x - nodeWidth / 2 + Math.random() / 1000,
      y: nodeWithPosition.y - nodeHeight / 2,
    }

    return el
  })
}

export default layoutedNodes
