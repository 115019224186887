var workflow_7352 = {
  init(target, params_id) {
    target.find('.validate').on('change', () => workflow_7352.validate(params_id))

    target.find('.item[data-tab]').tab();
    $(`#perform_insert_at_start_${params_id}[type="checkbox"]`).parent().checkbox({
      onChecked: function() {
        let search_field = $(`.search[data-target="#primary_video_-_insert_at_start_-_path_${params_id}"]`)
        search_field.parents('div.insert-at-start').show()
      },
      onUnchecked: function() {
        let search_field = $(`.search[data-target="#primary_video_-_insert_at_start_-_path_${params_id}"]`)
        search_field.search('set value', '')
        $(`#primary_video_-_insert_at_start_-_path_${params_id}`).val("")
        workflow_7352.clear_audio_selection(params_id, "start")
        workflow_7352.clear_content_tc(params_id, "start")
        workflow_7352.clear_hdr_values(params_id, 'start')
        search_field.parents('div.insert-at-start').hide()
      }
    })
    $(`#perform_insert_at_end_${params_id}[type="checkbox"]`).parent().checkbox({
      onChecked: function() {
        let search_field = $(`.search[data-target="#primary_video_-_insert_at_end_-_path_${params_id}"]`)
        search_field.parents('div.insert-at-end').show()
      },
      onUnchecked: function() {
        let search_field = $(`.search[data-target="#primary_video_-_insert_at_end_-_path_${params_id}"]`)
        search_field.search('set value', '')
        $(`#primary_video_-_insert_at_end_-_path_${params_id}`).val("")
        workflow_7352.clear_audio_selection(params_id, "end")
        workflow_7352.clear_content_tc(params_id, "end")
        workflow_7352.clear_hdr_values(params_id, 'end')
        search_field.parents('div.insert-at-end').hide()
      }
    })

    $.each(target.find('.ui.search.insert-asset'), (_i, item) =>
      workflow_7352.insert_asset(params_id, item, { insert_for_asset_id: $(`#primary_video_-_asset_ID_${params_id}`).val(), ignore_client: true, skip_sync_groups: true })
    )

    $.each(target.find('.ui.search.insert-overlay-asset'), (_i, item) =>
      workflow_7352.insert_overlay_asset(params_id, item, { insert_overlay_for_asset_id: $(`#primary_video_-_asset_ID_${params_id}`).val(), ignore_client: true, skip_sync_groups: true })
    )

    awaited_asset.init(target, params_id)

    let deployment_spec_dropdown = target.find('.deployment.spec.dropdown').dropdown({
      preserveHTML: false,
      match: 'text',
      fireOnInit: true,
      onChange(value, _text, selectedItem) {
        let description = selectedItem.data('longDescription')
        let tdg_content_package = selectedItem.data('tdgContentPackage')
        target.find('.deployment-spec').val(false)
        target.find('[id^="deployment_specification_-_variant_id_"]').removeClass('params-input')
        target.find(`.deployment-spec[data-id=${value}]`).next('[id^="deployment_specification_-_variant_id_"]').addClass('params-input')
        target.find(`.deployment-spec[data-uuid=${target.find(`.deployment-spec[data-id=${value}]`).data("uuid")}]`).val(true).trigger('change')
        if (description) {
          target.find('.description.message').text(selectedItem.data('longDescription'))
          target.find('.description.message').removeClass('hidden')
        } else {
          target.find('.description.message').addClass('hidden')
        }
        if (tdg_content_package) {
          target.find('.ui.toggle.content-package.checkbox').checkbox('check')
        } else {
          target.find('.ui.toggle.content-package.checkbox').checkbox('uncheck')
        }
        this.dispatchEvent(new CustomEvent("dropdown:changed", { detail: { value: value } }))
      }
    })
    if (deployment_spec_dropdown.dropdown('get text') == "Deployment Specification") {
      setTimeout(() => {
        deployment_spec_dropdown.dropdown('set selected', '1')
      }, 0)
    }

    target.find('[id^="perform_pre_processing_video_"]').parent('.checkbox').checkbox({
      onChecked() {
        workflow_7352.enable_overlays(target)
        $('input[id^="force_resize"]').parent().removeClass('disabled')
      },
      onUnchecked() {
        workflow_7352.disable_overlays(target)
        $('input[id^="force_resize"]').parent().checkbox('uncheck')
        $('input[id^="force_resize"]').parent().addClass('disabled')
      }
    })

    target.find('.audio.loudness.correction .field').addClass('disabled')
    target.find(`#perform_pre_processing_video_${params_id}`).on('change', function() {
      target.find('.video.preprocessing').toggleClass('disabled')
      if (!$(this).prop('checked')) {
        target.find('.video.preprocessing .ui.checkbox').checkbox('uncheck')
      }
    })

    target.find('.perform-light-level-measurement .custom.dropdown').dropdown({
      fireOnInit: true,
      onChange(value, _text, _choice) {
        let light_level_input = $(this).parent().siblings('[data-uuid="9cf41a50-da93-4931-a434-d535c7696a8c"]')
        let light_level_info = $(this).parent().siblings('.field').find('.light-level-info')
        if (value == 'true') {
          light_level_input.prop("checked", true)
          light_level_info.show()
        } else {
          light_level_input.prop("checked", false)
          light_level_info.hide()
        }
        light_level_input.trigger('change')
      }
    })

    assets.language_buttons(target)
    
    target.find('.spec .ui.toggle.checkbox').on('click', function() {
      target.find('.spec .ui.toggle.checkbox').not(this).checkbox('uncheck')
    })

    target.find('.trim.ui.toggle.checkbox').checkbox({
      fireOnInit: true,
      onChecked() {
        target.find('.perform-edit.field').removeClass("disabled")
        target.find('.active_contents_selection').show()
      },
      onUnchecked() {
        target.find('.perform-edit.field').addClass("disabled")
        target.find('.active_contents_selection').hide()
      }
    })

    target.find('.active_contents_selection .buttons .button').each((_i, e) => {
      let button = $(e)
      button.popup({
        variation: 'very wide flowing',
        lastResort: 'bottom left',
        forcePosition: true,
        prefer: "opposite",
        hoverable: true,
        html: `
        <div style="width: 260px">
          <h5 class="ui dividing header">
            ${button.text()}${button.is('.inactive_content') && "<span class='ui basic orange mini label'>INACTIVE CONTENT</span>"}
          </h5>
          <span class="ui basic tiny grey label">
            START
            <div class="detail">${button.data('content-tc-in')}</div>
          </span>&nbsp;-&nbsp;<span class="ui basic tiny grey label">
            END
            <div class="detail">${button.data('content-tc-out')}</div>
          </span>
        </div>`,
        position: 'bottom center',
      })
    })
    
    target.find('.active_contents_selection .buttons .button').on('click', function() {
      var activeButtons = $(this).parent('.buttons').find('.ui.button.active')
      let centerIndex = activeButtons.eq(Math.floor(activeButtons.length / 2)).index()
      if ($(this).hasClass('active')) {
        if ($(this).index() < centerIndex) {
        
          let previousButtons = $(this).prevUntil('.ui.button:not(.active)')
          if (previousButtons.length > 0) {
            previousButtons.removeClass('active')
          } else {
            if (activeButtons.length != 1) { $(this).removeClass('active') }
          }
        } else {
          let nextButtons = $(this).nextUntil('.ui.button:not(.active)')
          if (nextButtons.length > 0) {
            nextButtons.removeClass('active')
          } else {
            if (activeButtons.length != 1) { $(this).removeClass('active') }
          }
        }
      }
      else {
        if ($(this).index() < centerIndex) {
          $(this).nextUntil('.ui.button.active').addBack().addClass('active')
        } else {
          $(this).prevUntil('.ui.button.active').addBack().addClass('active')
        }
      }

      activeButtons = $(this).parent('.buttons').find('.ui.button.active')
      const trim_div = $(this).closest('div.trim')
      const framerate = $(this).closest('.active_contents_selection').data('framerate')
      const timecode_format = $(this).closest('.active_contents_selection').data('timecode-format')
      let start_tc_string = activeButtons.first().data('content-tc-in')
      if (start_tc_string) {
        let start_tc = Timecode.from_string(start_tc_string, framerate, timecode_format).toString() + `@${parseFloat(framerate.toString()).toString()}`
        trim_div.find('input[id^=media_start_time]').val(start_tc).trigger('change')
      }

      let end_tc_string   = activeButtons.last().data('content-tc-out')
      if (end_tc_string) {
        let end_tc = Timecode.from_string(end_tc_string, framerate, timecode_format).toString() + `@${parseFloat(framerate.toString()).toString()}`
        trim_div.find('input[id^=media_end_time]').val(end_tc).trigger('change')
      }
    })


    target.find('.perform-black .ui.toggle.checkbox').each((_i,element) => {
      const input_field = $(element).parent().next('.field')
      $(element).checkbox({
        onChecked() {
          input_field.show()
        },
        onUnchecked() {
          input_field.hide()
        }
      })
    })

    const command_line_field = target.find('.command-line.field')
    target.find('.command-line.ui.toggle.checkbox').checkbox({
      onChecked() {
        command_line_field.show()
      },
      onUnchecked() {
        command_line_field.hide()
        command_line_field.find('input').val("")
      }
    })

    target.find('.command-line.info.icon').popup({
      popup : $('.command-line.popup'),
      on    : 'click'
    })

    target.find('.ui.toggle.content-package.checkbox').checkbox({
      onChecked() {
        target.find('.title-editorial').hide()
        target.find('.asset-from.checkbox').checkbox('uncheck')
        target.find('.import.awaited.asset.field').hide()
        target.find('.awaited.asset .checkbox').checkbox('uncheck')
        target.find('.content-package-component').show()
      },
      onUnchecked() {
        target.find('.content-package-component').hide()
      }
    })
    
    target.find(`#scan_format_baseband_${params_id}`).on('change', function() {
      const scan_order = target.find('.scan-order.field')
      if ($(this).val() == "p") {
        scan_order.hide()
      } else {
        scan_order.show()
      }
    }).trigger('change')

    target.find(`#preview_-_creation_${params_id}`).on('change', function() {
      if ($(this).prop("checked")) {
        target.find(".preview.field").show()
        target.find(`#perform_add_black_at_start_${params_id}`).prop('checked', true).trigger('change')
        target.find(`#perform_add_black_at_end_${params_id}`).prop('checked', true).trigger('change')
      } else {
        target.find(".preview.field").hide()
      }
    }).trigger('change')

    target.find('.set-preview-tag').on('click', () => {
      target.find(`#asset_tags_${params_id}`).dropdown('queryRemote', '', function() { 
        target.find(`#asset_tags_${params_id}`).dropdown('set exactly', 41) // set preview clip tag
      })
      target.find('.set-preview.text').show(0).delay(1500).hide(0)
    })

    target.find('.framerate-conversion.checkbox').each((_i, element) => {
      $(element).checkbox({
        fireOnInit: true,
        onChecked() {
          target.find('.framerate-conversion.checkbox').not($(element)).checkbox('uncheck')
          target.find('.framerate-conversion.target-framerate').removeClass('disabled')
        },
        onUnchecked() {
          if (target.find('.framerate-conversion.checkbox :checked').length == 0)
            target.find('.framerate-conversion.target-framerate').addClass('disabled')
        }
      })
    })

    target.find(`#perform_-_tachyon_processing_${params_id}`).parent().checkbox({
      fireOnInit: true,
      onChecked() {
        target.find(`#perform_-_color_space_conversion_${params_id}`).parent().checkbox('uncheck')
        target.find(`#perform_-_color_space_conversion_${params_id}`).parent().addClass('disabled')
        target.find('.tachyon.fields').show()
      },
      onUnchecked() {
        target.find('.tachyon.fields').hide()
        target.find(`#perform_-_color_space_conversion_${params_id}`).parent().removeClass('disabled')
      }
    })

    target.find(`#motion_compensation_${params_id}`).on('change', function (){
      if ($(this).prop('checked')) {
        target.find('.motion-compensation.field').show()
      } else {
        target.find('.motion-compensation.field').hide()
      }
    }).trigger('change')

    target.find(`#perform_-_dark_energyn_processing_${params_id}`).on('change', function (){
      if ($(this).prop('checked')) {
        target.find('.dark-energyn.field').show()
      } else {
        target.find('.dark-energyn.field').hide()
      }
    }).trigger('change')


    target.find(`#perform_-_color_space_conversion_${params_id}`).parent().checkbox({
      fireOnInit: true,
      onChecked() {
        target.find(`#perform_-_tachyon_processing_${params_id}`).parent().checkbox('uncheck')
        target.find(`#perform_-_tachyon_processing_${params_id}`).parent().addClass('disabled')
        target.find('.color-primary').show()
      },
      onUnchecked() {
        target.find('.color-primary').hide()
        if ($('.header .label').toArray().filter(x => /HDR/i.test(x.textContent)).length == 0) {
          target.find(`#perform_-_tachyon_processing_${params_id}`).parent().removeClass('disabled')
        }
      }
    })

    target.find(`#perform_pre_processing_audio_${params_id}`).on('change', function() {
      target.find('.audio.preprocessing .ui.checkbox').checkbox('uncheck')
      target.find(`#perform_pre_processing_loudness_correction_${params_id}`).trigger('change')
      if($(this).prop('checked')) {
        target.find(`#perform_pre_processing_loudness_correction_${params_id}`).removeAttr("disabled")
      } else {
        target.find(`#perform_pre_processing_loudness_correction_${params_id}`).attr("disabled", true)
      }
    })

    target.find(`#perform_pre_processing_loudness_correction_${params_id}`).on('change', function() {
      if ($(this).prop('checked')) {
        target.find('.audio.loudness.correction .field').removeClass('disabled')
        target.find('.accordion.loudness').accordion('open', 0)
        return (() => {
          const result = []
          for (let field of Array.from(target.find('.audio.loudness.correction .field [data-default]'))) {
            const default_value = $(field).data('default')
            if ($(field).is(':checkbox')) {
              result.push($(field).prop('checked', default_value));
            } else if ($(field).is('input')) {
              result.push($(field).val(default_value))
            } else if ($(field).is('select')) {
              result.push($(field).dropdown('set selected', default_value))
             } else {
              result.push(undefined)
            }
          }
          return result
        })()
      } else {
        target.find('.audio.loudness.correction .field').addClass('disabled')
        target.find('.accordion.loudness').accordion('close', 0)
      }
    })

    target.find(`#force_resize_${params_id}`).on('change', function() {
      if ($(this).prop("checked")) {
        $(".resize.field").show()
      } else {
        $(".resize.field").hide()
      }
    })

    $(`#force_resize_${params_id}`).trigger('change')

    $(target).find(`.params-input #full_subtitle_language_${params_id}`).on('change', function() {
      $(this).parent().find('.text').css('color', 'inherit')
    })
    $(target).find(`.params-input #partial_forced_subtitle_language_${params_id}`).on('change', function() {
      $(this).parent().find('.text').css('color', 'inherit')
    })

    $(target).find('.subtitle-select').on('change', function() {
      const id = $(this).find("select").attr('id').split("-")[0]
      const type = $(this).find(":selected").data('subtitle-type')
      const container = $(this).find(":selected").data('container')
      $(`#${id}-_type_${params_id}`).val(type).trigger('change')
      $(`#${id}-_container_${params_id}`).val(container).trigger('change')

      let str = $(this).find('select').attr('id') || ""
      let subtitle_number = /secondary_subtitle_(?<snumber>\d+)_.*/.exec(str)?.groups?.snumber
      if (subtitle_number) {
        $(target).find(`[for^="secondary_subtitle_${parseInt(subtitle_number) + 1}_-_language_select"]`).siblings('.subtitle-select').removeClass('disabled')
      }
    })

    $(target).find(`#primary_subtitle_-_language_manual_select_${params_id}`).on('change', function() {
      const lc = $(this).find(":selected").data('language-code')
      const type = $(`#primary_subtitle_-_language_select_${params_id}`).find(":selected").data('subtitle-type')
      let subtitle_element;

      switch (type) {
        case "CC":
        case "FULL":
        case "HOH": 
          subtitle_element = $(`.params-input #full_subtitle_language_${params_id}`)
          break;
        case 'FORCED':
          subtitle_element = $(`.params-input #partial_forced_subtitle_language_${params_id}`)
          break
        default: 
      }
      subtitle_element.dropdown('set exactly', lc)
      subtitle_element.parent().find('.text').css('color', 'red')
    })

    target.find(`input[data-uuid="3649814f-7540-4974-ae24-eaa20f9bfeb9"],
                 input[data-uuid="a596ccb1-461b-4f24-8478-904d7ed21e63"],
                 input[data-uuid="898a84c6-b3ad-4d35-aa70-642df3ee35fb"],
                 input[data-uuid="62b9d855-a4ed-421e-8a71-a8c940ecf520"]`).on('change', function() {
      let toggle_button = $(this).parent('.toggle.button')
      if (['true', true].includes($(this).val())) {
        toggle_button.addClass('active')
      } else {
        toggle_button.removeClass('active')
      }
    }) 

    target.find('.bold.toggle.button').on('click', function() {
      const bold_input = $(this).find('input[data-uuid="3649814f-7540-4974-ae24-eaa20f9bfeb9"]')
      bold_input.val(['false', false].includes(bold_input.val())).trigger('change')
    })
    target.find('.underline.toggle.button').on('click', function() {
      const underline_input = $(this).find('input[data-uuid="a596ccb1-461b-4f24-8478-904d7ed21e63"]')
      underline_input.val(['false', false].includes(underline_input.val())).trigger('change')
    })
    target.find('.italic.toggle.button').on('click', function() {
      const italic_input = $(this).find('input[data-uuid="898a84c6-b3ad-4d35-aa70-642df3ee35fb"]')
      italic_input.val(['false', false].includes(italic_input.val())).trigger('change')
    })
    target.find('.strikethrough.toggle.button').on('click', function() {
      const strikethrough_input = $(this).find('input[data-uuid="62b9d855-a4ed-421e-8a71-a8c940ecf520"]')
      strikethrough_input.val(['false', false].includes(strikethrough_input.val())).trigger('change')
    })

    let enable_subtitle_overlay_checkbox = target.find('.ui.toggle.enable-subtitle-overlay.checkbox')
    enable_subtitle_overlay_checkbox.checkbox({
      onChecked() {
        target.find('.subtitle-overlay-settings').show()
      },
      onUnchecked() {
        target.find('.subtitle-overlay-settings').hide()
        target.find('[id^="primary_subtitle_-_language_select_"]').parent('.dropdown.subtitle-select').dropdown('clear')
      }
    })

    target.find('.ui.toggle.text-overlay-wrap.checkbox').checkbox({})

    target.find('.ui.toggle.font-decoration.checkbox').checkbox({
      onChecked() {
        target.find('.text-overlay-font-decoration-settings').show()
      },
      onUnchecked() {
        target.find('.text-overlay-font-decoration-settings').hide()
      }
    })

    let enable_image_overlay_checkbox = target.find('.ui.toggle.enable-image-overlay.checkbox')
    enable_image_overlay_checkbox.checkbox({
      onChecked() {
        target.find('.image-overlay-settings').show()
      },
      onUnchecked() {
        target.find('.image-overlay-settings').hide()
        let search_element = target.find('.image-overlay-settings .search.insert-overlay-asset')
        search_element.search("set value", "")
        search_element.search("query")
        $(search_element.data('target')).val("")
      }
    })
    target.find('.ui.toggle.image-overlay-timing.checkbox').checkbox({
      onChecked() {
        target.find('.image-overlay-timing-settings').show()
      },
      onUnchecked() {
        target.find('.image-overlay-timing-settings').hide()
      }
    })

    let enable_video_overlay_checkbox = target.find('.ui.toggle.enable-video-overlay.checkbox')
    enable_video_overlay_checkbox.checkbox({
      onChecked() {
        target.find('.video-overlay-settings').show()
      },
      onUnchecked() {
        target.find('.video-overlay-settings').hide()
        let search_element = target.find('.video-overlay-settings .search.insert-overlay-asset')
        search_element.search("set value", "")
        search_element.search("query")
        $(search_element.data('target')).val("")
      }
    })
    target.find('.ui.toggle.video-overlay-timing.checkbox').checkbox({
      onChecked() {
        target.find('.video-overlay-timing-settings').show()
      },
      onUnchecked() {
        target.find('.video-overlay-timing-settings').hide()
      }
    })
    
    let enable_text_overlay_checkbox = target.find('.ui.toggle.enable-text-overlay.checkbox')
    enable_text_overlay_checkbox.checkbox({
      onChecked() {
        target.find('.text-overlay-settings').show()
      },
      onUnchecked() {
        target.find('.text-overlay-settings').hide()
      }
    })
    target.find('.ui.toggle.text-overlay-timing.checkbox').checkbox({
      onChecked() {
        target.find('.text-overlay-timing-settings').show()
      },
      onUnchecked() {
        target.find('.text-overlay-timing-settings').hide()
      }
    })

    target.find('.ui.toggle.enable-timecode-overlay.checkbox').checkbox({
      onChecked() {
        target.find('.timecode-overlay-settings').show()
      },
      onUnchecked() {
        target.find('.timecode-overlay-settings').hide()
      }
    })

    let overlay_text_color_field = target.find('input[data-uuid="57b7c2fd-92dd-4df2-ac1d-16a6232a2d27"]')
    let overlay_background_color_field = target.find('input[data-uuid="f2a677d9-6519-4c78-b596-7511179397ef"]')
    let overlay_outline_color_field = target.find('input[data-uuid="84d18727-541a-4013-86db-3d5941de0de3"]')

    overlay_text_color_field.on('change', function() {
      const vantage_color_regex = /(?<h>#)(?<alpha>[0-9A-Fa-f]{2})?(?<color>[0-9A-Fa-f]{6}).*/gm
      let match = vantage_color_regex.exec(`${$(this).val()}`)
      if (match) overlay_text_color_field.siblings('input[type="color"]').val(`${match.groups.h}${match.groups.color}`)
    })
    overlay_background_color_field.on('change', function() {
      const vantage_color_regex = /(?<h>#)(?<alpha>[0-9A-Fa-f]{2})?(?<color>[0-9A-Fa-f]{6}).*/gm
      let match = vantage_color_regex.exec(`${$(this).val()}`)
      if (match) overlay_background_color_field.siblings('input[type="color"]').val(`${match.groups.h}${match.groups.color}`)
    })
    overlay_outline_color_field.on('change', function() {
      const vantage_color_regex = /(?<h>#)(?<alpha>[0-9A-Fa-f]{2})?(?<color>[0-9A-Fa-f]{6}).*/gm
      let match = vantage_color_regex.exec(`${$(this).val()}`)
      if (match) overlay_outline_color_field.siblings('input[type="color"]').val(`${match.groups.h}${match.groups.color}`)
    })

    target.find('[id^="text_overlay_text_colorpicker_"]').on('change', function() {
      let color = `${$(this).val()}`.replace('#', '').toUpperCase()
      overlay_text_color_field.val(`#FF${color}`).trigger('change')
    })
    target.find('[id^="text_overlay_background_colorpicker_"]').on('change', function() {
      let color = `${$(this).val()}`.replace('#', '').toUpperCase()
      overlay_background_color_field.val(`#FF${color}`).trigger('change')
    })
    target.find('[id^="text_overlay_outline_colorpicker_"]').on('change', function() {
      let color = `${$(this).val()}`.replace('#', '').toUpperCase()
      overlay_outline_color_field.val(`#FF${color}`).trigger('change')
    })
  },

  validate(params_id) {
    const validator = { is_valid: true, messages: { errors: [], warnings: [], info: [] } }   
    
    const error_message = $(`#error_message_${params_id}`)
    error_message.addClass('hidden').html("")
    error_message.removeClass('visible')
    
    const warning_message = $(`#warning_message_${params_id}`)
    warning_message.addClass('hidden').html("")
    warning_message.removeClass('visible')
    
    let submit_button = $(':input[type=submit]') // queue if multiple workflows?
    submit_button.prop('disabled', false)
    submit_button.addClass('primary').removeClass('red')
    submit_button.val('Save')
    
    // validations
    workflow_7352.validate_insert_at(params_id, validator, "start")
    workflow_7352.validate_insert_at(params_id, validator, "end")
    workflow_7352.validate_sources(params_id, validator)
    
    // warnings
    if (validator.messages.warnings?.length > 0) {
      for (let message of Array.from(validator.messages.warnings)) {
        warning_message.append(`<p>${message}</p>`)
      }
      warning_message.removeClass('hidden')
      warning_message.addClass('visible')
      // return $(':input[type=submit]').prop('disabled', true)
      submit_button.addClass('red').removeClass('primary')
      submit_button.val('Save Anyway!')
    }
    
    //errors
    if (validator.messages.errors?.length > 0) {
      for (let message of Array.from(validator.messages.errors)) {
        error_message.append(`<p>${message}</p>`)
      }
      error_message.removeClass('hidden')
      error_message.addClass('visible')

      submit_button.addClass('red').removeClass('primary')
      submit_button.val('Validations failed!')
      submit_button.prop('disabled', true)
    }
  },

  validate_sources(params_id, validator) {
    if ($(`#subtitle_overlay_enabled_${params_id}`).prop('checked')) {
      const subtitle_overlay_source_selected = $(`#primary_subtitle_-_language_select_${params_id}`).val()?.length > 0
      if (!subtitle_overlay_source_selected) {
        validator.is_valid = false
        validator.messages.errors.push(`No source selected for subtitle overlay!`)
      }
    }

    if ($(`#image_overlay_-_present_${params_id}`).prop('checked')) {
      const image_overlay_source_selected = $(`#image_overlay_-_path_${params_id}`).val()?.length > 0
      if (!image_overlay_source_selected) {
        validator.is_valid = false
        validator.messages.errors.push(`No source selected for image overlay!`)
      }
    }

    if ($(`#video_overlay_-_present_${params_id}`).prop('checked')) {
      const video_overlay_source_selected = $(`#video_overlay_-_path_${params_id}`).val()?.length > 0
      if (!video_overlay_source_selected) {
        validator.is_valid = false
        validator.messages.errors.push(`No source selected for video overlay!`)
      }
    }
  },

  validate_insert_at(params_id, validator, suffix) {
    if ($(`#perform_insert_at_${suffix}_${params_id}`).prop('checked')) {
      const primary_audio_51_selected = $(`#primary_audio_-_51_-_insert_at_${suffix}_-_language_select_${params_id}`).val()?.length > 0
      const primary_audio_20_selected = $(`#primary_audio_-_20_-_insert_at_${suffix}_-_language_select_${params_id}`).val()?.length > 0
      if (!primary_audio_51_selected && !primary_audio_20_selected) {
        validator.is_valid = primary_audio_51_selected && primary_audio_20_selected
        validator.messages.warnings.push(`No primary audio selected for Insert-at-${suffix}`)
      }

      const insert_video = $(`#primary_video_-_insert_at_${suffix}_-_path_${params_id}`).val()
      if (!insert_video) {
        validator.is_valid = false
        validator.messages.errors.push(`No source selected for Insert-at-${suffix}`)
      }
    }
  },

  insert_overlay_asset(_params_id, node, params) {
    let url = '/assets/search_dropdown?q={/query}'
    const data = { asset_type: $(node).data('asset-type') }
    let merged_data = {...data, ...params}

    const target = ($(node).data('target'))
    $(node).search({
      minCharacters: 3,
      delay: {search: 300},
      apiSettings: {
        cache: false,
        url: url,
        data: merged_data,
      },
      templates: {
        standard: search.asset_search
      },
      onSelect(result, _response) {
        $(target).val(result.path).trigger("change")
      }
    })
  },

  insert_asset(params_id, node, params) {
    let url = '/assets/search_dropdown?q={/query}'
    if (params) { url = search.add_params_to_url(url, params) }
    const target = ($(node).data('target'))
    const suffix = target.includes("insert_at_start") ? "start" : "end"
    $(node).search({
      minCharacters: 3,
      apiSettings: {
        url
      },
      templates: {
        standard: search.asset_search
      },
      onSelect(result, _response) {
        if (result.content_in_out) {
          workflow_7352.fill_content_tc(params_id, suffix, result.content_in_out)
        }
        $(target).val(result.path).trigger("change")
        for (let index = 0; index < result.sync_groups.length; index++) {
          const atg = result.sync_groups[index]
          workflow_7352.fill_audio_selection(params_id, atg, index, suffix)
        }
        workflow_7352.fill_hdr_values(params_id, suffix, result)        
      },
      onSearchQuery(_query) {
        forms.disable('insert-asset')
      },
      onResults(_response) {
        forms.enable('insert-asset')
      }
    })
  },

  fill_audio_selection(params_id, track_group, index, suffix) {
    let channel_prefix;
    if (track_group.channel_count === 2) {
      channel_prefix = "20";
    } else if (track_group.channel_count === 6) {
      channel_prefix = "51";
    }
    for (var prefix of ["primary", "secondary", "tertiary", "quaternary", "quinary"]) {
      const target_dropdown = $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_language_select_${params_id}`)
      const selected = track_group.disabled ? "disabled" : (track_group[`${prefix}_${channel_prefix}_default`] ? "selected" : "")
      const label = track_group.disabled ? `<span class='ui mini red basic label' style='margin-left: 1em;'>Invalid</span>` : ""
      target_dropdown.append(`<option value='${index}' data-layout='${track_group.layout}' data-track-id='${track_group.track_id}' data-iso639_2='${track_group.iso639_2}' data-channel_count='${track_group.channel_count}' data-location='${track_group.storage_location}' ${selected}>${track_group.description} ${label}</option>`)
      if (channel_prefix === "51") {
        const target_dropdown_20 = $(`#${prefix}_audio_-_20_-_insert_at_${suffix}_-_language_select_${params_id}`)
        target_dropdown_20.append(`<option value='${index}' data-layout='${track_group.layout}' data-track-id='${track_group.track_id}' data-iso639_2='${track_group.iso639_2}' data-channel_count='${track_group.channel_count}' data-location='${track_group.storage_location}' ${selected}>${track_group.description} ${label}</option>`)
        workflow_7352.init_audio_selection_on_change(target_dropdown_20, "20", suffix, params_id)
      }
      workflow_7352.init_audio_selection_on_change(target_dropdown, channel_prefix, suffix, params_id)
    }
  },

  init_audio_selection_on_change(target_dropdown, channel_prefix, suffix, params_id) {
    target_dropdown.on("change", function() {
      let prefix;
      if ($(this).attr('id')[0] === "p") {
        prefix = "primary"
      } else if  ($(this).attr('id')[0] === "s") {
        prefix = "secondary"
      } else if  ($(this).attr('id')[0] === "t") {
        prefix = "tertiary"
      } else if  ($(this).attr('id').startsWith('qua')) {
        prefix = "quaternary"
      }else if  ($(this).attr('id').startsWith('qui')) {
        prefix = "quinary"
      }

      if ($(this).val() === "" || $(this).val() === undefined) {
        $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_present_${params_id}`).val(false)
        $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_path_${params_id}`).val("")
        $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_l_${params_id}`).val("")
        $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_r_${params_id}`).val("")
        $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_c_${params_id}`).val("")
        $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_lfe_${params_id}`).val("")
        $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_ls_${params_id}`).val("")
        $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_rs_${params_id}`).val("")
      } else {
        const selected_data = $(this).find(`option[value=${$(this).val()}]`).data()
        const { layout } = selected_data
        const { channel_count } = selected_data
        const { location } = selected_data
      
        if (layout !== undefined) {
          $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_present_${params_id}`)
          $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_present_${params_id}`).val(true)
          $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_path_${params_id}`).val(location)
          if (channel_count === 2) {
            if ('Mono_1' in layout) {
              $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_l_${params_id}`).val(layout['Mono_1'])
              $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_r_${params_id}`).val(layout['Mono_2'])
            } else {
              $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_l_${params_id}`).val('LT' in layout ? layout['LT'] : layout['Stereo L'])
              $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_r_${params_id}`).val('RT' in layout ? layout['RT'] : layout['Stereo R'])
            }
          } else if (channel_count === 6 && channel_prefix === "51") {
            $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_l_${params_id}`).val(layout['Left Front'])
            $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_r_${params_id}`).val(layout['Right Front'])
            $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_c_${params_id}`).val(layout['Center'])
            $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_lfe_${params_id}`).val(layout['LFE'])
            $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_ls_${params_id}`).val(layout['Left Surround'])
            $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_rs_${params_id}`).val(layout['Right Surround'])
          } else if (channel_count === 6 && channel_prefix === "20") {
            $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_l_${params_id}`).val(`${layout[ 'Left Front']}(0.707),${layout['Center']}(0.5),${layout[ 'Left Surround']}(0.354)`)
            $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_r_${params_id}`).val(`${layout['Right Front']}(0.707),${layout['Center']}(0.5),${layout['Right Surround']}(0.354)`)
          }
        }
      }
    }).trigger("change")
  },

  clear_audio_selection(params_id, suffix) {
    for (var prefix of ["primary", "secondary", "tertiary", "quaternary", "quinary"]) {
      for (var channel_prefix of ["20", "51"]) {
        const target_dropdown = $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_language_select_${params_id}`)
        target_dropdown.dropdown('clear')
      }
    }
  },

  fill_content_tc(params_id, suffix, content_in_out) {
    $(`#primary_video_-_insert_at_${suffix}_media_start_time_${params_id}`).val(content_in_out.start)
    $(`#primary_video_-_insert_at_${suffix}_media_end_time_${params_id}`).val(content_in_out.end)
  },

  clear_content_tc(params_id, suffix) {
    $(`#primary_video_-_insert_at_${suffix}_media_start_time_${params_id}`).val('')
    $(`#primary_video_-_insert_at_${suffix}_media_end_time_${params_id}`).val('')
  },

  fill_hdr_values(params_id, suffix, { video_range, color_primary, color_matrix, color_transfer }) {
    $(`#primary_video_-_insert_at_${suffix}_video_range_${params_id}`).val(video_range)
    $(`#primary_video_-_insert_at_${suffix}_color_primaries_${params_id}`).val(color_primary)
    $(`#primary_video_-_insert_at_${suffix}_color_matrix_${params_id}`).val(color_matrix)
    $(`#primary_video_-_insert_at_${suffix}_color_transfer_characteristics_${params_id}`).val(color_transfer)
  },

  clear_hdr_values(params_id, suffix) {
    $(`#primary_video_-_insert_at_${suffix}_video_range_${params_id}`).val([])
    $(`#primary_video_-_insert_at_${suffix}_color_primaries_${params_id}`).val([])
    $(`#primary_video_-_insert_at_${suffix}_color_matrix_${params_id}`).val([])
    $(`#primary_video_-_insert_at_${suffix}_color_transfer_characteristics_${params_id}`).val([])
  },

  disable_overlays(target) {
    target.find('.enable-subtitle-overlay.checkbox').checkbox('uncheck')
    target.find('.enable-image-overlay.checkbox').checkbox('uncheck')
    target.find('.enable-video-overlay.checkbox').checkbox('uncheck')
    target.find('.enable-text-overlay.checkbox').checkbox('uncheck')
    target.find('.enable-timecode-overlay.checkbox').checkbox('uncheck')
    target.find('.item[data-tab="overlays"]').addClass('disabled')
  },

  enable_overlays(target) {
    target.find('.item[data-tab="overlays"]').removeClass('disabled')
  }

}

window.workflow_7352 = workflow_7352
