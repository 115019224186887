import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['input']

  initialize() {
    //console.log('init')
  }

  fillUUID() {
    //console.log('fill uuid')
    this.inputTargets.forEach(input => {
      $(input).val(crypto.randomUUID().toUpperCase())
    })
  }

}
