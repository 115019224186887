document.addEventListener("turbo:load", () => bets.init())

var bets = {
  init() {
    if ($("#bets").length > 0) {
      bets.init_fields()
      bets.ladder()
      general.message_closable()
      
      if ($('#bets .menu .item').length > 0) {
        $('#bets .custom.item[data-tab]').tab({
          onVisible(path) {
            general.set_url_with_parameter("?tab=" + path)
          }
        })
      }

      $("#bets").off("fields_added.nested_form_fields.nested_bet_rounds");
      return $("#bets").on("fields_added.nested_form_fields.nested_bet_rounds", function(_event, param) {
        switch (param.object_class) {
          case "round":
            bets.init_fields()
            return
          default:
            return console.log("Fields were successfully added, callback not handled.")
        }
      });
    }
  },

  init_fields() {
    $('#bets .ui.dropdown').dropdown({ placeholder: false });
    $('#bets .ui.read-only.checkbox').checkbox({ uncheckable: true });
    $('#bets .ui.checkbox').not(".read-only").checkbox({ uncheckable: false });
    calendar.datetimepicker($("#bets"))

    bets.init_tip().done( () => bets.init_submit_tip()).fail(function(_xhr, _err) {
      return bets.init_submit_tip()
    })
  },

  init_tip() {
    $.each($("#bets .tab[data-tab='rounds'] .grid.round"), (_index, value) => bets.get_tip($(value).attr("data-round-id")))
    $.each($("#bets .tab[data-tab='group'] .card"), (_index, value) => bets.get_tip($(value).attr("data-round-id")))
    return bets.get_tip($("#bets .tab[data-tab='world'] input[name='country']").attr("data-round-id"))
  },

  init_submit_tip() {
    $("#bets .tab[data-tab='rounds'] .input input").not(".disabled").keyup(function() {
      //console.log "tip!"
      const round_id = $(this).attr("name");
      let vs_score_l = "";
      let vs_score_r = "";
      let vs_score = "";

      $.each($(`#bets .tab[data-tab='rounds'] input[name='${round_id}']`), function(index, value) {
        if (index === 0) {
          vs_score_l = $(value).val();
        }
        if (index === 1) {
          vs_score_r = $(value).val();
        }
      });
      if ((vs_score_l !== "") && (vs_score_r !== "")) {
        vs_score = vs_score_l + ":" + vs_score_r;

        console.log(`Round ${round_id}: ${vs_score}`);
        
        return bets.send_tip(round_id, vs_score);
      }
    });

    if ($(".ui.read-only.radio.checkbox").length === 0) {
      $("#bets .tab[data-tab='group'] input[type='radio']").change(function() {
        //console.log "change"
        console.log($(this).attr("data-club"));
        return bets.send_tip($(this).attr("data-round-id"), $(this).attr("data-club"));
      });
    }

    return $("#bets .tab[data-tab='world'] input[name='country']").change(function() {
      //console.log "change"
      console.log($(this).val());
      return bets.send_tip($(this).attr("data-round-id"), $(this).val());
    });
  },

  send_tip(round_id, tip) {
    $(`.round-${round_id}`).html('Sending <span class="ui active inline mini loader"></span>');

    return $.ajax({
      url: `/bets/${$("#bet-id").attr("data-bet-id")}/round/${round_id}/tip`,
      type: "POST",
      dataType: 'JSON',
      data: {
        tip
      }
    }).done(function(result) {
      console.log(result);
      $(`.round-${round_id}`).html(result.message);
      setTimeout(`bets.clean_message(${round_id})`, 1500);
    }).fail(function(xhr, _err) {
      console.log('error');
      $(`.round-${round_id}`).html(xhr.responseText);
    });
  },

  get_tip(round_id) {
    //console.log("get_tip for round: " + round_id)
    return $.ajax({
      url: `/bets/${$("#bet-id").attr("data-bet-id")}/round/${round_id}/tip`,
      type: "GET",
      dataType: 'JSON'
    }).done(function(result) {
      if (result.data.round_type === 1) {
        const vs_tip = result.data.tip.split(":");
        $.each($(`#bets .tab[data-tab='rounds'] input[name='${round_id}']`), (index, value) => $(value).val(vs_tip[index]));
      }
      if (result.data.round_type === 2) {
        $(`#bets .tab[data-tab='group'] input[data-club='${result.data.tip}'][data-round-id='${round_id}']`).attr("checked", "checked");
      }
      if (result.data.round_type === 3) {
        $("#bets .tab[data-tab='world'] .dropdown").dropdown("set selected", result.data.tip);
      }
    }).fail(function(_xhr, _err) {
      //console.log 'error'
    });
  },

  clean_message(round_id) {
    return $(`.round-${round_id}`).html("");
  },

  ladder() {
    return $.ajax({
      url: `/bets/${$("#bet-id").attr("data-bet-id")}/ladder`,
      type: "GET"
    }).done(function(result) {
      $("#bets .tab[data-tab=ladder]").html(result)

      $(this).find(".ui.accordion").accordion({
        animateChildren: false,
        duration: 0,
      })

      let clicker = $("#bets .tab[data-tab=ladder] .clicker")
      clicker.on('click', function(element) {
        $(element.currentTarget).find(".ui.accordion").accordion("toggle", 0)

        if ($(element.currentTarget).hasClass("open")) {
          $(element.currentTarget).removeClass("open")
        } else {
          $(element.currentTarget).addClass("open")
        }
        
        console.log($(element.currentTarget))
        bets.ladder_details($(element.currentTarget).data("user-id"))
        return 
      });
    }).fail(function(_xhr, _err) {
      //console.log 'error'
    });
  },

  ladder_details(user_id) {
    return $.ajax({
      url: `/bets/${$("#bet-id").attr("data-bet-id")}/ladder_details`,
      type: "GET",
      data: {
        user_id: user_id
      }
    }).done(function(result) {
      const table = $(`#bets .tab[data-tab=ladder] .clicker[data-user-id=${user_id}] .accordion .content`)
      if (table.find(".table").length == 0) {
        table.html(result);
      }
    }).fail(function(_xhr, _err) {
      //console.log 'error'
    });
  }
};

window.bets = bets;
