const index_select = {
  init() {
    if ($(".select.index.elements").data("active") == true) {
      index_select.add_selection_options()
    }

    if ($(".button.start-select").length > 0) {
      if ($(".selector").data("style") === 'symbol') {
        index_select.animation_style = "symbol"
      }
    
      $(".button.start-select").on('click', function() {
        let button = $(this)
        button.addClass("loading")
        $.ajax({
          url: "/general/select_options",
          complete() {
            button.removeClass("loading")
          },
          success(data, _textStatus, _xhr) {
            $(".body.segment #" + $(".button.start-select").data("path").replace('/','') + ".index").before(data)
            $(".select.index.elements").data("path", $(".button.start-select").data("path"))
            $(".select.index.elements").data("active", true)
            $(".actions.menu").hide()
            index_select.add_index_selection()
            index_select.add_selection_options()
            index_select.init_options_buttons()
            $(".selector").addClass(index_select.animation_in()).show()
          },
          error(_jqXHR, _textStatus, errorThrown) {
            console.log(errorThrown);
          }
        });
      })
    }

    $(".selector, .list.item .symbol").on('animationend', function(event) {
      if (event.originalEvent.animationName.includes("In")) {
        $(this).removeClass(index_select.animation_in())
      }
      if (event.originalEvent.animationName.includes("Out")) {
        $(this).hide()
        $(this).removeClass(index_select.animation_out())
      }
    })

    if ($(".select.index.elements").data("active") == true) {
      index_select.add_index_selection()
    }
  },

  init_options_buttons() {
    $(".select.index.end").on('click', function() {
      index_select.end_selection()
    })

    $(".select.index.on.page").on('click', function() {
      index_select.select_all_on_page()
    })

    $(".select.index.all").on('click', function() {
      index_select.select_all()
    })

    $(".select.index.none").on('click', function() {
      index_select.unselect_all()
    })

    $(".select-options .actions.menu").on('click', function() {
      let button = $(this)
      if (!button.hasClass("loaded")) {
        dropdown.init_action_menu()
        button.find(".button").addClass("loading")
        $.ajax({
          url: $(".select.index.elements").data("path") + "/select_menu",
          complete() {
            button.find(".button").removeClass("loading")
            button.addClass("loaded")
          },
          success(data, _textStatus, _xhr) {
            button.find(".vertical.menu").html(data)
            button.find(".button").trigger("click")
            index_select.init_action_buttons()
          },
          error(_jqXHR, _textStatus, errorThrown) {
            console.log(errorThrown);
          }
        });
      }
    })
  },

  init_action_buttons() {
    let collection_name = $(".select.index.elements").data("path").replace('/', '');

    $(".actions .qc-reports.pdf").on('click', function() {
      qc_elements.pdf_report()
    })

    $(".actions .qc-elements.set-status").on('click', function() {
      qc_elements.set_status_modal(index_select.selected_ids())
    })

    $(".actions .qc-elements.finish-all").on('click', function() {
      qc_elements.finish_all_modal(index_select.selected_ids())
    })

    $(".actions .qc-elements.unassign").on('click', function() {
      qc_elements.unassign_selected(index_select.selected_ids())
    })

    $(".actions .qc-requests.set-status").on('click', function() {
      qc_requests.set_status_modal(index_select.selected_ids())
    })

    $(".actions .qc-requests.unassign-qc-elements").on('click', function() {
      qc_requests.unassign_qc_elements(index_select.selected_ids())
    })

    workflow_jobs.init_status_modal_button()
    workflow_jobs.init_priority_modal_button()

    baskets.index_menu()
    qc_elements.index_menu()
    quicklinks.index_menu()
    asset_tags.index_menu()
    media_portals.index_menu()

    index_select.call_model_index_menu(collection_name)

    $(".copy.all.ids").on('click', function() {
      const copy_value = index_select.selected_ids()
      context_menu.copy_text(copy_value)
    })
  },

  call_model_index_menu(collection_name) {
    if (window[collection_name] !== undefined) {
      if ((typeof window[collection_name].index_menu) === "function") {
        window[collection_name].index_menu()
      }
    }
  },

  add_index_selection() {
    $(".button.start-select").hide()
    $(".selector").show()
    index_select.count_elements()

    $(".selector").on('click', function() {
      if ($(this).find(".selected").length > 0) {
        index_select.unselect(this)
        index_select.remove_from_list($(this).attr("data-model-id"))
      } else {
        index_select.select(this)
        index_select.add_to_list($(this).attr("data-model-id"))
      }
    })

    $(".list.item").each(function() {
      if ($(".select.index.elements").length > 0) {
        let counter = $(".select.index.elements").data("selected")
        if (counter.includes($(this).find(".selector").data("model-id").toString())) {
          index_select.select($(this).find(".selector"))
        }
      }
    })

    if (index_select.animation_style === "symbol") {
      $('.list.item .symbol').addClass(index_select.animation_out())
    }
  },

  end_selection() {
    $(".button.start-select").show()
    $(".select.index.elements").data("active", false)
    index_select.remove_selection_options()
  },

  add_selection_options() {
    $(".select.index.options").show()
  },

  remove_selection_options() {
    $(".select.index.options").hide()
    index_select.remove_index_selection()

    $(".select.index.elements").remove()
    $(".index.select.counter").text("")
  },

  remove_index_selection() {
    $(".selector").removeClass(index_select.animation_in())
    $(".selector").addClass(index_select.animation_out())
    if (index_select.animation_style === "symbol") {
      $('.list.item .symbol').addClass(index_select.animation_in()).show()
    }
   
    index_select.unselect_all()
    $(".selector").off("click")
  },

  select(element) {
    $(element).find("svg").remove()
    $(element).find("i").remove()
    $(element).html('<i class="fas fa-check-circle fa-w-16 fa-2x selected"></i>')
  },

  unselect(element) {
    $(element).find("svg").remove()
    $(element).find("i").remove()
    $(element).html('<i class="far fa-circle fa-w-16 fa-2x unselected"></i>')
  },

  select_all_on_page() {
    $(".list.item").each(function() {
      const counter = $(".select.index.elements").data("selected")
      let selector = $(this).find(".selector")
      let model_id = selector.data("model-id").toString()

      if (!counter.includes(model_id)) {
        index_select.select(selector)
        index_select.add_to_list(model_id)
      }
    });
  },

  select_all() {
    $(".select.index.all").addClass("loading")
    if (search.search_count() > 250 ) {
      index_select.confirm_select_all()
    } else {
      index_select.load_select_all()
    }
  },

  unselect_all() {
    if ($(".select.index.elements").length > 0) {
      $(".select.index.elements").data("selected", [])
      index_select.count_elements()
      $(".selector").find("svg").remove()
      $(".selector").find("i").remove()
      $(".selector").html('<i class="far fa-circle fa-w-16 fa-2x unselected"></i>')
    }
  },

  load_select_all() {
    return $.ajax({
      url: $(".select.index.elements").data("path") + "?only_ids=true",
      dataType: "json",
      data: search.collect_search(),
      complete() {
        $(".select.index.all").removeClass("loading")
      },
      success(data, _textStatus, _xhr) {
        let elements = data.map(String)
        $(".select.index.elements").data("selected", elements)
        index_select.count_elements()
        $(".selector").find("svg").remove()
        $(".selector").find("i").remove()
        $(".selector").html('<i class="fas fa-check-circle fa-w-16 fa-2x selected"></i>')
      },
      error(_jqXHR, _textStatus, errorThrown) {
        console.log(errorThrown);
      }
    });
  },

  confirm_select_all() {
    if ($(".ui.dimmer").length > 0) {
      $(".ui.dimmer").remove();
      $("body").removeClass("dimmable dimmed")
    }
    
    $.ajax({
      url: '/general/confirm_modal',
    }).done(function(result) {
      $("body").append(result)

      let confirm_cooldown = 25
      let small_limit = 250
      let large_limit = 1000

      const modal = $('.ui.confirm.modal')
      $(modal).find('.header').text(`Select all in scope`)
      $(modal).find('.content p').text(`Selecting more than ${small_limit} elements in scope can be dangerous!`)

      if (search.search_count() > large_limit ) {
        $(modal).find('.content p').text(`Selecting more than ${large_limit} elements in scope is very dangerous!`)

        $(modal).find('.ok.button').html(`<div class="ui red button"><i class="exclamation triangle icon"></i>I'm sure</div><span id="confirm-countdown" class="ui basic red left pointing label">25</span>`)
        $(modal).find('.ok.button').addClass("labeled disabled").removeClass("green")

          //console.log("wait")
          async function wait() {
            for (let tick = confirm_cooldown; tick > 0; tick--) {
              //console.log(step)
              await general.sleep(1000);
              $(modal).find('#confirm-countdown').text(`${tick}`)
            }

            //console.log("wait done.")
            $(modal).find('.ok.button').html(`<div class="ui red button"><i class="exclamation triangle icon"></i>I'm sure</div>`)
            $(modal).find('.ok.button').removeClass("disabled")
          }
          wait()
      }
      
      modal.modal({
        onHidden() {
          $(".select.index.all").removeClass("loading")
          modal.remove()
        },
        onApprove() {
          $(modal).find('.ok.button').addClass("loading disabled")

          let load_select = index_select.load_select_all()
          load_select.done(function() {
            //console.log("loading done")
            modal.modal("hide")
          })
          return false
        }
      }).modal('show');

    }).fail(function(error) {
      
    })
  },

  add_to_list(id) {
    if ($(".select.index.elements").length) {
      let counter = $(".select.index.elements").data("selected")
      counter.push(id)
      $(".select.index.elements").data("selected", counter.map(String))
      index_select.count_elements()
    }
  },

  remove_from_list(id) {
    if ($(".select.index.elements").length) {
      let counter = $(".select.index.elements").data("selected")
      let index_count = counter.indexOf(id)

      if (index_count >= 0) {
        counter.splice (counter.indexOf(id), 1);
        $(".select.index.elements").data("selected", counter.map(String))
      }
      
      index_select.count_elements()
    }
  },

  count_elements() {
    if ($(".select.index.elements").length > 0) {
      let counter = $(".select.index.elements").data("selected").length
      $(".index.select.counter").text(counter)
      if (counter == 0) {
        if ($(".actions.menu").data("active") == true) {

          $(".actions.menu").find(".button").addClass("xyz-out")
          setTimeout(function() {
            $(".actions.menu").find(".button").removeClass("xyz-out")
            $(".actions.menu").hide()
            $(".actions.menu").data("active", false)
          }, 1000)
          
        }
      } else {
        if ($(".actions.menu").data("active") == false) {
          $(".actions.menu").data("active", true)
          $(".actions.menu").show()
          $(".actions.menu").find(".button").addClass("xyz-in")
          setTimeout(function() {
            $(".actions.menu").find(".button").removeClass("xyz-in")
          }, 1000)
        }
      }
    }
  },

  selected_ids() {
    return $(".select.index.elements").data("selected")
  },

  animation_in() {
    let animation = "animate__animated "
    if (index_select.animation_style === "symbol") {
      animation += " animate__zoomIn animate__faster"
    } else {
      animation += "animate__bounceIn"
    }
    return animation
  },

  animation_out() {
    let animation = "animate__animated "
    if (index_select.animation_style === "symbol") {
      animation += " animate__zoomOut animate__faster"
    } else {
      animation += "animate__bounceOut"
    }
    return animation
  }
};

index_select.animation_style = "cover"
window.index_select = index_select;
