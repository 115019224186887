import NestedForm from 'stimulus-rails-nested-form'

export default class extends NestedForm {

  static targets = [
    "placeholder",
    "fallback",
  ]

  static values = {
    success: { type: Boolean, default: true },
  }

  initialize() {
    if (this.hasPlaceholderTarget) this.placeholder_element = this.placeholderTarget.cloneNode(true)
    if (this.hasFallbackTarget) this.fallback_element = this.fallbackTarget.content.cloneNode(true)
  }
  
  connect() {
    this.element.addEventListener('turbo:before-fetch-request', this.beforeFetchRequest.bind(this))
    this.element.addEventListener('turbo:frame-missing', this.frameMissing.bind(this))
    this.element.addEventListener('turbo:fetch-request-error', this.fetchRequestError.bind(this))
    this.element.addEventListener('turbo:frame-load', this.frameLoad.bind(this))
    
  }

  disconnect() {
    this.element.removeEventListener('turbo:before-fetch-request', this.beforeFetchRequest.bind(this))
    this.element.removeEventListener('turbo:frame-missing', this.frameMissing.bind(this))
    this.element.removeEventListener('turbo:fetch-request-error', this.fetchRequestError.bind(this))
    this.element.removeEventListener('turbo:frame-load', this.frameLoad.bind(this))
  }

  reload(force) {
    if (force) {
      let was_disabled = this.element.disabled
      if (was_disabled) this.element.disabled = false
      this.element.reload()
      if (was_disabled) this.element.disabled = true
    } else {
      this.element.reload()
    }
    this.dispatch('reload')
  }

  frameMissing(event) {
    if (event instanceof Event) event.stopPropagation()
    if (this.fallback_element) {
      this.element.innerHTML = ''
      this.element.appendChild(document.importNode(this.fallback_element, true))
    }
    this.dispatch('frame-missing')
  }

  disable(event) {
    if (event instanceof Event) event.stopPropagation()
    this.element.disabled = true
    this.dispatch('disabled')
  }

  enable(event) {
    event.preventDefault()
    event.stopPropagation()
    this.element.disabled = false
    this.dispatch('enabled')
  }

  beforeFetchRequest(_event) {
    this.successValue = false
    if (this.placeholder_element) {
      this.element.innerHTML = ''
      this.element.appendChild(document.importNode(this.placeholder_element, true))
    }
    this.dispatch('before-fetch-request')
  }

  fetchRequestError(_event) {
    if (this.fallback_element) {
      this.element.innerHTML = ''
      this.element.appendChild(document.importNode(this.fallback_element, true))
    }
    this.dispatch('fetch-request-error')
  }

  frameLoad(_event) {
    this.successValue = true
    this.dispatch('frame-load')
  }
  
}
