import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
  ]

  static values = {
    paramsId: String,
  }

  connect() {
  }
}