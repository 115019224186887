import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'


export default class extends Controller {
  
  static outlets = [
    'accordion'
  ]
  
  static targets = [
    'player ',
    'clppContainer',
  ]

  static values = {
    qcMode: { type: String, default: 'none' },
    assetId: String,
    autoplay: { type: Boolean, default: false},
    hideControls: { type: String, default: ''},
  }

  initialize() {
    this.initial_hidden_controls = this.hideControlsValue?.split(' ').filter(x => x !== '') || []
  }

  async connect() {
    const c = this
    await c.getvideoParams()
    c.initHTML()
    c.setupVisibilityObserver()
  }
  
  disconnect() {
    this.removeVideo()
    this.visibility_observer?.disconnect()
    this.visibility_observer = null
    window.player = null
  }

  hideControlsValueChanged(value, _previous_value) {
    if (value === '') {
      this.hidden_controls = this.initial_hidden_controls
    } else {
      this.hidden_controls = value?.split(' ')
    }
    this.updateControls()
  }

  async getvideoParams() {
    const c = this
    if (c.video_params) return c.video_params
    try {
      const response = await get(`/assets/${c.assetIdValue}/proxy_video`,{ responseKind: 'json' })
      if (response.ok) {
        c.video_params = await response.json
        return c.video_params
      } else {
        throw "Video params response was invalid"
      }
    } catch(error) {
      console.error('Unable to get video params: ', error)
    }
  }

  get init_html() {
    return `<video id='vid' class='video-js vjs-default-skin vjs-big-play-centered embed-responsive-item vjs-static-controls' preload='none' width='100%' height='100%' poster='${this.video_params['poster']}' data-setup='{}' data-proxy-video-target="clppContainer"></video>`
  }

  get video_js_player() {
    return this.player?.videojs_?.player()
  }

  get control_bar() {
    return this.video_js_player?.controlBar
  }

  initHTML() {
    if (!this.hasClppContainerTarget) {
      $(this.element).html('').append(this.init_html)
    }
  }

  async insertVideo() {
    if (this.video_inserted) return
    const c = this
    c.initHTML()
    await c.initPlayer()
    await c.initPlayerCallbacks()
    await c.load()
    this.video_inserted = true
  }

  setupVisibilityObserver() {
    const c = this
    if (!this.visibility_observer) {
      this.visibility_observer = new ResizeObserver(entries => {
        entries.forEach(({ contentRect, target }) => {
          contentRect.width === 0 ? c.elementBecameHidden(target) : c.elementBecameVisible(target)
        })
      })
    }
    this.visibility_observer.observe(this.element)
  }

  elementBecameVisible(_element) {
    this.insertVideo()
    this.visible = true
    this.dispatch('visible')
  }

  elementBecameHidden(_element) {
    this.player?.pause()  // move to action on hidden
    this.visible = false
    this.dispatch('hidden')
  }

  removeVideo() {
    const c = this
    try {
      if (typeof c.player !== 'undefined' && c.player) {
        c.player.dispose()
      }
      videojs(c.clppContainerTarget).dispose()
    } catch (error) {
      console.log(`could not remove video ${error}`)
    }
    c.video_inserted = false
  }

  async initPlayer() {
    const c = this
    this.player = clpp.init(c.clppContainerTarget, {
      license: c.video_params['license'],
      autoplay: c.autoplayValue,
      muted: false,
      restrictions: {
        // minHeight: 400
      },
      abr: {
        defaultBandwidthEstimate: 6145800
      },
      drmtoday: {
        userId: c.video_params['optData']['userId'],
        sessionId: c.video_params['optData']['sessionId'],
        merchant: c.video_params['optData']['merchant'],
        environment: c.video_params['environment']
      },
      subtitles: {
        character: {
          color: clpp.statics.subtitles.colors.white,
          opacity: clpp.statics.subtitles.opacities.opacity100,
          size: clpp.statics.subtitles.sizes.size100,
          font: clpp.statics.subtitles.fonts.defaultFont,
          edge: clpp.statics.subtitles.edges.uniformEdges,
          edgeColor: clpp.statics.subtitles.colors.black
        },
        showConfig: true
      }
    })
  }

  async load() {
    const c = this
    window.player = c.player // legacy

    try {
      await new Promise(resolve => setTimeout(resolve, 500)) // wait 50ms
      await c.player.load([
        {
          type: clpp.statics.types.DASH,
          src: c.video_params['src'] + ".mpd",
          drmProtected: true
        },
        {
          type: clpp.statics.types.HLS,
          src: c.video_params['src'] + ".m3u8",
          drmProtected: true
        }
      ], 
        { 
        drmtoday: {
          assetId: c.video_params['uuid'],
          variantId: c.video_params['variantId'],
          authToken: null
        }
      })
      c.playerReady()
    } catch (error) {
      console.log('The player could not be initialized properly')
      console.error(error)
      console.log(c.video_params)
    }
  }

  playerReady() {
    const c = this
    c.loadSubtitles()
    c.adjustAudioTracks()
    c.loadThumbnails()
    c.loadVideoQC()
    c.loadWatermark()

    c.loadOverlayData()
    c.loadQCAnnotations()
    c.loadMarker()

    if (c.autoplayValue === true) {
      c.player.autoplay = true
    } else {
      $(c.element).find('video').removeAttr('autoplay')
      c.player.autoplay = false
    }

    if ($(".proxyvideo_asset_id[data-binge-marker='true']").length > 0) {
      c.loadBingeMarker()
    } else {
      c.loadQCMenu()
    }

    c.loadCropMenu()
    c.player.timecode_data = { framerate: c.video_params.framerate, timecode_format: c.video_params.tcFormat, offset: c.video_params.offset }
    c.updateControls()
  }

  loadBingeMarker() {
    playerTools.insert_binge_watching_controls(this.video_params)
  }

  loadQCMenu() {
    playerTools.insert_qc_menu()
  }

  loadThumbnails() {
    const probably_existing_thumbnails = playerTools.thumbnails(this.video_params)
    const first_thumbnail_url = probably_existing_thumbnails[0]['src']
    if (first_thumbnail_url != null) {
      const img = new Image()
      // window.img = img
      img.onerror = function() { console.log("Error loading thumbnails e.g:" + first_thumbnail_url) }
      img.onload = function() {  player.videojs_.thumbnails(probably_existing_thumbnails) }
      img.src = first_thumbnail_url
    } else {
      console.log('Error: no thumbnails')
    }
  
  }

  loadVideoQC() {
    const c = this
    this.player.videojs_.video_qc({ framerate: c.video_params['framerate'], tcFormat: c.video_params['tcFormat'], offset: c.video_params['offset'], qc_mode: c.qcModeValue })
  }

  loadWatermark() {
    const c = this
    if (c.video_params.watermarks != null) {
      for (const watermark of Array.from(c.video_params.watermarks)) {
        this.player.videojs_.watermark({
          file: ((watermark.file != null) ? `${watermark.file}` : ""),
          text: ((watermark.text != null) ? `${watermark.text}` : ""),
          xpos: watermark.xpos, ypos: watermark.ypos,
          opacity: watermark.opacity,
          url: watermark.url,
          clickable: watermark.clickable,
          moving: watermark.moving
        });
      }
    } else {
      this.player.videojs_.watermark({ file: "", xpos: 0, ypos: 0, opacity: 0.0 });
    }
    window.c = $('.vjs-watermark').prop('outerHTML').hashCode()
  }

  loadSubtitles() {
    if (this.video_params['subtitles'].length > 0) playerTools.load_subtitles(this.player, this.video_params['subtitles'])
  }

  loadOverlayData() {
    const c = this
    const overlay_data = { resolution: c.video_params['resolution'], aspect_ratio: c.video_params['aspect_ratio'],  confirmed_cropping_values: c.video_params['confirmed_cropping_values'], original_confirmed_cropping_values: Object.assign({}, c.video_params['confirmed_cropping_values']), measured_cropping_values: c.video_params['measured_cropping_values'], proxy_encoding_parameter_id: c.video_params['proxy_encoding_parameter_id'] }
    this.player.overlay_data = overlay_data
    this.player.on('fullscreenchange', c.resetCropOverlay.bind(c))
  }

  resetCropOverlay() {
    $('.vjs-overlay.crop-overlay').remove()
    $('.crop-overlay-settings').find('.crop.icon').addClass('alternate')
    $('.crop-overlay-settings').find('.vjs-selected').removeClass('vjs-selected')
    if (document.fullscreenElement != null) {
      $('.crop-overlay-settings.vjs-button').show()
    } else {
      $('.crop-overlay-settings.vjs-button').show()
    }
  }

  loadCropMenu() {
    playerTools.insert_crop_menu(this.player)
  }

  loadQCAnnotations() {
    assets.qc_annotations($(".proxyvideo_asset_id").attr("data-asset-id")).done(function(qc_annotations) {
      if (qc_annotations !== null) {
        console.log(qc_annotations)
      }
    })
  }

  loadMarker() {
    this.player.videojs_.markers()
  }

  adjustAudioTracks() {
    playerTools.adjust_audio_tracks(this.player, this.video_params['audio_track_info'])
  }

  initPlayerCallbacks() {
    const c = this
    c.player.on(clpp.statics.events.CLPP_PLAY_NOT_ALLOWED, function(_e) { // no event listener for this and it will raise an exception
      console.log("autoplay not allowed") 
    })
    c.player.on('clpp-selected-drm', function (ev) {
      console.log("Selected DRM")
      console.log(ev.detail)
    })
    c.player.on('clpp-selected-tech', function (ev) {
      console.log("Selected tech")
      console.log(ev.detail)
    })

    c.player.on('timeupdate', c.timeupdate.bind(c) )
  }

  timeupdate()  {
    const c = this
    const current_time = c.player.currentTime()
    c.dispatch('timeupdate', { detail: { value: current_time }})
  }

  jumpToTime(time, pause = false) {
    const c = this
    c.player.videojs_.currentTime(time)
    if (c.player.paused() && !pause) c.player.videojs_.play()
  }

  currentTimecode() {
    const c = this
    const framerate = c.player.timecode_data?.framerate
    const timecode_format = c.player.timecode_data?.timecode_format
    const offsetString = c.player.timecode_data?.offset
    const time = c.player.currentTime()
    const offset = Timecode.from_string(offsetString, framerate, timecode_format)
    let tc = Timecode.from_runtime_seconds(time, framerate, timecode_format)
    if (typeof(offset.toString()) === 'string') { tc = tc.add(offset) }
    tc = tc.toString()
    return tc
  }

  CONTROL_SELECTOR_MAPPING = {
    play: '.vjs-play-control',
    progress: '.vjs-progress-control',
    fullscreen: '.vjs-fullscreen-control',
    subtitle: '.vjs-subtitles-button',
    audio: '.vjs-audio-button',
    airplay: '.vjs-airplay-button',
    chromecast: '.vjs-chromecast-button',
    captions: '.vjs-captions-button',
    cropping: '.crop-overlay-settings',
    settings: '.vjs-settings-button',
    qc: '.qc_setting',
    lock: '.vjs-toggle-control-bar-button',
    timecode: '.vjs-time-control.vjs-timecode',
    volume: '.vjs-volume-menu-button',
    remaining_time: '.vjs-time-control.vjs-remaining-time'
  }


  updateControls() {
    this.showControls('all', true)
    if (this.hidden_controls.length > 0) this.showControls(this.hidden_controls, false)
  }

  showControls(controls, show) {
    const c = this
    let selectors
    if (controls === 'all' || controls == '') {
      selectors = Object.values(this.CONTROL_SELECTOR_MAPPING)
    } else {
      selectors = [controls].flat().map((e, _i) => c.CONTROL_SELECTOR_MAPPING[e]).filter(x => x)
    }

    selectors.forEach((selector, _i) => {
      const element = $(this.element).find(selector)
      const class_name = 'proxy-video-hidden-control'
      
      show ? element.removeClass(class_name) : element.addClass(class_name)
    })
  }

}
