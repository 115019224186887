import { Controller } from "@hotwired/stimulus"

let id = 0

export default class extends Controller {

  static values = {
    dynamicParameters: Object,
  }

  initialize() {
    this.id = id++
  }

  connect() {
    if (this.hasDynamicParametersValue && (this.element.href || this.element.src)) {
      for (const [parameter, settings] of Object.entries(this.dynamicParametersValue)) {
        let parameter_element = $(settings.id)
        let controller = this
        parameter_element.off(`change.dl${this.id}`)
        parameter_element.on(`change.dl${this.id}`, function(_e) {
          controller.update_link_param(parameter, parameter_element.val())
        })
        controller.update_link_param(parameter, parameter_element.val())
      }
    }
  }

  update_link_param(parameter_name, value) {
    if (this.element.href) {
      var href = new URL(this.element.href)
      href.searchParams.set(parameter_name, value)
      this.element.href = href.toString()
    } else if (this.element.src) {
      var src = new URL(this.element.src)
      src.searchParams.set(parameter_name, value)
      this.element.src = src.toString()
    }
  }
}
