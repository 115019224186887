import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    let default_options = {
      popup : $(this.element),
      movePopup: false,
    }
    let options =  $(this.element).data('popup-options')
    let merged_options = { ...default_options, ...options }

    let selector =  $(this.element).data('selector')
    let parent_selector = $(this.element).data('parent-selector')
    
    if (selector) {
      $(selector).popup(merged_options)
    } else if (parent_selector) {
      $(this.element).parents(parent_selector).popup(merged_options)
    } else {
      $(this.element).popup()
    }
  }
}
