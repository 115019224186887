import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    if (this.element?.parentNode?.nodeName == 'TURBO-FRAME') {
      assets.sync_group_modal($(this.element))
    }
  }
  
}