document.addEventListener("turbo:load", function() {
  if ($("#data_mover_storage_locations").length > 0) {
    data_mover_storage_locations.init();
  }
});

var data_mover_storage_locations = {
  init() {
    if ($("#data_mover_storage_locations").length > 0) {
      search.init("/data_mover_storage_locations");
      $("#data_mover_storage_locations .dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
    }
  },
};

window.data_mover_storage_locations = data_mover_storage_locations;
