document.addEventListener("turbo:load", function() {

  general.common.quicklinks = function() {
    quicklinks.index_menu()  
    if ($("#quicklinks").length > 0) {
      quicklinks.init()
    }
  }
  general.common.quicklinks()

  return quicklinks.quicklink_button();
});

var quicklinks = {
  init() {
    $('.ui.form .dropdown').dropdown({fullTextSearch: true});
    $('.ui.form .dropdown').not('.remote').dropdown({ placeholder: false, fullTextSearch: true });
    $('.ui.pointing.secondary.menu .item').tab();

    search.init("/quicklinks");
  },

  quicklink_button() {
    return $('.quicklink-button').on('click', function() {
      quicklinks.load_modal()
    });
  },

  load_modal(asset_ids = []) {
    $.ajax({
      url: "/quicklinks/modal",
      type: "GET",
      success(data, _textStatus, _xhr) {
        const quicklink_modal = $(data).appendTo("body")
        quicklink_modal.modal({
          observeChanges: true,
          autofocus: false,
          onShow() {
            quicklinks.init_quicklink(quicklink_modal).then(function(_t) {
              $.each([asset_ids].flat(), function( _index, value ) {
                quicklinks.add_asset_item(value)
              })
              quicklink_modal.find('#send-quicklink.button').on('click', function() {
                quicklinks.send_quicklink()
              })
            })
          },
          onHidden() {
            $(quicklink_modal).remove()
          },
        }).modal("show");
      },
      error(_jqXHR, _textStatus, errorThrown) {
        console.log(errorThrown);
      }
    });
  },

  init_quicklink(target) {

    return new Promise(function (resolve, _reject) {
      calendar.datepicker(".expire-date.field", { } )
      target.find(".dropdown.contact.watermark").dropdown()
      target.find(".dropdown.user.contact").dropdown({
        apiSettings: {
          url: '/users/' + $("#current_user_id").val() + '/contacts?q={/query}&dropdown=true',
        },
        onChange: function() {
          quicklinks.mail_options();
        },
        onRemove: function() {
          quicklinks.mail_options();
        },
      })
      
      target.off("fields_added.nested_form_fields.nested_transfer_quicklink_assets")
      target.on("fields_added.nested_form_fields.nested_transfer_quicklink_assets", function(event, param) {
        switch (param.object_class) {
          case "quicklink_asset":
            assets.set_asset_data(event.target, param.additional_data).then(function() {
              if (!$(event.target).find('[data-quicklink-compatible]').data('quicklink-compatible') ) {
                $(event.target).find('.quicklink_asset.segment').addClass('disabled')
                $("[id^='warning_message']").text("Some of the selected assets cannot be send via Quicklink").show()
              }
            })
            break
          default:
            console.log("Fields were successfully added, callback not handled.")
        }
      })
      search.asset_autocompletion(".ui.search.quicklink-assets", { quicklink: true })
  
      target.find(".ui.search.quicklink.asset").search({
        minCharacters: 3,
        apiSettings: {
          url: '/assets/quicklink?q={/query}'
        },
        onSelect(result, _response) {
          quicklinks.add_asset_item(result.a_id);
          $(".ui.search.quicklink.asset").search("set value", "")
          $(".ui.search.quicklink.asset").search("hide results")
          return false
        }
      })
      resolve(target)
    })
    
  },

  send_quicklink() {
    const quicklink_modal = $('.ui.quicklink.large.modal')
    //$('#quicklink_progress_indicator').addClass('active')
    $("#send-quicklink").addClass("loading disabled")
    $("[id^='error_message']").text("").hide()

    const asset_ids = quicklink_modal.find("#quicklink-asset-list fieldset[style!='display: none;'] .quicklink_asset.segment").not('.disabled').find('.asset.list.item').toArray().map(x => $(x).data('asset-id'))
    const contact_ids = quicklink_modal.find('.dropdown.user.contact').dropdown('get values')
    $.ajax({
      url: "/transfers/create_quicklink",
      type: "post",
      dataType: "json",
      data: {
        subject: quicklink_modal.find("[id^='subject']").val(),
        notes: quicklink_modal.find("[id^='notes']").val(),
        sendercopy: quicklink_modal.find("[id^='quicklink_sendercopy']").prop("checked"),
        notification: quicklink_modal.find("[id^='quicklink_notification']").prop("checked"),
        expiredate: quicklink_modal.find("[id^='inputExpireDate']").val(),
        expireclicks: quicklink_modal.find("[id^='inputExpireClicks']").val(),
        viewcomments: quicklink_modal.find("[id^='quicklink_viewcomments']").prop("checked"),
        addcomments: quicklink_modal.find("[id^='quicklink_addcomments']").prop("checked"),
        subtitles: quicklink_modal.find("[id^='quicklink_subtitles']").prop("checked"),
        download: quicklink_modal.find("[id^='quicklink_download']").prop("checked"),
        streaming: quicklink_modal.find("[id^='quicklink_streaming']").prop("checked"),
        drm: quicklink_modal.find("[id^='quicklink_drm']").prop("checked"),
        // resolution: quicklink_modal.find('input[name="quicklinkResolution"]:checked').val(),
        asset_ids: asset_ids,
        contact_ids: contact_ids,
        //client: quicklink_modal.find("[id^='client']").parents('.client.dropdown').dropdown('get value'),
        watermark: quicklink_modal.find("[id^='watermark']").parents('.contact.watermark.dropdown').dropdown('get value'),
        watermark_text: quicklink_modal.find("[id^='quicklink_wartermark_text']").prop("checked")
      },
    }).done(function(data, _textStatus, _xhr) {
      let transfer_id = data.tr_ID
      $.ajax({
        url: `/transfers/${data.tr_ID}/build_quicklink`,
        type: "post",
        dataType: "json",
      }).done(function(data, _textStatus, _xhr) {
        if (data.token != null) {
          quicklink_modal.modal('hide')
          transfers.quicklook(transfer_id)
        } else {
          //$('#quicklink_progress_indicator').removeClass('active')
          $("#send-quicklink").removeClass("loading disabled")
          $("[id^='error_message']").text("Could not Create Quicklink").show()
        }
      }).fail(function(_jqXHR, _textStatus, errorThrown) {
        console.log(errorThrown)
        //$('#quicklink_progress_indicator').removeClass('active')
        $("#send-quicklink").removeClass("loading disabled")
        $("[id^='error_message']").text("Could not Create Quicklink").show()
      })
    }).fail(function(_jqXHR, _textStatus, errorThrown) {
      console.log(errorThrown)
      //$('#quicklink_progress_indicator').removeClass('active')
      $("#send-quicklink").removeClass("loading disabled")
      $("[id^='error_message']").text("Could not Create Quicklink").show()
    })
  },

  mail_options() {
    if ($(".dropdown.user.contact").dropdown("get value").length > 0) {
      $(".mail_options").show()
    } else {
      $(".mail_options").hide()
    }
  },
  
  index_menu() {
    $(".add.all.to.quicklink").off('click')
    $(".add.all.to.quicklink").on('click', function() {
      quicklinks.load_modal(index_select.selected_ids())
    })
  },

  context_menu() {
    $(".item.asset.quicklink").on('click', function() {
      quicklinks.load_modal([$(this).attr("data-target-id")])
    })
  },

  add_asset_item(id) {
      $('.quicklink-assets .add_nested_fields_link').trigger('click', [{ asset: { a_ID: id } }])
  },
}

window.quicklinks = quicklinks;
