import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'modal'
  ]
  static values = {
    target: String,
    initializer: String,
  }

  connect() {
    if(this.hasModalTarget && this.modalTarget?.previousElementSibling?.tagName == 'BODY') {
      if (this.hasTargetValue && this.hasInitializerValue) {
        window[this.targetValue]?.[this.initializerValue]?.(this.modalTarget)
      } else {
        $(this.modalTarget).modal()
      }
    }
  }
}
