import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    if (document.querySelector('#qc_requests.edit')) {
      $('#qc_requests .dropdown').dropdown({ placeholder: false, fullTextSearch: true })
      qc_requests.init()
      qc_requests.load_remote_cards()
      qc_requests.init_edit_qc_element_modal()
    }
  }
}
