var telekom_audio = {
  init(_target, _params_id) {},

  set_audio_params(target, params_id) {
    const selected_option = target.find(':selected')
    return (() => {
      const result = []
      for (let prefix of ['primary_audio_-_51_-', 'primary_audio_-_20_-', 'secondary_audio_-_51_-', 'secondary_audio_-_20_-', 'tertiary_audio_-_51_-', 'tertiary_audio_-_20_-', 'quaternary_audio_-_51_-', 'quaternary_audio_-_20_-', 'quinary_audio_-_51_-', 'quinary_audio_-_20_-']) {
        if (target.hasClass(prefix)) {

          workflow.display_potential_issues(selected_option, target)

          $(`#${prefix}_channel_-_l_${params_id}`).val("")
          $(`#${prefix}_channel_-_r_${params_id}`).val("")
          $(`#${prefix}_channel_-_c_${params_id}`).val("")
          $(`#${prefix}_channel_-_lfe_${params_id}`).val("")
          $(`#${prefix}_channel_-_ls_${params_id}`).val("")
          $(`#${prefix}_channel_-_rs_${params_id}`).val("")
          $(`#${prefix}_channel_-_lss_${params_id}`).val("")
          $(`#${prefix}_channel_-_rss_${params_id}`).val("")

          if (selected_option.val() === "" || selected_option.val() === undefined) {
            $(`#${prefix}_track_id_${params_id}`).val("")
            $(`#${prefix}_present_${params_id}`).val(false)
            $(`#${prefix}_path_${params_id}`).val("")
            $(`#${prefix}_framerate_${params_id}`).val("")
            $(`#${prefix}_loudness_${params_id}`).val("")
            $(`#${prefix}_channels_mapping_${params_id}`).val("")
            $(`#${prefix}_usage_${params_id}`).val("")
            $(`#${prefix}_loudness_measurement_-_present_${params_id}`).val(false)
            $(`#${prefix}_loudness_measurement_-_loudness_rage_${params_id}`).val("")
            $(`#${prefix}_loudness_measurement_-_relative_gated_loudness_${params_id}`).val("")
            $(`#${prefix}_loudness_measurement_-_true_peak_${params_id}`).val("")
            $(`#${prefix}_loudness_measurement_-_dial_norm_${params_id}`).val("")
            $(`#${prefix}_loudness_measurement_-_max_momentary_loudness_${params_id}`).val("")
            $(`#${prefix}_loudness_measurement_-_max_short_term_loudness_${params_id}`).val("")
          } else {
            const layout = selected_option.data('layout')
            const loudness_measurement = selected_option.data('loudnessMeasurement')
            if (layout !== undefined) {
              const layout_string = Object.keys(layout).join(', ')
              $(`#${prefix}_channels_mapping_${params_id}`).val(layout_string)
              $(`#${prefix}_usage_${params_id}`).val(selected_option.data('usage'))
              $(`#${prefix}_track_id_${params_id}`).val(selected_option.data('track-id'))
              $(`#${prefix}_present_${params_id}`).val(true)
              $(`#${prefix}_framerate_${params_id}`).val(selected_option.data('framerate'))
              $(`#${prefix}_loudness_${params_id}`).val(selected_option.data('loudness'))
              $(`#${prefix.replace(/_-_51/g, '').replace(/_-_20/g, '')}_language_manual_select_${params_id}`).dropdown("set selected", `${selected_option.data('iso639_2')} - ${selected_option.data('language')}`)
              if (prefix.includes('-_51_-')) {
                $(`#${prefix.replace('51', '20')}_language_select_${params_id}`).dropdown().removeClass('disabled')
                if ('5.1 (11) 1' in layout) {
                  $(`#${prefix}_channel_-_l_${params_id}`).val(layout['5.1 (11) 1'])
                  $(`#${prefix}_channel_-_r_${params_id}`).val(layout['5.1 (11) 2'])
                } else if ('Atmos' in layout) {
                  $(`#${prefix}_channel_-_l_${params_id}`).val(layout['Atmos'])
                  $(`#${prefix}_channel_-_r_${params_id}`).val(layout['Atmos'])
                } 
                else {
                  $(`#${prefix}_channel_-_l_${params_id}`).val(layout['Left Front'])
                  $(`#${prefix}_channel_-_r_${params_id}`).val(layout['Right Front'])
                }
              } else {
                if (layout !== undefined) {
                  if (selected_option.data('channel_count') === 1) {
                    if (('Atmos' in layout)) {
                      $(`#${prefix}_channel_-_l_${params_id}`).val(layout['Atmos'])
                      $(`#${prefix}_channel_-_r_${params_id}`).val(layout['Atmos'])
                    } else {
                      $(`#${prefix}_channel_-_l_${params_id}`).val(layout['Mono_1'])
                      $(`#${prefix}_channel_-_r_${params_id}`).val(layout['Mono_1'])
                    }
                  }
                  else if (selected_option.data('channel_count') === 2) {
                    if ('Mono_1' in layout) {
                      $(`#${prefix}_channel_-_l_${params_id}`).val(layout['Mono_1'])
                      $(`#${prefix}_channel_-_r_${params_id}`).val(layout['Mono_2'])
                    } else if ('5.1 (11) 1' in layout) {
                      $(`#${prefix}_channel_-_l_${params_id}`).val(layout['5.1 (11) 1'])
                      $(`#${prefix}_channel_-_r_${params_id}`).val(layout['5.1 (11) 2'])
                    } else {
                      $(`#${prefix}_channel_-_l_${params_id}`).val('LT' in layout ? layout['LT'] : layout['Stereo L'])
                      $(`#${prefix}_channel_-_r_${params_id}`).val('RT' in layout ? layout['RT'] : layout['Stereo R'])
                    }
                  } 
                  else if (selected_option.data('channel_count') === 8) {
                    $(`#${prefix}_channel_-_l_${params_id}`).val(`${layout['Left Front']}(0.707),${layout['Center']}(0.5),${layout['Left Side Surround']}(0.354),${layout['Left Surround Rear']}(0.354)`)
                    $(`#${prefix}_channel_-_r_${params_id}`).val(`${layout['Right Front']}(0.707),${layout['Center']}(0.5),${layout['Right Side Surround']}(0.354),${layout['Right Surround Rear']}(0.354)`)
                  } else {
                    $(`#${prefix}_channel_-_l_${params_id}`).val(`${layout['Left Front']}(0.707),${layout['Center']}(0.5),${layout['Left Surround']}(0.354)`)
                    $(`#${prefix}_channel_-_r_${params_id}`).val(`${layout['Right Front']}(0.707),${layout['Center']}(0.5),${layout['Right Surround']}(0.354)`)
                  }
                }
              }
              if (layout !== undefined) {
                $(`#${prefix}_channel_-_c_${params_id}`).val(layout['Center'])
                if (selected_option.data('channel_count') === 5 && target.hasClass('allow_50_mix')) {
                  $(`#${prefix}_channel_-_lfe_${params_id}`).val(`${layout['Left Front']}(0)`)
                } else {
                  $(`#${prefix}_channel_-_lfe_${params_id}`).val(layout['LFE'])
                }

                if (selected_option.data('channel_count') === 8) {
                  $(`#${prefix}_channel_-_ls_${params_id}`).val(layout['Left Surround Rear'])
                  $(`#${prefix}_channel_-_rs_${params_id}`).val(layout['Right Surround Rear'])
                  $(`#${prefix}_channel_-_lss_${params_id}`).val(layout['Left Side Surround'])
                  $(`#${prefix}_channel_-_rss_${params_id}`).val(layout['Right Side Surround'])
                  if (prefix.includes('-_51_-')) {
                    $(`#${prefix.replace('51', '20')}_language_select_${params_id}`).dropdown('clear')
                    $(`#${prefix.replace('51', '20')}_language_select_${params_id}`).dropdown().addClass('disabled')
                  }
                } else {
                  $(`#${prefix}_channel_-_ls_${params_id}`).val(layout['Left Surround'])
                  $(`#${prefix}_channel_-_rs_${params_id}`).val(layout['Right Surround'])
                }
              }
              if (selected_option.data('storage-location') !== undefined) {
                $(`#${prefix}_path_${params_id}`).val(selected_option.data('storage-location'))
              }
            }
            if (loudness_measurement !== undefined) {
              $(`#${prefix}_loudness_measurement_-_present_${params_id}`).val(loudness_measurement['loudness_measurement_present'])
              $(`#${prefix}_loudness_measurement_-_loudness_rage_${params_id}`).val(loudness_measurement['loudness_range'])
              $(`#${prefix}_loudness_measurement_-_relative_gated_loudness_${params_id}`).val(loudness_measurement['relative_gated_loudness'])
              $(`#${prefix}_loudness_measurement_-_true_peak_${params_id}`).val(loudness_measurement['true_peak'])
              let dialnorm = parseInt(loudness_measurement['dialnorm'])
              dialnorm = (dialnorm > 31) ? 31 : dialnorm
              $(`#${prefix}_loudness_measurement_-_dial_norm_${params_id}`).val(dialnorm)
              $(`#${prefix}_loudness_measurement_-_max_momentary_loudness_${params_id}`).val(loudness_measurement['max_momentary_loudness'])
              $(`#${prefix}_loudness_measurement_-_max_short_term_loudness_${params_id}`).val(loudness_measurement['max_short_term_loudness'])
            }
          }
          break
        } else {
          result.push(undefined)
        }
      }
      return result
    })()
  },

  set_dropdown(dropdown, value) {
    dropdown.empty()
    if (typeof value === "string") {
      dropdown.append($('<option></option>').val(value).html(value))
    } else {
      for (let option of Array.from(value)) {
        dropdown.append($('<option></option>').val(option).html(option))
      }
    }
    dropdown.dropdown()
    return setTimeout((function() {
      dropdown.dropdown('set selected', dropdown.find('option:first').val())
    }), 1)
  }
}
    

window.telekom_audio = telekom_audio
