import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
  }

  applyCurrencyFormat({ target, params: { locale, currency } }) {
    let value = target.value.replaceAll(',', '.')
    locale ||= 'de-DE'
    currency ||= 'EUR'
    // currencyDisplay: none/
    let formatted_value = new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(value.gsub(',', '.'))
    if (formatted_value !== 'NaN') target.value = formatted_value
    this.dispatch('formatted:currency')
  }

  applyNumberFormat({ target, params: { locale, minimumFractionDigits } }) {
    let value = target.value.replaceAll(',', '.')
    locale ||= 'de-DE'
    let formatted_value = new Intl.NumberFormat('de-DE', { minimumFractionDigits }).format(value)
    if (formatted_value !== 'NaN') target.value = formatted_value
    this.dispatch('formatted:number')
  }
}
