document.addEventListener("turbo:load", function() {
  if ($("#metadata_roles").length > 0) {
    search.init("/metadata_roles");
    return init_dropdowns();
  }
});


var init_dropdowns = function() {
  $(".search-field.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
  return $("#metadata_roles .default-options.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
};
