import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'modal',
    'distributionChannelTypes',
    'artworkSelect',
    'createMultipleArtworks',
    'submitButton',
    'useXml',
  ]

  connect() {
    let c = this

    this.modalTarget.addEventListener('turbo:before-fetch-request', c.disable_submit_button)
    this.modalTarget.addEventListener('turbo:frame-load', c.enable_submit_button)

    this.modalTarget.querySelectorAll('turbo-frame[loading=lazy]').forEach(x => x.loading = 'eager')

    $(this.element).find('.checkbox.many-artworks').checkbox()
    $(this.element).find('.checkbox.use_xml').checkbox({
      onChange() {
        c.refresh_artwork_preview()
      }
    })
  }

  disconnect() {
    this.modalTarget.removeEventListener('turbo:before-fetch-request', this.disable_submit_button)
    this.modalTarget.removeEventListener('turbo:turbo:frame-load', this.enable_submit_button)
  }

  select_all_artworks() {
    $(this.modalTarget).find('.select.artwork.checkbox').checkbox("check")
  }

  deselect_all_artworks() {
    $(this.modalTarget).find('.select.artwork.checkbox').checkbox("uncheck")
  }

  select_mandatory_artworks() {
    this.modalTarget.querySelectorAll('.select.artwork.checkbox').forEach(x => {
      x.parentElement?.querySelector('.mandatory') ? $(x).checkbox('check') : $(x).checkbox('uncheck')
    })
  }

  disable_submit_button = () => {
    this.submitButtonTarget.classList.add('disabled')
  }

  enable_submit_button = () => {    
    if (!this.hasSubmitButtonTarget) return
    if (this.artworkSelectTargets.every(x => x.value != '' ) && this.checkFrames()) {
      this.submitButtonTarget.classList.remove('disabled')
    } else {
      this.submitButtonTarget.classList.add('disabled')
    }
  }

  checkFrames = () => {
    const frames = this.modalTarget.querySelectorAll('turbo-frame')
    return Array.from(frames).every(frame => frame.hasAttribute('complete'))
  }

  refresh_artwork_preview() {
    if (!this.hasDistributionChannelTypesTarget) return
    let dct_ids = $(this.distributionChannelTypesTarget).dropdown('get values')
    if (dct_ids.length > 0) {
      this.modalTarget.querySelectorAll('[id^="mapping_info_"],[id^="artwork_cards_"]').forEach( turbo_frame => {
        var src = new URL(turbo_frame.src)
        src.searchParams.set('dct_ids', dct_ids)
        if (this.hasUseXmlTarget) {
          src.searchParams.set('use_xml', this.useXmlTarget.checked)
        }
        turbo_frame.innerHTML = '<div class="ui active blue elastic loader"></div>'
        turbo_frame.src = src.toString()
      })
    }
  }

  add_all_packages() {
    let dropdown = this.modalTarget.querySelector('#docp_ids')
    let optionValues = [...dropdown.options].map(o => o.value)
    $(dropdown).dropdown("set selected", optionValues)
  }

  display_submit_toasts() {
    if (!this.hasDistributionChannelTypesTarget) return
    this.distributionChannelTypesTarget.querySelectorAll('option:checked').forEach(x => {
      $.toast({
        title: 'Creating Artworks for',
        message: `${x.text}`,
        showIcon: 'images outline',
        class: "initalizer",
        displayTime: 5000,
      })
    })
  }

  close(event) {
    if (event.detail.success && !(this.hasCreateMultipleArtworksTarget && this.createMultipleArtworksTarget.checked)) {
      $(this.modalTarget).modal('hide')
    }
  }
}
