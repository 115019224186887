const workflow_1503 = {
  init(_target, _params_id) {},
    //target.find('.ui.accordion').accordion()

  set_audio_params(target, params_id) {
    const selected_option = target.find(':selected');
    const index = $(target).find('select').data('index');
    const layout = selected_option.data('layout');
    const location = selected_option.data('storage-location');
    const language = selected_option.data('language');
    const iso639_1 = selected_option.data('iso639_1');
    const iso639_2 = selected_option.data('iso639_2');
    if (selected_option.val() === "") {
      $(`#audio_${index}_track_id_${params_id}`).val("")
      $(`#audio_${index}_present_${params_id}`).val(false);
      $(`#audio_${index}_channel_l_path_${params_id}`).val(null);
      $(`#audio_${index}_channel_l_mapping_${params_id}`).val(null);
      $(`#audio_${index}_channel_r_path_${params_id}`).val(null);
      $(`#audio_${index}_channel_r_mapping_${params_id}`).val(null);
      $(`#audio_${index}_language_${params_id}`).val(null);
      $(`#audio_${index}_language_iso_639_1_${params_id}`).val(null);
      $(`#audio_${index}_language_iso_639_2_${params_id}`).val(null);
    } else {
      $(`#audio_${index}_track_id_${params_id}`).val(selected_option.data('track-id'))
      $(`#audio_${index}_language_${params_id}`).val(language);
      $(`#audio_${index}_present_${params_id}`).val(true);
      $(`#audio_${index}_language_iso_639_1_${params_id}`).val(iso639_1);
      $(`#audio_${index}_language_iso_639_2_${params_id}`).val(iso639_2);
      if ((selected_option.data('channel-count') === 1) || (selected_option.data('channel-count') === 2)) {
        $(`#audio_${index}_channel_l_path_${params_id}`).val(`${location}` + selected_option.data('filename').L);
        $(`#audio_${index}_channel_l_mapping_${params_id}`).val(layout['Stereo L'] || layout['LT'] || layout['Mono_1']);
        $(`#audio_${index}_channel_r_path_${params_id}`).val(`${location}` + selected_option.data('filename').R);
        $(`#audio_${index}_channel_r_mapping_${params_id}`).val(layout['Stereo R'] || layout['RT'] || layout['Mono_2']);
      } else if (selected_option.data('channel-count') === 6) {
        $(`#audio_${index}_channel_l_path_${params_id}`).val(`${location}` + selected_option.data('filename').L);
        $(`#audio_${index}_channel_l_mapping_${params_id}`).val(`${layout['Left Front']}(0.707),${layout['Center']}(0.5),${layout['Left Surround']}(0.354)`);
        $(`#audio_${index}_channel_r_path_${params_id}`).val(`${location}` + selected_option.data('filename').R);
        $(`#audio_${index}_channel_r_mapping_${params_id}`).val(`${layout['Right Front']}(0.707),${layout['Center']}(0.5),${layout['Right Surround']}(0.354)`);
      } else if (selected_option.data('channel-count') === 8) {
        $(`#audio_${index}_channel_l_path_${params_id}`).val(`${location}` + selected_option.data('filename').L);
        $(`#audio_${index}_channel_l_mapping_${params_id}`).val(`${layout['Left Front']}(0.707),${layout['Center']}(0.5),${layout['Left Side Surround']}(0.354),${layout['Left Surround Rear']}(0.354)`);
        $(`#audio_${index}_channel_r_path_${params_id}`).val(`${location}` + selected_option.data('filename').R);
        $(`#audio_${index}_channel_r_mapping_${params_id}`).val(`${layout['Right Front']}(0.707),${layout['Center']}(0.5),${layout['Right Side Surround']}(0.354),${layout['Right Surround Rear']}(0.354)`);
      }
    }
  }
};

window.workflow_1503 = workflow_1503;
