import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['input']

  fill({ params: { timecode }}) {
    this.inputTargets.forEach(input => {
      $(input).val(timecode)
    })
  }

}
