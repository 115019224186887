
var context_menu = {
  init() {
    context_menu.init_menu()
    context_menu.init_copy_id_button()
  },

  init_copy_id_button() {
    $('.copy.id.item').off('click')
    $('.copy.id.item').on('click', function() {
      const copy_value = $(this).data('modelValue')
      context_menu.copy_text(copy_value)
    });
  },

  copy_text(copy_value) {
    general.copy_menu()
    $('#copytextarea').text(copy_value);
    $('#copytextarea').show();
    $('#copytextarea').select();
    const icon = $(this).find('i');
    try {
      const successful = document.execCommand('copy');
      if (successful) {
        icon.addClass('green').delay(1000).queue(() => icon.removeClass('green').dequeue());
      } else {
        icon.addClass('red').delay(1000).removeClass('red').queue(() => icon.removeClass('red').dequeue());
      }
    } catch (err) {
      icon.addClass('red').delay(1000).removeClass('red').queue(() => icon.removeClass('red').dequeue());
    }
    $('#copytextarea').hide();
  },

  init_export_menu() {
    $('.action.bar .export.csv.item, .action.bar .export.xlsx.item').on("click", function() {
      let format = $(this).data("format")
      if (search.search_count() > 2000 ) {
        context_menu.confirm_export(format)
      } else {
        context_menu.open_export_path(format)
      }
    })
  },

  open_export_path(format) {
    window.open(window.location.origin + window.location.pathname + '.' + format + window.location.search)
  },

  confirm_export(format) {
    if ($(".ui.dimmer").length > 0) {
      $(".ui.dimmer").remove();
      $("body").removeClass("dimmable dimmed")
    }
    
    $.ajax({
      url: '/general/confirm_modal',
    }).done(function(result) {
      $("body").append(result)

      const modal = $('.ui.confirm.modal')
      $(modal).find('.header').text(`Do you really want start this export?`)
      $(modal).find('.content p').text(`Too many elements in scope may cause high system load or a timeout.`)
      $(modal).find('.ok.button').text(`Export`)
      
      modal.modal({
        onHidden() {
          modal.remove()
        },
        onApprove() {
          context_menu.open_export_path(format)
        }
      }).modal('show');

    }).fail(function(error) {
      
    })
  },
  
  init_menu() {
    $('.context.menu').off("click");
    $('.context.menu').on('click', function(event) {
      if ($(event.target).parents('.context.menu .vertical.menu').length > 0) {
        return
      }
      context_menu.menu_trigger($(this))
    })
  },

  menu_trigger(menu) {
    $(menu).find('.vertical.menu').html("<div class='item' style='width: 200px; height: 80px;'><span class='ui active small loader'></span></div>")
    $(menu).find('.ui.actions.dropdown').dropdown({
      action: 'hide',
      onHide() {
        setTimeout(function() {
          $(menu).find('.vertical.menu').html("")
        }, 400)
      }
    }).dropdown('show')

    let collection_name = $(menu).data('collection-name')

    $.ajax({
      url: "/context_menu_entry",
      data: {
        model_id: $(menu).data('model-id'),
        model_name: $(menu).data('model-name'),
        title: $(menu).data('title'),
        menu_type: $(menu).data('menu-type')
      }
    }).done(function(result) {
      $(menu).find(".loader").remove()

      $(menu).find('.vertical.menu').html(result)
      context_menu.init_copy_id_button()
      context_menu.init_export_menu()
      
      dfl_statuses.init_status_modal()
      bookmarks.init_action_menu()
      bookmarks.check_state(menu)
      asset_tags.context_menu()

      context_menu.call_model_context_menu(collection_name)

      search_settings.context_menu()
      datamovers.context_menu()

    }).fail(function(error) {
      $(menu).find('.vertical.menu').html("")
      console.log("error")
      console.log(error)
    })
  },

  call_model_context_menu(collection_name) {
    if (window[collection_name] !== undefined) {
      if ((typeof window[collection_name].context_menu) === "function") {
        window[collection_name].context_menu()
      }
    }
  }
};

window.context_menu = context_menu;
