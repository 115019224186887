import * as Credential from "./credential";

document.addEventListener("turbo:load", function() {
  if($("#user_session").length == 0) {
    general.sidebar()
    general.common.general()
  } else {
    general.fields_with_error()
  }
});

var sidebar_peaking_timeout

var delay = (function() {
  var timer
  timer = 0
  return function(callback, ms) {
    clearTimeout(timer)
    timer = setTimeout(callback, ms)
  }
})()

var general = {
  common: {
    general: function() { 
      window.color_array = ["olive", "teal", "purple", "grey", "yellow", "brown", "blue", "violet", "orange", "black"]

      $('.main.menu .with-tooltip').popup()
      //general.sidebar()
      search.init_global_search()
      general.init_body()
      general.init_extensions()
    }
  },
  
  init_common() {
    Object.values( general.common ).filter( s => typeof s === 'function' ).forEach( s => s() )
  },

  init_body() {

    $('.item[data-tab]').not(".custom").tab()
    $('.ui.checkbox').checkbox()
    
    dropdown.init()

    general.fields_with_error()
  
    general.message_closable()
    popup.init()
    context_menu.init()
    
    general.form_submit()
    asset_drop.init()

    $('.item.show.sidebar').off('click.general')
    $('.item.show.sidebar').on('click.general', function() {
      if ($(".sidebar.menu").hasClass('open')) {
        users.settings("sidebar.navigation.opened", false);
        $(".sidebar.menu").removeClass('open').addClass('closed');
        $(".main.content").removeClass('with-sidebar').addClass('without-sidebar');
        $(".action.bar").removeClass('with-sidebar').addClass('without-sidebar');
      } else {
        users.settings("sidebar.navigation.opened", true);
        $(".sidebar.menu").removeClass('closed').addClass('open');
        $(".main.content").removeClass('without-sidebar').addClass('with-sidebar');
        $(".action.bar").removeClass('without-sidebar').addClass('with-sidebar');
      }
    });

    $('.action.bar .show.contextbar, .context.bar .show.contextbar').off('click.general')
    $('.action.bar .show.contextbar, .context.bar .show.contextbar').on('click.general', function() {
      if ($(".context.bar").hasClass('open')) {
        users.settings("contextbar.opened", false);
        $(".context.bar").removeClass('open').addClass('closed');
        $(".main.content").removeClass('with-contextbar').addClass('without-contextbar');
        $(".action.bar").removeClass('with-contextbar').addClass('without-contextbar');
      } else {
        users.settings("contextbar.opened", true);
        $(".context.bar").removeClass('closed').addClass('open');
        $(".main.content").removeClass('without-contextbar').addClass('with-contextbar');
        $(".action.bar").removeClass('without-contextbar').addClass('with-contextbar');
        setTimeout(function() {
          $(".context.bar").append("<span class='view-fix'></span>")
        }, 180)
        setTimeout(function() {
          $(".context.bar").find(".view-fix").remove()
        }, 200)
      }
    });

    $('.popup').popup({
      hoverable: true,
      inline: true
    });

    $('.menu .browse').popup({
      inline: true,
      hoverable: true,
      position: 'bottom left',
      delay: {
        show: 0,
        hide: 800
      }
    });

    general.dark_mode_button()
    //general.dark_mode()

    $('.chameleon').off('change.general')
    $('.chameleon').on('change.general', function() {
      this.style.color = this.value;
    })
  },

  dark_mode_button() {
    $(':checkbox#dark-toggle').off('change.general')
    $(':checkbox#dark-toggle').on('change.general', function () {
      if ($('#dark-toggle').prop('checked')) {
        general.dark_mode()
      } else {
        general.dark_mode(true)
      }
    })
  },

  dark_mode(state = false) {
    const dark_mode_preference = window.matchMedia("(prefers-color-scheme: dark)");

    if (dark_mode_preference.matches) {
      const used_components = ["container", "grid", "button", "calendar", "card", "checkbox", "dimmer", "divider", "dropdown", "form", "header", "icon", "image", "input", "label", "list", "loader", "menu", "message", "modal", "placeholder", "popup", "progress", "segment", "sidebar", "statistics", "step", "tab", "table", "text", "toast", "api", "transition"];

      used_components.forEach(used_component => {
        let ui_components = document.querySelectorAll('.main.content .ui.' + used_component);

        ui_components.forEach(component => {
          if (state) {
            component.classList.remove('inverted');
          } else {
            component.classList.add('inverted');
          }
        });
      });
    }
  },

  message_closable() {
    $('.message .close').off('click.general');
    $('.message .close').on('click.general', function() {
      $(this).closest('.message').transition('fade');
    });
  },

  fields_with_error() {
    $('.field_with_errors').each(function() {
      return $(this).contents().unwrap().parent().addClass('error');
    });
  },

  form_submit() {
    let submit_button = $("form").find("input[type=submit].button.submit")
    let is_button = false
    if ( $("form").find("button[type=submit].button.submit").length > 0) {
      submit_button = $("form").find("button[type=submit].button.submit")
      is_button = true
    }
    
    $("form").off("ajax:before.general")
    $("form").on("ajax:before.general", function(_data) {
      console.log("form - ajax:before")
      if (is_button) {
        submit_button.addClass("loading disabled")
      } else {
        submit_button.hide()
        submit_button.parent().prepend("<button class='ui mini primary button loading disabled submit'>" + submit_button.val() + "</button")
      }
      
    })
    $("form").off("ajax:complete.general")
    $("form").on("ajax:complete.general", function(_data) {
      console.log("form - ajax:complete")
      if (is_button) {
        submit_button.removeClass("loading disabled")
      } else {
        submit_button.parent().find("button.button.loading.disabled.submit").remove()
        submit_button.show()
      }
    })
    $("form").off("ajax:success.general")
    $("form").on("ajax:success.general", function(_data) {
      //sconsole.log("form - ajax:success")
      //console.log(data)
      //general.clear_form_errors(data.target)
      
      // var class_name = $(".main.menu .breadcrumb .navigation").data("controller-name")
      // if (window[class_name] !== undefined) {
      //   if ((typeof window[class_name].adjust_form_body) === "function") {
      //     window[class_name].adjust_form_body();
      //   }
      // }
    })
    $("form").off("ajax:error.general")
    $("form").on("ajax:error.general", function(data) {
      //console.log("form - ajax:error")
      //console.log(data)
      $('body').toast({ 
        class: 'error', 
        className: {
          toast: 'ui icon message'
        }, 
        showIcon: 'times', 
        title: 'Error saving', 
        message: `${$(".main.menu .breadcrumb .navigation").data("model-name-human")}: ${$(".main.menu .breadcrumb .navigation").data("record-id")}`  
      })
      general.form_errors(JSON.parse(data.originalEvent.detail[0]), data.target)
    })
  },

  display_record_message(text) {
    $('body').toast({ 
      class: 'success', 
      className: {
        toast: 'ui icon message'
      }, 
      showIcon: 'checkmark', 
      title: text, 
      message: `${$(".main.menu .breadcrumb .navigation").data("model-name-human")}: ${$(".main.menu .breadcrumb .navigation").data("record-id")}` 
    })
  },

  form_errors(errors, form) {
    general.clear_form_errors(form)
    general.form_error_messages(form, errors.full_messages)

    $.each(errors.errors, function(field, messages) {
      var input;
      input = $(form).find('input, select, textarea').filter(function() {
        var name;
        name = $(this).attr('name');
        if (name) {      
          return name.match(new RegExp($(".main.menu .breadcrumb .navigation").data("model-name") + '\\[' + field + '\\(?'));
        }
      });

      input.closest('.field').addClass('error');
      return input.closest('.field').find("label").append('<span class="ui mini left pointing red basic label" style="position: relative; top: -1px; margin-right: 4px; margin-left: 10px;">' + $.map(messages, function(m) {
        return m.charAt(0).toUpperCase() + m.slice(1);
      }) + '</span>')
    });
  },

  form_error_messages(form, messages) {
    var error_messages_list = ""
    $.each(messages, function(_index, message) {
      error_messages_list += '<li>' + message + '</li>'
    })

    console.log(error_messages_list)
    const error_message = `<div class="ui error visible message">
      <i class="close icon"></i>
      <div class="header">${messages.length} error${messages.length > 1 ? "s" : ""} prohibited this ${$(".main.menu .breadcrumb .navigation").data("model-name-human")} from being saved:</div>
      <ul class="list">
        ${error_messages_list}
      </ul>
    </div>`

    $(form).find(".ui.jamesblue.bg.segment.header").parent().prepend(error_message)
    $(form).find(".error.visible.message .close").off('click.general')
    $(form).find(".error.visible.message .close").on('click.general', function() {
      $(this).closest('.message').transition('fade');
    });
  },

  clear_form_errors(form) {
    const field = $(form).find(".field.error")
    $(field).removeClass("error").find("span.red.label").remove()
    $(form).find(".error.visible.message").remove()
  },

  sidebar() {
    general.menu_peaking()

      if ($('.sidebar.menu .chosen').length) { if (!utils.isElementInView($('.sidebar.menu .chosen'), false)) { $('.sidebar.menu .ui.segment')[0].scrollTop = $('.sidebar.menu .chosen')[0].offsetTop; } }

      $(".sidebar.menu .navigation.row").off('click.general')
      $(".sidebar.menu .navigation.row").on("click.general", function() {
        general.load_view_from_sidebar(this)
      })

      $('.ui.navigation.accordion').accordion({
        animateChildren: false,
        onChange() {
          const open_accordions = [];
          for (let accordion of Array.from($('.ui.navigation.accordion .title.active'))) {
            open_accordions.push($(accordion).text());
          }
          return users.settings("sidebar.opened_accordions", open_accordions);
        }
      });

      $('.show.sidebar').off('click.general')
      $('.show.sidebar').on('click.general', function() {
        $(".main.content").addClass("transition")
        if ($(".sidebar.menu").hasClass('open')) {
          $('.sidebar.menu .sidebar-settings .button').find("svg").remove()
          $('.sidebar.menu .sidebar-settings .button').html('<i class="fa-solid fa-angle-right icon"></i>')

          users.settings("sidebar.navigation.opened", false)
          $(".sidebar.menu").removeClass('open').addClass('closed')
          $(".main.content").removeClass('with-sidebar').addClass('without-sidebar')
          $(".action.bar").removeClass('with-sidebar').addClass('without-sidebar')          
        } else {
          $('.sidebar.menu .sidebar-settings .button').find("svg").remove()
          $('.sidebar.menu .sidebar-settings .button').html('<i class="fa-solid fa-angle-left icon"></i>')

          users.settings("sidebar.navigation.opened", true)
          $(".sidebar.menu").removeClass('closed').addClass('open')
          $(".main.content").removeClass('without-sidebar').addClass('with-sidebar')
          $(".action.bar").removeClass('without-sidebar').addClass('with-sidebar')
        }

        setTimeout(function() {
          $(".main.content").removeClass("transition")
        }, 180)
      })
    
  },

  load_view_from_sidebar(element) {
    const sidebar = $(".sidebar.menu")
    
    sidebar.off("ajax:before.general")
    sidebar.on("ajax:before.general", function(_data) {
      //console.log("ajax:before")
      Turbo.navigator.delegate.adapter.showProgressBar();
    })
    sidebar.off("ajax:complete.general")
    sidebar.on("ajax:complete.general", function(_data) {
      //console.log("ajax:complete")
    })
    sidebar.off("ajax:success.general")
    sidebar.on("ajax:success.general", function(_data) {
      //console.log("ajax:success")
      //console.log(data)

      $(".sidebar.menu .navigation.row").removeClass("chosen")
      $(element).addClass("chosen")
    })
    sidebar.off("ajax:error.general")
    sidebar.on("ajax:error.general", function(_data) {
      //console.log("ajax:error")
      //console.log(data)
    })
  },

  load_view_from_index(element) {
    //console.log(element)
    element.off("ajax:before.general")
    element.on("ajax:before.general", function(_data) {
      //console.log("ajax:before")
      Turbo.navigator.delegate.adapter.showProgressBar();
    })
    element.off("ajax:complete.general")
    element.on("ajax:complete.general", function(_data) {
      //console.log("ajax:complete")
    })
    element.off("ajax:success.general")
    element.on("ajax:success.general", function(_data) {
      //console.log("ajax:success")
      //console.log(data)
    })
    element.off("ajax:error.general")
    element.on("ajax:error.general", function(_data) {
      //console.log("ajax:error")
      //console.log(data)
    })
  },

  adjust_body() {
    Turbo.navigator.delegate.adapter.hideVisitProgressBar()

    general.init_body()
    general.adjust_title()

    if ($(".main.body .main.content .ui.body.segment").hasClass("without-contextbar")) {
      $(".main.body .main.content").removeClass("without-contextbar").removeClass("with-contextbar")
      $(".main.body .main.content").addClass("without-contextbar")
    }
    if ($(".main.body .main.content .ui.body.segment").hasClass("with-contextbar")) {
      $(".main.body .main.content").removeClass("without-contextbar").removeClass("with-contextbar")
      $(".main.body .main.content").addClass("with-contextbar")
    }
  },

  adjust_title() {
    const controller_name = $(".main.menu .breadcrumb .section.name").text()
    const element_id = $(".main.menu .breadcrumb .navigation").data("record-id")
    const action = $(".main.menu .breadcrumb .section.action").text()

    const nav_text = `${action != "" ? action + " " : ""}${controller_name}${element_id != undefined ? " " + element_id : ""}`

    $("title").text(`James${ controller_name != undefined ? " - " + nav_text : ""}`)

    let url = $(".main.menu .breadcrumb .section.name").attr("href")
    if ($(".main.menu .breadcrumb .navigation .section").length > 0) {
      url = $(".main.menu .breadcrumb .navigation .section").attr("href")
    }
    history.pushState(null, $("title").text(), url);
  },

  fetch_view(target) {
    let fetch_promise = new Promise(function (resolve, reject) {
      if ($(target).data('model-name') != "" && $(target).data('model-id') != "" && $(target).data('model-name') != undefined && $(target).data('model-id') != undefined) {
        $.ajax({
          data: {
            type: $(target).data('type'),
            fields: $(target).data('fields')
          },
          url: `/${$(target).data('model-name')}/${$(target).data('model-id')}/card`
        }).done(function(result) {
          $(target).html(result)
          resolve(result)
        }).fail(function(error) {
          reject(error)
        })
      } else {
        resolve()
      }
    })
    fetch_promise.then(() => {}, () => {}) 
    return fetch_promise
  },
  
  scroll_to_bottom(id){
    var element = document.getElementById(id);
    element.scrollTop = element.scrollHeight - element.clientHeight;
  },

  table_sort(target, default_column = null, reverse = false) {
    $(target).find('th').each((_i, th) => { 

      const down = '<i class="fas fa-angle-down" aria-hidden="true"></i>'
      const up = '<i class="fas fa-angle-up" aria-hidden="true"></i>'

      if (default_column == $(th).text()) {
        $(th).html(`${$(th).text()} ${reverse ? up : down}`)
      }
      
      $(th).off('click')
      $(th).on('click', () => {
      $(target).find('th').each((_i, th) => { $(th).html($(th).text().replace(/<\/?[^>]+(>|$)/g, "")) })
      const tbody = $(th).closest('table').find('tbody')[0]
      $(tbody).find('tr').sort(
        (([idx, asc] = [$(th.parentNode.children).index(th), this.asc = !this.asc]) =>
          (a,b) => 
            (([v1,v2] = [...((ab) => asc ? ab : ab.reverse())([a && $(a.children[idx]).text(), b && $(b.children[idx]).text()])]) => 
              v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)
            )( this.asc ? $(th).html(`${$(th).text()} ${reverse ? down : up}`) : $(th).html(`${$(th).text()} ${reverse ? up : down}`) )
        )()
      ).each((_i,tr) => tbody.appendChild(tr) )
    })})
  },

  tab_with_url(node) {
    let menu_tab = node.find('.item[data-tab]')
    menu_tab.tab({
      evaluateScripts: false,
      onVisible(path) {
        let current_parameters = general.get_url_parameter()
        current_parameters["tab"] = path
        let url = search.add_params_to_url("", current_parameters);
        general.set_url_with_parameter(url)
      }
    })
    node.find(".menu").menu({ overflowMethod: 'dropdown', });

    let parameter = general.get_url_parameter()
    if (parameter["tab"]) {
      menu_tab.tab("change tab", parameter["tab"])
    }
  },

  quicklook_icon(selector) {
    $(selector).on('mouseenter mouseleave', function(e) {
      switch(e.type) {
      case 'mouseenter':
        if ($(this).find("> .active.ajax.dimmer").length == 0) {
          if ($(this).find(".cover.image").length == 0) {
            $(this).find("i.icon").hide()
            $(this).append('<span class="animate__animated animate__fadeIn animate__faster quicklook-icon"><i class="far fa-eye fa-w-16 fa-2x"></i></span>')
          } else {
            $(this).append('<span class="animate__animated animate__fadeIn animate__faster quicklook-icon quicklook-icon-background"><i class="far fa-eye fa-w-16 fa-2x"></i></span>')
          }
        }
        break
      case 'mouseleave':
        $(this).find("i.icon").show()
        $(this).find(".quicklook-icon").remove()
        break
      }
    }) 
  },

  quicklook(modal_name, model_id) {
    $.ajax({
      url: `/${modal_name}/${model_id}/quicklook`,
      data:{
        modal: true
      },
      success(data, _textStatus, _xhr) {
        let modal_id = general.makeid()
        let new_data = $(data).addClass(modal_id)

        $('body').append(new_data);

        let modal = $(`.ui.quicklook.modal.${modal_id}`)
        
        modal.modal({
          duration: 200,
          allowMultiple: true,
          autofocus: false,
          onShow() {
            window[modal_name].show()
          },
          onHidden() {
            modal.remove()
          }
        }).modal('show');
      },

      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown);
      }
    });
  },

  index_quicklook(model_name) {
    if ($(`#${model_name}.index`).length > 0) {
      let quicklook_element = `#${model_name} .quicklook` 
      general.quicklook_icon(quicklook_element)
      $(quicklook_element).off('click')
      $(quicklook_element).on('click', function() {
        general.quicklook(model_name, $(this).data('model-id'));
      });
    }
  },

  makeid() {
    let text = "";
    const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let i = 0;

    while (i < 5) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
      i++;
    }
    return text;
  },

  index_view_settings() {
    let settings_button = $(".main.content .index.view.settings")
    settings_button.off('click')
    settings_button.on('click', function() {
      settings_button.addClass("loading")

      $.ajax({
        url: '/dashboard/settings',
        data: {
          model: settings_button.data("model")
        }
      }).done(function(data) {
        settings_button.removeClass("loading")
        settings_button.popup({
          delay: {
            show: 200
          },
          position: 'bottom center',
          html: data,
          on: 'manual',
          exclusive: true,
          hoverable: false,
          onShow() {
            search.init_settings(settings_button);
          },
          onHidden() {
            settings_button.popup('hide');
            settings_button.popup('remove popup');
            settings_button.popup('destroy');
          }
        }).popup("show")
      }).fail(function(error) {
        console.log(error)
      });

    });
  },

  collect_parameter(scope) {
    let parameters = {};
    for (let parameter of Array.from($(scope).find(".parameter"))) {
     parameters[general.get_id(parameter)] = general.get_value(parameter)
    }
    return parameters
  },

  get_id(field) {
    if ($(field).hasClass('api')) {
      return $(field).find('input').attr('id')
    } else if ($(field).hasClass('dropdown')) {
      return $(field).find("select").attr('id')
    } else {
      return $(field).attr('id')
    }
  },

  get_value(field) {
    if ($(field).is(':checkbox')) {
      return $(field).prop('checked')
    } else if ($(field).hasClass('api')) {
      return $(field).find('input').val()
    } else if ($(field).hasClass('dropdown')) {
      return $(field).find("select").val()
    } else {
      return $(field).val()
    }
  },

  set_url_with_parameter(parameter) {
    //const url = window.location.href
    const url = location.protocol + '//' + location.host + location.pathname
    
    history.pushState(null, $("title").text(), url + parameter);
  },

  get_url_parameter() {
    var vars = {};
    window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(_m,key,value) {
        vars[key] = value;
    });
    return vars;
  },

  hover_link(namespace) {
    let namespace_field = $(`.${namespace}.field`)
    let namespace_field_label = namespace_field.find('label')

    namespace_field.off("mouseenter")
    namespace_field.off("mouseleave")
    namespace_field
    .on("mouseenter", function() {
      if (!(namespace_field_label.find('a').length > 0)) {
        if ($(this).find("select").val() != "") {
          namespace_field_label.append(`<a target='_blank' href="/${namespace}/${$(this).find("select").val()}/edit">&nbsp;&nbsp;<i class='fas fa-external-link'></i></a>`)

          if (namespace_field.is('.with_fact_sheet')) {
            namespace_field_label.append(`<a target='_blank' href="/${namespace}/${$(this).find("select").val()}/fact_sheet">&nbsp;&nbsp;<i class='info circle icon'></i></a>`)
          }
        } else {
          namespace_field_label.append(`<a target='_blank' href="/${namespace}">&nbsp;&nbsp;<i class='fas fa-external-link'></i></a>`)
        }
      }
    })
    .on("mouseleave", function() {
      namespace_field_label.find('a').remove()
    })
  },

  copy_menu() {
    if ($("#copytextarea").length == 0) {
      $("body").append('<textarea id="copytextarea" style="display:none;"></textarea>')
    }
  },

  init_extensions() {
    jQuery.fn.extend({
      r_try: function(s) {
        let o = this[0]
        if (o !== null && typeof(o) !== 'undefined') {
          if (typeof(o[s]) == 'function') {
            return o[s].apply(o, [].slice.call(arguments, 2))
          } else if (typeof(s) == 'function') {
            return s(o)
          } else {
            return o[s]
          }
        }
        return o
      }
    })
  },

  menu_peaking() {
    $('.sidebar.menu').on('mouseenter', function() {
      var thisElement = this

      if (sidebar_peaking_timeout != null) { clearTimeout(sidebar_peaking_timeout) }

      sidebar_peaking_timeout = setTimeout(function () {
        if (!thisElement.classList.contains('open')) {
          thisElement.classList.remove('closed')
          thisElement.classList.add('focus', 'peak')
        }
      }, 400)
    }).on('mouseleave', function() {
      if (sidebar_peaking_timeout != null) { 
        clearTimeout(sidebar_peaking_timeout) 
        sidebar_peaking_timeout = null
      }
      if (!this.classList.contains('open')) {
        this.classList.remove('peak', 'focus')
      }
      if (!this.classList.contains('open') || this.classList.contains('focus')) {
        this.classList.remove('peak')
        this.classList.add('closed')
      }
    })

    // $('.context.bar').hover((function() {
    //   if (!$(".context.bar").hasClass('open')) {
    //     $(".context.bar").addClass('focus')
    //     $(".search.bar").addClass('focus')
    //   }
    //   if (!$(".context.bar").hasClass('open')) {
    //     $(".context.bar").removeClass('closed').addClass('peak')
    //     $(".search.bar").removeClass('closed').addClass('peak')
    //   }
    // }), function() {
    //   if (!$(".context.bar").hasClass('open')) {
    //     $(".context.bar").removeClass('peak').removeClass('focus')
    //     $(".search.bar").removeClass('peak').removeClass('focus')
    //   }
    //   if (!($(".context.bar").hasClass('open') || $(".context.bar").hasClass('focus'))) {
    //     $(".context.bar").removeClass('peak').addClass('closed')
    //     $(".search.bar").removeClass('peak').addClass('closed')
    //   }
    // });
  },

  check_menu() {
    const su_back = general.cookie('su_return_to_user');

    if (su_back) {
      if ($(".main.menu .dropdown .menu .item.su-back").length == 0) {
        const item = '<div class="divider"></div><a class="item su-back" href="/users/su_back"><i class="fa-regular fa-person-walking-arrow-loop-left fa-fw icon"></i> Return</a>'
        delay((function() {
          $(".main.menu .dropdown .menu.visible").append(item)
        }), 300)
      }
    }
  },

  cookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  },

  element_sidebar(sidebar_name) {
    let element_sidebar = $(`.element-sidebar-container.${sidebar_name}`)
    let fields = element_sidebar.find(`.nested-fields.${sidebar_name}`)
    let sidebar = element_sidebar.find('.element-sidebar')
    let menu = sidebar.find(".menu-item")

    general.set_sidebar_counter(element_sidebar)

    if (fields.length > 0) {
      fields.hide()
      $(fields[0]).show()
    }

    if (menu.length > 0) {
      $(menu[0]).addClass("active")
    }

    general.sidebar_item(menu, sidebar_name)
  },

  sidebar_item(menu, sidebar_name) {
    menu.off("click")
    menu.on("click", function(element) {
      let target = $(element.currentTarget)
      
      if (target.hasClass("active")) { return }
      
      menu.removeClass("active")
      $(target).addClass("active")
    
      $(`.element-sidebar-container.${sidebar_name} .nested-fields`).hide()
      let model_id = $(target).data("model-id")
      $(`.element-sidebar-container.${sidebar_name} .nested-fields[data-model-id=${model_id}]`).show()
    })
  },

  set_sidebar_counter(element_sidebar) {
    let sidebar = element_sidebar.find('.element-sidebar')
    let menu = sidebar.find(".menu-item")
    let counter = sidebar.find(".element-counter")

    if (counter.length > 0) {
      counter.text(menu.length)
    }

    general.toggle_sidebar_welcome(element_sidebar, menu.length)
  },

  toggle_sidebar_welcome(element_sidebar, counter_value) {
    let welcome = $(element_sidebar).find(".placeholder-welcome")
    let elements_found = $(element_sidebar).find(".no-elements-found")
    
    if (counter_value == 0) {
      welcome.show()
      elements_found.show()
    } else {
      welcome.hide()
      elements_found.hide()
    }
  },

  add_sidebar_item(element, sidebar_name) {
    let element_sidebar = $(`.element-sidebar-container.${sidebar_name}`)
    let fields = element_sidebar.find(`.nested-fields.${sidebar_name}`)
    let sidebar = element_sidebar.find('.element-sidebar')
    let menu = sidebar.find(".menu-item")

    element.attr("data-model-id", general.makeid())

    let template = sidebar.find("template")[0]
    let template_clone = template.content.cloneNode(true)

    menu.removeClass("active")
    template.before(template_clone)

    let new_sidebar_item = sidebar.find(".menu-item[data-model-id=new-element]")

    new_sidebar_item.attr("data-model-id", element.attr("data-model-id"))
    new_sidebar_item.addClass("active")

    general.sidebar_item(sidebar.find(".menu-item"), sidebar_name)

    fields.hide()
    element.show()

    general.set_sidebar_counter(element_sidebar)
  },

  remove_sidebar_item(model_id, sidebar_name) {
    let element_sidebar = $(`.element-sidebar-container.${sidebar_name}`)
    let sidebar = element_sidebar.find('.element-sidebar')  
    sidebar.find(`.menu-item[data-model-id=${model_id}]`).remove()

    let menu = sidebar.find(".menu-item")
    menu.removeClass("active")

    if (menu.length > 0) {
      $(menu[0]).trigger("click")
    }

    general.set_sidebar_counter(element_sidebar)
  },

  post_redirect(url, data) {
    let form = document.createElement('form');
    document.body.appendChild(form);
    form.method = 'post';
    form.action = url;
    for (var name in data) {
      const array_data = data[name]
      if (Array.isArray(array_data)) {
        array_data.forEach(element => {
          let input = document.createElement('input');
          input.type = 'hidden';
          input.name = name;
          input.value = element;
          form.appendChild(input);
        })
      } else {
        let input = document.createElement('input');
        input.type = 'hidden';
        input.name = name;
        input.value = data[name];
        form.appendChild(input);
      } 
    }
    let input = document.createElement('input');
        input.type = 'hidden';
        input.name = "authenticity_token";
        input.value = Credential.getCSRFToken();
        form.appendChild(input);
    form.submit();
  }
}

window.general = general;

window.ClientSideValidations.formBuilders['ActionView::Helpers::FormBuilder'] = {
  add: function(element, _settings, message) {
    const form = $(element[0].form)
    const field = form.find("#" + element.attr('id')).closest(".field")

    if (element.data('valid') !== false && (form.find("label.message[for='" + (element.attr('id')) + "']")[0] == null)) {
      $(field).addClass("error").find("label").append('<span class="ui mini left pointing red basic label" style="position: relative; top: -1px; margin-right: 4px; margin-left: 10px;">' + message + '</span>')
      $(form).find("input[type='submit']").addClass("disabled")
      $(form).find("button[type='submit']").addClass("disabled")
    }
  },

  remove: function(element, _settings) {
    const form = $(element[0].form)
    const field = form.find("#" + element.attr('id')).closest(".field")
    $(field).removeClass("error").find("span.red.label").remove()
    $(form).find("input[type='submit']").removeClass("disabled")
    $(form).find("button[type='submit']").removeClass("disabled")
  }
}
