document.addEventListener("turbo:load", function() {

  if ($("#metadata_mapping_contact_sub_channels.index").length > 0) {
    search.init("/metadata_mapping_contact_sub_channels");
  }

  if ($("#metadata_mapping_contact_sub_channels").filter(".edit,.new").length > 0) {
    
    $(".dropdown").not('.remote').not('.custom').dropdown({placeholder: false, fullTextSearch: true});

    $(".dropdown.custom").not('.remote').dropdown({
      placeholder: false, 
      fullTextSearch: true,
      preserveHTML: true,
    });

    $('.mmc.field').hover((function() {
      if (!($('.mmc.field label a').length > 0)) {
        $('.mmc.field label').append("<a target='_blank' href='/metadata_mapping_contacts/" + $(".mmc.dropdown").dropdown("get value") + "/edit'>&nbsp;&nbsp;<i class='fas fa-external-link'></i></a>"); 
      }
    }), function() {
      $('.mmc.field label a').remove();
    });
    
    $('#metadata_mapping_contact_sub_channels .with-tooltip').popup();
  }
});
