var workflow_7202 = {
  init(target, params_id) {
    // change decoder to force correct selection
    target.find('.decoder-select').change();
    target.find('.item[data-tab]').tab()
    target.find('.validate').change(() => workflow_7202.validate(params_id));
    
    $(`#perform_insert_at_start_${params_id}[type="checkbox"]`).parent().checkbox({
      fireOnInit: true,
      onChecked: function() {
        let search_field = $(`.search[data-target="#primary_video_-_insert_at_start_-_path_${params_id}"]`)
        search_field.parents('div.insert-at-start').show()
      },
      onUnchecked: function() {
        let search_field = $(`.search[data-target="#primary_video_-_insert_at_start_-_path_${params_id}"]`)
        search_field.search('set value', '')
        $(`#primary_video_-_insert_at_start_-_path_${params_id}`).val("")
          workflow_7352.clear_audio_selection(params_id, "start")
          workflow_7352.clear_content_tc(params_id, "start")
          workflow_7352.clear_hdr_values(params_id, 'start')
        search_field.parents('div.insert-at-start').hide()
      }
    })
    $(`#perform_insert_at_end_${params_id}[type="checkbox"]`).parent().checkbox({
      fireOnInit: true,
      onChecked: function() {
        let search_field = $(`.search[data-target="#primary_video_-_insert_at_end_-_path_${params_id}"]`)
        search_field.parents('div.insert-at-end').show()
      },
      onUnchecked: function() {
        let search_field = $(`.search[data-target="#primary_video_-_insert_at_end_-_path_${params_id}"]`)
        search_field.search('set value', '')
        $(`#primary_video_-_insert_at_end_-_path_${params_id}`).val("")
          workflow_7352.clear_audio_selection(params_id, "end")
          workflow_7352.clear_content_tc(params_id, "end")
          workflow_7352.clear_hdr_values(params_id, 'end')
        search_field.parents('div.insert-at-end').hide()
      }
    })
    
    
    $.each(target.find('.ui.search.insert-asset'), (_i, item) =>
      workflow_7202.insert_asset(params_id, item, { insert_for_asset_id: $(`#primary_video_-_asset_ID_${params_id}`).val(), ignore_client: true, skip_sync_groups: true })
    )

    target.find('.ui.videoload_avc.checkbox').checkbox({
      onChecked() {
        target.find('.ui.fallback_proxy.checkbox').removeClass('disabled')
      },
      onUnchecked() {
        target.find('.ui.fallback_proxy.checkbox').checkbox('uncheck')
        target.find('.ui.fallback_proxy.checkbox').addClass('disabled')
      }
    })

    target.find('.ui.hevc.checkbox').checkbox({
      onChecked() {
        const framerate_checkbox = target.find('.ui.avc_original_framerate.checkbox')
        const default_value = framerate_checkbox.data('default')
        if (default_value) {
          target.find('.ui.avc_original_framerate.checkbox').checkbox('check')
        }
      },
      onUnchecked() {
        const framerate_checkbox = target.find('.ui.avc_original_framerate.checkbox')
        framerate_checkbox.checkbox('uncheck')
      }
    })

    const command_line_field = target.find('.command-line.field')
    target.find('.command-line.ui.toggle.checkbox').checkbox({
      onChecked() {
        command_line_field.show()
      },
      onUnchecked() {
        command_line_field.hide()
        command_line_field.find('input').val("")
      }
    })

    target.find('.command-line.info.icon').popup({
      popup : $('.command-line.popup'),
      on    : 'click'
    })

    target.find('.perform-black .ui.toggle.checkbox').each((_i,element) => {
      const input_field = $(element).parent().next('.field')
      $(element).checkbox({
        onChecked() {
          input_field.show()
        },
        onUnchecked() {
          input_field.hide()
        }
      })
    })

    let dfxp_conversion_selectors = [
    ].join(',')

    let dfxp_conversion_disabling_checkboxes = target.find(dfxp_conversion_selectors)
    dfxp_conversion_disabling_checkboxes.on('change.dfxp_conversion', function() {
      if (dfxp_conversion_disabling_checkboxes.toArray().some(e => e.checked)) {
        workflow_7202.clear_dfxp_conversion_subtitles(target)
        $('[id^="secondary_subtitle_1_-_language_select_"]').parent('.dropdown').addClass('disabled')
        $('[id^="secondary_subtitle_2_-_language_select_"]').parent('.dropdown').addClass('disabled')
      } else {
        $('[id^="secondary_subtitle_1_-_language_select_"]').parent('.dropdown').removeClass('disabled')
        $('[id^="secondary_subtitle_2_-_language_select_"]').parent('.dropdown').removeClass('disabled')
      }
    })
    dfxp_conversion_disabling_checkboxes.trigger('change.dfxp_conversion')
    
    target.find('.subtitle-select').on('change', function() {
      const id = $(this).find("select").attr('id').split("-")[0]
      const type = $(this).find(":selected").data('subtitle-type')
      const container = $(this).find(":selected").data('container')
      $(`#${id}-_type_${params_id}`).val(type).trigger('change')
      $(`#${id}-_container_${params_id}`).val(container).trigger('change')

      let str = $(this).find('select').attr('id') || ""
      let subtitle_number = /secondary_subtitle_(?<snumber>\d+)_.*/.exec(str)?.groups?.snumber
      if (subtitle_number) {
        $(target).find(`[for^="secondary_subtitle_${parseInt(subtitle_number) + 1}_-_language_select"]`).siblings('.subtitle-select').removeClass('disabled')
      }
    })

    target.find('.trim.ui.toggle.checkbox').checkbox({
      fireOnInit: true,
      onChecked() {
        target.find('.perform-edit.field').removeClass("disabled")
        target.find('.active_contents_selection').show()
      },
      onUnchecked() {
        target.find('.perform-edit.field').addClass("disabled")
        target.find('.active_contents_selection').hide()
      }
    })

    target.find('.active_contents_selection .buttons .button').each((_i, e) => {
      let button = $(e)
      button.popup({
        variation: 'very wide flowing',
        lastResort: 'bottom left',
        forcePosition: true,
        prefer: "opposite",
        hoverable: true,
        html: `
        <div style="width: 260px">
          <h5 class="ui dividing header">
            ${button.text()}${button.is('.inactive_content') && "<span class='ui basic orange mini label'>INACTIVE CONTENT</span>"}
          </h5>
          <span class="ui basic tiny grey label">
            START
            <div class="detail">${button.data('content-tc-in')}</div>
          </span>&nbsp;-&nbsp;<span class="ui basic tiny grey label">
            END
            <div class="detail">${button.data('content-tc-out')}</div>
          </span>
        </div>`,
        position: 'bottom center',
      })
    })
    
    target.find('.active_contents_selection .buttons .button').on('click', function() {
      var activeButtons = $(this).parent('.buttons').find('.ui.button.active')
      let centerIndex = activeButtons.eq(Math.floor(activeButtons.length / 2)).index()
      if ($(this).hasClass('active')) {
        if ($(this).index() < centerIndex) {
        
          let previousButtons = $(this).prevUntil('.ui.button:not(.active)')
          if (previousButtons.length > 0) {
            previousButtons.removeClass('active')
          } else {
            if (activeButtons.length != 1) { $(this).removeClass('active') }
          }
        } else {
          let nextButtons = $(this).nextUntil('.ui.button:not(.active)')
          if (nextButtons.length > 0) {
            nextButtons.removeClass('active')
          } else {
            if (activeButtons.length != 1) { $(this).removeClass('active') }
          }
        }
      }
      else {
        if ($(this).index() < centerIndex) {
          $(this).nextUntil('.ui.button.active').addBack().addClass('active')
        } else {
          $(this).prevUntil('.ui.button.active').addBack().addClass('active')
        }
      }

      activeButtons = $(this).parent('.buttons').find('.ui.button.active')
      const trim_div = $(this).closest('div.trim')
      const framerate = $(this).closest('.active_contents_selection').data('framerate')
      const timecode_format = $(this).closest('.active_contents_selection').data('timecode-format')
      let start_tc_string = activeButtons.first().data('content-tc-in')
      if (start_tc_string) {
        let start_tc = Timecode.from_string(start_tc_string, framerate, timecode_format).toString() + `@${parseFloat(framerate.toString()).toString()}`
        trim_div.find('input[id^=media_start_time]').val(start_tc).trigger('change')
      }

      let end_tc_string   = activeButtons.last().data('content-tc-out')
      if (end_tc_string) {
        let end_tc = Timecode.from_string(end_tc_string, framerate, timecode_format).toString() + `@${parseFloat(framerate.toString()).toString()}`
        trim_div.find('input[id^=media_end_time]').val(end_tc).trigger('change')
      }
    })

  },
    
  clear_dfxp_conversion_subtitles(target) {
    target.find('[id^="secondary_subtitle_1_-_"]').val('').trigger('change')
    target.find('[id^="secondary_subtitle_2_-_"]').val('').trigger('change')
  },

  validate(params_id) {
    const validator = { is_valid: true, messages: { errors: [], warnings: [], info: [] } }   

    const error_message = $(`#error_message_${params_id}`);
    error_message.addClass('hidden').html("")
    error_message.removeClass('visible')

    const warning_message = $(`#warning_message_${params_id}`)
    warning_message.addClass('hidden').html("")
    warning_message.removeClass('visible')

    let submit_button = $(':input[type=submit]')
    submit_button.prop('disabled', false)
    submit_button.addClass('primary').removeClass('red')
    submit_button.val('Save')

    workflow_7202.validate_deploy_spec(params_id, validator)
    workflow_7202.validate_insert_at(params_id, validator, "start")
    workflow_7202.validate_insert_at(params_id, validator, "end")
    
    if (validator.messages.warnings?.length > 0) {
      for (let message of Array.from(validator.messages.warnings)) {
        warning_message.append(`<p>${message}</p>`)
      }
      warning_message.removeClass('hidden')
      warning_message.addClass('visible')
      // return $(':input[type=submit]').prop('disabled', true)
      submit_button.addClass('red').removeClass('primary')
      submit_button.val('Save Anyway!')
    }

    //errors
    if (validator.messages.errors?.length > 0) {
      for (let message of Array.from(validator.messages.errors)) {
        error_message.append(`<p>${message}</p>`)
      }
      error_message.removeClass('hidden')
      error_message.addClass('visible')

      submit_button.addClass('red').removeClass('primary')
      submit_button.val('Validations failed!')
      submit_button.prop('disabled', true)
    }


  },

  validate_deploy_spec(params_id, validator) {
    // check Videoload and Erotic Lounge mutually exclusive
    if ($(`#deployment_specification_-_videoload_${params_id}`).prop('checked') && $(`#deployment_specification_-_erotic_lounge_${params_id}`).prop('checked')) {
      validator.is_valid = false
      validator.messages.errors.push("Videoload and Erotic Lounge are mutually exclusive deploy specifications")
    }
    if ($(`#deployment_specification_-_magenta_musik_${params_id}`).prop('checked') && $(`#deployment_specification_-_erotic_lounge_${params_id}`).prop('checked')) {
      validator.is_valid = false
      validator.messages.errors.push("Magenta Musik and Erotic Lounge are mutually exclusive deploy specifications")
    }
  },

  validate_insert_at(params_id, validator, suffix) {
    if ($(`#perform_insert_at_${suffix}_${params_id}`).prop('checked')) {
      const primary_audio_51_selected = $(`#primary_audio_-_51_-_insert_at_${suffix}_-_language_select_${params_id}`).dropdown('get text').length > 0
      const primary_audio_20_selected = $(`#primary_audio_-_20_-_insert_at_${suffix}_-_language_select_${params_id}`).dropdown('get text').length > 0
      if (!primary_audio_51_selected && !primary_audio_20_selected) {
        validator.is_valid = primary_audio_51_selected && primary_audio_20_selected
        validator.messages.warnings.push(`No primary audio selected for Insert-at-${suffix}`)
      }

      const insert_video = $(`#primary_video_-_insert_at_${suffix}_-_path_${params_id}`).val()
      if (!insert_video) {
        validator.is_valid = false
        validator.messages.errors.push(`No source selected for Insert-at-${suffix}`)
      }
    }
  },
      
  insert_asset(params_id, node, params) {
    let url = '/assets/search_dropdown?q={/query}';
    if (params) { url = search.add_params_to_url(url, params); }
    const target = ($(node).data('target'))
    const suffix = target.includes("insert_at_start") ? "start" : "end"
    $(node).search({
      minCharacters: 3,
      apiSettings: {
        url
      },
      templates: {
        standard: search.asset_search
      },
      onSelect(result, _response) {
        debugger
        $(target).val(result.path);
        for (let i = 0; i < result.sync_groups.length; i++) {
          const atg = result.sync_groups[i];
          workflow_7202.fill_audio_selection(params_id, atg, i, suffix)
        }
        workflow_7202.validate(params_id);
      },
      onSearchQuery(_query) {
        forms.disable('insert-asset');
      },
      onResults(_response) {
        forms.enable('insert-asset');
      }
    });
  },

  fill_audio_selection(params_id, track_group, index, suffix) {
    let channel_prefix;
    if (track_group.channel_count === 2) {
      channel_prefix = "20"
    } else if (track_group.channel_count === 6) {
      channel_prefix = "51"
    }

    for (var prefix of ["primary", "secondary"]) {
      const target_dropdown = $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_language_select_${params_id}`)
      const selected = track_group.disabled ? "disabled" : (track_group[`${prefix}_${channel_prefix}_default`] ? "selected" : "")
      const label = track_group.disabled ? `<span class='ui mini red basic label' style='margin-left: 1em;'>Invalid</span>` : ""
      target_dropdown.append(`<option value='${index}' data-layout='${track_group.layout}' data-track-id='${track_group.track_id}' data-iso639_2='${track_group.iso639_2}' data-channel_count='${track_group.channel_count}' data-location='${track_group.storage_location}' ${selected}>${track_group.description} ${label}</option>`)
      if (channel_prefix === "51") {
        const target_dropdown_20 = $(`#${prefix}_audio_-_20_-_insert_at_${suffix}_-_language_select_${params_id}`)
        target_dropdown_20.append(`<option value='${index}' data-layout='${track_group.layout}' data-track-id='${track_group.track_id}' data-iso639_2='${track_group.iso639_2}' data-channel_count='${track_group.channel_count}' data-location='${track_group.storage_location}' ${selected}>${track_group.description} ${label}</option>`)
        workflow_7202.init_audio_selection_on_change(target_dropdown_20, "20", suffix, params_id)
      }
      workflow_7202.init_audio_selection_on_change(target_dropdown, channel_prefix, suffix, params_id)
    }
  },
  init_audio_selection_on_change(target_dropdown, channel_prefix, suffix, params_id) {
    target_dropdown.on('change', function() {
      let prefix;
      if ($(this).attr('id')[0] === "p") {
        prefix = "primary"
      } else if  ($(this).attr('id')[0] === "s") {
        prefix = "secondary"
      }
      if ($(this).val() === "" || $(this).val() === undefined) {
        $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_present_${params_id}`).val(false)
        $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_path_${params_id}`).val("")
        $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_l_${params_id}`).val("")
        $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_r_${params_id}`).val("")
        $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_c_${params_id}`).val("")
        $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_lfe_${params_id}`).val("")
        $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_ls_${params_id}`).val("")
        $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_rs_${params_id}`).val("")
      } else {
        const selected_data = $(this).find(`option[value=${$(this).val()}]`).data()
        const { layout } = selected_data
        const { channel_count } = selected_data
        const { location } = selected_data
      
        if (layout !== undefined) {
          $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_present_${params_id}`)
          $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_present_${params_id}`).val(true)
          $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_path_${params_id}`).val(location)
          if (channel_count === 2) {
            if ('Mono_1' in layout) {
              $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_l_${params_id}`).val(layout['Mono_1'])
              $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_r_${params_id}`).val(layout['Mono_2'])
            } else {
              $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_l_${params_id}`).val('LT' in layout ? layout['LT'] : layout['Stereo L'])
              $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_r_${params_id}`).val('RT' in layout ? layout['RT'] : layout['Stereo R'])
            }
          } else if (channel_count === 6 && channel_prefix === "51") {
            $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_l_${params_id}`).val(layout['Left Front'])
            $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_r_${params_id}`).val(layout['Right Front'])
            $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_c_${params_id}`).val(layout['Center'])
            $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_lfe_${params_id}`).val(layout['LFE'])
            $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_ls_${params_id}`).val(layout['Left Surround'])
            $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_rs_${params_id}`).val(layout['Right Surround'])
          } else if (channel_count === 6 && channel_prefix === "20") {
            $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_l_${params_id}`).val(`${layout[ 'Left Front']}(0.707),${layout['Center']}(0.5),${layout[ 'Left Surround']}(0.354)`)
            $(`#${prefix}_audio_-_${channel_prefix}_-_insert_at_${suffix}_-_channel_-_r_${params_id}`).val(`${layout['Right Front']}(0.707),${layout['Center']}(0.5),${layout['Right Surround']}(0.354)`)
          }
        }
      }
    })
  }
};
           
window.workflow_7202 = workflow_7202;
