document.addEventListener("turbo:load", function() {
  if ($("#contact_provider_names.index").length > 0) {
    search.init("/contact_provider_names")
  }

  if ($("#contact_provider_names.edit").length > 0) {

    $('#contact_provider_names .remote.contact').dropdown({
      direction: 'downward',
      apiSettings: {
        cache: false,
        url: '/contacts.json?q={/query}&search_input={/query}'
      },
      templates: {
        menu: dropdownTemplates.search_menu
      }
    })

    contacts.init_dc_dropdown($('#contact_provider_names').find(".cpn-dc.dropdown"))

    general.hover_link("contacts")
    general.hover_link("distribution_channels")
  }
})
