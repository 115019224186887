const workflow_50017 = {
  init(target, params_id) {
    $(target).find('.upload-parameter').change(function() {
      const selected_files = $(target).find('.upload.checkbox.checked')
      const pluralized = selected_files.length === 1 ? "file" : "files"
      const asset_transfer_account_id = $(`#azure_destination_container_${params_id}`).find(':selected').data('assetTransferAccountId')
      $(target).find('.filecount.header').text(`${selected_files.length} ${pluralized} to upload`)
      
      const uploads = []
      for (let file of Array.from(selected_files)) {
        const profile = $(this).parentsUntil('.item').find('.profile.buttons .active.button').data('profile')
        const container = $(`#azure_destination_container_${params_id}`).dropdown('get value')
        let source = $(file).data(`${profile}-proxy`)

        uploads.push({ 
          source: source, 
          destination: container
        })
      }

      $(`#uploads_${params_id}`).val(JSON.stringify(uploads))
      $(`#asset_transfer_account_id_${params_id}`).val(asset_transfer_account_id)
      workflow.update_whparams($(target))
    })

    $(target).find('.profile.buttons .button').on('click', function() {
      $(target).find('.profile.buttons .button').removeClass('active')
      $(this).addClass('active')
      $(this).parentsUntil('.item').find('.upload.checkbox').change()
    })

    $(target).find('.upload.checkbox :first').change()
  }
};

window.workflow_50017 = workflow_50017;
