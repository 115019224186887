import { Controller } from "@hotwired/stimulus"
import { install, uninstall } from '@github/hotkey'

export default class extends Controller {
  static targets = ['shortcut']
  

  initialize() {
    if (this.hasShortcutTarget) {
      this.shortcutTarget.innerHTML = this.shortcutTarget.replace("Control+", "^")
      observeMutations.bind(this)(this.toggleWhenDisabled, this.element, { attributeFilter: ["disabled"] })
    }
  }

  connect() {
    if (this.disabled) return
    install(this.element)
  }

  disconnect() {
    uninstall(this.element)
  }

  toggleWhenDisabled([ mutation ]) {
    const hotkey = mutation.target
    const controllers = new Set((hotkey.dataset.controller || "").split(/\s/))
    if (hotkey.disabled) {
      controllers.delete(this.identifier)
    } else {
      controllers.add(this.identifier)
    }
    hotkey.dataset.controller = [...controllers].join(" ")
  }

  get disabled() {
    return document.body.hasAttribute('data-hotkeys-disabled')
  }
 }