const workflow_50014 = {
  init(target, params_id) {
    $(target).find('.upload-parameter').change(function() {
      const selected_files = $(target).find('.upload.checkbox.checked')
      const pluralized = selected_files.length === 1 ? "file" : "files"
      const asset_transfer_account_id = $(`#s3_destination_bucket_${params_id}`).find(':selected').data('assetTransferAccountId')
      $(target).find('.filecount.header').text(`${selected_files.length} ${pluralized} to upload`)
      
      const uploads = []
      for (let file of Array.from(selected_files)) {
        const location = $(file).data('location')
        const filename = $(file).data('filename')
        const a_ID = $(file).data('asset-id')
        const parent_path = $(file).data('parent-path')
        const bucket = $(`#s3_destination_bucket_${params_id}`).dropdown('get value')
        const target_path = $(`#s3_destination_path_${params_id}`).val()
        
        uploads.push({ 
          source: location + '/' + filename, 
          destination: bucket + (target_path.length > 0 ? target_path.trim() + '/' : "") + (parent_path.length > 0 ? parent_path.trim() + '/' : "") + filename,
          asset_id: a_ID,
          asset_transfer_account_id: asset_transfer_account_id
        })
      }
      $(`#uploads_${params_id}`).val(JSON.stringify(uploads))
      $(`#asset_transfer_account_id_${params_id}`).val(asset_transfer_account_id)
      workflow.update_whparams($(target))
    })
    $(target).find('.upload.checkbox :first').change()
  }
};

window.workflow_50014 = workflow_50014;
