import { Controller } from "@hotwired/stimulus"
import * as Credential from "../src/credential";

let abortController = new AbortController();
let challenge = null
export default class extends Controller {

  initialize() {
    this.passkeyButton = this.passkeyButton.bind(this)
    this.passkey = this.passkey.bind(this)
    this.init_passkey = this.init_passkey.bind(this)
  }

  connect() {
    super.connect()

    user_session.init()

    //console.log("new session")

    const submit_button = $("#user_session .next.button")
    const password_button = $("#user_session .password.button")
    const input_username = $("#user_session input.username")
    const input_password = $("#user_session input.password")

    let keydown_handler = function(event) {
      if (event.key === 'Enter') {

        if (input_username.is(":focus")) {
          submit_button.trigger("click")
        }

        if (input_password.is(":focus")) {
          password_button.trigger("click")
        }

        //console.log("Enter")
        return false
      }
    }
    $(document).off('keydown.user_session', keydown_handler)
    $(document).on('keydown.user_session', keydown_handler)

    input_username.on("input", this.updateNextButton)
    input_password.on("input", this.updatePasswordButton)

    if ($("#user_session .digits").length > 0) {
      submit_button.removeClass("loading")
    }

    $('#user_session .checkbox').checkbox()

    if ($("#user_session .otp.back").length > 0) {
      $("#user_session .otp").show()
      $("#user_session .otp input#digit-1").focus()
      $("#user_session .login").hide()

      $("#user_session .login-back").hide()

      $("#user_session .next.button").hide()
      $("#user_session .login-options").hide()


      $("#user_session .login-otp").show()

      $("#user_session .submit.button").removeClass("disabled loading")
      $("#user_session .otp.button").addClass("disabled")


      $("#user_session .otp.back").off("click")
      $("#user_session .otp.back").on("click", function() {
        $("#otp-error").remove()
        $("#user_session .otp.back").hide()
        $("#user_session .otp").hide()
        $("#user_session .login").show()
        $('input.digit-field').val("")
        $('#otp-code-field').val("")

        $("#user_session .login-options").show()
        $("#user_session .login-back").show()

        $("#user_session .login-otp").hide()
        $("#user_session .submit.button").removeClass("disabled loading")

        if ($("#user_session .passkey.button").data("passkey") == true) {
          $("#user_session .passkey.button").show()
        } else (
          $("#user_session .passkey.button").hide()
        )
      })
    }

    $("#user_session .login-back").off("click")
    $("#user_session .login-back").on("click", function() {
      $("#user_session .login-back").hide()

      $("#user_session .field.username .input").removeClass("disabled")
      $("#user_session .next.button").show()
      $("#user_session .field.password input").val("")
      $("#user_session .field.password").hide()
      $("#user_session .login-options").hide()
      $("#user_session .submit.button").removeClass("disabled loading")
    })

    if ($("#user_session .logged_in").length > 0) {
      post(`/user_sessions/redirect`).then(request=>{
        if(request?.response?.redirected){
          Turbo.visit(request.response.url)
        }
      })
    }

    this.updateNextButton()
    this.updatePasswordButton()

    this.init_passkey()
  }

  disconnect() {
    $("#user_session input.username").off("input", this.updateNextButton)
    $("#user_session input.password").off("input", this.updatePasswordButton)
  }

  async next() {
    $("#user_session .next.button").addClass("disabled loading")

    const check = await fetch(`/user_sessions/check`, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": Credential.getCSRFToken()
      },
      body: JSON.stringify({ login: $("#user_session input.username").val() }),
    })
    .then(response => response.json())

    $("#user_session .next.button").removeClass("disabled loading")

    if (check.status == "ok") {
      $("#user_session .field.username .input").addClass("disabled")
      $("#user_session .next.button").hide()
      $("#user_session .field.password").show()
      $("#user_session .login-options").show()
      $("#user_session .password.button").show()

      $("#user_session .login-back").show()

      $("#user_session .field.password input").trigger("focus")

      if (check.passkey) {
        $("#user_session .passkey.button").data("passkey", true)
      } else (
        $("#user_session .passkey.button").data("passkey", false)
      )
      this.passkeyButton()
    } else {
      $("#user_session .submit.button").removeClass("disabled loading")
    }
    
  }

  updateNextButton() {
    const submit_button = $("#user_session .next.button")

    if ($("#user_session input.username").val().length > 0) {
      submit_button.removeClass("disabled")
    } else {
      submit_button.addClass("disabled")
    }
  }

  updatePasswordButton() {
    const password_button = $("#user_session .password.button")

    if ($("#user_session input.password").val().length > 0) {
      password_button.removeClass("disabled")
    } else {
      password_button.addClass("disabled")
    }
  }

  passkeyButton() {
    if ($("#user_session .passkey.button").data("passkey") == true) {
      $("#user_session .passkey.button").show()
    } else (
      $("#user_session .passkey.button").hide()
    )
  }

  async init_passkey() {
    //console.log("passkey")
    
    try {
      if (window.PublicKeyCredential && PublicKeyCredential.isConditionalMediationAvailable) {  
        const isCMA = await PublicKeyCredential.isConditionalMediationAvailable();  
        if (isCMA) { 
          this.passkey(true)
        }   
      }

    } catch (error) {
      console.log(error);
    }
  }

  trigger_passkey() {
    this.passkey()
  }

  async passkey(conditional = false) {
    if (!conditional) {
      $("#user_session .password.button").hide()
      $("#user_session .passkey.button").addClass("disabled loading")
    }

    try {
      if (window.PublicKeyCredential) {
        if (challenge == null) {
          challenge = await fetch(`/user_sessions/challenge`).then(response => response.json())
        }
        
        let options = {
          mediation: 'conditional',
          signal: abortController.signal,
          publicKey: challenge,
          userVerification: "discouraged",
        }
        if (!conditional) {
          if(abortController.signal.aborted == false) {
            abortController.reason = "button"
            await abortController.abort()

            abortController = new AbortController();
          }

          options = { publicKey: challenge, signal: abortController.signal, }
        }

        const credentials = await Credential.get(options)
        
        if (credentials != undefined) {
          await Credential.callback("/user_sessions/callback", credentials);
          $("#user_session .password.button").hide()
          $("#user_session .submit.button").addClass("disabled loading")
        } else {
          this.init_passkey()
        }
      }

    } catch (error) {
      console.log(error);
      abortController.reason = "button"
      await abortController.abort()

      abortController = new AbortController();
    }
  }

  password() {
    $("#user_session .passkey.button").hide()
    $("#user_session .password.input").addClass("disabled")
    $("#user_session .submit.button").addClass("disabled loading")

    $("#form_error_message").remove()
    Turbo.navigator.submitForm(document.querySelector('#user_session form'))
  }
}
