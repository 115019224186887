import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    "input",
    "icon",
  ]

  static values = {
    reset: String,
  }

  connect() {
    this.checkValue()
  }

  disconnect() {
    this.iconTarget.style.display = "none"
  }

  async reset() {
    this.inputTarget.value = this.resetValue
    this.checkValue()
  }
  
  checkValue() {
    if (this.inputTarget.value !== this.resetValue) {
      this.iconTarget.style.display = "inline-block"
    } else {
      this.iconTarget.style.display = "none"
    }
  }

}
