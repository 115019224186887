import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [ "intervalTime", "intervalJobs", "message" ]

  connect() {
    this.changeInterval()
  }

  changeInterval() {
    let seconds = this.intervalTimeTarget.value / this.intervalJobsTarget.value
    if (seconds < 1) {
      seconds = seconds.toFixed(1)
    } else {
      seconds = Math.round(seconds)
    }
    this.messageTarget.innerHTML = `Submit a job every <b>${seconds}</b> seconds until <b>${this.intervalJobsTarget.value}</b> jobs are submitted to a queue. Wait <b>${this.intervalTimeTarget.value}</b> seconds until next queue is proceeded.`
  }

}
