import { StreamActions } from "@hotwired/turbo"

StreamActions.dispatch = function() {
  const getAllAttributes = el => el
  .getAttributeNames()
  .reduce((obj, name) => ({
    ...obj,
    [name]: el.getAttribute(name)
  }), {})
  let { name, target, targets, options } = getAllAttributes(this)
  const event = new CustomEvent(name, {
    detail: {},
    bubbles: true,
    cancelable: true,
    composed: false,
    ...JSON.parse(options),
  })

  if (target) {
    document.getElementById(target)?.dispatchEvent(event)
  } else if (targets) {
    const target_elements = document.querySelectorAll(targets)
    target_elements.forEach(el => el.dispatchEvent(event))
  } else {
    window.dispatchEvent(event)
  }
}