import NestedForm from 'stimulus-rails-nested-form'

export default class extends NestedForm {
  connect() {
    super.connect()
  }

  add(event) {
    super.add(event)
    $(".tc.formatter").on("change", function() {
      assets.clean_framerate($(this))
    })
  }

  remoteAdd(e) {
    e.preventDefault()
    var template_html = $(this.templateTarget).html()
    var added_index = new Date().getTime().toString()
    template_html = template_html.replace(/NEW_RECORD/g, added_index)

    var parsed_template = $(template_html)

    $(this.targetTarget).before(parsed_template)

    parsed_template.trigger(`stimulus_nested_form.fields_added.${e.params.association}`, {
      // object_class: object_class,
      added_index: added_index,
      association: e.params.association,
      event: e,
      additional_data: e.detail
    })
  }
}
