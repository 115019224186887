import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    projects.edit()
  }

  project_id() {
    return $("#project_p_ID").val()
  }

  openAssetRequestsModal() {
    //console.log("openAssetRequestsModal")

    let context = this

    $(".gen-asset-requests.button").addClass("loading")
    fetch("/projects/" + this.project_id() + "/asset_requests_modal")
    .then(response => response.text())
    .then(data => {
      $('body').append(data)
      let modal = $('.generate-asset-requests.modal')
      modal.modal({
        duration: 200,
        autofocus: false,
        onShow() {
          $(".gen-asset-requests.button").removeClass("loading")
          asset_requests.gen_modal_from_init()
          asset_requests.gen_form_submit()
        },
        onHidden() {
          modal.remove()
        },
        onApprove() {
          Turbo.visit(window.location.href)
        }
      }).modal('show')
    })
    .catch(error => {
      console.log(error)
    })
  }
}
