import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    catalog_base_objects.init_form($(this.element))
    catalog_base_objects.send_form()
    general.fields_with_error()
  }

  closeWithKeyboard(e) {
    if (e.code == "Escape") {
      $('.edit-catalog_base_object').modal('hide')
    }
  }
}
