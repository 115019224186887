import { Controller } from "@hotwired/stimulus"
import { get, post } from '@rails/request.js'

export default class extends Controller {

  isReverting = false

  connect() {
    console.log('connected')
  }

  async viewChangeset(_event) {
    this.highlight_diff('on')
  }

  async hideChangeset(_event) {
    if(this.isReverting) return
    this.highlight_diff('off')
  }

  highlight_diff(state) {
    const changeset = $(this.element).data('changeset')

    switch(state) {
      case 'on':
        $.each(changeset, function(key, value) {
          $(`[id$=${key}]`).addClass("diff")
          $(`[id$=${key}]`).data('original-value', $(`[id$=${key}]`).val())
          $(`[id$=${key}]`).val(value[0])
        })
        break
      case 'off':
        $.each(changeset, function(key, _value) {
          $(`[id$=${key}]`).removeClass("diff")
          $(`[id$=${key}]`).val($(`[id$=${key}]`).data('original-value'))
        })
        break
    }
  }

  revert() {
    this.isReverting = true
    const changeset = $(this.element).data('changeset')
    $.each(changeset, function(key, value) {
      $(`[id$=${key}]`).val(value[0]);
      return $(`[id$=${key}]`).removeClass("diff")
    })
    $(this.element.closest('.popup')).popup('hide all')
    // this.isReverting = false
  }

}
