import { Controller } from "@hotwired/stimulus"
import * as Credential from "../../src/credential";

export default class extends Controller {

  static targets = [
    'modal',
    'availCount',
    'message',
    'submitButton',
    'exportFormat',
  ]

  static values = {
    exportUrl: String,
  }

  connect() {
    $(this.messageTarget).addClass("info").removeClass("positive negative")
    this.availCountTarget.textContent = index_select.selected_ids()?.length
  }

  disconnect() {
  }

  async export_xlsx() {
    const modal = $(this.modalTarget)
    const button = $(this.submitButtonTarget)
    const message = $(this.messageTarget)
    var export_link = this.exportUrlValue
    let avail_ids = index_select.selected_ids()
    let export_format = this.exportFormatTarget.value
    let export_format_string = $(this.exportFormatTarget).siblings('div.text').text()

    let toast = $('body').toast({
      class: 'info',
      displayTime: 0,
      message: `Exporting Avails to ${export_format_string}`,
      showProgress: 'bottom'
    })

    button.addClass('disabled loading')
    
    let format = /xml/i.test(export_format) ? 'xml' : 'xlsx'
    message.find('.list li').html(`${avail_ids.length} avails exporting...`)
    try {
      const response = await fetch(`${export_link}.${format}`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          'Accept': 'application/xml,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.turbo-stream.html',
          "X-CSRF-Token": Credential.getCSRFToken()
        },
        body: JSON.stringify({ avail_ids: avail_ids, export_format: export_format }),
      })

      if (response.ok) {
        button.removeClass('loading').addClass('disabled')
        const file_blob = await response.blob()
        const object_url = window.URL.createObjectURL(file_blob)
        const link = document.createElement('a')
        link.href = object_url
        let content_disposition = response.headers.get('Content-Disposition') ?? ''
        
        let date = new Date().toLocaleDateString("de-DE")
        link.download = /filename="(?<filename>.*)"/.exec(content_disposition)?.groups?.filename ?? `EMA_Avails_Export_${date}.${format}`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        message.addClass("positive").removeClass("info")
        toast.removeClass('neutral').addClass('success')
        message.html('Export Successful!')
        setTimeout(() => {
          toast.toast('close')

          modal.modal("hide")
        }, 2000)
      } else {
        toast.toast('close')
        if (response.headers.get("Content-Type").includes("vnd.turbo-stream.html")) {
          const text = await response.text()
          Turbo.renderStreamMessage(text)
        }
        throw new Error(`HTTP error! status: ${response.status}`)
      }
    } catch (error) {
      button.removeClass('loading green').addClass('disabled red')
      console.error('Fetch request failed:', error)
    }
  }
}
