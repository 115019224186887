document.addEventListener("turbo:load", function() {
  if ($("#genres").length > 0) {
    search.init("/genres");
    return init_dropdowns();
  }
});

var init_dropdowns = function() {
  $(".search-field.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
  return $("#genres .default-options.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
};
