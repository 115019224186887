import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  static targets = [ 
    "keyArtworkLabel", 
    "editModal",
    "addImageModal",
    "searchForm",
    "searchInput",
    "searchIndexView",
    "addImageForm"
  ]

  static values = {
    modelId: Number,
    modelType: String,
    clientId: Number,
    mtTerritoryId: Number
  }
  
  connect() {
    metadata_images.edit()
    this.element.addEventListener('turbo:submit-end', (_event) => {
      this.searchInputTarget.classList?.remove('loading')
    })
  }

  editModalTargetConnected(element) {
    $(element).find('select.default-options.dropdown').dropdown()
  }

  searchFormTargetConnected(element) {
    $(element).find(".dropdown").not('.remote').dropdown()
    $(element).find(".accordion").accordion()
    $(element).find(".checkbox").checkbox()

    if (this.hasClientIdValue) {
      $('#search_client').dropdown('set selected', this.clientIdValue)
    }
  }

  searchIndexViewTargetConnected(element) {
    $(element).find(".selector").show()
  }

  addImageModalTargetConnected(element) {
    $(element).find(".checkbox").checkbox()
    $(element).modal({
      observeChanges: true,
      autofocus: false,
      onHidden() {
        $(element).remove()
      },
      onApprove() {
        $(element).find(".green.ok.button").addClass("loading disabled")
        return false
      }
    })
  }

  toggleKeyArtwork(event) {
    const divNode = event.target.parentNode
    const isKeyArtworkInput = divNode.querySelector('input')

    this.keyArtworkLabelTarget.toggleAttribute('isKeyArtwork')
    isKeyArtworkInput.value = this.keyArtworkLabelTarget.hasAttribute('isKeyArtwork')
    
    if (this.keyArtworkLabelTarget.hasAttribute('isKeyArtwork')) {
      this.keyArtworkLabelTarget.style.display = "block"
      divNode.querySelector("i").classList.add('check')
      divNode.querySelector("span").textContent = " Unset Key Artwork"
    } else {
      this.keyArtworkLabelTarget.style.display = "none"
      divNode.querySelector("i").classList.remove('check')
      divNode.querySelector("span").textContent = " Set Key Artwork"
    }
  }

  search(_event) {
    clearTimeout(this.timeout)
    this.searchInputTarget.classList?.remove('loading')
    this.timeout = setTimeout(() => {
      this.searchInputTarget.classList?.add('loading')
      this.searchFormTarget.requestSubmit()
    }, 500)
  }

  select(event) {
    const cover = $(event.currentTarget).find('.selector')
    const submitButton = $(this.addImageModalTarget).find("button.ok")

    if ($(cover).find(".selected").length > 0) {
      index_select.unselect(cover)
      cover.removeClass('selected')
    } else {
      index_select.select(cover)
      cover.addClass('selected')
    }

    if ($(this.addImageModalTarget).find(".selector .selected").length > 0) {
      submitButton.removeClass('disabled')
    } else {
      submitButton.addClass('disabled')
    }
  }

  submit() {
    const formData = new FormData(this.addImageFormTarget)
    const selectedImages = this.searchIndexViewTarget.querySelectorAll('.selector.selected')

    selectedImages.forEach(async (image, index) => {
      const metadataImageId = image.dataset.modelId

      if (this.hasMtTerritoryIdValue) {
        formData.append(`${this.modelTypeValue}[${this.modelTypeValue}_territories_attributes][0][id]`, this.mtTerritoryIdValue)
        formData.append(`${this.modelTypeValue}[${this.modelTypeValue}_territories_attributes][0][${this.modelTypeValue}_territory_images_attributes][${index}][mtti_mi_ID]`, metadataImageId)
        formData.append(`${this.modelTypeValue}[${this.modelTypeValue}_territories_attributes][0][${this.modelTypeValue}_territory_images_attributes][${index}][mtti_mtt_ID]`, this.mtTerritoryIdValue)
      } else {
        formData.append(`${this.modelTypeValue}[${this.modelTypeValue}_images_attributes][${index}][metadata_image_id]`, metadataImageId)
        formData.append(`${this.modelTypeValue}[${this.modelTypeValue}_images_attributes][${index}][${this.modelTypeValue}_id]`, this.modelIdValue)
        if ($('.key-artwork.checkbox').checkbox('is checked')) {
          formData.append(`${this.modelTypeValue}[${this.modelTypeValue}_images_attributes][${index}][is_key_artwork]`, 1)
        }
      }
    })

    fetch(`/${this.modelTypeValue}s/${this.modelIdValue}`, {
      method: "POST",
      body: formData
    })
    .then(_response => {
      $(this.addImageModalTarget).modal('hide')
      Turbo.visit(window.location.href)
    })
    .catch(_error => {
      this.addImageModalTarget.querySelector(".green.ok.button").classList.remove("loading")
      this.addImageModalTarget.querySelector(".green.ok.button").classList.add("red")
    })
  }
}
