document.addEventListener("turbo:load", function() {
  if ($("#genre_mains").length > 0) {
    search.init("/genre_mains");
    return init_dropdowns();
  }
});


var init_dropdowns = function() {
  $(".search-field.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
  return $("#genre_mains .default-options.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
};
