import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    'valueDropdown',
    'workflowParameterSearchDropdown',
  ]

  connect() {
    $(this.element).off("stimulus_nested_form.fields_added.preset")
    $(this.element).on("stimulus_nested_form.fields_added.preset", function(event, params) {
      let target = $(event.target)
      target.find('input.workflow_parameter_id').val(params.additional_data.workflow_parameter_id)
      target.find('.label.workflow_parameter_name').text(params.additional_data.workflow_parameter_name)
    })
    console.log('connected')
  }

  workflowParameterSearchDropdownTargetConnected(element) {
    const url = '/workflow_parameters.json?search_input={/query}'
    $(element).search({
      minCharacters: 1,
      apiSettings: {
        url: url,
        beforeSend: function(settings) {
          let previously_added_identifiers = $('#deployment_specs .workflow.preset.list input.workflow_parameter_id').map((_i, e) => $(e).val()).get().unique()
          settings.data = {
            exclude_ids: previously_added_identifiers
          }
          return settings
        }
      },
      templates: {
        standard: search.workflow_parameter_search,
      },
      onSelect(result, _response) {
        const addSelectionEvent = new CustomEvent('addSelection', {
          detail: {
            workflow_parameter_id: result.wp_ID,
            workflow_parameter_name: result.wp_Name,
          }
        })
        $(".ui.search.workflow.parameter [data-action='addSelection->nested-form#remoteAdd']")[0]?.dispatchEvent(addSelectionEvent)        
        $(".ui.search.workflow.parameter").search("set value", "")

        $(".ui.search.workflow.parameter").search("hide results")
        return false
      }
    })
  }

  workflowParameterSearchDropdownTargetDisconnected(element) {
    $(element).search('destroy')
  }

  valueDropdownTargetConnected(element) {
    $(element).dropdown({
      fullTextSearch: true, 
      allowAdditions: true,
      hideAdditions: false,
      message: {
        noResults: 'No results found.'
      },
      apiSettings: { 
        url: `/workflow_parameters/dropdown_values`, 
        cache: false ,
        beforeSend: function(settings) {
          let fieldset = $(this).closest('fieldset.nested-form-wrapper')
          let workflow_parameter_id = fieldset.find('input.workflow_parameter_id').val()
          settings.data = {
            workflow_parameter_id: workflow_parameter_id
          }
          return settings
        }
      }
    })
  }

  valueDropdownTargetDisconnected(element) {
    $(element).dropdown('destroy')
  }
}
