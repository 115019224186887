import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'modal',
    'createMultipleMetadatas',
    'checkMetadata',
    'submitButton',
  ]

  connect() {
    let c = this

    this.modalTarget.addEventListener('turbo:before-fetch-request', c.disable_submit_button)
    this.modalTarget.addEventListener('turbo:frame-load', c.enable_submit_button)

    this.modalTarget.querySelectorAll('turbo-frame[loading=lazy]').forEach(x => x.loading = 'eager')

    $(this.element).find('.checkbox.many-metadatas').checkbox()
  }

  disconnect() {
    this.modalTarget.removeEventListener('turbo:before-fetch-request', this.disable_submit_button)
    this.modalTarget.removeEventListener('turbo:turbo:frame-load', this.enable_submit_button)
  }

  add_all_packages() {
    let dropdown = this.modalTarget.querySelector('#docp_ids')
    let optionValues = [...dropdown.options].map(o => o.value)
    $(dropdown).dropdown("set selected", optionValues)
  }

  disable_submit_button = () => {
    this.submitButtonTarget.classList.add('disabled')
  }

  enable_submit_button = () => {    
    if (!this.hasSubmitButtonTarget) return
    this.submitButtonTarget.classList.remove('disabled')
  }

  close(event) {
    if (event.detail.success && !(this.hasCreateMultipleMetadatasTarget && this.createMultipleMetadatasTarget.checked)) {
      $(this.modalTarget).modal('hide')
    }
  }
}
