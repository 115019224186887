document.addEventListener("turbo:load", function() {
  if ($("#magenta_tv_qcs").length > 0) {
    $('#magenta_tv_qcs .dropdown').dropdown({ placeholder: false, fullTextSearch: true });
    
    search.init("/magenta_tv_qcs");
  }

  if ($(".magentatv.click").length > 0) {
    $(".magentatv.click").on('click', function () {
      $(".magentatv.click").css("pointer-events", "none")
      $.ajax({
        url: `/magenta_tv_qcs/${$("#asset_a_ID").val()}/magenta_tv_qc_modal`,
        success(data, _textStatus, _xhr) {
          $("body").append(data);
          $('.ui.maganta_tv_qc.modal').modal({
            onShow() {
              $(".maganta_tv_qc.modal .primary.approve.button").on('click', function() {
                $("#assets .menu .item[data-tab=events]").trigger('click')
                $("#assets #events .menu .item[data-tab=content-marker]").trigger('click')
              })
              $(".magentatv.app.button").on('click', function () {

                $.ajax({
                  url: `/magentastream`,
                  data: {
                    gracenote_id: `GN_EP031533530002` // hardcoded gracenote_id blechschaden $('.navigation')[0].dataset.recordId -> gracenote or movie id
                  },
                  success(data, _textStatus, _xhr) {
                    data.url = "http://s1.t-online.de/smooth12/smoothstream_m1/streaming/apc_serien/9221438342941331964/636842708908556986/Episode_2-Main_Movie-9221571562372063048_V2_deu_20_1300k_HD_H_264_ISMV.ism/Manifest?streamProfile=NGTV-OTT";
                    console.log("button clicked");
                    console.log("de");
                    window.location.assign('MagentaTVPlayer://');
                  
                    var ws = new WebSocket('wss://localhost:9201');

                    ws.timeout = 5; // # of retries

                    ws.onclose = function (event) {
                      console.log("onclose");
                      console.log(event); //1006
                    };
                    ws.onmessage = function (message) {
                      console.log("onmessage");
                      console.log(message);
                    };

                    ws.onerror = function(error) {
                      console.log("onerror");
                      console.log(error);
                      poll();
                    };

                    ws.onopen = function(o) { // player opened & connection established
                      console.log("onopen");
                      console.log(o);

                      // var msg = {"PlayerApi" : "GetPlayerInfo"};
                      // o.srcElement.send(JSON.stringify(msg));

                      // msg = {"PlayerAPI":"TSOpen","Params":["http://s1.t-online.de/smooth92/smoothstream_m1/streaming/odc_ard_collection/9221438342941307609/636736855702391366/SHEHH_143155_Finke_Blechschaden_deu_20_1300k_SD_H_264_ISMV.ism/Manifest?streamProfile=NGTV-OTT&sid=YOvSzFmavoJc2iETfzvBshhbtDVMaFQo&uid=imagion01&i=9&dp=0&licenseProfile=IPTV","",0]};
                      // o.srcElement.send(JSON.stringify(msg));

                      // var msg = {"PlayerApi" : "GetPlayerInfo"};
                      // o.srcElement.send(JSON.stringify(msg));

                      var msg = {"PlayerAPI":"GetPlayerInfo"}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SqmRelease","Params":[]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SetArgs","Params":["-p SQM_USER_NAME=ottHuaweiWeb"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SetArgs","Params":["-p SQM_USER_PASSWORD_EXT=e86a4778fc282c98"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SqmInit","Params":["{\"DeviceID\":\"7c2df68f-ef38-5793-a6ca-4fd2a2e3c38a\",\"DeviceType\":\"Multiscreen-Web\",\"MACAddress\":\"\",\"SubscriberID\":\"120049010000000205450212\",\"DeviceModel\":\"iMac17,1\",\"DeviceSupplier\":\"Apple Inc.\",\"DeviceSoC\":\"Intel(R) Core(TM) i7-6700K CPU @ 4.00GHz\",\"IPAddress\":\"172.23.2.6\",\"SubnetMask\":\"255.255.255.0\",\"DefaultGateway\":\"172.23.2.1\",\"ClientVersion\":\"1.3.7.32\",\"DeviceOS\":\"Version 10.14.6 (Build 18G87)\",\"Player\":\"6.30.0.51_20190701\",\"AccessType\":\"\",\"NTPServer\":\"ntp.prod.sngtv.t-online.de\",\"AuthURL\":\"https://web.magentatv.de/EDS/Login\"}","https://hcltmcfk11100.prod.sngtv.t-online.de:37020/init"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SetArgs","Params":["-p FULLSCREENMODE=TRUE"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"FullCoreError\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"PlayerRateList\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"PlayerRecord\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"PlayerVolume\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"HDSetting\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"FullRecordingTips\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"VodOptionList\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"VodBufferTimeOut\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"VodChapter\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"RecmVod\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"FisrtSettingTip\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"FisrtHNLTip\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"PlayCompleteChoice\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"PlayerMiniDetail\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"playerLeftPage\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"playerRightPage\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"HideWnd\",\"WindowName\":\"PlayerCheckPIN\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"type\":\"CLOSE_PLAYER_RECORD_PLAYBAR\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"type\":\"CLOSE_PLAYER_VOLUME_PLAYBAR\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"type\":\"CLOSE_PLAYER_HDSETTING_PLAYBAR\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"WindowName\":\"leftBar\",\"width\":200,\"height\":612,\"IsAutoHide\":\"FALSE\",\"ControlByUser\":\"True\",\"Poisition\":\"left-center\",\"innerHtml\":\"/Users/fgebhard/Library/WebTV/Default/app/src/customWindow/template/playerLeftPage.html?v=20190701&screen_width=1140&screen_height=612\",\"command\":\"ShowWnd\",\"autoFocus\":\"False\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"FullCoreError\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"PlayerRateList\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"PlayerRecord\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"PlayerVolume\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"HDSetting\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"FullRecordingTips\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"VodOptionList\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"VodBufferTimeOut\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"VodChapter\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"RecmVod\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"FisrtSettingTip\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"FisrtHNLTip\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"PlayCompleteChoice\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"PlayerMiniDetail\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"playerLeftPage\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"playerRightPage\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"HideWnd\",\"WindowName\":\"PlayerCheckPIN\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"type\":\"CLOSE_PLAYER_RECORD_PLAYBAR\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"type\":\"CLOSE_PLAYER_VOLUME_PLAYBAR\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"type\":\"CLOSE_PLAYER_HDSETTING_PLAYBAR\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"WindowName\":\"rightBar\",\"width\":200,\"height\":612,\"IsAutoHide\":\"FALSE\",\"ControlByUser\":\"True\",\"Poisition\":\"right-center\",\"innerHtml\":\"/Users/fgebhard/Library/WebTV/Default/app/src/customWindow/template/playerRightPage.html?v=20190701&screen_width=1140&screen_height=612\",\"command\":\"ShowWnd\",\"autoFocus\":\"False\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"ShowWnd\",\"WindowName\":\"TopBar\",\"width\":1140,\"height\":60,\"Poisition\":\"top-center\",\"IsAutoHide\":\"FALSE\",\"ControlByUser\":\"True\",\"innerHtml\":\"/Users/fgebhard/Library/WebTV/Default/app/src/customWindow/template/topFull.html?v=20190701&screen_width=1140&screen_height=612\",\"autoFocus\":\"True\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"WindowName\":\"TimeShiftEntry\",\"width\":210,\"height\":42,\"IsAutoHide\":\"FALSE\",\"xPosition\":0,\"yPosition\":0,\"innerHtml\":\"/Users/fgebhard/Library/WebTV/Default/app/src/customWindow/template/timeShiftEntry.html?v=20190701&screen_width=1140&screen_height=612\",\"command\":\"ShowWnd\",\"ControlByUser\":\"True\",\"autoFocus\":\"False\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"WindowName\":\"InstantRestartEntry\",\"width\":210,\"height\":42,\"IsAutoHide\":\"FALSE\",\"xPosition\":0,\"yPosition\":0,\"innerHtml\":\"/Users/fgebhard/Library/WebTV/Default/app/src/customWindow/template/instantRestartEntry.html?v=20190701&screen_width=1140&screen_height=612\",\"command\":\"ShowWnd\",\"ControlByUser\":\"True\",\"autoFocus\":\"False\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"WindowName\":\"TstvAndIrPlayOrPause\",\"width\":210,\"height\":42,\"IsAutoHide\":\"FALSE\",\"xPosition\":0,\"yPosition\":0,\"innerHtml\":\"/Users/fgebhard/Library/WebTV/Default/app/src/customWindow/template/tstvAndIrPlayOrPause.html?v=20190701&screen_width=1140&screen_height=612\",\"command\":\"ShowWnd\",\"ControlByUser\":\"True\",\"autoFocus\":\"False\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"WindowName\":\"ReturnLivetvEntry\",\"width\":210,\"height\":42,\"IsAutoHide\":\"FALSE\",\"xPosition\":0,\"yPosition\":0,\"innerHtml\":\"/Users/fgebhard/Library/WebTV/Default/app/src/customWindow/template/returnLivetvEntry.html?v=20190701&screen_width=1140&screen_height=612\",\"command\":\"ShowWnd\",\"ControlByUser\":\"True\",\"autoFocus\":\"False\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"WindowName\":\"TstvRewind\",\"width\":85,\"height\":85,\"IsAutoHide\":\"FALSE\",\"xPosition\":0,\"yPosition\":0,\"innerHtml\":\"/Users/fgebhard/Library/WebTV/Default/app/src/customWindow/template/tstvRewind.html?v=20190701&screen_width=1140&screen_height=612\",\"command\":\"ShowWnd\",\"ControlByUser\":\"True\",\"autoFocus\":\"False\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"WindowName\":\"TstvForward\",\"width\":85,\"height\":85,\"IsAutoHide\":\"FALSE\",\"xPosition\":0,\"yPosition\":0,\"innerHtml\":\"/Users/fgebhard/Library/WebTV/Default/app/src/customWindow/template/tstvForward.html?v=20190701&screen_width=1140&screen_height=612\",\"command\":\"ShowWnd\",\"ControlByUser\":\"True\",\"autoFocus\":\"False\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"type\":\"GET_TIME_OFFSET\",\"data\":{\"offset\":-409}}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"GetPlayerInfo"}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"GetPlayerInfo"}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"HideWnd\",\"WindowName\":\"TimeShiftEntry\",\"IsAutoHide\":\"FALSE\",\"ControlByUser\":\"True\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"HideWnd\",\"WindowName\":\"InstantRestartEntry\",\"IsAutoHide\":\"FALSE\",\"ControlByUser\":\"True\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"HideWnd\",\"WindowName\":\"TstvAndIrPlayOrPause\",\"IsAutoHide\":\"FALSE\",\"ControlByUser\":\"True\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"HideWnd\",\"WindowName\":\"ReturnLivetvEntry\",\"IsAutoHide\":\"FALSE\",\"ControlByUser\":\"True\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"HideWnd\",\"WindowName\":\"TstvRewind\",\"IsAutoHide\":\"FALSE\",\"ControlByUser\":\"True\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"HideWnd\",\"WindowName\":\"TstvForward\",\"IsAutoHide\":\"FALSE\",\"ControlByUser\":\"True\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"HeartBeat":"HeartBeat"}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"GetPlayerInfo"}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"GetPlayerInfo"}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"GetPlayerInfo"}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SetArgs","Params":["-p PLAYREADYHEADERDATA=LAPB:PGxhcGIgdmVyc2lvbj0iMiI+PGNvbnRlbnRObz45MjIxNDM4MzQyOTQxMzkzNTgxPC9jb250ZW50Tm8+PHByb2ZpbGU+SVBUVjwvcHJvZmlsZT48YXV0aCB0eXBlPSJzdHMiPkVDQUlCYWkyS1gxQzVacDZrZ2ExSE1qZkFBRUhxQUFBQVd6eGxidGNBQUFCYlBKeGRWd0JBQWdBQUFGczNRa1Q0eThILWZKZXY0RkhYY0dLUE5tV19jb1ZFNm0yQVF3U0FFa0JBQUFBQWdWRkFoSVRKUUJWQVFSaGJHbGhBQWxwYldGbmFXOXVNREVCQkc1aGJXVUFNbFJsYkdWcmIyMGdSR1YxZEhOamFHeGhibVFnUjIxaVNDQkFSVzUwWlhKMFlXbHVJRlJsYzNSclpXNXVkVzVuWlc1ZkFRUjFjM1JoQUFFekFRUjZkWE5oQUJORVpYVjBjMk5vWlNCVVpXeGxhMjl0SUVGSEFRWnpkSE5mWlhNQUxGTTROREFyWkRkS1dHVldlbHBEVFZOYVVrbDFXWFZRVmtGVVFWUlRhbFV4YmxJemJrTnNWSEJKV2xFOUFnRXBBb0J4QWdCUkFnWVhBZ0ZIQWc2REFnNV9BZzNjQWc1akFvNkFBZzZGQWc2QkFnNWxBZ0lGQWdJREFnSUJBZ0lIQWc1ZUFnNWRBZzViQWc0WUFnNDVBZzUyQWc0OUFnNWFBZzRlQWc0V0FnNXRBZzVyQWc1dkFnNUVBZzVEQWc0a0FnVmJBZzROQWczeEFnM3pBZzN5QWczbEFnM2tBZzVrQWc0akFvMkVBbzRNQW9JVEFvSVJBZzVtQWc0bUFnNFBBZzJJQWc0bEFvMlFBbzRPQWcyTUFvNTRBbzU1QWc0b0FnNFFBZzJZQWcyVUFnNkpBZzV5QWc0dkFnNHVBZzQzQWc0dEFnNHNBZzQ0QWc0cEFnM21BZzMxQWczb0FnM25BZzRuQWcyYUFnMmNBZzV1QWc1c0FvUE5Bb081QUFBPTwvYXV0aD48YWdlbnQ+TUFDV0VCVFY8L2FnZW50PjxyZWNvdmVyeSBhbGxvdz0iZmFsc2UiIC8+PHJlcXVlc3RJZD5nZHFYclNLRzZyN1BzZm5lRWZzZ1BUaVN5OHhvT0F3cTwvcmVxdWVzdElkPjxzZXJ2aWNlSWQ+TUFHRU5UQVRWQVBQPC9zZXJ2aWNlSWQ+PC9sYXBiPg=="]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SetArgs","Params":["-p PLAYREADYSERVER=https://licf.dmm.t-online.de/LicenseFrontend_Iptv/v1.0/LicenseAcquisition.asmx"]}
                      // msg = {"PlayerAPI":"SetArgs","Params":["-p PLAYREADYSERVER=https://mam.test/LicenseFrontend_Iptv/v1.0/LicenseAcquisition.asmx"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"HappyList\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SetArgs","Params":["-p CHANNEL_TYPE=0"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SetPosition","Params":[87]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"type\":\"PLAY_CONTENT_TYPE\",\"data\":{\"type\":\"vod\",\"buffTime\":\"10\",\"chapterTimes\":[]}}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"Close","Params":[]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SetArgs","Params":["-p PREFERE_AUD_LANG=de,deu,ger"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"FullCoreError\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"PlayerRateList\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"PlayerRecord\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"PlayerVolume\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"HDSetting\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"FullRecordingTips\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"VodOptionList\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"VodBufferTimeOut\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"VodChapter\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"RecmVod\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"FisrtSettingTip\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"FisrtHNLTip\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"PlayCompleteChoice\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"PlayerMiniDetail\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"playerLeftPage\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"playerRightPage\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"HideWnd\",\"WindowName\":\"PlayerCheckPIN\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"type\":\"CLOSE_PLAYER_RECORD_PLAYBAR\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"type\":\"CLOSE_PLAYER_VOLUME_PLAYBAR\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"type\":\"CLOSE_PLAYER_HDSETTING_PLAYBAR\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"RewindAndForward\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"PlayOrPause\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"PlayerRateList\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"HDSetting\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"type\":\"CLOSE_PLAYER_HDSETTING_PLAYBAR\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SetBlackOut","Params":["stopStreamBgPicture.bmp",0]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SetArgs","Params":["-p AUTO_PLAY_AFTER_PAUSE=TRUE"]}
                      o.srcElement.send(JSON.stringify(msg));

                      // msg = {"PlayerAPI":"TSOpen","Params":["http://s1.t-online.de/smooth36/smoothstream_m1/streaming/magentatv_talkshow/9221438342941393581/637020616150688288/Folge_01-Main_Movie-9221571562372109226_v2_deu_20_1300k_HD_H_264_ISMV.ism/Manifest?streamProfile=NGTV-OTT&sid=gdqXrSKG6r7PsfneEfsgPTiSy8xoOAwq&uid=imagion01&i=0&dp=0&licenseProfile=IPTV\",",0]}
                      // msg = {"PlayerAPI":"TSOpen","Params":["http://s1.t-online.de/smooth36/smoothstream_m1/streaming/magentatv_talkshow/9221438342941393581/637020616150688288/Folge_01-Main_Movie-9221571562372109226_v2_deu_20_1300k_HD_H_264_ISMV.ism/Manifest?streamProfile=NGTV-OTT&sid=gdqXrSKG6r7PsfneEfsgPTiSy8xoOAwq&uid=imagion01&i=0&dp=0&licenseProfile=IPTV",'',0]}
                      // o.srcElement.send(JSON.stringify(msg));
                      msg = {"PlayerAPI":"TSOpen","Params":[`${data.url}&sid=egjaR1I70tD3OnLXEdWJ8bNfywQmF94k&uid=imagion01&i=9&dp=0&licenseProfile=IPTV`,"",0]};
                      // console.log(">>>>>> URL <<<<");
                      // console.log(JSON.stringify(msg));
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SetPosition","Params":[0]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"HideWnd\",\"WindowName\":\"leftBar\",\"IsAutoHide\":\"FALSE\",\"ControlByUser\":\"True\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"HideWnd\",\"WindowName\":\"rightBar\",\"IsAutoHide\":\"FALSE\",\"ControlByUser\":\"True\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"WindowName\":\"HPPCtrlBar\",\"command\":\"ShowWnd\",\"Poisition\":\"Bottom\",\"width\":1140,\"IsAutoHide\":\"FALSE\",\"ControlByUser\":\"True\",\"height\":80,\"autoFocus\":\"False\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"WindowName\":\"TopBar\",\"command\":\"ShowWnd\",\"Poisition\":\"top-center\",\"IsAutoHide\":\"FALSE\",\"ControlByUser\":\"True\",\"width\":1140,\"height\":60,\"autoFocus\":\"True\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"PlayOrPause\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"WindowName\":\"RewindAndForward\",\"width\":670,\"height\":150,\"IsAutoHide\":true,\"Poisition\":\"Center\",\"innerHtml\":\"/Users/fgebhard/Library/WebTV/Default/app/src/customWindow/template/rewindAndForward.html?v=20190701&screen_width=1140&screen_height=612#Idle\",\"command\":\"ShowWnd\",\"ControlByUser\":\"True\",\"autoFocus\":\"False\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"type\":\"GET_CURRENT_POSITION\"}"]}
                      o.srcElement.send(JSON.stringify(msg));

                      // msg = {"PlayerAPI":"SetMute","Params":[0]}
                      // o.srcElement.send(JSON.stringify(msg));
                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"command\":\"CloseWnd\",\"WindowName\":\"PlayOrPause\"}"]}
                      o.srcElement.send(JSON.stringify(msg));
                    
                      msg = {"PlayerAPI":"SendInternalJson","Params":["{\"WindowName\":\"TopBar\",\"type\":\"INIT_TOPBAR\",\"data\":{\"isVodPlay\":true,\"name\":\"Wohnungsnot\",\"isHideLogo\":true}}"]}


                    };
                    
                    async function poll() {
                      await sleep(2000);
                      
                      var old_ws = ws;
                      if (ws.timeout >= 0) {
                        console.log(`tries left: ${ws.timeout}`);
                        ws = new WebSocket('wss://localhost:9201');
                        ws.onopen = old_ws.onopen;
                        ws.onclose = old_ws.onclose;
                        ws.onerror = old_ws.onerror;
                        ws.onmessage = old_ws.onmessage;
                        ws.timeout = old_ws.timeout - 1;
                      }
                    }

                    function sleep(ms) {
                      return new Promise(resolve => setTimeout(resolve, ms));
                    }

                  },
                  error(_jqXHR, _textStatus, errorThrown) {
                    console.log(errorThrown);
                  }
                });
                return;
              }); 
            },
            onHidden() {
              $(".magentatv.click").css("pointer-events", "auto")
              $('.ui.maganta_tv_qc.modal').remove();
            }
          }).modal('show');
        },
        error(_jqXHR, _textStatus, errorThrown) {
          return console.log(errorThrown);
        }
      });
    });
  }
  
  $(".magentatv.list.item").on('click', function() {
    console.log("click")
  })
});
