// By default, this pack is loaded for server-side rendering.
// It must expose react_ujs as `ReactRailsUJS` and prepare a require context.

import components from "./components/**/*.jsx"

let componentsContext = {}
components.forEach((component, i) => {
  let cleanName = component.filename.replace("./components/", "").replace(".jsx", "")
  componentsContext[cleanName] = component.module.default
})

const ReactRailsUJS = require("react_ujs")
window.ReactRailsUJS = ReactRailsUJS

ReactRailsUJS.getConstructor = (name) => {
  return componentsContext[name]
}

ReactRailsUJS.handleEvent('turbo:load', ReactRailsUJS.handleMount, false);
ReactRailsUJS.handleEvent('turbo:frame-load', ReactRailsUJS.handleMount, false);
ReactRailsUJS.handleEvent('turbo:before-render', ReactRailsUJS.handleUnmount, false);
