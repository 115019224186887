import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static outlets = [
    'remote-search',
    'nested-fields',
    'turbo-frame',
  ]

  static targets = [
    'accessTypeSearch',
    'typeSelectionDropdown',
    'receiverTypeSelectionDropdown',
    'scopeTypeSelectionDropdown',
    'accessTypeSearch',
    'accessReceiverSearch',
    'accessScopeSearch',
    'submitButton',
  ]

  static values = {
    mainScopeType: String,
    mainScopeId: Number,
  }

  nestedFieldsOutletConnected(outlet, element) {
    outlet.checkFields(outlet.identifierValue)
  }

  accessTypeChanged({ detail: { value } }) {
    const c = this
    const access_type_search = c.remoteSearchOutlets.find(outlet => outlet.element == c.accessTypeSearchTarget)
    const access_scope_search = c.remoteSearchOutlets.find(outlet => outlet.element == c.accessScopeSearchTarget)

    if (value.length > 0) {
      access_type_search.setQueryParam('type', value)
      access_scope_search.setQueryParam('source', value)
    } else {
      access_type_search.unsetQueryParam('type')
      access_scope_search.unsetQueryParam('source')

    }
  }

  accessScopeTypeChanged({ detail: { value } }) {
    const c = this
    const access_scope_search = c.remoteSearchOutlets.find(outlet => outlet.element == c.accessScopeSearchTarget)
    if (value.length > 0) {
      access_scope_search.setQueryParam('type', value)
      access_scope_search.setQueryParam('association_name', 'contact') // hardcoded Asset -> Contact
    } else {
      access_scope_search.unsetQueryParam('type')
      access_scope_search.unsetQueryParam('association_name')
    }
  }

  accessReceiverTypeChanged({ detail: { value } }) {
    const c = this
    const access_receiver_search = c.remoteSearchOutlets.find(outlet => outlet.element == c.accessReceiverSearchTarget)
    
    if (value.length > 0) {
      access_receiver_search.setQueryParam('type', value)
    } else {
      access_receiver_search.unsetQueryParam('type')
    }
  }

  addAccessType({ detail: { result: { id, title, type, description }}}) {
    this.addType(type, id, title, description)
  }
  
  addAccessScope({ detail: { result: { id, title, type, description, association_name }}}) {    
    this.addScope(type, id, title, description, association_name) 
  }

  addAccessReceiver({ detail: { result: { id, title, type, description }}}) {
    this.addReceiver(type, id, title, description)
  }

  addType(type, id, _title, description) {
    const c = this
    const access_type_list = c.nestedFieldsOutlets.find(o => o.element.dataset.nestedFieldsIdentifierValue == 'access_type')
    access_type_list.remoteAdd({}, (template) => {
      template.querySelector('fieldset').dataset.model = type
      template.querySelector('fieldset').dataset.model_id = id

      $(template).find('[id$=accessed_type]').val(type)
      $(template).find('[id$=accessed_id]').val(id) 
      $(template).find('.accessed_type.display').html(type)
      $(template).find('.accessed_id.display').html(id || 'ALL')
      $(template).find('.accessed_description.display').html(description || '-') 
      return template
    })
  }

  addScope(type, id, title, description, association_name) {
    const c = this
    const scope_list = c.nestedFieldsOutlets.find(o => o.element.dataset.nestedFieldsIdentifierValue == 'access_scope')

    scope_list.remoteAdd({}, (template) => {
      template.querySelector('fieldset').dataset.model = type
      template.querySelector('fieldset').dataset.model_id = id
      template.querySelector('fieldset').dataset.other_model_identifier = association_name

      $(template).find('[id$=scope_type]').val(type)
      $(template).find('[id$=association_name]').val(association_name)
      $(template).find('[id$=scope_id]').val(id) 
      $(template).find('.scope_type.display').html(type)
      $(template).find('.scope_id.display').html(title)
      $(template).find('.scope_description.display').html(description || '-') 

      return template
    })
  }

  accessScopeCountChanged({ detail: { _count } }) {
    const c = this
    const scope_list = c.nestedFieldsOutlets.find(o => o.element.dataset.nestedFieldsIdentifierValue == 'access_scope')

    this.scopeTypeSelectionDropdownTarget.querySelectorAll('option[value]').forEach(o => {
      const type = o.value
      const number_of_fields = scope_list.visible_fields.filter((e, _i) => e.querySelectorAll('[id$=scope_type]')[0]?.value == type ).length
     
      if (number_of_fields >= (parseInt(o.dataset.limit) || Infinity)) {
        c.disableScopeType(type)
      } else {
        c.enableScopeType(type)
      }
    })
  }
  
  disableScopeType(scope_type) {
    if (scope_type.length == 0) return
    const scope_type_dropdown = this.scopeTypeSelectionDropdownTarget
    $(scope_type_dropdown).find(`option[value=${scope_type}]`).attr('disabled', true)
    
    const is_initialized_dropdown = $(scope_type_dropdown).parent('.dropdown').data('module-dropdown')    
    if (is_initialized_dropdown) $(scope_type_dropdown).dropdown('get item', scope_type).addClass('disabled')    
    if (scope_type_dropdown.value == scope_type) {
      if (is_initialized_dropdown) {
        $(scope_type_dropdown).dropdown('clear')
      } else {
        scope_type_dropdown.value = ''
        $(scope_type_dropdown).trigger('change')
      }
    }
  }

  enableScopeType(scope_type) {
    if (scope_type.length == 0) return
    $(this.scopeTypeSelectionDropdownTarget).find(`option[value=${scope_type}]`).removeAttr('disabled')
    const is_initialized_dropdown = $(this.scopeTypeSelectionDropdownTarget).parent('.dropdown').data('module-dropdown')
    if (is_initialized_dropdown) $(this.scopeTypeSelectionDropdownTarget).dropdown('get item', scope_type).removeClass('disabled')
  }

  addReceiver(type, id, title, description) {
    const c = this
    const receiver_list = c.nestedFieldsOutlets.find(o => o.element.dataset.nestedFieldsIdentifierValue == 'access_receiver')
    receiver_list.remoteAdd({}, (template) => {
      template.querySelector('fieldset').dataset.model = type
      template.querySelector('fieldset').dataset.model_id = id
      
      $(template).find('[id$=receiver_type]').val(type)
      $(template).find('.receiver_type.display').html(type)
      $(template).find('[id$=receiver_id]').val(id) 
      $(template).find('.receiver_id.display').html(id)
      $(template).find('.receiver_description.display').html(description) 

      return template
    })
  }

  addAllOfType() {
    const type = this.typeSelectionDropdownTarget.value
    this.addType(type, null, 'ALL', null)
  }

  close(event) {
    if (event.detail.success) {
      this.turboFrameOutlets[0]?.reload(true)
      $(this.element).modal('hide')
    }
  }
  
}
