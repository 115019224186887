import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    interval: { type: Number, default: 2000 },
    src: { type: String }
  }

  connect() {
    this.startPolling()
  }

  disconnect() {
    this.stopPolling()
  }

  startPolling() {
    this.interval = setInterval(() => {
      this.poll()
    }, this.intervalValue)
  }

  async poll() {
    const c = this
    try {
      const response = await fetch(this.srcValue, {
        headers: { 'Accept': 'text/vnd.turbo-stream.html' }
      })
      if (response.ok && response.status !== 204) {
        c.stopPolling()
        const html = await response.text()
        Turbo.renderStreamMessage(html)
      }
    } catch (error) {
      console.error('Polling Request Error:', error)
    }
  }

  stopPolling() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }
}