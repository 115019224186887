document.addEventListener("turbo:load", function() {
  if ($("#user_profile_contacts").length > 0) {
   return user_profile_contacts.init();
 }
});

var user_profile_contacts = {
  init() {
    return search.init("/user_profile_contacts");
  },

  init_index_body() {
    return search.add_pagination_to_actionbar();
  }
};

window.user_profile_contacts = user_profile_contacts;
