document.addEventListener("turbo:load", function () {
  if ($("#orders.index").length > 0) {
    orders.index();
  }

  if ($("#orders.show").length > 0) {
    orders.show();
  }
});

var order_quicklook_request = null
var quicklook_loading_indicator = null

var orders = {

  index() {
    search.init("/orders").then(function(_value) {
      calendar.datepicker(".due_date_start", {
        position: 'top left',
        lastResort: 'top left'
      })
      calendar.datepicker(".due_date_end", {
        position: 'top right',
        lastResort: 'top right'
      })
  
      calendar.datepicker(".charged_date_start", {
        position: 'top left',
        lastResort: 'top left'
      })
      calendar.datepicker(".charged_date_end", {
        position: 'top right',
        lastResort: 'top right'
      })
    })    
  },

  init_index_body() {
    general.quicklook_icon('#orders .cover.quicklook')

    $('#orders .quicklook').on("click", function () {
      quicklook_loading_indicator = $('<div class="ui active ajax dimmer"><div class="ui loader"></div></div>').appendTo(this)
      $(this).find(".quicklook-icon").remove()
      orders.quicklook($(this).data('model-id'))
    })
  },

  quicklook(model_id) {
    if (order_quicklook_request) {
      order_quicklook_request.abort()
    }
    order_quicklook_request = $.ajax({
      url: `/orders/${model_id}/quicklook`,
      cache: false,
      data: {
        modal: true
      }
    }).done(function (data, _textStatus, _xhr) {
      var quicklook_modal = $(data).appendTo('body')
      quicklook_modal.modal({
        duration: 200,
        onShow() {
          orders.show()
          context_menu.init()
          quicklook_loading_indicator.remove()
        },
        onHidden() {
          quicklook_modal.remove()
        }
      }).modal('show')

    }).fail(function (_jqXHR, _textStatus, errorThrown) {
      if (errorThrown != 'abort') {
        console.log(errorThrown)
        quicklook_loading_indicator.remove()
      }
    })
  },

  edit() {
    orders.load_asset_requests()

    orders.init_dropdowns()    

    calendar.datepicker("#orders")
    calendar.yearpicker()

    general.hover_link("titles")

    orders.update_due_date_in_asset_requests()

    orders.gen_arq()
  },

  show() {
    $("#orders.show .menu .item").tab()
    //$('#orders.show .ui.checkbox').checkbox();
  },

  index_menu() {
    $(".actions .set.order.value").on('click', function() {
      orders.init_set_value_modal()
    })
  },
  
  init_dropdowns() {
    $('#orders .dropdown').not('.remote').dropdown({
      placeholder: false,
      fullTextSearch: true
    });
    $('.ui.pointing.secondary.menu .item').tab()

    $.each($('.language-search'), (_i, item) =>
      $(item).dropdown({
        apiSettings: {
          cache: false,
          url: '/languagecode?q={/query}'
        },
        templates: {
          menu: dropdownTemplates.language_search_menu
        }
      }).dropdown('set selected', $(item).val())
    );

    $('.gen-fulfillment').dropdown({
      apiSettings: {
        url: '/contacts/fulfillment_partner?q={/query}',
        data: {
          c_ID: $("#order_o_c_ContentPartnerID").val()
        },
        beforeSend(settings) {
          settings.data.c_ID = $("#order_o_c_ContentPartnerID").val();
          return settings;
        }
      }
    });

    orders.init_contact_dropdowns()

    $('#order_o_dc_ID').dropdown({
      apiSettings: {
        cache: false,
        url: '/delivery_orders/channels?q={/query}',
        data: {
          all: true
        },
      },
      templates: {
        menu: dropdownTemplates.search_menu
      },
    }).dropdown('set selected', $('#order_o_dc_ID').val())

    $('#order_o_t_ID').dropdown({
      minCharacters: 2,
      apiSettings: {
        cache: false,
        action: 'get titles',
        beforeSend(settings) {
          const value = $(this).find("select").data('value')
          const name = $(this).find("select").data('name')

          if ($(this).find("select").attr('params')) {
            settings.onResponse = function (JsonResponse) {
              const response = {
                success: true,
                results: []
              }
              $.each(JsonResponse, function (_index, item) {
                if (typeof item === 'object') {
                  return response.results.push({
                    value: item[value],
                    name: item[name]
                  })
                }
              })
              return response
            }
            $.extend(settings.urlData, $(this).find("select").attr('params'))
          }
          return settings
        }
      },
    }).dropdown('set selected', $('#order_o_t_ID').val())

    $('#client_pricelist').dropdown({
      allowReselection: true,
      apiSettings: {
        cache: false,
        url: '/orders/client_pricelist?q={/query}',
        data: {
          c_ID: $("#order_o_c_ContentPartnerID").val(),
          cp_ID: $("#order_o_c_FulfillmentPartnerID").val()
        },
        beforeSend(settings) {
          settings.data.c_ID = $("#order_o_c_ContentPartnerID").val();
          settings.data.cp_ID = $("#order_o_c_FulfillmentPartnerID").val();
          return settings;
        }
      },
      templates: {
        menu: dropdownTemplates.pricelist_search_menu
      },
      onChange(_value, _text, choice) {
        $("#order_o_CPExpectedMaterialCost").val(choice.data("price"));
        return $("#order_o_CPWillChargeMaterialCosts").prop("checked", choice.data("active"));
      }
    });
  },

  init_contact_dropdowns(without_prefix = false) {
    let prefix = "order_"
    if (without_prefix) { prefix = "" }

    $(`#${prefix}o_c_ContentPartnerID`).dropdown({
      apiSettings: {
        cache: false,
        url: '/contacts/contentpartners?q={/query}',
        data: {
          c_ID: $(`#${prefix}o_c_ClientContactID`).val()
        },
        beforeSend(settings) {
          settings.data.c_ID = $(`#${prefix}o_c_ClientContactID`).val();
          return settings;
        }
      }
    }).dropdown('set selected', $(`#${prefix}o_c_ContentPartnerID`).val());

    $(`#${prefix}o_c_FulfillmentPartnerID`).dropdown({
      apiSettings: {
        cache: false,
        url: '/contacts/fulfillment_partner?q={/query}',
        data: {
          c_ID: $(`#${prefix}o_c_ContentPartnerID`).val()
        },
        beforeSend(settings) {
          settings.data.c_ID = $(`#${prefix}o_c_ContentPartnerID`).val();
          return settings;
        }
      },
      templates: {
        menu: dropdownTemplates.search_menu
      }
    }).dropdown('set selected', $(`#${prefix}o_c_FulfillmentPartnerID`).val());
  },

  gen_arq() {
    $(".generate.arq").on('click', function () {
      $(".generate.arq").addClass("loading")
      const o_ID = $(this).data('order-id')
      $.ajax({
          url: `/orders/${o_ID}/generate_asset_requests`,
          type: "GET",
          data: {
            order_o_Status: $('#order_o_Status').val()
          },
        })
        .done(function (data) {
          $(".generate.arq").removeClass("loading")
          $("body").append(data)

          $('.ui.generate-asset-requests.modal').modal({
            autofocus: false,
            onShow() {
              asset_requests.gen_modal_from_init()
              asset_requests.gen_form_submit()
            },
            onHidden() {
              return $(this).remove();
            }
          }).modal("show")
        })
        .fail(() => console.log("error"))
    })
  },
  
  context_menu() {
    $(".item.order.set-values").on('click', function () {
      orders.init_set_value_modal([$(this).attr("data-target-id")])
    })
  },

  selected_order_ids(order_id) {
    if ($(".select.index.elements").data("active")) {
      return index_select.selected_ids()
    } else {
      return order_id
    }
  },

  init_set_value_modal(order_id = $(".select.index.elements").data("selected")) {
    $.ajax({
      url: "/orders/set_value_modal",
    }).done(function (data) {
      $("body").append(data);
      $('.set.order.value.modal').modal({
        autofocus: false,
        onShow() {
          calendar.datepicker('.set.order.value.modal')
          $('.set.order.value.modal .dropdown').not(".remote").dropdown()
          api.init()
          orders.init_contact_dropdowns(true)

          const order_count = order_id.length

          if (order_count > 1) {
            $('.set.order.value.modal .orders-search-count').text(order_count)
          } else {
            $('.set.order.value.modal .warning.message').hide()
          }

          let button = $('.set.order.value.modal .ui.set-value.button')
          button.on("click", function () {
            button.addClass("loading");
            $.ajax({
              url: "/orders/set_value",
              type: "POST",
              dataType: "json",
              data: {
                model_ids: orders.selected_order_ids(order_id),
                orders_params: general.collect_parameter('.set.order.value.modal')
              }
            }).done(function (_data) {
              button.removeClass("loading");
              if ($(".select.index.elements").data("active")) {
                index_select.end_selection()
              }
              $('.set.order.value.modal').modal('hide')
              $(".start-search .primary.button").trigger("click")
            }).fail(() => button.removeClass('loading').removeClass('loading green').addClass('red').text("Error"));
          });
        },
        onHidden() {
          $('.set.order.value.modal').remove();
        }
      }).modal("show");

    }).fail(() => console.log("error"));
  },

  load_asset_requests() {
    $.ajax({
      url: `/orders/${$(".generate.arq").data("order-id")}/asset_requests`,
    }).done(function (data, _textStatus, _xhr) { 
      $(".asset_requests").html(data)

      Array.from($('#orders .remote.asset_request.card')).map((asset_request_card) => general.fetch_view(asset_request_card))

      $("#arq-count").text($(".remote.asset_request.card").length)

      if ($(".remote.asset_request.card").length > 0) {
        $("#asset_requests_update_options").show()
      } else {
        $("#asset_requests_update_options").hide()
      }
    })
  },

  update_due_date_in_asset_requests() {

    $("#update-due-date-button").on("click", function() {
      orders.due_date_modal()
    })

    let namespace_field = $(`.duedate.field`)
    let namespace_field_label = namespace_field.find('label')
    namespace_field
    .on("mouseenter", function() {
      if (!(namespace_field_label.find('a').length > 0)) {
        if ($(this).find("input").val() != "" && $("#arq-count").text() != "0") {
          namespace_field_label.append(`<span id="update-due-date" class='ui mini black label' style="margin-left: 10px; position: relative; top: -3px;">Update in Asset Requests</span>`)

          $("#update-due-date").on("click", function() {
            orders.due_date_modal()
          })
        }
      }
    })
    .on("mouseleave", function() {
      namespace_field_label.find('.label').remove()
    })
  },

  due_date_modal() {
    $.get(`/orders/${$(".generate.arq").data("order-id")}/due_date_modal`).done(function(data) {
      var modal = $(data).appendTo('body')
      modal.modal({
        onShow() {
          let date_field = ".due_date.modal .set_due_date"
          calendar.datepicker(date_field, {})
          $(".preset.field .button").on("click", function() {
            //console.log($(this).data("date"))
            $(date_field).find(".ui.calendar").calendar("set date", $(this).data("date"));
          })

          $("#save_due_date").on("click", function() {
            $("#save_due_date").addClass("loading")
            $.post({ url: `/orders/${$(".generate.arq").data("order-id")}/asset_requests_due_date`, data: { date: $("#set_due_date").val() } }).done(function() {
              modal.modal("hide")
              orders.load_asset_requests()
            }).fail(function(xhr, _text, _errorThrown) {
              $("#save_due_date").removeClass('loading')
              Turbo.renderStreamMessage(xhr.statusText)
            })
          })
        },
        onHidden() {
          modal.remove()
        }
      }).modal('show')
    })
  },

  init_titles_modal(modal_target) {
    let modal = $(modal_target)
    modal.modal({
      observeChanges: true,
      autofocus: false,
      onShow() {

      },
      onHidden() {
        modal.remove()
      },
      onApprove() {
        modal.find(".ok.button").addClass("loading")

        $.post(`/orders/${$(".generate.arq").data("order-id")}/asset_requests_titles`).done(function() {
          modal.modal("hide")
          orders.load_asset_requests()
        }).fail(function(xhr, _text, _errorThrown) {
          modal.find('.ok.button').removeClass('loading')
          Turbo.renderStreamMessage(xhr.statusText)
        })

        return false
      }
    }).modal("show")
  },

  init_status_modal(modal_target) {
    let modal = $(modal_target)
    modal.modal({
      observeChanges: true,
      autofocus: false,
      onShow() {

      },
      onHidden() {
        modal.remove()
      },
      onApprove() {
        modal.find(".ok.button").addClass("loading")

        $.post(`/orders/${$(".generate.arq").data("order-id")}/asset_requests_status`).done(function() {
          modal.modal("hide")
          orders.load_asset_requests()
        }).fail(function(xhr, _text, _errorThrown) {
          modal.find('.ok.button').removeClass('loading')
          Turbo.renderStreamMessage(xhr.statusText)
        })

        return false
      }
    }).modal("show")
  }
};

window.orders = orders;
