document.addEventListener("turbo:load", function() {
  
  general.common.baskets = function() {
    baskets.menu_button()
    baskets.index_menu()  
    if ($("#baskets.index").length > 0) {
      baskets.init();
    }

    if ($("#baskets.show").length > 0) {
      $("#baskets.show .basket.item .clicker").on('click', function() {
        baskets.open_quickmenu(false, "", "", $(this).parent().attr("data-basket-id"), $(this).find(".main-title").text());
      });
    }
  }
  general.common.baskets()

});

var baskets = {
  init() {
    search.init("/baskets");
  },

  init_form(target) {   
    target.find('.dropdown').not('.remote,.custom').dropdown()
    target.find('.checkbox').not('.custom').checkbox({ fireOnInit: true })

    let url = '/assets/search_dropdown?q={/query}'
    const basket_asset_search_dropdown = target.find('.search.basket-assets').search({
      minCharacters: 0,
      apiSettings: {
        url,
        beforeSend(settings) {
          settings.data.exclude_ids = target.find('.basket_item [data-asset-id]').toArray().map(x => x.dataset.assetId)
          settings.data.asset_ids = target.find('#original-basket-item-list .basket_item [data-asset-id]').toArray().map(x => x.dataset.assetId)
          let formData = new FormData(target.find('form#basket_asset_search_options')[0])
          let search_options = Object.fromEntries(Array.from(formData.keys()).map(key => [key.replace(/\[\]$/, ''), formData.getAll(key).length > 1 ? formData.getAll(key) : formData.get(key)]))
          settings.data = {...settings.data, ...search_options}
          return settings
        }
      },
      templates: {
        standard: search.asset_search
      },
      onSelect(result, _response) {
        search.add_assets_from_dropdown(this, result)
      }
    })

    baskets.nested_fields(target)
  },

  init_index_body() {
    $("#baskets.index .basket.item .clicker").on('click', function() {
      baskets.open_quickmenu(false, "", "", $(this).parent().attr("data-basket-id"), $(this).find(".main-title").text());
    });
  },

  nested_fields(target) {
    target.off("fields_added.nested_form_fields.nested_basket_basket_items")
    target.on("fields_added.nested_form_fields.nested_basket_basket_items", function(event, param) {
      switch (param.object_class) {
        case "basket_item":
          return baskets.set_basket_item_data(event.target, param.additional_data)
        default:
          return console.log("Fields were successfully added, callback not handled.")
      }
    }) 
  },

  set_basket_item_data(target, additional_data) {
    let id = additional_data.item.id
    $(target).find('.target-id').val(id)
    let turbo_frame = $(target).find('turbo-frame')[0]
    turbo_frame.id += `_${id}`
    turbo_frame.src = `/assets/${id}/index_card?include_link=false&modal=true&turbo_frame_id=card_basket_item_${id}&type=list`
  },

  items(el) {
    let item_ids = []
    if ($(el).attr("data-target-id") == "") {
      item_ids = index_select.selected_ids()
    } else {
      if ($(el).data("target-id").toString().includes(',')) {
        item_ids = $(el).data("target-id").split(",")
      } else {
        item_ids = [$(el).data("target-id")]
      }
    }

    return item_ids
  },

  quickmenu_actions() {
    $("#quickmenu .new.basket").on('click', function() {

      $("#quickmenu .new.basket").addClass("loading")

      const item_ids = baskets.items(this)

      $.ajax({
        url: "/baskets/name",
        type: "POST",
        data: {
          target_type: "Asset",
          target_ids: item_ids
        },
        success(data, _textStatus, _xhr) {
          $("#quickmenu .quickmenu.body").html(data);
          $("#quickmenu .basket.name").focus();
          document.querySelector("#quickmenu .basket.name").addEventListener('keyup', ({key}) => {
            if (key === "Enter") $("#quickmenu .create.basket").trigger('click')
          })

          baskets.back_button()

          $("#quickmenu .create.basket").on('click', function() {
            $("#quickmenu .create.basket").addClass("loading")
            $.ajax({
              url: "/baskets/add",
              type: "POST",
              data: {
                target_type: "Asset",
                target_ids: item_ids,
                description: $("#quickmenu .basket.name").val()
              },
              success(data, _textStatus, _xhr) {
                index_select.end_selection()
                $("#quickmenu .quickmenu.header .ui.header span").text($("#quickmenu .basket.name").val());
                baskets.load_basket_items(data["basket_id"]);
              },
              error(_jqXHR, _textStatus, errorThrown) {
                console.log(errorThrown);
                $("#quickmenu .create.basket").removeClass("loading")
                $("#quickmenu .quickmenu.body").html("<br><br><span class='center'><div class='ui red basic label'>Loading Error</div></span>");
              }
            });
          })
        },
        error(_jqXHR, _textStatus, errorThrown) {
          console.log(errorThrown);
          $("#quickmenu .new.basket").removeClass("loading")
          $("#quickmenu .quickmenu.body").html("<br><br><span class='center'><div class='ui red basic label'>Loading Error</div></span>");
        }
      });

    })

    if ($("#quickmenu .basket.item.selection").length > 0) {
      $("#quickmenu .basket.item .clicker").on('click', function() {
        const item_ids = baskets.items(this)

        const basket_id = $(this).parent().attr("data-basket-id")
        const main_title = $(this).find(".main-title").text()

        $.ajax({
          url: "/baskets/" + basket_id + "/add_items",
          type: "POST",
          data: {
            target_type: "Asset",
            target_ids: item_ids
          },
          success(_data, _textStatus, _xhr) {
            index_select.end_selection()
            $("#quickmenu .quickmenu.header .ui.header span").text(main_title);
            baskets.load_basket_items(basket_id);
          },
          error(_jqXHR, _textStatus, errorThrown) {
            console.log(errorThrown);
            $("#quickmenu .quickmenu.body").html("<br><br><span class='center'><div class='ui red basic label'>Loading Error</div></span>");
          }
        });
      });
    } else {
      $("#quickmenu .basket.item .clicker").on('click', function() {
        $("#quickmenu .quickmenu.header .ui.header span").text($(this).find(".main-title").text());
        baskets.load_basket_items($(this).parent().attr("data-basket-id"));
      });
    }
    
  },

  current_basket_id() {
    return $("#quickmenu .rename.basket").attr("data-target-id")
  },

  add_items(basket_id, target_type, item_ids, local_element) {
    if (local_element) {
      $(local_element).find('.add_nested_fields_link').trigger('click', [{ item: { id: item_ids } }])
    } else {
      let url = "/baskets/" + basket_id + "/add_items";
      $.ajax({
        url,
        type: "POST",
        data: {
          target_type: target_type,
          target_ids: item_ids,
        },
        success(_data, _textStatus, _xhr) {
          baskets.load_basket_items(basket_id);
        },
        error(_jqXHR, _textStatus, errorThrown) {
          console.log(errorThrown);
          $("#quickmenu .create.basket").removeClass("loading")
          $("#quickmenu .quickmenu.body").html("<br><br><span class='center'><div class='ui red basic label'>Loading Error</div></span>");
        }
      })
    }
  },

  menu_button() {
    $(".main.menu .basket.item").off('click')
    $(".main.menu .basket.item").on('click', function () {
      baskets.open_quickmenu()
    });
  },

  open_quickmenu(selection_only = false, target_model = "", target_id = "", basket_id = null, basket_name = null) {
    $('body').dimmer({
      dimmerName: "quickmenu",
      onShow() {
        if (selection_only) {
          $(".ui.dimmer.quickmenu").append("<div id='quickmenu' class='open animate__animated animate__slideInRight'><div class='quickmenu header'></div><div class='quickmenu body'><span class='center'><br><br><br><div class='fa-3x'><i class='far fa-circle-notch fa-spin'></i></div></span></div></div>");
          $("#quickmenu .quickmenu.header").append("<h3 class='ui header'><i class='fas fa-shopping-basket fa-fw fa-sm icon' aria-hidden='true'></i> <span>Add <span class='basket-items-count'></span>to Basket</span></h3>");
          baskets.load_selection(target_model, target_id);
        } else if (basket_id != null) {
          $(".ui.dimmer.quickmenu").append("<div id='quickmenu' class='open animate__animated animate__slideInRight'><div class='quickmenu header'></div><div class='quickmenu body'><span class='center'><br><br><br><div class='fa-3x'><i class='far fa-circle-notch fa-spin'></i></div></span></div></div>");
          $("#quickmenu .quickmenu.header").append("<h3 class='ui header'><i class='fas fa-shopping-basket fa-fw fa-sm icon' aria-hidden='true'></i> <span>" + basket_name + "</span></h3>");
          baskets.load_basket_items(basket_id);
        } else {
          $(".ui.dimmer.quickmenu").append("<div id='quickmenu' class='open animate__animated animate__slideInRight'><div class='quickmenu header'></div><div class='quickmenu body'><span class='center'><br><br><br><div class='fa-3x'><i class='far fa-circle-notch fa-spin'></i></div></span></div></div>");
          $("#quickmenu .quickmenu.header").append("<h3 class='ui header'><i class='fas fa-shopping-basket fa-fw fa-sm icon' aria-hidden='true'></i> <span>Baskets</span></h3>");
          baskets.load_index();
        }
      },
      onHide() {
        $("#quickmenu").removeClass("animate__animated animate__slideInRight")
        $("#quickmenu").addClass("animate__animated animate__slideOutRight")
        setTimeout(function() {
          $(".ui.dimmer.quickmenu").remove();
          $("body").removeClass("dimmable dimmed")
        }, 800)
      }
    }).dimmer('show')
  },

  load_index(back = false) {
    return $.ajax({
      url: "/baskets/quickmenu",
      success(data, _textStatus, _xhr) {
        $("#quickmenu .quickmenu.body").html(data);
        if (back) {
          $("#quickmenu .quickmenu.body .segment").removeClass("animate__animated animate__fadeInLeft fastest")
          $("#quickmenu .quickmenu.body .segment").addClass("animate__animated animate__fadeInLeft fastest")
        }
        
        $("#quickmenu .quickmenu.header .ui.header span").text("Baskets");
        context_menu.init_menu();
        baskets.quickmenu_actions();

        setTimeout(function() {
          $("#quickmenu .quickmenu.body .segment").removeClass("animate__animated animate__fadeInLeft fastest")
        }, 300)
      },
      error(_jqXHR, _textStatus, errorThrown) {
        console.log(errorThrown);
        $("#quickmenu .quickmenu.body").html("<br><br><span class='center'><div class='ui red basic label'>Loading Error</div></span>");
      }
    });
  },

  load_selection(target_model, target_id) {
    $.ajax({
      url: "/baskets/quickmenu?type=selection&target_model=" + target_model + "&target_id=" + target_id,
      success(data, _textStatus, _xhr) {
        $("#quickmenu .quickmenu.body").html(data);
        baskets.quickmenu_actions();

        const item_ids = baskets.items($("#quickmenu .new.basket"))
        $("#quickmenu .quickmenu .basket-items-count").html(" " + item_ids.length + " Items ")
      },
      error(_jqXHR, _textStatus, errorThrown) {
        console.log(errorThrown);
        $("#quickmenu .quickmenu.body").html("<br><br><span class='center'><div class='ui red basic label'>Loading Error</div></span>");
      }
    });
  },

  back_button() {
    const back_button = $("#quickmenu .quickmenu.body .for-header");
    $("#quickmenu .quickmenu.header").append(back_button);
    $("#quickmenu .quickmenu.header .back-button").on('click', function() {
      $("#quickmenu .quickmenu.body").removeClass("animate__animated animate__fadeInRight fastest")
      $("#quickmenu .quickmenu.header .ui.header span").text("Baskets");
      baskets.load_index(true)
      $("#quickmenu .quickmenu.header .back-button").remove();
      $("#quickmenu .quickmenu.header .edit-button").remove();
    });
  },

  load_basket_items(basket_id) {
    $.ajax({
      url: `/baskets/${basket_id}/items`,
      success(data, _textStatus, _xhr) {
        $("#quickmenu .quickmenu.body").html(data);
        $("#quickmenu .quickmenu.body").addClass("animate__animated animate__fadeInRight fastest")
        context_menu.init_menu();
        
        baskets.back_button()        

        $("#quickmenu .edit-button.for-header").on('click', function() {
          if ($("#quickmenu .segment.rename").css('display') !== 'none') {
            $("#quickmenu .segment.rename").removeClass("animate__animated animate__slideInUp fastest")
            $("#quickmenu .segment.rename").hide()
          } else {
            $("#quickmenu .segment.rename").addClass("animate__animated animate__slideInUp fastest")
            $("#quickmenu .segment.rename").show()
          }
        })

        $("#quickmenu .rename.basket").on('click', function() {
          baskets.rename_basket(basket_id, $("#quickmenu .rename.basket").parent().find(".basket.name").val())
        })

        $("#quickmenu .remove.basket.item").on('click', function() {
          baskets.remove_basket_items(basket_id, $(this).attr("data-item-id"))
        })

        $("#quickmenu .send.to.quicklink").on('click', function() {
          $('body').find(".quicklook.modal").remove()
          $('body').find(".dimmer").remove()
          $('body').removeClass("dimmable dimmed scrolling")
          quicklinks.load_modal($(this).data('asset-ids'))
        })

        search.asset_autocompletion("#quickmenu .ui.search.basket-assets", { mam: true });

        $('#quickmenu .header').find('.dropdown').dropdown({ 
          action: "hide", 
          forceSelection: false,
          onHide : function() {
            $(this).dropdown('clear');
          },
        })
      },
      error(_jqXHR, _textStatus, errorThrown) {
        console.log(errorThrown);
        $("#quickmenu .quickmenu.body").html("<br><br><span class='center'><div class='ui red basic label'>Loading Error</div></span>");
      }
    });
  },

  remove_basket_items(basket_id, item_id) {
    $.ajax({
      url: `/baskets/${basket_id}/item/${item_id}/remove`,
      type: "POST",
      success(_data, _textStatus, _xhr) {
        $("#basket_item_" + item_id).remove()
        baskets.load_basket_items(basket_id)
      },
      error(_jqXHR, _textStatus, errorThrown) {
        console.log(errorThrown);
      }
    });
  },

  rename_basket(basket_id, name) {
    $("#quickmenu .rename.basket").addClass("loading")
    $.ajax({
      url: `/baskets/${basket_id}/rename`,
      type: "POST",
      data: {
        name: name
      },
      complete() {
        $("#quickmenu .rename.basket").removeClass("loading")

        $("#quickmenu .segment.rename").removeClass("animate__animated animate__slideInUp fastest")
        $("#quickmenu .segment.rename").hide()
      },
      success(data, _textStatus, _xhr) {
        $("#quickmenu .basket.name").val(data["name"])
        $("#quickmenu .quickmenu.header .ui.header span").text(data["name"]);
      },
      error(_jqXHR, _textStatus, errorThrown) {
        console.log(errorThrown);
      }
    });
  },

  index_menu() {
    $(".add.all.to.basket").off('click')
    $(".add.all.to.basket").on('click', function() {
      baskets.open_quickmenu(true)
    })
  },

  context_menu() {
    $(".contextmenu.edit.basket").on('click', function() {
      if ($("#quickmenu .quickmenu.header .ui.header span").length > 0) {
        $("#quickmenu .quickmenu.header .ui.header span").text($(this).attr("data-basket-name"));
        baskets.load_basket_items($(this).attr("data-basket-id"));
      } else {
        baskets.open_quickmenu(false, "", "", $(this).attr("data-basket-id"), $(this).attr("data-basket-name"));
      }
    })
    $(".contextmenu.open.basket").on('click', function() {
      const target_model = $(".contextmenu.open.basket").attr("data-target-model")
      const target_id = $(".contextmenu.open.basket").attr("data-target-id")
      $('body').find(".quicklook.modal").remove()
      $('body').find(".dimmer").remove()
      $('body').removeClass("dimmable dimmed scrolling")
      baskets.open_quickmenu(true, target_model, target_id)
    })
  }
};
    
window.baskets = baskets;
