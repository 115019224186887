var workflow_7425 = {
  init(target, params_id) {
    workflow_7425.set_modals(target, params_id);
    workflow_7425.set_on_changes(target, params_id);
    return target.find('.ui.accordion').accordion();
  },

  set_on_changes(target, params_id) {
    target.find('.priority.dropdown').dropdown('setting', 'onChange', (value, _text, _$choice) => $(`#priority_${params_id}`).val(value));
    $(`#override_scanning_format_${params_id}`).change(function() {
      const value = $(`#override_scanning_format_${params_id}`).val();
      if (value === "i") {
        return target.find('.override-scan-order').hide().show();
      } else {
        return target.find('.override-scan-order').hide();
      }
    });
    $(`#content_${params_id}`).change(function() {
      $(`#media_start_time_${params_id}`).val($("option:selected", $(`#content_${params_id}`)).data('content-in'));
      $(`#media_end_time_${params_id}`).val($("option:selected", $(`#content_${params_id}`)).data('content-out'));
      $(`#content_type_id_${params_id}`).val($("option:selected", $(`#content_${params_id}`)).data('content-type-id'));
      return $(`#original_duration_${params_id}`).val($("option:selected", $(`#content_${params_id}`)).data('content-duration'));
    });
    // trigger defaults
    $(`#override_scanning_format_${params_id}`).change();
    return $(`#content_${params_id}`).change();
  },

  set_modals(target, params_id) {
    const audiolayoutmodal = target.find(".ui.modal.audio-layout-overview-modal");
    return target.find(".popup.audio-channel-overview").on('click', function() {
      const mc_header = $(`#multi_channel_selection_${params_id}`).val();
      const stereo_header = $(`#stereo_selection_${params_id}`).val();
      $('.audio-layout.content').empty();
      if ($(`#output_audio_channels_${params_id}`).val() === "2") {
        $('.audio-layout.content').append(
          `<h3>${stereo_header}</h3> \
<div class='ui two column grid'> \
<div class='column'><div class='ui massive label'><i class='volume off icon'></i>${$(`#audio_channel_01_${params_id}`).val()}</div></div> \
<div class='column'><div class='ui massive label'><i class='horizontally flipped volume off icon'></i>${$(`#audio_channel_02_${params_id}`).val()}</div></div> \
</div>`
        );
      } else if ($(`#output_audio_channels_${params_id}`).val() === "8") {
        $('.audio-layout.content').append(
          `<h3>${mc_header}</h3> \
<div class='ui three column grid'> \
<div class='column'><div class='ui massive label'><i class='rotated-left-speaker volume off icon'></i>${$(`#audio_channel_01_${params_id}`).val()}</div></div> \
<div class='column'><div class='ui massive label'><i class='rotated volume off icon'></i>${$(`#audio_channel_02_${params_id}`).val()}</div></div> \
<div class='column'><div class='ui massive label'><i class='rotated-right-speaker volume off icon'></i>${$(`#audio_channel_03_${params_id}`).val()}</div></div> \
</div> \
<div class='ui three column grid'> \
<div class='column'></div> \
<div class='column'><div class='ui massive label'><i class='vertically flipped archive icon'></i>${$(`#audio_channel_04_${params_id}`).val()}</div></div> \
<div class='column'></div> \
</div> \
<div class='ui three column grid'> \
<div class='column'><div class='ui massive label'><i class='rotated-ls-speaker volume off icon'></i>${$(`#audio_channel_05_${params_id}`).val()}</div></div> \
<div class='column'></div> \
<div class='column'><div class='ui massive label'><i class='rotated-rs-speaker volume off icon'></i>${$(`#audio_channel_06_${params_id}`).val()}</div></div> \
</div> \
<div class='ui divider'></div> \
<h3>${stereo_header}</h3> \
<div class='ui three column grid'> \
<div class='column'><div class='ui massive label'><i class='volume off icon'></i>${$(`#audio_channel_07_${params_id}`).val()}</div></div> \
<div class='column'></div> \
<div class='column'><div class='ui massive label'><i class='horizontally flipped volume off icon'></i>${$(`#audio_channel_08_${params_id}`).val()}</div></div> \
</div>`
        );
      } else {
        return;
      }
      audiolayoutmodal.modal();
      return audiolayoutmodal.modal('show');
    });
  },

  set_audio_params(target, params_id) {
    const selected_option = target.find(':selected');
    const layout = selected_option.data('layout');
    const parent = selected_option.parent();
    if (parent.attr('id') === `stereo_selection_${params_id}`) {
      if ($(`#stereo_selection_${params_id}`).val().length === 0) {
        $(`#audio_channel_01_${params_id}`).val(0);
        $(`#audio_channel_02_${params_id}`).val(0);
        $(`#audio_channel_03_${params_id}`).val(0);
        $(`#audio_channel_04_${params_id}`).val(0);
        $(`#audio_channel_05_${params_id}`).val(0);
        $(`#audio_channel_06_${params_id}`).val(0);
        $(`#audio_channel_07_${params_id}`).val(0);
        $(`#audio_channel_08_${params_id}`).val(0);
      } else {
        if ($(`#multi_channel_selection_${params_id}`).val().length === 0) {
          $(`#audio_channel_01_${params_id}`).val(layout['Stereo L'] || layout['LT'] || layout['Mono_1']);
          $(`#audio_channel_02_${params_id}`).val(layout['Stereo R'] || layout['RT'] || layout['Mono_2']);
        } else {
          const multi_layout = $(`#multi_channel_selection_${params_id}`).find(':selected').data('layout');
          $(`#audio_channel_01_${params_id}`).val(multi_layout['Left Front']);
          $(`#audio_channel_02_${params_id}`).val(multi_layout['Right Front']);
          $(`#audio_channel_03_${params_id}`).val(multi_layout['Center']);
          $(`#audio_channel_04_${params_id}`).val(multi_layout['LFE']);
          $(`#audio_channel_05_${params_id}`).val(multi_layout['Left Surround']);
          $(`#audio_channel_06_${params_id}`).val(multi_layout['Right Surround']);
          $(`#audio_channel_07_${params_id}`).val(layout['Stereo L'] || layout['LT'] || layout['Mono_1']);
          $(`#audio_channel_08_${params_id}`).val(layout['Stereo R'] || layout['RT'] || layout['Mono_2']);
        }
      }
    } else if (parent.attr('id') === `multi_channel_selection_${params_id}`) {
      if ($(`#multi_channel_selection_${params_id}`).val().length === 0) {
        $(`#audio_channel_03_${params_id}`).val(0);
        $(`#audio_channel_04_${params_id}`).val(0);
        $(`#audio_channel_05_${params_id}`).val(0);
        $(`#audio_channel_06_${params_id}`).val(0);
        $(`#audio_channel_07_${params_id}`).val(0);
        $(`#audio_channel_08_${params_id}`).val(0);
      } else if ($(`#stereo_selection_${params_id}`).val().length > 0) {
        $(`#audio_channel_01_${params_id}`).val(layout['Left Front']);
        $(`#audio_channel_02_${params_id}`).val(layout['Right Front']);
        $(`#audio_channel_03_${params_id}`).val(layout['Center']);
        $(`#audio_channel_04_${params_id}`).val(layout['LFE']);
        $(`#audio_channel_05_${params_id}`).val(layout['Left Surround']);
        $(`#audio_channel_06_${params_id}`).val(layout['Right Surround']);
        const stereo_layout = $(`#stereo_selection_${params_id}`).find(':selected').data('layout');
        $(`#audio_channel_07_${params_id}`).val(stereo_layout['Stereo L'] || stereo_layout['LT'] || layout['Mono_1']);
        $(`#audio_channel_08_${params_id}`).val(stereo_layout['Stereo R'] || stereo_layout['RT'] || layout['Mono_2']);
      }
    }
    return workflow_7425.set_output_audio_channels(params_id);
  },

  set_output_audio_channels(params_id){
    if ($(`#stereo_selection_${params_id}`).val().length === 0) {
      $(`#output_audio_channels_${params_id}`).val(0);
      return $(`#override_input_audio_channel_number_${params_id}`).val(false);
    } else if (($(`#stereo_selection_${params_id}`).val().length > 0) && ($(`#multi_channel_selection_${params_id}`).val().length === 0)) {
      $(`#output_audio_channels_${params_id}`).val(2);
      return $(`#override_input_audio_channel_number_${params_id}`).val(true);
    } else if (($(`#stereo_selection_${params_id}`).val().length > 0) && ($(`#multi_channel_selection_${params_id}`).val().length > 0)) {
      $(`#output_audio_channels_${params_id}`).val(8);
      return $(`#override_input_audio_channel_number_${params_id}`).val(true);
    } else {
      $(`#output_audio_channels_${params_id}`).val(0);
      return $(`#override_input_audio_channel_number_${params_id}`).val(false);
    }
  }
};

window.workflow_7425 = workflow_7425;
