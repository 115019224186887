const workflow_50000 = {
  init(element) {
    element.find('.validate').on("change", () => workflow_50000.validate(element))

    $(element).find(".datamover-source-changer").on('change', function() {
      $(element).find(".datamover-source").val($(element).find(".datamover-source").val().replace(/\\/g, "/"))
      $(element).find(".datamover-source-path").val($(element).find(".sl-select-source").dropdown('get value') + $(element).find(".datamover-source").val())
      workflow.update_whparams(element)
    });

    $(element).find(".datamover-destination-changer").on("change", function() {

      const asset_ids = ($('.compose-workflow-job').length > 0) ? '' : $(element).find(".workflow-job-asset-id").val() + '/'

      $(element).find(".datamover-destination").val($(element).find(".datamover-destination").val().replace(/\\/g, "/"))

      if (($(this).find("select").attr('id') === 'storage_destination') && ($(this).find("select").find(':selected').val() === "smb://")) {
        $(element).find(".datamover-destination").val(`address/share/${asset_ids}`);
        $(element).find(".datamover-destination-search").data('autocomplete', 'off');
      } else if (($(this).find("select").attr('id') === 'storage_destination') && ($(this).find("select").find(':selected').val() !== undefined) && ($(this).find("select").find(':selected').val().substring(0, 21) === '/mnt/snfs1/WORKSPACE/')) {
        $(element).find(".datamover-destination").val(`_user/${$("#current_username").val()}/${asset_ids}`);
        $(element).find(".datamover-destination-search").data('autocomplete', null);
      } else if (($(this).find("select").attr('id') === 'storage_destination') && ($(this).find("select").find(':selected').val() !== undefined) && ($(this).find("select").find(':selected').val().substring(0, 5) === '/mnt/')) {
        $(element).find(".datamover-destination").val(asset_ids);
        $(element).find(".datamover-destination-search").data('autocomplete', null);
      }
      $(element).find(".datamover-destination-path").val($(element).find(".sl-select-destination").dropdown('get value') + $(element).find(".datamover-destination").val());
      workflow.update_whparams(element);
    });

    $(element).find(".datamover-source").on("change", function() {
      const uri = $(this).val();
      const select = $(element).find('.sl-select-source select option');
      return (() => {
        const result = [];
        for (let option of Array.from(select)) {
          if (typeof uri.startsWith === 'function' ? uri.startsWith($(option).val()) : undefined) {
            $(element).find(".sl-select-source").dropdown("set selected", $(option).val());
            if ($(option).val() !== null) {
              $(element).find(".datamover-source").val(uri.substring($(option).val().length, uri.length));
            }
            $(element).find(".datamover-source-path").val($(element).find(".sl-select-source").dropdown('get value') + $(element).find(".datamover-source").val());
            workflow.update_whparams(element);
            break;
          } else {
            result.push(undefined);
          }
        }
        return result;
      })();
    });

    $(element).find(".datamover-destination").on("change", function() {
      const uri = $(this).val();
      const select = $(element).find('.sl-select-destination select option');
      return (() => {
        const result = [];
        for (let option of Array.from(select)) {
          if (typeof uri.startsWith === 'function' ? uri.startsWith($(option).val()) : undefined) {
            $(element).find(".sl-select-destination").dropdown("set selected", $(option).val());
            if ($(option).val() !== null) {
              $(element).find(".datamover-destination").val(uri.substring($(option).val().length, uri.length));
            }
            $(element).find(".datamover-destination-path").val($(element).find(".sl-select-destination").dropdown('get value') + $(element).find(".datamover-destination").val());
            workflow.update_whparams(element);
            break;
          } else {
            result.push(undefined);
          }
        }
        return result;
      })();
    });

    $(element).find(".datamover-destination-changer").trigger('change');
    workflow.update_whparams(element);

    search.datamover_autocompletion($(element).find(".datamover-source-search"), $.extend($(element).find(".datamover-source").data(), {'mount': $(element).find('.sl-select-source')}));
    search.datamover_autocompletion($(element).find(".datamover-destination-search"), $.extend($(element).find(".datamover-destination").data(), {'mount': $(element).find('.sl-select-destination')}));

    if ($(element).find('.workflow_50000.nested-fields #host').length > 0) {

      if ($('.compose-workflow-job').length > 0) {
        $('.source.fields').hide()
      } else {
        $.ajax({
          url: $(element).find('.workflow_50000.nested-fields #host').val() + "/storage_location/",
          type: "GET",
          dataType: "json",
          data: {
            asset_id:  $(element).find(".workflow-job-asset-id").val(),
            token: $(element).find(".datamover-source").data('token')
          },
          complete() {},

          success(data, _textStatus, _xhr) {
            //$(element).find(".sl-select-source").val($("#current-storage-location-mountpoint").val())
            $(element).find(".sl-select-source").dropdown('set exactly', data["default_mount_point"]);
            $(element).find(".datamover-source").val(data["default_location"]);
            $(element).find(".datamover-source").trigger('change');
          },
          error(_jqXHR, _textStatus, errorThrown) {
            console.log(errorThrown);
          }
        });
      }
      
      $.ajax({
        url: $(element).find('.workflow_50000.nested-fields #host').val() + "/mounted_storages/",
        type: "GET",
        dataType: "json",
        data: {
          token: $(element).find(".datamover-source").data('token')
        },
        success(data, _textStatus, _xhr) {
          const select = $(element).find('.sl-select-destination select');
          for (let mount of Array.from(data)) {
            const option = document.createElement("option");
            option.value = mount.dmsl_Ressource;
            option.innerHTML = mount.dmsl_Name;
            select.append(option);
          }
          $(element).find(".sl-select-destination").dropdown('refresh').dropdown('set value', '/mnt/snfs1/WORKSPACE/').dropdown('set text', 'Workspace');

          if ($('.compose-workflow-job').length > 0) {
            $(element).find(".datamover-destination").val(`_user/${$("#current_username").val()}/`);
          } else {
            $(element).find(".datamover-destination").val(`_user/${$("#current_username").val()}/${$(element).find(".workflow-job-asset-id").val()}/`);
          }
          
          $(element).find(".datamover-source-path").val($(element).find(".sl-select-source").dropdown('get value') + $(element).find(".datamover-source").val());
          $(element).find(".datamover-destination-changer").trigger('change');
        },
        error(_jqXHR, _textStatus, errorThrown) {
          console.log(errorThrown);
        }
      });
    }
  },

  validate(element) {
    const validator = { is_valid: true, messages: [] };

    const error_message = $(element).find('.error.message');
    error_message.addClass('hidden').html("");
    $(':input[type=submit]').prop('disabled', false);

    workflow_50000.validate_locations(element, validator);

    if (!validator.is_valid) {
      for (let message of Array.from(validator.messages)) {
        error_message.append(`<p>${message}</p>`);
      }
      error_message.removeClass('hidden');
      $(':input[type=submit]').prop('disabled', true);
    }
  },

  validate_locations(element, validator) {
    const source = $(element).find('.datamover-source')
    const destination = $(element).find('.datamover-destination')
    
    if (source.val().length === 0 && $('.compose-workflow-job').length == 0) {
      validator.is_valid = false;
      validator.messages.push("Source path is empty");
    }
    if (destination.val().length === 0) {
      validator.is_valid = false;
      validator.messages.push("Destination path is empty");
    }
  }
};

window.workflow_50000 = workflow_50000;
