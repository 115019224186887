import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static values = {
    targetModel: String,
    targetIds: Array,
  }

  send_to_basket() {
    let target_model = this.targetModelValue
    let target_ids = this.targetIdsValue
    baskets.open_quickmenu(true, target_model, target_ids)
  }
}