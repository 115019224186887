/* eslint-disable no-undef */
/* eslint-disable no-prototype-builtins */
var forms = {
  enable(queue_entry, form) {
    if (queue_entry == null) { queue_entry = "default" }
    if (typeof form === 'undefined' || form === null) { form = $('.ui.form') }
    forms.queue.delete(queue_entry)
    if (forms.queue.size === 0) {
      $('button[type=submit],input[type=submit]', form).removeAttr('disabled')
    }
  },
  disable(queue_entry, form) {
    if (typeof form === 'undefined' || form === null) { form = $('.ui.form') }
    forms.queue.add(queue_entry)
    $('button[type=submit],input[type=submit]', form).attr('disabled', 'disabled')
  },
  
  deferred_queues: new Proxy({}, {
    get: function(object, property) {
      if (!Object.prototype.hasOwnProperty.call(object, property)) {
        object[property] = new DeferredQueue(property)
      }
      return object[property]
    }
  })
}

forms.queue = new Set();
    
window.forms = forms;
