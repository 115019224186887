import { Controller } from "@hotwired/stimulus"
import { FetchRequest } from '@rails/request.js'

export default class extends Controller {

  static targets = [
    'modal',
    'availCount',
    'message',
    'submitButton',
    'mode',
    'output',
    'errors',
  ]

  static values = {
    runUrl: String,
  }

  connect() {
    $(this.messageTarget).addClass("info").removeClass("positive negative")
    this.availCountTarget.textContent = index_select.selected_ids()?.length
  }

  disconnect() {
  }

  async run() {
    const modal = $(this.modalTarget)
    const button = $(this.submitButtonTarget)
    const message = $(this.messageTarget)
    var run_url = this.runUrlValue
    let avail_ids = index_select.selected_ids()
    let mode = this.modeTarget.value

    let toast = $('body').toast({
      class: 'info',
      displayTime: 0,
      message: `Running mddf tools to ${mode} avails`,
      showProgress: 'bottom'
    })

    button.addClass('disabled loading')

    const request = new FetchRequest("post", run_url, {
      responseKind: "turbo-stream",
      body: JSON.stringify({
        avail_ids: avail_ids,
        mode: mode,
      })
    })
    const response = await request.perform()
    toast.toast('close')
    button.removeClass('disabled loading')
  }
}
