import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['loading', 'trigger']
  
  loading(_e) {
    this.loadingTarget?.classList?.add('loading')
  }

  disable(_e) {
    this.loadingTarget?.classList?.add('disabled')
  }

  enable(_e) {
    this.loadingTarget?.classList?.remove('disabled')
  }

  loaded({ detail: { fetchResponse} }) {
    if (this.hasLoadingTarget) {
      let response_url = fetchResponse?.response?.url
      let request_url = this.loadingTarget?.href?.replace(/\?.*/gm, '') || (this.hasTriggerTarget ? this.triggerTarget?.href?.replace(/\?.*/gm, '') : null) || this.loadingTarget.form?.action
      if (typeof response_url === 'string' && typeof request_url === 'string') {
        if (response_url.includes(request_url)) {
          this.loadingTarget.classList?.remove('loading')
          this.dispatch('loaded')
        }
      } else {
        this.loadingTarget.classList?.remove('loading')
        this.dispatch('loaded')
      }
    }
  }
}
