const universal_audio = {
  set_audio_params(target, params_id) {
    const selected_option = target.find(':selected');
    const index = $(target).find('select').data('index');
    const layout = selected_option.data('layout');
    const location = selected_option.data('storage-location');
    const language = selected_option.data('language');
    const secondary_language = selected_option.data('secondary-language');
    const iso639_1 = selected_option.data('iso639_1');
    const iso639_2 = selected_option.data('iso639_2');
    const secondary_iso639_1 = selected_option.data('secondary-iso639_1');
    const secondary_iso639_2 = selected_option.data('secondary-iso639_2');
    const codec = selected_option.data('codec');
    const channel_count = selected_option.data('channel-count');
    const usage = selected_option.data('usage');
    const samplerate = selected_option.data('samplerate');
    const bit_depth = selected_option.data('bitdepth');
    const note = selected_option.data('note');
    const loudness = selected_option.data('loudness');
    const relative_gated_loudness = selected_option.data('loudnessRelativeGatedLoudness');
    const true_peak = selected_option.data('loudnessTruePeak');
    const loudness_rage = selected_option.data('loudnessRange');
    const dialnorm = selected_option.data('loudnessDialnorm');
    const trackgroup = selected_option.data('trackgroup');
    const split_tracks = selected_option.data('mono');

    workflow.display_potential_issues(selected_option, target)

    if (selected_option.val() === "") {
      $(`.${params_id}.audio_${index}`).val("");
      $(`#audio_${index}_channels_mapping_${params_id}`).val("")
      $(`#audio_${index}_present_${params_id}`).val(false);
    } else {
      $(`.${params_id}.audio_${index}`).val("");
      $(`#audio_${index}_channels_mapping_${params_id}`).val("")
      const layout_string = Object.keys(layout).join(', ')
      if (relative_gated_loudness && true_peak && loudness_rage && dialnorm) {
        $(`#audio_${index}_loudness_measurement_present_${params_id}`).val(true);
      } else {
        $(`#audio_${index}_loudness_measurement_present_${params_id}`).val(false);
      }
      $(`#audio_${index}_present_${params_id}`).val(true);
      $(`#audio_${index}_split_tracks_${params_id}`).val(split_tracks);
      $(`#audio_${index}_trackgroup_${params_id}`).val(trackgroup);
      $(`#audio_${index}_language_${params_id}`).val(language);
      $(`#audio_${index}_language_iso_639_1_${params_id}`).val(iso639_1);
      $(`#audio_${index}_language_iso_639_2_${params_id}`).val(iso639_2);
      $(`#audio_${index}_secondary_language_${params_id}`).val(secondary_language);
      $(`#audio_${index}_secondary_language_iso_639_1_${params_id}`).val(secondary_iso639_1);
      $(`#audio_${index}_secondary_language_iso_639_2_${params_id}`).val(secondary_iso639_2);
      $(`#audio_${index}_codec_${params_id}`).val(codec);
      $(`#audio_${index}_channel_count_${params_id}`).val(channel_count);
      $(`#audio_${index}_usage_${params_id}`).val(usage);
      $(`#audio_${index}_samplerate_${params_id}`).val(samplerate);
      $(`#audio_${index}_bit_depth_${params_id}`).val(bit_depth);
      $(`#audio_${index}_note_${params_id}`).val(note);
      $(`#audio_${index}_loudness_${params_id}`).val(loudness);
      $(`#audio_${index}_loudness_relative_gated_loudness_${params_id}`).val(relative_gated_loudness);
      $(`#audio_${index}_loudness_true_peak_${params_id}`).val(true_peak);
      $(`#audio_${index}_loudness_range_${params_id}`).val(loudness_rage);
      $(`#audio_${index}_loudness_dialnorm_${params_id}`).val(dialnorm);
      $(`#audio_${index}_channels_mapping_${params_id}`).val(layout_string);
      if ((selected_option.data('channel-count') === 1) || (selected_option.data('channel-count') === 2)) {
        $(`#audio_${index}_channel_l_path_${params_id}`).val(`${location}` + selected_option.data('filename').L);
        $(`#audio_${index}_channel_l_mapping_${params_id}`).val(layout['Stereo L'] || layout['LT'] || layout['Mono_1']);
        $(`#audio_${index}_channel_r_path_${params_id}`).val(`${location}` + selected_option.data('filename').R);
        $(`#audio_${index}_channel_r_mapping_${params_id}`).val(layout['Stereo R'] || layout['RT'] || layout['Mono_2']);
      } else if ((selected_option.data('channel-count') === 5)) {
        $(`#audio_${index}_channel_l_path_${params_id}`).val(`${location}` + selected_option.data('filename').L);
        $(`#audio_${index}_channel_l_mapping_${params_id}`).val(layout['Left Front']);
        $(`#audio_${index}_channel_r_path_${params_id}`).val(`${location}` + selected_option.data('filename').R);
        $(`#audio_${index}_channel_r_mapping_${params_id}`).val(layout['Right Front']);
        $(`#audio_${index}_channel_c_path_${params_id}`).val(`${location}` + selected_option.data('filename').C);
        $(`#audio_${index}_channel_c_mapping_${params_id}`).val(layout['Center']);
        $(`#audio_${index}_channel_ls_path_${params_id}`).val(`${location}` + selected_option.data('filename').LS);
        $(`#audio_${index}_channel_ls_mapping_${params_id}`).val(layout['Left Surround']);
        $(`#audio_${index}_channel_rs_path_${params_id}`).val(`${location}` + selected_option.data('filename').RS);
        $(`#audio_${index}_channel_rs_mapping_${params_id}`).val(layout['Right Surround']);
      } else if ((selected_option.data('channel-count') === 6) || (selected_option.data('channel-count') === 8)) {
        $(`#audio_${index}_channel_l_path_${params_id}`).val(`${location}` + selected_option.data('filename').L);
        $(`#audio_${index}_channel_l_mapping_${params_id}`).val(layout['Left Front']);
        $(`#audio_${index}_channel_r_path_${params_id}`).val(`${location}` + selected_option.data('filename').R);
        $(`#audio_${index}_channel_r_mapping_${params_id}`).val(layout['Right Front']);
        $(`#audio_${index}_channel_c_path_${params_id}`).val(`${location}` + selected_option.data('filename').C);
        $(`#audio_${index}_channel_c_mapping_${params_id}`).val(layout['Center']);
        $(`#audio_${index}_channel_lfe_path_${params_id}`).val(`${location}` + selected_option.data('filename').LFE);
        $(`#audio_${index}_channel_lfe_mapping_${params_id}`).val(layout['LFE']);
        if (selected_option.data('channel-count') === 6) {
          $(`#audio_${index}_channel_ls_path_${params_id}`).val(`${location}` + selected_option.data('filename').LS);
          $(`#audio_${index}_channel_ls_mapping_${params_id}`).val(layout['Left Surround']);
          $(`#audio_${index}_channel_rs_path_${params_id}`).val(`${location}` + selected_option.data('filename').RS);
          $(`#audio_${index}_channel_rs_mapping_${params_id}`).val(layout['Right Surround']);
        } else if (selected_option.data('channel-count') === 8) {
          $(`#audio_${index}_channel_ls_path_${params_id}`).val(`${location}` + selected_option.data('filename').LSR);
          $(`#audio_${index}_channel_ls_mapping_${params_id}`).val(layout['Left Surround Rear']);
          $(`#audio_${index}_channel_rs_path_${params_id}`).val(`${location}` + selected_option.data('filename').RSR);
          $(`#audio_${index}_channel_rs_mapping_${params_id}`).val(layout['Right Surround Rear']);
          $(`#audio_${index}_channel_lss_path_${params_id}`).val(`${location}` + selected_option.data('filename').LSS);
          $(`#audio_${index}_channel_lss_mapping_${params_id}`).val(layout['Left Side Surround']);
          $(`#audio_${index}_channel_rss_path_${params_id}`).val(`${location}` + selected_option.data('filename').RSS);
          $(`#audio_${index}_channel_rss_mapping_${params_id}`).val(layout['Right Side Surround']);
        }
      }
    }
  }
};

window.universal_audio = universal_audio;
