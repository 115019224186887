import { Controller } from "@hotwired/stimulus"
import * as Credential from "../src/credential";

export default class extends Controller {
  static targets = ["usernameField"]

  connect() {
  }

  async create(event) {
    var [data, status, xhr] = event.detail;
    var credentialOptions = data;

    // Registration
    if (credentialOptions["user"]) {
      var credential_nickname = event.target.querySelector("input[name='registration[nickname]']").value;
      var callback_url = `/registration/callback?credential_nickname=${credential_nickname}`

      await Credential.create(encodeURI(callback_url), credentialOptions);
      document.getElementById("manage_keys_modal").reload()
    }
  }

  error(event) {
    console.log(event)
    let response = event.detail[0];
    console.log(response["errors"][0])
  }
}
