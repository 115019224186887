import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'


export default class extends Controller {
  static targets = [
    "item",
    "container",
    "pauseControl",
  ]

  static values = {
    pause: { type: Boolean, default: false } 
  }

  static classes = [
    'paused',
    'scrolling',
  ]

  connect() {
    const c = this

    const scroll_events = ['DOMMouseScroll', 'mousewheel'] 
    scroll_events.forEach(function (e) {
      c.containerTarget.addEventListener(e, () => {
        c.pauseValue = true
      })
    })
  }

  togglePause(e) {
    this.pauseValue ? this.resume() : this.pause()
  }

  pause() {
    this.pauseValue = true
  }

  resume() {
    this.pauseValue = false
  }

  pauseValueChanged(pause) {
    const control = this.pauseControlTarget
    const icon_element = control.querySelector('i')
    const text_element = control.querySelector('span')

    if (!this.hasPauseControlTarget) return
    if (pause) {
      text_element.textContent = 'Paused'
      icon_element.classList.remove(...this.scrollingClasses)
      icon_element.classList.add(...this.pausedClasses)
    } else {
      text_element.textContent = 'Auto Scrolling'
      icon_element.classList.remove(...this.pausedClasses)
      icon_element.classList.add(...this.scrollingClasses)
    }
  }

  scroll({ params: { attribute }, detail: { value } }) {
    if (this.pauseValue) return
    this.scrollToItemWithAttribute(attribute, value)
  }

  scrollToItemWithAttribute(attribute, value) {
    if (this.pauseValue) return
    if (!value) return
    let matched_item = null
    let closest_difference = Infinity

    this.itemTargets.forEach((element) => {
      const attribute_value = element.querySelector(`[${attribute}]`)?.getAttribute(`${attribute}`) || null

      const item_value = parseFloat(attribute_value)
      const difference = Math.abs(item_value - value)

      if (difference < closest_difference) {
        closest_difference = difference
        matched_item = element
      }
    })

    if (matched_item) {
      const scroll_position = matched_item.offsetTop - (matched_item.offsetHeight / 2.0)
      this.containerTarget.scrollTo({
        top: scroll_position,
        behavior: 'smooth',
      })
      this.dispatch('scrolled', { detail: { value: scroll_position }})
    }
  }
}
