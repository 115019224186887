class DeferredQueue {
  constructor(id) {
    this.id = id
    this._queue = []
    this._processed = []
    
    this._onEnqueue = (_d) => {}
    this._onDequeue = (_d) => {}
    this._onEmpty = () => {}    
  }
  
  onEnqueue(f) {
    this._onEnqueue = f
  }
  onDequeue(f) {
    this._onDequeue = f
  }
  onEmpty(f) {
    this._onEmpty = f
  }
  
  enqueue(deferred) {
    let d = deferred ? deferred : new $.Deferred()
    this._queue.push(d)
    if (!d) return
    if (d.constructor.name === 'Promise') {
      d.finally(() => { this.dequeue(d) })
    } else { 
      d.always(() => { this.dequeue(d) })
    }
    this._onEnqueue(d)
    return d
  }
  dequeue(deferred) {
    if (typeof(deferred) === 'undefined') {
      let d = this._queue[0]
      if (d.constructor.name === 'Promise') {
        this.dequeue(d)
      } else {
        d.resolve()
      }
    } else {
      const index = this._queue.indexOf(deferred)
      if (index > -1) {
        let d = this._queue[index]
        this._queue = this._queue.filter(x => x !== deferred)
        this._processed.push(d)
        if (this._queue.length === 0) {
          this._onEmpty(this._processed)
        }
        
        this._onDequeue(d)
        return d
      }
    }
  }
  queued() {
    return this._queue
  }
}

window.DeferredQueue = DeferredQueue
