import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="input-validate"
export default class extends Controller {
  static targets = ["unique"]

  formatTc(event) {
    assets.clean_framerate($(event.target))
  }
  
  uniquenessCheck(event) {
    this.uniqueTargets.forEach((t) => t.closest('.field').classList.remove('error'))

    const duplicates = this.uniqueTargets.filter((t) => t.dataset.context === event.target.dataset.context).filter((t) => t !== event.target).filter((t) => t.value === event.target.value)

    if (duplicates.length > 0) {
      duplicates.push(event.target)
      duplicates.forEach((t) => t.closest('.field').classList.add('error'))
    }
  }
}
