import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "field"
  ]

  static values = {
  }

  connect() {
  }

  update({target: input, params: { target, targets, dispatch: globalDispatch, trigger: globalTrigger }}) {
    const c = this
    if (target) {
      c.setTargetValue(target, input, { dispatch: globalDispatch, trigger:globalTrigger })
    }
    if (targets) {
      targets.forEach(({selector, dispatch, trigger}) => {
        c.setTargetValue(selector, input, { dispatch, trigger })
      })
    }
  }

  setTargetValue(selector, input, { dispatch, trigger } ) {
    const target = $(selector)
    if (target.is(':checkbox')) {
      target.prop('checked', input.checked)
    } else {
      target.val(input.value)
    }

    if (dispatch) target[0]?.dispatchEvent(new Event(dispatch))
    if (trigger) target.trigger('change')
  }
}
