import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['tab']
  static values = {
    params: { type: Object, default: { autoTabActivation: false } },
  }

  initialize() {
    $(this.element).tab(this.paramsValue)
  }
}
