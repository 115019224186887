var workflow_50003 = {
  init(target, params_id) {
    $(target).find('.transfer.profile.buttons .button').on('click', function() {
      $(target).find('.transfer.profile.buttons .active.button').removeClass('active')
      $(this).addClass('active')
      $(target).find(`#transfer_profile_id_${params_id}`).val($(this).data('transfer-profile-id'))
      workflow.update_whparams($(target))

      return false
    })
  }
};

window.workflow_50003 = workflow_50003
