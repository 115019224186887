/* eslint-disable no-case-declarations */
import sortable from "html5sortable/dist/html5sortable.es";

document.addEventListener("turbo:load", function() {

  if ($('#workflows').length > 0) {
    workflow.nested_fields()

    if ($(".asset.rename.dialog").length > 0) {
      assets.rename()
    }
  }

  $('#workflows form').off('submit.workflows')
  $('#workflows form').on('submit.workflows', function (e) {
    //console.log("submitter")
    if ($('.load.view.error,[id^="workflow_view_"] .loader').length > 0) {
      e.preventDefault()
      alert("Some workflow views did not load!")
      return false
    }

    let new_jobs_count = $('fieldset.nested_workflow_workflow_jobs').length
    if (new_jobs_count > 0) {
      let new_jobs_target_types = $('input[id*=workflow_job_assignments_attributes_][id$=_target_type]').toArray().map(x => x.value)
      let new_jobs_target_ids   = $('input[id*=workflow_job_assignments_attributes_][id$=_target_id]').toArray().map(x => x.value)

      if (new_jobs_count != new_jobs_target_types.length ||
        new_jobs_count != new_jobs_target_ids.length || 
        !new_jobs_target_types.every(v => v !== undefined && v !== '') ||
        !new_jobs_target_ids.every(v => v !== undefined && v !== '')) {
        e.preventDefault()
        alert("Model ID(s) not set!")
        return false
      }
    }

    $(".workflow-params").find("input").attr("name", "")
  });

  workflow.sidebar()

  search.workflow_job_selection(".ui.search.asset-workflow")

  $(document).on('keydown', '#workflow-jobs input:not(textarea):not(:submit)', function(e) {
    if (e.key == "Enter") {
      e.preventDefault()
      return false
    }
  })

  if ($('.new-workflow-job').length > 0) {
    if ($(".default-workflows").length > 0) {
      workflow.fetch_default_workflow_views();
    }

    $('#workflow-jobs .quicklook.label').on('click', function() {
      assets.quicklook_asset($(this).find('[data-asset-id]').data('asset-id'));
    });
  }

  if ($('.compose-workflow-job').length > 0) {
    Array.from($('#workflow-jobs .remote.asset.card')).map((asset_card) => workflow.fetch_asset_view(asset_card))
  }
});

var workflow = {
  nested_fields() {
    $("#workflows").off("fields_removed.nested_form_fields.nested_workflow_workflow_jobs");
    $("#workflows").on("fields_removed.nested_form_fields.nested_workflow_workflow_jobs", function(event, param) {
      switch (param.object_class) {
        case "asset_project": {
          assets.set_asset_data(event.target, param.additional_data)
          break
        }
        case "workflow_job": {
          const workflow_named = $(this).find('.workflow-name').text()
          if (workflow_named === "Datamover - Retrieve" || workflow_named === "Datamover - Retrieve from S3 to Workspace") {
            workflow_jobs.switch_start_workflow_buttons(event.target, $(event.target).find(".start-workflow.buttons .button").not('active'))
          } 
          workflow_jobs.fill_start_workflow_dropdowns(event.target)
  
          if (workflow_jobs.count_visible_workflows() <= 1) {
            $(".start-workflow.dropdown").dropdown("clear")
            $(".start-workflow").hide()
          }
  
          $('.nested_workflow_workflow_jobs .start-workflow.dropdown').each(function() {
            if ($(this).dropdown('get values').length == 0) {
              $(this).parents('.bottom.attached.mini.message').find('.start-workflow.buttons .start.button').trigger('click')
            }
          })
  
          const new_counter_value = workflow.set_workflow_counter(false)
  
          const uuid = $(event.target).find('.workflow-job-uuid').val()
          $(".sidebar-item.workflow-uuid[value=" + uuid + "]").parent().remove()
          
          let delete_association_field_name = $(event.target).find('[data-delete-association-field-name]').data('delete-association-field-name')
          if (delete_association_field_name?.length > 0) {
            $(`input[name="${delete_association_field_name}"]`).remove()
          }
          event.target.remove() 
          
          const last_sidebar_item = $('.sidebar-item').last()
          last_sidebar_item.addClass('active')
          last_sidebar_item.trigger('click')

          workflow.show_welcome(new_counter_value)

          if ($('.load.view.error,[id^="workflow_view_"] .loader').length == 0) {
            forms.enable("fetch_view")
          }

          break
        }
        default:
          console.log("Fields were successfully removed, callback not handled.");
          break
      }
      
    })
    $("#workflows").off("fields_added.nested_form_fields.nested_workflow_workflow_jobs");
    $("#workflows").on("fields_added.nested_form_fields.nested_workflow_workflow_jobs", function(event, param) {
      switch (param.object_class) {
        case "workflow_job": {
          forms.disable("fetch_view")
          workflow.fetch_workflow_field($(event.target), param.additional_data)
          workflow.set_workflow_counter(true)
        }
      }
    })
  },

  show_not_for_production_warning() {
    const count = workflow_jobs.count_not_for_production_assets()
    if (count > 0) {
      $(".not_for_production_count").html(`<div class="ui red basic mini label" style="margin-left: 5px;"><i class="warning sign icon"></i>${count}</div>`)
    } else {
      $(".not_for_production_count").html("")
    }
  },

  fetch_asset_view(asset_card) {
    general.fetch_view(asset_card).then((_results) => {
      workflow.show_not_for_production_warning()
    })
    
    workflow.update_asset_ids()
    
    $(asset_card).hover((function() {
      $(asset_card).find(".card.list.item").prepend('<a class="ui black right mini corner label"><i class="times icon"></i></a>')
      $(asset_card).find(".card.list.item .corner.label").on('click', function () {
        $(asset_card).remove()
        workflow.update_asset_ids()
        workflow.show_not_for_production_warning()
      })
    }), function() {
      $(asset_card).find(".card.list.item .corner.label").remove()
    });
  },

  update_asset_ids() {
    $(".label.asset-count").text($(".asset-id-display").length)
    let asset_ids = $(".asset-list").find(".asset.card.asset-id-display").map((_i,x) => x.dataset.modelId).get().map(Number)
    $("#workflow_asset_ids").val(asset_ids.join(' '))[0]?.dispatchEvent(new Event('change'))
    $("#workflow_search").data("asset-ids", asset_ids)
  },

  add_workflow_template(result, default_workflow = false) {
    const is_archived = ($("#workflow_search").data("is-archived").includes(true))
    const add_workflow_link = $('.add_nested_fields_link[data-object-class="workflow_job"]')
    const needs_retrieve = result.wjt_NeedsRetrieve
          
    let promises = []
    $.when.apply($, promises).then( function() {
      if(is_archived && needs_retrieve && !workflow_jobs.find_templates_to_exclude().includes("47")) {
        add_workflow_link.trigger("click", [{ asset_ids: $("#workflow_search").data("asset-ids"), workflow_id: 47, workflow_name: "Datamover - Retrieve", workflow_type: result.wjt_wt_ID }]);
      }
    }).then(function() {
      add_workflow_link.trigger('click', [{ asset_ids: $("#workflow_search").data("asset-ids"), workflow_id: result.wjt_ID, workflow_name: result.wjt_Description, workflow_description: `${result.wjt_WorkflowID}${ result.wjt_LongDescription != "" ? " - " + result.wjt_LongDescription : ""}`, workflow_type: result.wjt_wt_ID, is_archived: is_archived, default_workflow }]);
      $(".ui.search.asset-workflow").search("set value", "").search("hide results");
    })
    $(".ui.search.asset-workflow").search("set value", "").search("hide results");
  },

  fetch_workflow_field(element, params) {
    const params_id = general.makeid();
    workflow.init_elements(element, params, params_id);
    return workflow.fetch_view(element, params, params_id)
  },
  
  fetch_default_workflow_views() {
    forms.disable("fetch_view")
    const promises = [];

    for (let asset_segment of Array.from($(".asset-segment"))) {
      const asset_ids = $(asset_segment).data("asset-id");
      for (let default_workflow of Array.from($("#workflow-job-list .nested_workflow_workflow_jobs"))) {
        const workflow_id = $(default_workflow).find(".workflow-job-template").val()
        const workflow_name = $(default_workflow).find(".workflow-job-name").val()
        const workflow_type = $(default_workflow).find(".workflow-job-type").val()
        const workflow_description = $(default_workflow).find(".workflow-job-description").val()
        const params = { asset_ids, workflow_id, workflow_name, workflow_type, workflow_description, default_workflow: true }
        const params_id = general.makeid()
        workflow.init_elements(default_workflow, params, params_id)
        promises.push(workflow.fetch_view($(default_workflow), params, params_id))
      }
    }

    $.when.apply($, promises).then(function() {
      if (promises.length == 0) forms.enable("fetch_view")
    })
  },
  
  set_audio_params(target, params_id) {
    if (target.hasClass('itunes-audio')) {
      itunes_audio.set_audio_params(target, params_id);
      return;
    }
    if (target.hasClass('telekom-audio')) {
      telekom_audio.set_audio_params(target, params_id);
      return;
    }
    if (target.hasClass('primary-audio')) {
      primary_audio.set_audio_params(target, params_id);
      return;
    }
    if (target.hasClass('secondary-audio-01')) {
      secondary_audio_01.set_audio_params(target, params_id);
      return;
    }
    if (target.hasClass('stereo-audio-convert')) {
      stereo_audio_convert.set_audio_params(target, params_id);
      return;
    }
    if (target.hasClass('audio-convert')) {
      audio_convert.set_audio_params(target, params_id);
      return;
    }
    if (target.hasClass('workflow-1503-audio-convert')) {
      workflow_1503.set_audio_params(target, params_id);
      return;
    }
    if (target.hasClass('universal-audio-convert')) {
      universal_audio.set_audio_params(target, params_id);
      return;
    }
  },

  set_audio_language(target, params_id) {
    const selected_option = target.find(':selected')
    for (let prefix of ['primary_audio', 'secondary_audio', 'tertiary_audio', 'quaternary_audio', 'quinary_audio']) {
      if (target.hasClass(prefix)) {
        if (selected_option.val() === "") {
          $(`#${prefix}_-_language_${params_id}`).val("")
          $(`#${prefix}_-_language_iso_639-2_${params_id}`).val("")
          $(`#${prefix}_-_51_-_language_select_${params_id}`).val("")
          $(`#${prefix}_-_20_-_language_select_${params_id}`).val("")
        } else {
          const language = selected_option.text().split(" - ")
          $(`#${prefix}_-_language_${params_id}`).val(language[1])
          $(`#${prefix}_-_language_iso_639-2_${params_id}`).val(language[0])
        }
      }
    }
  },

  set_subtitle_params(target, params_id) {
    let selected_option;
    if (target.hasClass('dropdown')) {
      if (target.dropdown("get value") !== "") {
        selected_option = $(`#${target.find("select").attr("id")}`).find(`option[value="${target.dropdown("get value")}"][data-iso639-1],option[value="${target.dropdown("get value")}"]`)
      } else {
        selected_option = target.find("select")
      }
    } else {
      selected_option = target.find("select")
    }
    return (() => {
      const result = [];
      for (let prefix of ['primary_subtitle_-', 'secondary_subtitle_1_-', 'secondary_subtitle_2_-', 'secondary_subtitle_1_avc_-', 'secondary_subtitle_2_avc_-', 'secondary_subtitle_1_hevc_-', 'secondary_subtitle_2_hevc_-', 'secondary_subtitle_3_-', 'secondary_subtitle_4_-', 'secondary_subtitle_5_-', 'secondary_subtitle_6_-', 'secondary_subtitle_7_-', 'secondary_subtitle_8_-']) {
        if (target.find("select").attr('id') === `${prefix}_language_select_${params_id}`) {
          if (selected_option.val() === "" || selected_option.val() === undefined) {
            $(`#${prefix}_present_${params_id}`).val(false)
            $(`#${prefix}_asset_id_${params_id}`).val("")
            $(`#${prefix}_subtitle_type_${params_id}`).val("")
            $(`#${prefix}_path_${params_id}`).val("")
            $(`#${prefix}_framerate_${params_id}`).val("")
            $(`#${prefix}_language_${params_id}`).val("")
            $(`#${prefix}_language_iso_639-2_${params_id}`).val("")
            $(`#${prefix}_language_manual_select_${params_id}`).val("").change()
            $(`#${prefix}_language_manual_select_${params_id}`).parent().dropdown("clear")
            $(`#${prefix}_override_start_timecode_${params_id}`).val("")
            $(`#${prefix}_start_timecode_${params_id}`).val("")
            $(`#${prefix}_start_tc_label_${params_id}`).text("")
            $(`#${prefix}_spec_types_${params_id}`).text("")
            target.parent().parent().parent().find('.subtitle.field').hide()
            result.push(target.parent().parent().parent().find('.subtitle.accordion').hide())
          } else {
            $(`#${prefix}_present_${params_id}`).val(true)
            $(`#${prefix}_asset_id_${params_id}`).val(parseInt(selected_option.val()))
            $(`#${prefix}_subtitle_type_${params_id}`).val(selected_option.data('subtitle-type'))
            $(`#${prefix}_path_${params_id}`).val(selected_option.data('storageLocation'))
            $(`#${prefix}_framerate_${params_id}`).val(selected_option.data('framerate'))
            $(`#${prefix}_language_manual_select_${params_id}`).val(`${selected_option.data('iso639_2')} - ${selected_option.data('language')}`).trigger('change')
            $(`#${prefix}_override_start_timecode_${params_id}`).val(selected_option.data('overrideStartTc'))
            $(`#${prefix}_start_timecode_${params_id}`).val(selected_option.data('startTc'))
            $(`#${prefix}_start_tc_label_${params_id}`).text(selected_option.data('startTc'))
            $(`#${prefix}_spec_types_${params_id}`).val(selected_option.data('specTypes'))

            let tc_a = $(`#${prefix}_start_tc_label_${params_id}`).text().split("@")
            let tc_b = $(`#${prefix}_master_start_tc_label_${params_id}`).text().split("@")
            let tc_a_fr = parseFloat(tc_a[1]) || ""
            let tc_b_fr = parseFloat(tc_b[1]) || ""
            if (tc_a[0] !== tc_b[0] || tc_a_fr !== tc_b_fr) {
              $(`#${prefix}_start_tc_label_${params_id}`).addClass('red')
            } else {
              $(`#${prefix}_start_tc_label_${params_id}`).removeClass('red')
            }
            
            target.parent().parent().parent().find('.subtitle.field').show();
            result.push(target.parent().parent().parent().find('.subtitle.accordion').show());
          }
        } else {
          result.push(undefined);
        }
      }
      return result;
    })();
  },

  sidebar() {
    $("#workflow-jobs.compose-workflow-job .show.asset-search").on('click', function() {
      if ($("#workflow-jobs.compose-workflow-job .search.compose-assets").css('display') !== 'none') {
        $("#workflow-jobs.compose-workflow-job .search.compose-assets").hide()
      } else {
        $("#workflow-jobs.compose-workflow-job .search.compose-assets").show()
      }
    })

    search.asset_autocompletion("#workflow-jobs.compose-workflow-job .ui.search.compose-assets", { mam: true });

    let saved_workflow_items = $("#workflow-jobs.new-workflow-job .saved-workflows .workflow.item").not(".no-hover")

    if ($('.workflow-notifications-enabled').val() == 'false') {
      $('.set.notifications.on.for.all').addClass('disabled')
      $('.set.notifications.off.for.all').addClass('disabled')
      $('.workflow-finished.subscription.notifications.item').find('.icon').removeClass('red times')
      $('.workflow-finished.subscription.notifications.item').find('.icon').addClass('green check')
      $('.workflow-finished.subscription.notifications.item').find('span').text('Subscribe to Workflow Notifications')
    } else {
      $('.workflow-finished.subscription.notifications.item').find('.icon').removeClass('green check')
      $('.workflow-finished.subscription.notifications.item').find('.icon').addClass('red times')
      $('.workflow-finished.subscription.notifications.item').find('span').text('Unsubscribe from Workflow Notifications')
    }

    $('.set.notifications.on.for.all').on('click', function() {
      const bell_icon = document.querySelector('.notification.dropdown .bell')
      bell_icon.className = bell_icon.className.replace(bell_icon.className, $(this).find('.bell').attr('class'))
      $('.notifications-enabled').val('true').change()
      $('.new-workflows .sidebar.item').find('.bell.icon').addClass('blue')
      $('.new-workflows .sidebar.item').find('.bell.icon').removeClass('red grey outline slash')
    })
    $('.set.notifications.off.for.all').on('click', function() {
      const bell_icon = document.querySelector('.notification.dropdown .bell')
      bell_icon.className = bell_icon.className.replace(bell_icon.className, $(this).find('.bell').attr('class'))
      $('.notifications-enabled').val('false').change()
      $('.new-workflows .sidebar.item').find('.bell.icon').addClass('red slash outline')
      $('.new-workflows .sidebar.item').find('.bell.icon').removeClass('grey blue')
    })

    $('.workflow-finished.subscription.notifications.item').off('click')
    $('.workflow-finished.subscription.notifications.item').on('click', function() {
      var action = { subscribe: true }
      if ($('.workflow-notifications-enabled').val() == 'true') {
        action = { unsubscribe: true }
      }

      $.ajax({
        url: `/notification_types/2/update_subscription`,
        type: "POST",
        dataType: "json",
        data: action
      })
      .done(function(data) {
        if (data.result == 'subscribed') {
          $('.workflow-finished.subscription.notifications.item').find('.icon').removeClass('green check')
          $('.workflow-finished.subscription.notifications.item').find('.icon').addClass('red times')
          $('.workflow-finished.subscription.notifications.item').find('span').text('Unsubscribe from Workflow Notifications')
          $('.workflow-notifications-enabled').val('true')
          $('.set.notifications.on.for.all').removeClass('disabled')
          $('.set.notifications.off.for.all').removeClass('disabled')
          $('.new-workflows .sidebar.item').find('.bell.icon').removeClass('disabled')
        } else if (data.result == 'unsubscribed') {
          $('.workflow-finished.subscription.notifications.item').find('.icon').removeClass('red times')
          $('.workflow-finished.subscription.notifications.item').find('.icon').addClass('green check')
          $('.workflow-finished.subscription.notifications.item').find('span').text('Subscribe to Workflow Notifications')
          $('.workflow-notifications-enabled').val('false')
          $('.set.notifications.on.for.all').addClass('disabled')
          $('.set.notifications.off.for.all').addClass('disabled')
          $('.notifications-enabled').val('false').change()
          $('.new-workflows .sidebar.item').find('.bell.icon').addClass('disabled')
          $('.new-workflows .sidebar.item').find('.bell.icon').removeClass('blue grey')
          $('.new-workflows .sidebar.item').find('.bell.icon').addClass('red slash outline')
          workflow.update_notification_bell()
        }
      })
      .fail(function(_data) {
        
      })
    })

    $('#workflows').find('.new-workflows,.saved-workflows').find('.dropdown').dropdown({ 
      action: "hide", 
      forceSelection: false,
      onHide : function() {
        $(this).dropdown('clear');
      },
    })
    
    for (let element of Array.from(saved_workflow_items)) {
      $(element).on('click', function () {
        workflow.show_welcome()
        let new_workflow_items = $("#workflows .new-workflows .sidebar.item").not(".no-hover")
        $(new_workflow_items).removeClass("active")
        $(saved_workflow_items).removeClass("active")
        $(this).addClass("active")
        workflow.load_workflow_view($(this).data("wj-id"))
      })
    }

    $('.filter.dropdown .set.show.all.workflows.item').off("click")
    $('.filter.dropdown .set.show.all.workflows.item').on("click", function() {
      $('.filter.dropdown').data('filter-mode', 0)
      $('.filter.dropdown .item').attr('data-active', "false")
      users.settings("workflow.saved_workflows.filter_mode", $('.filter.dropdown').data('filter-mode'))
      workflow.filter_workflow_view($('.filter.dropdown').data('filter-mode'))
    })

    $('.filter.dropdown .set.show.only.item').off("click")
    $('.filter.dropdown .set.show.only.item').on("click", function() {
      if ($(this).attr('data-active') == "true") {
        $('.filter.dropdown').data('filter-mode', $('.filter.dropdown').data('filter-mode') - $(this).data('value'))
        $(this).attr('data-active', "false")
      } else {
        $('.filter.dropdown').data('filter-mode', $('.filter.dropdown').data('filter-mode') + $(this).data('value'))
        $(this).attr('data-active', "true")
      }
      users.settings("workflow.saved_workflows.filter_mode", $('.filter.dropdown').data('filter-mode'))
      workflow.filter_workflow_view($('.filter.dropdown').data('filter-mode'))
    })

    $('.add-default-workflows').off("click")
    $('.add-default-workflows').on("click", function() {
      workflow.add_default_workfows()
    })

    $('.remove-all-workflows').off("click")
    $('.remove-all-workflows').on("click", function() {
      $("#workflow-job-list .remove_nested_fields_link").trigger("click")
    })

    workflow.filter_workflow_view($('.filter.dropdown').data('filter-mode'))
  },

  add_default_workfows() {
    $.ajax({
      url: `/assets/${$(".asset-segment").data("asset-id") || $("#workflow_asset_ids").val()?.split(' ')[0]}/default_workflows`,
    }).done( function(data) {
      data.forEach(function(item) {
        workflow.add_workflow_template(item, true)
      })
    })
  },

  ensure_default_workflow_order() {
    let wjt_ids_that_come_first = ['172']
    let first_order_jobs = $('fieldset.nested_workflow_workflow_jobs').filter((i, wj) => { 
      let template = $(wj).find('.workflow-job-template').val()
      return wjt_ids_that_come_first.includes(template)
    })
    let first_order_uuids = first_order_jobs.map((i, wj) => $(wj).find('.workflow-job-uuid').val()).toArray()
    if (first_order_uuids.length) {
      let excluded_templates = ['47'].concat(wjt_ids_that_come_first)
      let added_jobs = $('fieldset.nested_workflow_workflow_jobs').filter((i, wj) => { 
        let template = $(wj).find('.workflow-job-template').val()
        return !excluded_templates.includes(template)
      })
      added_jobs.each((i, wj) => {
        let uuid_dropdown = $(wj).find('.bottom.attached .start-workflow.dropdown')
        uuid_dropdown.parent().show()
        $(wj).find('.bottom.attached .start-workflow .wait.button').trigger('click')
        let current_selection = uuid_dropdown.dropdown('get value')
        uuid_dropdown.dropdown('set selected', first_order_uuids.concat(current_selection))
      })
    }
  },
  
  filter_workflow_view(filter_mode) {
    if (filter_mode == 0) {
      $('.filter.dropdown .filter.icon').removeClass('blue')
    } else {
      $('.filter.dropdown .filter.icon').addClass('blue')
    }
    $('.filter.dropdown .item[data-active=false]').find('.icon').removeClass('blue')
    $('.filter.dropdown .item[data-active=true]').find('.icon').addClass('blue')
    $('.saved-workflows .workflow.item').each(function(_i, _item) {
      $(this).show()
      if ([1, 3].includes(filter_mode)) {
        if ($(this).data('upw-id') != $("#current_user_id").val()) {
          $(this).hide()
        }
      }
      if ([2, 3].includes(filter_mode)) {
        if (["succeeded", "canceled", "failed"].includes($(this).data('status-id'))) {
          $(this).hide()
        }
      }
    })
  },

  load_workflow_view(workflow_id) {
    $("#workflow-job-list").hide()
    $("#saved-workflow-job-list").html('<div class="ui active loader"></div>')
    $.ajax({
      url: `/workflow_jobs/${workflow_id}/details`,
    }).done( function(data) {
      $("#saved-workflow-job-list").html(data)
      $('#saved-workflow-job-list .item[data-tab]').tab()
      $('#saved-workflow-job-list .accordion').accordion()
      context_menu.init()
      workflow.load_identifier_names(workflow_id, $("#saved-workflow-job-list .workflow-job[data-wj-id="+ workflow_id +"]").data("wjt-id"))
    })
  },

  load_identifier_names(workflow_id, template_id) {
    $.ajax({
      url: `/workflow_job_templates/${template_id}/identifier_names.json`,
      dataType: "json",
    }).done( function(data) {
      $("#saved-workflow-job-list .workflow-job[data-wj-id="+ workflow_id +"]").find("td strong").each(function(_i, item) {
        data.forEach(function(data_var) {
          if (data_var["Identifier"].toLowerCase() == $(item).text().toLowerCase()) {
            $(item).text(data_var["Name"])
          }
        })
      })
    })
  },

  set_subtitle_language(target, params_id) {
    let selected_option;
    if (target.hasClass('dropdown')) {
      if (target.dropdown("get value") !== "") {
        selected_option = $(`#${target.find("select").attr("id")}`).find(`option[value="${target.dropdown("get value")}"][data-iso639-1],option[value="${target.dropdown("get value")}"]`)
      } else {
        selected_option = target.find("select")
      }
    } else {
      selected_option = target.find("select")
    }
    return (() => {
      const result = [];
      for (let prefix of ['primary_subtitle_-', 'secondary_subtitle_1_-', 'secondary_subtitle_2_-', 'secondary_subtitle_1_avc_-', 'secondary_subtitle_2_avc_-', 'secondary_subtitle_1_hevc_-', 'secondary_subtitle_2_hevc_-', 'secondary_subtitle_3_-', 'secondary_subtitle_4_-', 'secondary_subtitle_5_-', 'secondary_subtitle_6_-', 'secondary_subtitle_7_-', 'secondary_subtitle_8_-']) {
        if (target.find("select").attr('id') === `${prefix}_language_manual_select_${params_id}`) {
          if (selected_option.val() === "" || selected_option.val() === undefined) {
            target.dropdown("clear")
            $(`#${prefix}_language_${params_id}`).val("")
            $(`#${prefix}_language_iso_639-1_${params_id}`).val("")
            result.push($(`#${prefix}_language_iso_639-2_${params_id}`).val(""))
          } else {
            const language = selected_option.text().split(" - ")
            $(`#${prefix}_language_${params_id}`).val(language[1])
            $(`#${prefix}_language_iso_639-1_${params_id}`).val(selected_option.data('iso639-1'))
            result.push($(`#${prefix}_language_iso_639-2_${params_id}`).val(language[0]))
          }
        } else {
          result.push(undefined);
        }
      }
      return result;
    })();
  },

  update_whparams(element) {
    let whparams = [];
    for (let params of Array.from($(element).find(".params-input").not($(element).find('.nested-params').find('.params-input')))) {
      var hash, params_value;
      if ($(params).is(':checkbox')) {
        params_value = $(params).prop('checked');
      } else if ($(params).hasClass('dropdown')) {
        params_value = $(params).find("select").val();
      } else {
        params_value = $(params).val();
      }
      if ($(params).hasClass('dropdown')) {
        hash = { id: $(params).find("select").attr('id'), uuid: $(params).find("select").data('uuid') , value: params_value };
      } else {
        hash = { id: $(params).attr('id'), uuid: $(params).data('uuid') , value: params_value };
      }
      whparams.push(hash);
    }

    for (let nested_param_array of Array.from(Array.from($(element).find('.nested-params-input')).reduce((m, o) => m.set(o['id'], [...(m.get(o['id']) || []), o]), new Map).values())) {
      if (nested_param_array === undefined || nested_param_array.length == 0) {
        // empty nested array
      } else {
        const hash = { id: $(nested_param_array[0]).attr('id'), uuid: $(nested_param_array[0]).data('uuid') , value: nested_param_array.map(x => JSON.parse($(x).val())) }
        whparams.push(hash)
      }
    }

    whparams = JSON.stringify(whparams, null, null);
    $(element).find(".wj_Params").val(whparams);
  },

  init_workflow_specific_code(element, params_id) {
    if ($(element).find(".nested-fields.workflow_template").length) {
      workflow_template.init(element, params_id);
    }
    if ($(element).find(".nested-fields .itunes-audio").length) {
      itunes_audio.init(element, params_id);
    }
    if ($(element).find(".nested-fields .telekom-audio").length) {
      telekom_audio.init(element, params_id);
    }
    if ($(element).find(".nested-fields .primary-audio").length) {
      primary_audio.init(element, params_id);
    }
    if ($(element).find(".nested-fields .secondary-audio-01").length) {
      secondary_audio_01.init(element, params_id);
    }
    if ($(element).find(".nested-fields .stereo-audio-convert").length) {
      stereo_audio_convert.init(element, params_id);
    }
    if ($(element).find(".nested-fields .cpn-select").length) {
      content_package_name.init(element, params_id)
    }
    if ($(element).find(".nested-fields.workflow_1503").length) {
      workflow_1503.init(element, params_id);
    }
    if ($(element).find(".nested-fields.workflow_7202").length) {
      workflow_7202.init(element, params_id);
    }
    if ($(element).find(".nested-fields.workflow_7203").length) {
      workflow_7203.init(element, params_id);
    }
    if ($(element).find(".nested-fields.workflow_7205").length) {
      workflow_7202.init(element, params_id);
    }
    if ($(element).find(".nested-fields.workflow_7352").length) {
      workflow_7352.init(element, params_id);
    }
    if ($(element).find(".nested-fields.workflow_7425").length) {
      workflow_7425.init(element, params_id);
    }
    if ($(element).find(".nested-fields.workflow_50000").length) {
      workflow_50000.init(element);
    }
    if ($(element).find(".nested-fields.workflow_50001").length) {
      workflow_50001.init(element, params_id);
    }
    if ($(element).find(".nested-fields.workflow_50003").length) {
      workflow_50003.init(element, params_id);
    }
    if ($(element).find(".nested-fields.workflow_50008").length) {
      workflow_50008.init(element, params_id);
    }
    if ($(element).find(".nested-fields.workflow_50012").length) {
      workflow_50012.init(element, params_id);
    }
    if ($(element).find(".nested-fields.workflow_50013").length) {
      workflow_50013.init(element, params_id);
    }
    if ($(element).find(".nested-fields.workflow_50014").length) {
      workflow_50014.init(element, params_id);
    }
    if ($(element).find(".nested-fields.workflow_50017").length) {
      workflow_50017.init(element, params_id);
    }
    if ($(element).find(".nested-fields.workflow_50018").length) {
      workflow_50018.init(element, params_id);
    }
    if ($(element).find(".nested-fields.workflow_50019").length) {
      workflow_50019.init(element, params_id);
    }
    $('.with-tooltip').popup();
  },

  init_elements(element, params, params_id) {
    $(element).find(".priority.dropdown").dropdown('clear');
    $(element).find(".primary-qc-box").hide();
    $(element).find(".datamover-scheduler").dropdown('clear');
    $(element).find(".datamover-scheduler").hide();
    $(element).find(".primary-qc-box").hide();
    $(element).find(".primary-qc").prop("checked", false);
    $(element).find(".workflow-params").empty();

    $(element).find(".workflow-name").empty();
    $(element).find(".workflow-name").text(params["workflow_name"]);
    $(element).find(".workflow-name").data("workflow-id", params["workflow_id"]);
    $(element).find(".workflow-params").append(`<div id="workflow_view_${params_id}"><div class="ui active centered inline loader"></div></div>`);
    $(element).find(".presets_placeholder").attr('id', `presets_placeholder_${params_id}`)
    $(element).find(".workflow-job-asset-id").val(params["asset_ids"]);
    $(element).find(".workflow-job-template").val(params["workflow_id"]);
    $(element).find(".workflow-job-type").val(params["workflow_type"]);
  },

  init_parameteroverviewmodal(element) {
    const parameteroverviewmodal = $(element).find(".ui.modal.parameter-overview-modal");
    $(element).find(".ui.modal.parameter-overview-modal").modal();
    $(element).find(".parameter-overview").on('click', function() {
      const parameters = JSON.parse($(element).find(".wj_Params").val());
      const table = $(parameteroverviewmodal).find('.parameter-table');
      table.html("");
      for (let parameter of Array.from(parameters)) {
        let value = parameter['value'];
        if (value !== null && typeof parameter['id'] === 'string') {
          table.append(`<tr><td class="six wide"><strong>${parameter['id'].slice(0, -6)}</strong></td><td class="ten wide">${value}</td></tr>`);
        }
      }
      $(parameteroverviewmodal).modal('show');
    });
  },
  
  init_whparams(element, params_id) {
    let whparams = [];
    for (let params of Array.from($(element).find('.params-input').not($('.nested-params').find('.params-input')))) {
      var params_value;
      if ($(params).is(':checkbox')) {
        params_value = $(params).prop('checked');
      } else {
        params_value = $(params).val();
      }
      if ($(params).hasClass('audio-select')) {
        workflow.set_audio_params($(params), params_id, true);
      } else if ($(params).hasClass('subtitle-select')) {
        workflow.set_subtitle_params($(params), params_id);
      } else if ($(params).hasClass('subtitle-language-select')) {
        workflow.set_subtitle_language($(params), params_id);
      }
      const hash = { id: $(params).attr('id'), uuid: $(params).data('uuid') , value: params_value };
      whparams.push(hash);

      $(params).on('change', function() {
        if ($(this).hasClass('format-timecode')) {
          $(this).val(Timecode.format_timecode_parameter($(this).val(), $('[id^="primary_video_-_framerate_"]').val()))
        }
        if ($(this).hasClass('decoder-select')) {
          $('.decoder-field').val(false)
          $(`#primary_video_-_force_decoder_-_${$(this).find('select').val().toLowerCase()}_${params_id}`).val("true").change()
        }
        if ($(this).hasClass('audio-select')) {
          workflow.set_audio_params($(this), params_id, false)
        } else if ($(this).hasClass('audio-language-select')) {
          workflow.set_audio_language($(this), params_id)
        }
        if ($(this).hasClass('subtitle-select')) {
          workflow.set_subtitle_params($(this), params_id)
        } else if ($(this).hasClass('subtitle-language-select')) {
          workflow.set_subtitle_language($(this), params_id)
        }
        return workflow.update_whparams(element)
      });
    }

    
    for (let nested_param_array of Array.from(Array.from($(element).find('.nested-params-input')).reduce((m, o) => m.set(o['id'], [...(m.get(o['id']) || []), o]), new Map).values())) {
      if (nested_param_array === undefined || nested_param_array.length == 0) {
        // empty nested array
      } else {
        const hash = { id: $(nested_param_array[0]).attr('id'), uuid: $(nested_param_array[0]).data('uuid') , value: nested_param_array.map(x => JSON.parse($(x).val())) }
        whparams.push(hash)
      }
      for (let nested_param of nested_param_array) {
        $(nested_param).change(function() {
          workflow.update_whparams(element)
        }) 
      }
    }

    whparams = JSON.stringify(whparams, null, null);
    return $(element).find(".wj_Params").val(whparams);
  },

  new_workflow_sidebar_item(element, uuid, params = {}) {
    $("#workflow-job-list fieldset").hide()

    $(element).show()
    const type = parseInt(params.workflow_type)

    var t_label = ''
    var t_color = ''
    switch (type) {
      case 3:
        t_label = 'MAM'
        t_color = "blue"
        break;
      case 1:
        t_label = 'Vantage'
        t_color = "orange"
        break;
      case 2:
        t_label = 'Vidchecker'
        t_color = "purple"
        break;
      default:
        t_label = 'Unknown'
        t_color = "grey"
    }
    const type_label = `<span class="ui mini ${ t_color } basic label pull-right" style="margin-top: 6px;">${t_label}</span>`
    const bell = `<i class="disabled ${$('.workflow-notifications-enabled').val() == 'true' ? 'blue' : 'red slash outline'} bell icon pull-right"></i>`
    const prio = '<i class="blue down angle icon pull-right" style="margin-right: 14px;"></i>'

    $(".new-workflows .active.item").removeClass("active")
    var item = `
    <a class='active sidebar item'>
      <input type="hidden" name="sidebar-item-workflow-${uuid}" id="sidebar-item-workflow-${uuid}" value="${uuid}" class="sidebar-item workflow-uuid">
      <div class="ui grid">
        <div class="twelve wide column">
          <h5 class="ui header">
            ${params.workflow_name}
            <div class="sub header" style="margin-top: 8px;">${params.workflow_description != undefined ? params.workflow_description : "&nbsp;" }</div>
          </h5>
        </div>
        <div class="four wide column">
          <div class="two columns">
            ${bell}${prio}${type_label}
          </div>
        </div>
      </div>
    </a>`

    let sidebar_item = $(item).appendTo('.new-workflows')
    workflow.init_sidebar_item_notification_bell(sidebar_item)

    let saved_workflow_items = $("#workflow-jobs.new-workflow-job .saved-workflows .workflow.item").not(".no-hover")
    $(saved_workflow_items).removeClass("active")
    $("#workflow-job-list").show()
    $("#saved-workflow-job-list").html("")

    workflow.init_sidebar_item_event_handler(sidebar_item, element)
  },

  init_sidebar_item_event_handler(item, element) {
    $(item).on('click', function() {
      workflow.show_welcome()
      let new_workflow_items = $("#workflow-jobs .new-workflows .sidebar.item").not(".no-hover")
      let saved_workflow_items = $("#workflow-jobs.new-workflow-job .saved-workflows .workflow.item").not(".no-hover")

      $("#workflow-job-list fieldset").hide()
      $(element).show()

      $(new_workflow_items).removeClass("active")
      $(saved_workflow_items).removeClass("active")
      $(this).addClass("active")
      $("#workflow-job-list").show()
      $("#saved-workflow-job-list").html("")
    })
  },

  init_sidebar_item_notification_bell(item) {
    $(item).find('.bell.icon').on('click', function() {
      const workflow_uuid = $(item).find('.sidebar-item.workflow-uuid').val()
      const notification_enabled_item = $(`.workflow-job-uuid:input[value="${workflow_uuid}"]`).parents('.nested_fields.nested_workflow_workflow_jobs').find('.notifications-enabled')
      var current_value = notification_enabled_item.val()
      notification_enabled_item.val(current_value == 'true' ? 'false' : 'true').change()
      current_value == 'true' ? $(this).first().removeClass('blue') : $(this).first().removeClass('red slash outline')
      current_value == 'true' ? $(this).first().addClass('red slash outline') : $(this).first().addClass('blue')

      workflow.update_notification_bell()
    })
  },

  update_notification_bell() {
    // update main notification bell
    const main_bell = $('.notification.dropdown .bell').first()

    const some_disabled = Array.from($('.new-workflows').find('.sidebar.item').find('.bell.icon')).map(x => $(x).hasClass('slash')).some(x => x == true)
    const some_enabled  = Array.from($('.new-workflows').find('.sidebar.item').find('.bell.icon')).map(x => $(x).hasClass('slash')).some(x => x == false)

    switch ([some_disabled, some_enabled].toString()) {
    case 'true,true':
      main_bell.removeClass('slash blue red')
      main_bell.addClass('grey outline')
      // custom icon
      break
    case 'true,false':
      main_bell.removeClass('blue grey')
      main_bell.addClass('red slash outline')
      // all disabled
      break
    case 'false,true': 
      main_bell.removeClass('slash red grey outline')
      main_bell.addClass('blue')
      // all enabled
      break
    default: 
    }
  },

  fetch_view(element, params, params_id) {
    workflow.show_welcome()
    const uuid_string = utils.uuidv4().toUpperCase()
    workflow.new_workflow_sidebar_item(element, uuid_string, params)
    $(element).find('.workflow-job-uuid').val(uuid_string)
    $(element).find('.workflow-job-params-id').val(params_id)
    let sidebar_item = $(`.sidebar.item input[value=${uuid_string}]`).parent('.sidebar.item')
    let workflow_view = element.find(`[id^="workflow_view_${params_id}"]`)

    let request_options = {
      url: `/workflow_job_templates/${params["workflow_id"]}/workflow_view`,
      type: "GET",
      headers: {
        'Accept': 'text/vnd.turbo-stream.html'
      },
      data: {
        asset_ids: params["asset_ids"],
        element_id: params_id,
        element_uuid: uuid_string,
        default_workflow: params['default_workflow'],
      },
      beforeSend() {
        forms.disable("fetch_view")
        sidebar_item.find('.view.load.error.label').remove()
        workflow_view.html(`<div class="ui active centered inline loader"></div>`)
      },
      success: function(data, _textStatus, _xhr) {
        Turbo.renderStreamMessage(data)
      },
      error: function(jqXHR, _textStatus, errorThrown) {
        console.log(jqXHR)
        console.log(errorThrown)
        workflow.display_view_load_error(element, params_id, uuid_string, request_options)
      }
    }

    return $.ajax(request_options)
  },

  display_potential_issues(selected_option, target) {
    let potential_issues = selected_option.data('potential-issues')
    $(target).siblings('.potential_issues').remove()
    if (potential_issues && !jQuery.isEmptyObject(potential_issues) && $(target).hasClass('display-potential-issues')) {
      var issues = ""
      for (const [key, value] of Object.entries(potential_issues)) {
        let titlecase_key = key.toString().split('_').map((item) => item.charAt(0).toUpperCase() + item.substring(1)).join(' ')
        issues += `<li>${titlecase_key}: ${value}</li>`
      }
      let message = `<div class="ui visible potential_issues warning message"><h4>Found potential issues!</h4><ul>${issues}</ul></div>`
      $(message).insertAfter($(target))
    }
    
  },

  display_parameter_validation_errors(error_hashes) {
    $('.field.error').removeClass('error')
    $.each(error_hashes, function(_index, error_hash) {
      if(error_hash.display_ids?.length > 0) {
        $.each(error_hash.display_ids, function(_index, display_id) {
          $(`[id=${display_id}]`).parents('.field').first().addClass('error')
        })
      } else if (error_hash.dependent_parameter_id) {
        $(`[id=${error_hash.dependent_parameter_id}]`).parents('.field').first().addClass('error')
      } else {
        $(`[id=${error_hash.parameter_id}]`).parents('.field').first().addClass('error')
      }
    })
  },
  
  // Presets
  get_preset_element_type(present_element) {
    let element = $(present_element)
    if(element.is(':checkbox'))              return ":checkbox"
    if(element.is('.dropdown:not(.remote)')) return "dropdown"
    if(element.is('.remote.dropdown'))       return "remote_dropdown"
    if(element.is(':input'))                 return ":input"
    if(element.is('select'))                 return "select"
  },
  
  get_preset_identifier(el) {
    let element = $(el)

    let selector = "[data-preset-identifier],[data-uuid]"
    var child_presettables = element.children(selector)

    if (element.data('preset-identifier')) return element.data('preset-identifier')
    if (element.data('uuid')) return element.data('uuid')
    if (element.is('.deployment.spec')) return ".deployment.spec"

    return child_presettables.data('preset-identifier') || child_presettables.data('uuid')
  },
  
  get_preset_value(el) {
    let element = $(el)
    switch (workflow.get_preset_element_type(element)) {
      case ':checkbox': return element.prop('checked')
      case 'dropdown': case 'remote_dropdown': case 'select': return element.dropdown('get value') // query remote?
      case ':input': return element.val()
      default: return undefined
    }
  },
  
  set_preset_value(el, value) {
    let element = $(el)
    switch (workflow.get_preset_element_type(element)) {
      case ':checkbox': 
        if (element.parent().is('.checkbox')) { 
          return element.parent().checkbox( value ? 'check' : 'uncheck' ) 
        } else {
          return element.prop('checked', value)
        }
      case 'dropdown': case 'select': return element.dropdown('set selected', value)
      case 'remote_dropdown': 
        let remote_dropdown_element = element.closest('.dropdown')
        if (remote_dropdown_element.data('module-dropdown')) {
          remote_dropdown_element.dropdown('queryRemote', '', function(response) {
            remote_dropdown_element.dropdown('set selected', value)
          })
          return true
        } else {
          return element.dropdown('set selected', value)
        }        
      case ':input':
        let search_item = element.parents(':eq(0).search,:eq(1).search').first()
        if (search_item.length > 0) { // search input
          element.val(value)
          search_item.search('query', () => {
            search_item.search('select firstResult')
            search_item.children('.results').find('.active').trigger('click')
          })
          return search_item
        } else {  // standard input
          return element.val(value).trigger('change')
        }
      default: return undefined
    }
  },
  
  gather_preset_values(target_element) {
    let target = $(target_element)
    let preset_fields = target.find('.presettable')
    var preset_values = []
    preset_fields.each(function() {
      let element = $(this)    
      let preset_identifier = workflow.get_preset_identifier(element)
      if (preset_identifier) {
        preset_values.push({identifier: preset_identifier, value: workflow.get_preset_value(element)})
      }
    })
    return preset_values
  },
  
  fill_preset_values(target, preset_values) {
    if (target && Array.isArray(preset_values)) {
      let preset_fields = $(target).find('.presettable')
      var filled_in_presets = []
      preset_fields.each(function() {
        let element = $(this)    
        let preset_identifier = workflow.get_preset_identifier(element)
        if (preset_identifier) {
          let preset = preset_values.find(o => o.identifier === preset_identifier)
          if (preset) {
            workflow.set_preset_value(element, preset?.value)
            filled_in_presets.push(preset)
          }
        }
      })
      let failed = preset_values.filter(e => !filled_in_presets.includes(e))
      return failed?.length == 0 ? true : failed
    } else {
      return false
    }
  },

  display_view_load_error(element, params_id, uuid_string, request_options) {
    let sidebar_item = $(`.sidebar.item input[value=${uuid_string}]`).parent('.sidebar.item')
    let workflow_view = element.find(`[id^="workflow_view_${params_id}"]`)
    
    let error_message = $(`
      <div class="ui center aligned icon view load error message">
        <i class="exclamation triangle icon"></i>
        <div class="content">
          <div class="header">
              Workflow View Failed to load!&nbsp;</div>
          <p><div class="ui red mini compact retry button">Click to Retry</div></p>
        </div>
      </div>
    `)
    workflow_view.html(error_message)
    error_message.find('.retry.button').on('click', function() {
        $.ajax(request_options)
        console.log('clicked')
    })

    sidebar_item.find('.grid').prepend(`
      <a class="ui left red mini view load error corner label">
        <i class="exclamation triangle icon"></i>
      </a>
    `)
  },

  finalize_view(element, params_id, uuid) {
    if (workflow_jobs.count_visible_workflows() > 1) { 
      $(".start-workflow").show()
    }

    sortable('.sortable', {
      forcePlaceholderSize: true,
      placeholderClass: 'workflow-placeholder',
      handle: '.top.attached.segment'
    })
    $(element).find(".workflow-params .dropdown").not('.remote,.ensure-same-language,.custom').dropdown({ placeholder: false, fullTextSearch: true })
    $('.ensure-same-language.dropdown').dropdown({ 
      placeholder: false, 
      fullTextSearch: true,
      allowReselection: true,
      clearable: true,
      onChange: function(value, _text, _$choice) {
        let select = $(this).is('select') ? $(this) : $(this).find('select')
        let selected_option = select.find(':selected')
        let associated_select_id = select.attr('id').replace(/(.*_audio_-_)(?<layout>\d\d)(_-_.*)/, (_a, prefix, layout, suffix) => {
          return `${prefix}${layout == "51" ? "20" : "51"}${suffix}`
        })
        let associated_select = $(`[id="${associated_select_id}"]`)
        let item_menu = associated_select.siblings('.menu')

        associated_select.find('option').toArray().forEach( x => x.removeAttribute('disabled') )
        item_menu.find('.item').toArray().forEach( x => x.classList.remove('disabled') )

        // eslint-disable-next-line no-empty
        if (value === "" || value === "Dummy Track") {
        } else {
          let options_to_disable = associated_select.find('option').not(`:not([data-track-id]),[data-track-id="dummy"],[data-iso639_2="${selected_option.data('iso639_2')}"]`).toArray()
          let associated_dropdown = associated_select.parents(':eq(0).dropdown,:eq(1).dropdown').first()
          
          options_to_disable.forEach( x => { 
            //$(x).attr('disabled', 'disabled')
            let option_item = item_menu.find(`.item[data-value="${$(x).val()}"]`)
            
            option_item.addClass('disabled')
            if (option_item.is('.selected,.selected.active')) {
              associated_dropdown.dropdown('clear', true)
              option_item.removeClass('selected active')
            }
          })
        }
      }
    })
    
    $(element).find('.ui.checkbox').not('.custom').checkbox()
    $(element).find('.workflow-job-uuid').val(uuid)
    const sidebar_bell = $(`.sidebar-item.workflow-uuid:input[value="${uuid}"]`).parents('.new-workflows .sidebar.item').find('.bell.icon')
    
    if ($('.workflow-notifications-enabled').val() == 'true') {
      sidebar_bell.removeClass('disabled')
    } else {
      $('.workflow-notifications-enabled').val('false').change()
      $(element).find('.notifications-enabled').val('false').change()
    }
    
    if ($(element).find('.notifications-enabled').val() == "false") {
      sidebar_bell.removeClass('blue grey')
      sidebar_bell.addClass('red slash outline')
    }

    workflow.update_notification_bell()
    $(element).find(".start-workflow.buttons .button").not('active').on('click',  function(event) {
      event.preventDefault()
      workflow_jobs.switch_start_workflow_buttons(element, $(this))
      return false
    })

    $(element).find('.workflow-options.dropdown').dropdown({ 
      action: "hide", 
      forceSelection: false,
      onHide : function() {
        $(this).dropdown('clear');
      },
      
    })

    $(element).find(".clone-workflow").on('click',  function() {
      if (element instanceof jQuery) { element = element.get(0) }
      $(this).closest('.workflow-options.dropdown').dropdown('hide', function() {
          workflow.duplicate_workflow(element)
        }
      )
    })

    $(element).find(".duplicate-workflow").on('click',  function() {
      const asset_ids = $(element).find('.workflow-job-asset-id').val()
      const template_id = parseInt($(element).find('.workflow-job-template').val())
      const name = $(element).find('.workflow-name').text()

      const workflow_description = $(".sidebar.item.active").find('.sub.header').text()
      const workflow_type = parseInt($(element).find('.workflow-job-type').val())

      const is_archived = (!$("#workflow_search").data("is-archived").includes(false))
      const add_workflow_link = $('.add_nested_fields_link[data-object-class="workflow_job"]')
      add_workflow_link.trigger('click', [{ asset_ids, workflow_id: template_id, workflow_name: name, workflow_description: workflow_description, workflow_type: workflow_type, is_archived: is_archived}])
      
      return false
    })

    if ($(element).find(`#workflow_type_${params_id}`).val() === "2") {
      $(element).find(".primary-qc-box").show();
      if ($(element).find(`#is_primary_automated_qc_${params_id}`).val() === "true") {
        $(element).find(".primary-qc").prop("checked", true);
      } else {
        $(element).find(".primary-qc").prop("checked", false);
      }
    }
    if ($(element).find(`#workflow_type_${params_id}`).val() === "3") {
      $(element).find(".datamover-scheduler").dropdown({
        clearable: true
      });
      $(element).find(".datamover-scheduler").show();
      $(element).find(".datamover-scheduler").dropdown('setting', 'onChange', function(value, _text, _$choice) {
        $(element).find(`#schedule_${params_id}`).val(value);
        workflow.update_whparams(element);
      });
    }
    workflow.init_parameteroverviewmodal(element);

    $.each($(element).find('.workflow-params .language-search'), (_i, item) =>
      $(item).dropdown({
        apiSettings: {
          cache: false,
          url: '/languagecode?q={/query}'
        },
        templates: {
          menu: dropdownTemplates.language_search_menu
        }
      }).dropdown('set selected', $(item).val())
    );
    $(element).find(".workflow-params .popup").popup();
    
    workflow.init_whparams(element, params_id);
    workflow.init_workflow_specific_code(element, params_id);

    $(element).find(".priority.dropdown").dropdown('setting', 'onChange', function(value, _text, $choice) {
      const wj_uuid = $(element).find('.workflow-job-uuid').val()
      $(`#sidebar-item-workflow-${wj_uuid}`).parent().find('.angle.icon').attr('class', $choice.find('i').attr('class')).addClass('pull-right')
      $(element).find(`#priority_${params_id}`).val(value);
      workflow.update_whparams(element);
    });
    //quick and dirty fix for #MAM-1655 No Language Flag on 5.1 Downmix in Telekom Workflow
    $('.audio-language-select.primary_audio').trigger('change')
    $('.audio-language-select.secondary_audio').trigger('change')
    $('.audio-language-select.tertiary_audio').trigger('change')
    $('.audio-language-select.quaternary_audio').trigger('change')
    $('.audio-language-select.quinary_audio').trigger('change')
    $(element).find(".accordion").not('.custom').accordion()

    $(`#create_preview_${params_id}`).change(function() {
      if ($(this).prop("checked")) {
        $(".create-preview.field").show();
      } else {
        $(".create-preview.field").hide();
      }
    });
    $(`#create_preview_${params_id}`).change();

    $(`#primary_video_-_force_mezzanine_resolution_${params_id}`).change(function() {
      if ($(this).prop("checked")) {
        $(".force-resolution.field").show();
      } else {
        $(".force-resolution.field").hide();
      }
    });
    $(`#primary_video_-_force_mezzanine_resolution_${params_id}`).change();

    $(".perform-upload").change(function() {
      if ($(".perform-upload").filter(":checked").length) {
        $(".perform-upload.field").show();
      } else {
        $(".perform-upload.field").hide();
      }
    });
    $(".perform-upload").change();

    $(`#perform_edit_${params_id}`).change(function() {
      if ($(this).prop("checked")) {
        $(".perform-edit.field").show();
      } else {
        $(".perform-edit.field").hide();
      }
    });
    $(`#perform_edit_${params_id}`).change();

    $(`#set_start_timecode_${params_id}`).change(function() {
      if ($(this).prop("checked")) {
        $(".start-tc.field").show();
      } else {
        $(".start-tc.field").hide();
      }
    });
    $(`#set_start_timecode_${params_id}`).change();

    $(`#perform_cropping_${params_id}`).change(function() {
      if ($(this).prop("checked")) {
        $(".cropping.fields").show();
      } else {
        $(".cropping.fields").hide();
      }
    });
    $(`#perform_cropping_${params_id}`).change();

    $('.all.priorities.selection .dropdown').dropdown({
      action: 'hide',
      onChange(value, _text, _$choice) {
        $('.nested-workflow .priority.dropdown').each((_i, item) =>
          $(item).dropdown('set selected', value)
        )
      }
    })

    workflow.set_subtitle_params(element, params_id);
    
    workflow.update_whparams(element);
    $(element).find(".workflow-params .dropdown").not('.remote,.custom').dropdown('setting', 'fullTextSearch', true)
    
    $(element).find(".workflow-params .custom.tmp_vantage_fix.dropdown").dropdown({
      fullTextSearch: true,
      fireOnInit: true,
      onChange: function(value, _text, _selectedItem) {  
        $(`#${this.id.replace('input', 'output')}`).dropdown('set selected', value)
      }
    })

    workflow.add_potential_issue_labels($('.selection.dropdown'))

    $('.sync_group_options.dropdown').dropdown({
      onChange(value, _text, _selectedItem) {

        let dropdown_element = $(this).is('.dropdown') ? $(this) : $(this).parent('.dropdown')

        switch(value) {
          case 'auto':
            dropdown_element.siblings('input.skip_sync_groups').val(false).trigger('change')
            dropdown_element.siblings('input.force_sync_slaves').val(false).trigger('change')
            break
          case 'copy_slaves':
            dropdown_element.siblings('input.skip_sync_groups').val(true).trigger('change')
            dropdown_element.siblings('input.force_sync_slaves').val(true).trigger('change')
            break
          case 'skip':
            dropdown_element.siblings('input.skip_sync_groups').val(true).trigger('change')
            dropdown_element.siblings('input.force_sync_slaves').val(false).trigger('change')
            break
        }
      }
    })

    let dropdown_warning = `
      <div class="dropdown-warning header">
      <a href="/assets/${$("#workflow_search").data("asset-ids")[0]}/edit?tab=association" class="fluid" target="_blank" style="">
      <i class="exclamation triangle icon"></i>
        Nothing Found! Possible reasons: Missing Sync Groups.
      <i class="external alternate icon link"></i>
      </a>
    </div>
    `
    $('.dropdown').not('.remote').find('select').filter((_i,x) => { 
      return x.id.match(/secondary_subtitle.*language_select|.*_audio_-_(20|51)_-_language_select/) &&
      $(x.options).filter((_i,x) => x.value != '').map((_i,x) => x.value).length == 0 
    }).each((_i,x) => {
      $(x).siblings('.menu').append($(dropdown_warning))
    })

    const wjt_id = $(element).find('[id$=wjt_ID]').val()

    $(element)[0]?.dispatchEvent(new CustomEvent('workflows:finalizedView', { bubbles: true, detail: { wjt_id: wjt_id } }))
    return true
  },

  add_potential_issue_labels(dropdowns) {
    $(dropdowns).find('option[data-potential-issues]').each(function(_i, option) { 
      let parent_dropdown = $(option).parentsUntil('.dropdown').parent('.dropdown')
      
      let option_index = Array.prototype.indexOf.call(parent_dropdown.find('option'), option)
      let associated_item = parent_dropdown.find('.item')[option_index]
      
      if (associated_item?.classList.contains('disabled')) {
        let potential_issues = $(option).data('potential-issues')
        
        let errors = Array().concat(potential_issues?.errors)
        var list = $(`<ul class="potential_issues errors list"></ul>`)
        
        for (let error of errors) {
          let list_item = `<li>${error}</li>`
          list.append(list_item)
        }
        var message = `
      <div class="ui compact potential_issues error message">
        <div class="header" style="margin-bottom: 4px">
          Potential Issues
        </div>
        ${list.prop('outerHTML')}
      </div>
      `
        
        let invalid_label = $(`<div class="ui red mini basic potential_issues label">Invalid</div>`)
        $(associated_item).find('.potential_issues.label').remove()
        invalid_label.appendTo(associated_item)
        invalid_label.popup({
          position: 'left center',
          hoverable: true,
          html: message,
          lastResort: 'left center',
        })
      }
    })
  },

  set_workflow_counter(up = true) {
    if (up) {
      $('.notification.settings.new.workflow.counter').data().value++
    } else {
      $('.notification.settings.new.workflow.counter').data().value--  
    }
    
    const new_counter_value = $('.notification.settings.new.workflow.counter').data().value
    $('.notification.settings.new.workflow.counter').text(new_counter_value)

    return new_counter_value
  },

  show_welcome(counter_value) {
    if (counter_value == 0) {
      $(".workflow-welcome,.show.asset-search").show()
    } else {
      $(".workflow-welcome,.show.asset-search").hide()
    }
  },

  add_asset(asset_id) {
    var asset_card_template = `<div class="remote asset card asset-id-display" data-model-name="assets" data-model-id="${asset_id}" data-type="list">
      <div class="asset card list item" style="height:62px; padding:0.4em 1em;">
        <div class="ui placeholder">
          <div class="line"></div>
          <div class="line"></div>
        </div>
      </div>
    </div>`
    $("#workflow-jobs.compose-workflow-job .menu .asset-list").append(asset_card_template)

    workflow.fetch_asset_view($('#workflow-jobs .remote.asset.card')[$('#workflow-jobs .remote.asset.card').length - 1])
  },

  duplicate_workflow(fieldset) {
    let workflow_list = fieldset.parentElement
    var original_params_id = fieldset.querySelector('.workflow-job-params-id')?.value
    let params_id = general.makeid()
    
    let original_uuid = fieldset.querySelector('input.workflow-job-uuid')?.value
    let workflow_uuid = utils.uuidv4().toUpperCase()
    
    if (!original_params_id || !original_uuid) {
      $.toast({
        showIcon: 'clone',
        title: 'Workflow Duplication',
        message: 'An error occurred during workflow duplication!',
        class: 'error',
      })
      return
    }
    original_params_id = `_${original_params_id}`

    let sidebar_input = document.querySelector(`input[id=sidebar-item-workflow-${original_uuid}]`)
    let original_sidebar_item = sidebar_input.parentElement
    let sidebar_list = original_sidebar_item.parentNode
    let new_sidebar_html = original_sidebar_item.outerHTML.replace(new RegExp(original_uuid, "g"), workflow_uuid)
    let sidebar_item_clone = $(new_sidebar_html).appendTo(sidebar_list)[0]
    
    original_sidebar_item.classList.remove('active')
    sidebar_item_clone.classList.add('active')
    
    let originalWorkflowHTML = fieldset.outerHTML
    let newHTML = originalWorkflowHTML.replace(new RegExp(original_params_id, "g"), `_${params_id}`).replace(new RegExp(original_uuid, "g"), workflow_uuid)

    let workflow_clone = $(newHTML).appendTo(workflow_list)
    fieldset.style.display = 'none'
    
    workflow.init_sidebar_item_notification_bell(sidebar_item_clone)
    workflow.init_sidebar_item_event_handler(sidebar_item_clone, workflow_clone)
        
    workflow.transfer_user_input(fieldset, workflow_clone)
    workflow.randomize_input_indices(fieldset)
    
    workflow.finalize_view($(workflow_clone), params_id, workflow_uuid)
    workflow.set_workflow_counter(true)
  },

  transfer_user_input(source_element, target_element) {
    let source = $(source_element)
    let clone = $(target_element)

    let source_inputs = source.find('input, textarea, select')
    let clone_inputs = clone.find('input, textarea, select')

    source_inputs.each(function(index, element) {
      var clone_input = $(clone_inputs[index]);

      if (element.type === 'checkbox' || element.type === 'radio') {
        clone_input.prop('checked', $(element).prop('checked'));
      } else if (element.tagName.toLowerCase() === 'select') {
        clone_input.val($(element).val());
      } else {
        clone_input.val($(element).val());
      }
    })
  },
  
  randomize_input_indices(fieldset) {
    const random_index = Math.floor(Math.random() * 10000)
    const inputs = fieldset.querySelectorAll('input, select, textarea')

    const name_regex = /(^workflow\[workflow_jobs_attributes\])\[\d+\]/
    const id_regex = /(^workflow_workflow_jobs_attributes_)\d+/

    inputs.forEach(input => {
      if (input.name) {
        input.name = input.name.replace(name_regex, `$1[${random_index}]`)
      }

      if (input.id) {
        input.id = input.id.replace(id_regex, `$1${random_index}`)
      }
    })
  }

}

window.workflow = workflow
