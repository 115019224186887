import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = [
    'workflowTemplateActiveCheckbox',
    'workflowTemplateDropdown',
    'advancedWorkflowTemplateFilter'
  ]

  toggleAdvancedWorkflowTemplateFilter({ detail: { _value, _text, selectedItem }}) {
    if ($('#search_workflowjobs').dropdown("get value").length > 0) {
      $(this.advancedWorkflowTemplateFilterTarget).show()
    } else {
      $(this.advancedWorkflowTemplateFilterTarget).hide()
    }
  }

  toggleActiveWorkflowJobTemplates() {
    let active = $(this.workflowTemplateActiveCheckboxTarget).checkbox('is checked')
    let template_url = new URL(this.workflowTemplateDropdownTarget.dataset.remoteDropdownUrlValue)
    let params = new URLSearchParams(template_url.search)
    params.set('active', active)
    template_url.search = params.toString()
    this.workflowTemplateDropdownTarget.dataset.remoteDropdownUrlValue = template_url
  }

  quickFilter({ params: { filter } }) {

    switch(filter) {
      case 'production':
        $('#search_not_for_production').dropdown('set exactly', 'false')
        break
      case 'not_for_production':
        $('#search_not_for_production').dropdown('set exactly', 'true')
        break
      case 'incomplete_workflows':
        $('#search_default_workflow_status').dropdown('set exactly', ["1", "2", "3", "7"])
        break
      case 'failed_workflows':
        $('#search_default_workflow_status').dropdown('set exactly', "6")
        break
      case 'todays_issues':
        $('#search_creation_date_start').val(this.formatDate(new Date()))
        this.generalIssues()
        break
      case 'one_week_issues':
        var a_week_ago = new Date()
        a_week_ago.setDate(a_week_ago.getDate() - 7)
        $('#search_creation_date_start').val(this.formatDate(a_week_ago))
        this.generalIssues()
        break
      case 'two_weeks_issues':
        var two_weeks_ago = new Date()
        two_weeks_ago.setDate(two_weeks_ago.getDate() - 14)
        $('#search_creation_date_start').val(this.formatDate(two_weeks_ago))
        this.generalIssues()
        break
    }
    $('.start-search button').trigger('click')
  }

  formatDate(date) {
    return date.toLocaleDateString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' })
  }

  generalIssues() {
    $('#search_has_asset_history_entries').parent('.checkbox').checkbox('set checked')
    $('#search_default_workflow_status').dropdown('set exactly', "6")
  }
}

