import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static outlets = [
    "remote-dropdown",
    "workflow--presets",
  ]

  static targets = [
    'shareButton',
    'editButton',
    'addButton',
    'updateButton',
    'applyButton',
    'presetDropdown',
  ]

  static values = {
    paramsId: String,
    wjtId: Number,
    contactId: { type: Number, default: null }
  }

  get asset_ids() {
    return $("#workflow_search").data("asset-ids")
  }

  get target() {
    return $(`#workflow_view_${this.paramsIdValue}`).closest('fieldset.nested_fields.nested_workflow_workflow_jobs')
  }

  get workflow_presets_controller() {
    return this.workflowPresetsOutlets.find(outlet => outlet.element.contains(document.querySelector(`#workflow_view_${this.paramsIdValue}`)))
  }

  connect() {
  }

  close(event) {
    if (event.detail.success) {
      $(this.element).modal('hide')
    }
  }

  tagFilterChanged({ detail: { dropdown }}) {
    const c = this
    const values = $(dropdown).dropdown('get values')
    if (values.length > 0) {
      c.remoteDropdownOutlet.setQueryParam('search_tags[]', values)
    } else {
      c.remoteDropdownOutlet.unsetQueryParam('search_tags[]')
    }
  }

  presetSelectionChanged({ detail: { _value, _text, selectedItem, _selectedOptions }}) {
    const c = this
    if (selectedItem && selectedItem.is('.addition.item')) { // new preset
      c.addButtonTarget.classList.remove('disabled')
      c.addButtonTarget.style.display = ''

      c.shareButtonTarget.classList.add('disabled')
      c.editButtonTarget.classList.add('disabled')
      c.updateButtonTarget.classList.add('disabled')
      c.updateButtonTarget.style.display = 'none'

      c.applyButtonTarget.classList.add('disabled')
    } else if (selectedItem && selectedItem.is('.item')) { // existing preset
      c.updateButtonTarget.classList.remove('disabled')
      c.updateButtonTarget.style.display = ''
      
      c.addButtonTarget.classList.add('disabled')     
      c.addButtonTarget.style.display = 'none'       
      if (selectedItem.find('.options').data('edit-enabled')) {
        c.shareButtonTarget.classList.remove('disabled')
        c.editButtonTarget.classList.remove('disabled')
      } else {
        c.shareButtonTarget.classList.add('disabled')
        c.editButtonTarget.classList.add('disabled')
        c.addButtonTarget.classList.add('disabled')
        c.updateButtonTarget.classList.add('disabled')
      }
      c.applyButtonTarget.classList.remove('disabled')
    } else { // deselected
      c.shareButtonTarget.classList.add('disabled')
      c.editButtonTarget.classList.add('disabled')
      c.addButtonTarget.classList.add('disabled')
      c.updateButtonTarget.classList.add('disabled')
      c.applyButtonTarget.classList.add('disabled')
    }
  }

  addOrUpdate(e) {
    const c = this
    let selected_preset = $(c.presetDropdownTarget).dropdown('get itemWithAdditions')
    var url = "/workflow_job_presets"
    var preset_data = {}
    const add_or_update_button = $(e.currentTarget)
    if (!selected_preset || !selected_preset?.is('.item')) return

    let is_addition = selected_preset?.is('.addition')
    if (is_addition) { // new preset
      url = "/workflow_job_presets"
      preset_data.wjp_Name = selected_preset.data('text')
    } else { // exiting preset
      url = `/workflow_job_presets/${selected_preset.data('value')}`
    }

    preset_data.wjp_Preset = workflow.gather_preset_values($(c.target))

    $.ajax({
      method: is_addition ? "POST" : "PATCH",
      url: url,
      contentType: 'application/json',
      dataType: 'json',
      data: JSON.stringify({ 
        workflow_job_preset: preset_data,
        wjt_ID: c.wjtIdValue,
      }),
      beforeSend: function() {
        add_or_update_button.addClass('loading')
      }
    }).done(function(data, _textStatus, _xhr) {
      $(c.presetDropdownTarget).dropdown('queryRemote', '', function() { 
        $(c.presetDropdownTarget).dropdown('set exactly', data.wjp_ID)
        add_or_update_button.removeClass('loading')
      })
    }).fail(function(_xhr, _textStatus, errorThrown) { 
      console.log(errorThrown)
      add_or_update_button.addClass('red')
      add_or_update_button.removeClass('loading')
      setTimeout(function(){ 
        add_or_update_button.removeClass("red")
        add_or_update_button.removeClass('loading')
      }, 3000)
    })
  }

  sharePreset() {
    const c = this
    let wjp_id = $(c.presetDropdownTarget).dropdown('get value')
    $.ajax({
      url: `/workflow_job_presets/${wjp_id}/preset_sharing_modal`,
      type: "GET",
      beforeSend(_jqXHR, _settings) {
        $(c.shareButtonTarget).addClass('loading')
      }
    }).done(function(data, _textStatus, _xhr) {
      let preset_sharing_modal = $(data).appendTo("body")
      preset_sharing_modal.modal({
        allowMultiple: true,
        onShow() {
          preset_sharing_modal.find('.ui.dropdown').dropdown({ fullTextSearch: true, showOnFocus:false})
        },
        onHidden() {
          preset_sharing_modal.remove()
        }
      })
      preset_sharing_modal.modal("show")
    }).fail(function(_jqXHR, _textStatus, errorThrown) {
      return console.log(errorThrown)
    }).always(function() {
      $(c.shareButtonTarget).removeClass('loading')
    })
  }

  editPreset() {
    const c = this
    let wjp_id = $(c.presetDropdownTarget).dropdown('get value')
    $.ajax({
      url: `/workflow_job_presets/${wjp_id}/preset_edit_modal`,
      type: "GET",
      beforeSend(_jqXHR, _settings) {
        $(c.editButtonTarget).addClass('loading')
      }
    }).done(function(data, _textStatus, _xhr) {
      let preset_edit_modal = $(data).appendTo("body")
      preset_edit_modal.modal({
        allowMultiple: true,
        onShow() {
          preset_edit_modal.find('.ui.dropdown').dropdown({ fullTextSearch: true, showOnFocus:false })
          preset_edit_modal.find('ui.checkbox').checkbox()
          preset_edit_modal.find('button[type="submit"][value="Delete"]').on('click', function(e) {
            let confirmed = confirm('Are you sure you want to delete this item?')
            if (!confirmed) {
              e.preventDefault()
              return false
            }
          })
        },
        onHidden() {
          $(c.presetDropdownTarget).dropdown('clear')
          $(c.presetDropdownTarget).dropdown('queryRemote', '', function() {
            if ($(c.presetDropdownTarget).find(`.item[data-value="${wjp_id}"]`).length > 0) {
              $(c.presetDropdownTarget).dropdown('set exactly', wjp_id)
            }
          })
          preset_edit_modal.remove() 
        }
      })
      preset_edit_modal.modal("show")
    }).fail(function(_jqXHR, _textStatus, errorThrown) {
      return console.log(errorThrown)
    }).always(function () {
      $(c.editButtonTarget).removeClass('loading')
    })
  }

  applyPresets() {
    const c = this
    let wjp_id = $(c.presetDropdownTarget).dropdown('get value')
    $.get({
      url: `/workflow_job_presets/${wjp_id}/presets`,
      contentType: 'application/json',
      dataType: 'json',
      beforeSend: function() {
        $(c.applyButtonTarget).addClass('loading')
      }
    }).done(function(data, _textStatus, _xhr) {
      c.workflow_presets_controller.applyPresets(data.presets, 'Applying Presets')
      $(c.applyButtonTarget).removeClass('loading')
    }).fail(function(_xhr, _textStatus, errorThrown) { 
      console.log(errorThrown)
      $(c.applyButtonTarget).addClass('red')
      $(c.applyButtonTarget).removeClass('loading')
      setTimeout(function(){ 
        $(c.applyButtonTarget).removeClass('red')
      }, 3000)
    })
  }
}
