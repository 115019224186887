var video_proxy = {
  init(target, params_id) {
    forms.disable("video_proxy");
    
    $(target).find('.remove_nested_fields_link').on('click', () => forms.enable("video_proxy"));
    
    $(`#video_asset_search_selection_${params_id}`).dropdown({
      apiSettings: {
        cache: false,
        url: '/assets/search_dropdown?q={/query}',
        data: {
          asset_id: parseInt($(target).find('.workflow-job-asset-id').val()),
          asset_type: 1,
          with_lowres_proxy: true
        },
      },
      templates: {
        menu: dropdownTemplates.asset_search_menu
      },
      onChange(value, _text, _$selectedItem) {
        video_proxy.set_video_path(value, params_id);
      }
    });
    video_proxy.set_video_path($(`#video_asset_search_selection_${params_id}`).dropdown('get value'), params_id);
  },

  set_video_path(value, params_id) {
    if (value !== null) {
      $(`#video_path_${params_id}`).val(`Z:\\PROXIES\\LOWRES_INTERNAL\\${value}\\${value}.mov`).change();
      $(`#video_present_${params_id}`).val(true).change();
      forms.enable("video_proxy");
    }
  }
};
    
window.video_proxy = video_proxy;
