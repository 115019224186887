import React, { memo, useState } from 'react'
import CatalogBaseObjectHandleBottom from './CatalogBaseObjectHandleBottom'
import CatalogBaseObjectHandleTop from './CatalogBaseObjectHandleTop'
import CatalogBaseObjectNodeIcon from './CatalogBaseObjectNodeIcon'
import CatalogBaseObjectNodeMenu from './CatalogBaseObjectNodeMenu';

export default memo(({ data, isConnectable }) => {
  const [showQuicklookIcon, setShowQuicklookIcon] = useState(false)
  const [modalIsLoading, setModalIsLoading] = useState(false)
  const [modalIsActive, setModalIsActive] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  
  const showModal = (model_id, mode) => {
    if (!modalIsActive) {
      setModalIsActive(true)
      setModalIsLoading(true)
      switch (mode) {
        case "quicklook":
          fetch(`/catalog_base_objects/${model_id}/quicklook?modal=true`)
          .then(response => response.text())
          .then(data => {
            setModalIsLoading(false)
            $('body').append(data)
            $('.ui.quicklook.modal').modal({
              duration: 200,
              onShow() {
                catalog_base_objects.show('.ui.quicklook.modal')
              },
              onHidden() {
                $('body').find(".quicklook.modal").remove()
                setModalIsActive(false)
              }
            }).modal('show')
          })
          .catch(() => { 
            setModalIsActive(false)
            setModalIsLoading(false)
          })
          return
        case "edit":
          catalog_base_objects.edit_base_object(model_id)
          setModalIsActive(false)
          setModalIsLoading(false)
          return
        case "relation":
          catalog_base_objects.add_base_object_relation(model_id)
          setModalIsActive(false)
          setModalIsLoading(false)
          return
        case "create":
          catalog_base_objects.create_base_object_from(model_id)
          setModalIsActive(false)
          setModalIsLoading(false)
          return
        case "import":
          catalog_base_objects.import_modal(model_id)
          setModalIsActive(false)
          setModalIsLoading(false)
          return
      }
    }
  }

  return (
    <>
    <CatalogBaseObjectHandleTop parentless={data.parentless} model_id={data.id} style={{backgroundColor: data.style.color}}/>
    <div className="catalog_base_objects quicklook" data-collapsed={data.collapsed}
      onMouseEnter={() => setShowMenu(true)}
      onMouseLeave={() => setShowMenu(false)}
    >
      <div className="ui horizontal fluid card" style={{margin: 0, borderColor: data.style.borderColor}}>
        <div className="image spinner"
          style={{backgroundColor: data.style.color, width: '80px'}}
          data-model-id={data.id}
          onMouseEnter={() => setShowQuicklookIcon(true)}
          onMouseLeave={() => setShowQuicklookIcon(false)}
          onClick={() => {
            showModal(data.id, "quicklook")
            setShowQuicklookIcon(false)
          }}
        >
          <CatalogBaseObjectNodeIcon modalIsLoading={modalIsLoading} showQuicklookIcon={showQuicklookIcon} icon={data.icon}/>
        </div>
        <div className="content">
          <div className="header" style={{fontWeight: 700}}>{data.name}
            <CatalogBaseObjectNodeMenu model_id={data.id} style={{...data.style, opacity: showMenu ? 1 : 0}} link={data.link} showModal={showModal}/>
          </div>
          <div className="meta" style={{fontSize: "0.8em"}}>
            {data.contentId}
            <div className='ui basic circular label pull-right' style={{ display: data.numberDisplay, color: data.style.color}}>{data.number}</div>
          </div>
        </div>
      </div>
      <div className="ui bottom right attached label" style={{backgroundColor: data.style.color }}>{data.referent_type}</div>
    </div>
    <CatalogBaseObjectHandleBottom collapsed={data.collapsed} childless={data.childless} style={{backgroundColor: data.style.color}} model_id={data.id}/>
    </>
  );
});
