document.addEventListener("turbo:load", function() {
  if ($("#audio_track_layouts").length > 0) {
    search.init("/audio_track_layouts");
    $("#audio_track_layouts .dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: false})

    $("#audio_track_layouts").on('fields_added.nested_form_fields', function(_event, _param) {
      $(this).find('.ui.dropdown').dropdown({placeholder: false, fullTextSearch: false});
    });

    $(document).on("fields_added.nested_form_fields", function(event, param) {
      switch (param.object_class) {
        case "audio_track_layout_template":
          $(event.target).find('.usage.dropdown').dropdown('set selected', 7)
          $(event.target).find('.track-id').val(param.added_index+1)
      }
    });
  }
});
