import { Controller } from "@hotwired/stimulus"
import { patch } from "@rails/request.js"
export default class extends Controller {

  static targets = ["input"]

  connect() {
    avails.edit()

    if ($("#avails_queue").length) {
      this.refreshAvailImportQueueView()
    }
  }

  refreshAvailImportQueueView() {
    const index_body = document.getElementById("index_body")
      
    $('.cancel.label').on('click', function () {
      $(this).addClass('disabled')
      const queue_id = $(this).parents('.item').data('id')
      patch(`/avails/${queue_id}/cancel`).then(() => index_body.reload())
    })

    $('.progress').progress({
      text: {
        active  : '',
        success : '{total} Avails Imported!'
      }
    })

    $('.active.progress').each(function() {
      $(this).find('.label').text(`Importing ${$(this).data('value')} of ${$(this).data('total')} Avails`)
    })

    if ($("#avails_queue").find('.item[data-status="in_progress"], .item[data-status="queued"]').length > 0) {
      setTimeout( function() { index_body.reload() }, 5000)
    }
  }
  
}
