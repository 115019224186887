document.addEventListener("turbo:load", function() {
  if ($("#notification_queue_items.index").length > 0) {
    notification_queue_items.index()
  }
  if ($('#notification_queue_history.index').length > 0) {
    notification_queue_items.history()
  }
  if ($('#delivery_note_overview.index').length > 0) {
    notification_queue_items.delivery_note_overview()
  }

})
var notification_preview_request = null
var notification_queue_items = {
  index() {
    general.init_common()
    search.init("/notification_queue_items")
    notification_queue_items.init_index_body()
    notification_queue_items.init_dropdowns()

    calendar.datepicker2(".queued_date_start", { 
      popupOptions: {
        position: 'top left',
        lastResort: 'top left'
      },
    })
    calendar.datepicker2(".queued_date_end", { 
      popupOptions: {
        position: 'top right',
        lastResort: 'top right'
      },
    })
    $(".action.bar .send.notification").on('click', function() {
      notification_queue_items.show_send_modal()
    })
  },

  history() {
    general.init_common()
    search.init("/notification_queue_history")

    calendar.datepicker2(".queued_date_start", { 
      popupOptions: {
        position: 'top left',
        lastResort: 'top left'
      },
    })
    calendar.datepicker2(".queued_date_end", { 
      popupOptions: {
        position: 'top right',
        lastResort: 'top right'
      },
    })
  },

  delivery_note_overview() {
    general.init_common()
    search.init("/delivery_note_overview")

    calendar.datepicker2(".shipped_date_start", { 
      popupOptions: {
        position: 'top left',
        lastResort: 'top left',
        // movePopup: false
      },
    })
    calendar.datepicker2(".shipped_date_end", { 
      popupOptions: {
        position: 'top right',
        lastResort: 'top right',
        inline: true
      },
    })
  },

  init_dropdowns() {
   
    // $("#notification_queue_items .default-options.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});

    let notification_type_dropdown = $('.search.notification_types.dropdown').dropdown({
      placeholder: false,
      fullTextSearch: true,
      allowReselection: true
    })

    $(".search-field.dropdown").dropdown('setting', 'onAdd', function(_addedValue, _addedText, _$addedChoice) {
      $(".start-search .primary.button").trigger('click')
    })
    $(".search-field.dropdown").dropdown('setting', 'onRemove', function(_removedValue, _removedText, _$removedChoice) {
      $(".start-search .primary.button").trigger('click')
    })

    $('.quick_filter.menu .client.item,.platform.item,.fulfillment_partner.item').each((_i, x) => {
        $(x).on('click', function () {
          let nt_id = $(this).data('nt-id')
          let selected_types = $.merge([nt_id], notification_type_dropdown.dropdown('get value'))
          notification_type_dropdown.dropdown('set exactly', selected_types)
        })
      })
  },

  init_index_body() {
    // notification_queue_items.load_status_labels();

    $('#notification_queue_items .quicklook').on('click', function() {
      const preview_button = $(this)
      let target_type = preview_button.data('target-type')
      let target_id = preview_button.data('target-id')
      if(notification_preview_request) { notification_preview_request.abort() }
      notification_preview_request = $.ajax({
        url: `/notification_types/preview_modal`,
        type: "GET",
        data: {
          model_name: target_type,
          model_id: target_id,
        },
        beforeSend() {
          preview_button.addClass('loading')
        }
      }).done(function(data) {
        $(data).appendTo('body')
        let preview_modal = $('#notification_preview_modal')
        preview_modal.modal({
          observeChanges: true,
          autofocus: false,
          closable: false,
          allowMultiple: true,
          onShow() {
            notification_types.init_notification_preview_modal(preview_modal)
          },
          onHidden() {
            preview_modal.remove()
          }
        }).modal('show')
      }).fail(function(_xhr, _textStatus, errorThrown) {
        if (errorThrown != 'abort') { console.log(errorThrown) }
      }).always(() => preview_button.removeClass('loading'))

    })

  },

  show_send_modal(model_ids) {
    $.ajax({
      url: "/notification_queue_items/send_modal",
      type: "GET",
    }).done(function(data) {
      $("body").append(data)
      let send_queued_items_modal = $('#send_notification_queue_items.modal')
      send_queued_items_modal.modal({
        observeChanges: true,
        autofocus: false,
        onShow() {
          // send_queued_items_modal.find('.notification_queue_template.dropdown').dropdown({

          // })
          $('#send_notification_queue_items .disabled.send-notification').removeClass('disabled')
          if (model_ids === undefined) {
            $(".queued-notification-search-count").text($("#search-count").text())
          } else {
            $(".queued-notification-search-count").parent().text("Do you relly want to send this notification?")
          }
          $('#send_notification_queue_items .message').show()
        }, 
        onHidden() {
          send_queued_items_modal.remove()
        },
        onApprove(button) {
            button.addClass("loading")
            const search_params = search.collect_search("only_ids")
            search_params["only_ids"] = true

            if (model_ids === undefined) {
              $.ajax({
                url: "/notification_queue_items",
                type: "GET",
                dataType: "json",
                data: search_params
              }).done(function(data) {
                notification_queue_items.send_notifcations(data)
                  .done(function(data) {
                    notification_queue_items.display_notification_toasts(data)
                  }).fail((_xhr, _textStatus, _errorThrown) => { 
                    console.log("error")
                  }).always(() => { 
                    button.removeClass("loading")
                    send_queued_items_modal.modal('hide')
                  })
              }).fail((_xhr, _textStatus, _errorThrown) => {
                console.log("error")
                button.removeClass("loading")
              })
            } else {
              notification_queue_items.send_notifcations(model_ids)
                .done(function(data) {
                  notification_queue_items.display_notification_toasts(data)
                }).fail((_xhr, _textStatus, _errorThrown) => { 
                  console.log("error")
                }).always(() => { 
                  button.removeClass("loading") 
                  send_queued_items_modal.modal('hide')
                })
            }
          return false
        }
      }).modal("show")

    }).fail(() => console.log("error"))
  },
  display_notification_toasts(data) {
    if (data.succeeded?.length > 0) {
      $('body').toast({
        title: `Notifications Sent!`,
        class: 'success',
        displayTime: 3000,
        message: `${data.succeeded}`
      })
      console.log(`${data.succeeded}`)
    }
    if (data.failed?.length > 0 || data.other?.length > 0) {

      let message = ""
      if (data.reason?.length > 0) {
        message += `Possible Reason:<br>${data.reason}<br>`
      }
      message += `${data.failed}`

      $('body').toast({
        title: 'Some Notifications Failed!',
        class: 'error',
        displayTime: 3000,
        message: message
      })
      console.log(`${data.failed} - ${data.reason}`)
    }
  },
  send_notifcations(model_ids) {
    return $.ajax({
      url: "/notification_queue_items/send_notifications",
      type: "POST",
      dataType: "json",
      data: {
        model_ids: model_ids
      }
    })
  },

  context_menu() {
    $(".context.menu .send.notification").on('click', function() {
      notification_queue_items.show_send_modal($(this).parents('.notification_queue_item').data('notificationQueueItemIds'))
    })
  }
}

window.notification_queue_items = notification_queue_items
