document.addEventListener("turbo:load", function() {
  if ($("#metadata_mapping_genres").length > 0) {
    search.init("/metadata_mapping_genres");
    return init_dropdowns();
  }
});

var init_dropdowns = function() {
  $(".search-field.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
  return $("#metadata_mapping_genres .default-options.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
};
