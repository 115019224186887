var image_preview = {
  show_modal(src) {
    image_preview = src =>
      $.ajax({
        url: "/image_preview",
        type: "GET",
        data: {
          src
        }
      })
    ;
    return image_preview(src).done(function(html) {
      $('body').append(html);
      return $('.ui.basic.image.preview.modal').modal({
        autofocus: false,
        onHidden() {
         return $(this).remove();
       }
      }).modal('show');
    });
  }
};

window.image_preview = image_preview;
