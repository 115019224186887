document.addEventListener("turbo:load", function() {
  if ($("#metadata_original_release_media").length > 0) {
    search.init("/metadata_original_release_media");
    return init_dropdowns();
  }
});


var init_dropdowns = function() {
  $(".search-field.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
  return $("#metadata_original_release_media .default-options.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
};
