import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    document.querySelector('#validations.edit') && validations.edit()
    validations.init_form($(this.element))
  }

}
