document.addEventListener("turbo:load", function() {
  if ($("#distribution_channels").length > 0) {
    $(".dropdown").not('.remote').dropdown({fullTextSearch: true});
    search.init("/distribution_channels")

    $(".remove-logo").on('click', function() {
      $("#distribution_channel_remove_dc_LogoFilename").trigger('click')
      $(".remove-logo").hide()
      $(".logo-img").removeAttr('src')
    })

    $(".logo-button").on('click', () => {
      $(".logo-file").trigger('click')
    })

    $('.card .image').dimmer({on: 'hover'})

    const logo_preview = $(".logo-img")
    $(".logo-file").on('change', function(event) {
      const input = $(event.currentTarget)
      const file = input[0].files[0]
      const reader = new FileReader()
      reader.onload = function(e) {
        const image_base64 = e.target.result
        logo_preview.attr("src", image_base64)
      }
      reader.readAsDataURL(file)
    })
  }
});
