/* eslint-disable no-case-declarations */
document.addEventListener("turbo:load", function() {
  document.querySelector('#file_transfers.index') && file_transfers.index()
  document.querySelector('#file_transfers.edit') && file_transfers.edit()
});

var file_transfers = {
  index() {
    $(".dropdown.custom.accounts").dropdown({ fullTextSearch: false, match: "text" })
    search.init("/file_transfers");
    calendar.datepicker(".search.bar")
    file_transfers.init_overview_search_filters()
  },

  edit() {

  },

  init_index_body() {
    file_transfers.asset_request_button()
  },

  init_overview_search_filters() {
    $('.file_transfers.overview .unassigned.item').on("click", function() {
      $("#search_unassigned").parent().checkbox('set checked')
      $(".start-search .primary.button").trigger("click")
    })

    $('.file_transfers.overview .el-unassigned.item').on("click", function() {
      $("#search_unassigned").parent().checkbox('set checked')
      $("#search_root").dropdown('set exactly', ["fasp1-el", "faspex1-el"])
      $(".start-search .primary.button").trigger("click")
    })
  },

  asset_request_button() {
    $("#file_transfers.index .asset.request.label").on("click", function () {
      file_transfers.asset_request_modal($(this).data("ft-id"))
    })
  },

  asset_request_modal(model_id) {
    $.ajax({
      url: `/file_transfers/${model_id}/asset_request_link_modal`,
      success(data, _textStatus, _xhr) {
        $('body').append(data);
        
        $('.ui.asset_request.link.modal').modal({
          duration: 200,
          allowMultiple: true,
          closable: false,
          autofocus: false,
          onShow() {
            file_transfers.nested_fields('.ui.asset_request.link.modal')
            file_transfers.asset_request_autocompletion()

            if ($('.ui.asset_request.link.modal .asset_request.remote.card').length > 0) {
              general.fetch_view($('.ui.asset_request.link.modal .asset_request.remote.card'))
              $(".link.modal .search.asset-requests").hide()
            }

            $('.ui.asset_request.link.modal .link-request.button').on("click", function() {
              if (!$(this).hasClass("disabled")) {
                $('.ui.asset_request.link.modal .link-request.button').addClass("loading")
                file_transfers.link_asset_request(model_id, $(".remote.asset_request.card").last().data("model-id"), $('.ui.asset_request.link.modal .plus-ingest i').hasClass("check"))
              }
            })

            $('.ui.asset_request.link.modal .plus-ingest').on("click", function() {
              if ($(this).find("i").hasClass("check")) {
                $(this).find("i").removeClass("check")
              } else {
                $(this).find("i").addClass("check")
              }
            })
          },
          onHidden() {
            $('.ui.asset_request.link.modal').remove()
          }
        }).modal('show');
      },

      error(_jqXHR, _textStatus, errorThrown) {
        return console.log(errorThrown);
      }
    });
  },

  nested_fields(node = "#file_transfers") {
    $(node).off("fields_added.nested_form_fields.nested_file_transfer_asset_requests")
    $(node).on("fields_added.nested_form_fields.nested_file_transfer_asset_requests", function(event, param) {
      switch (param.object_class) {
        case "asset_request": {
          asset_requests.set_card_data(event.target, param.additional_data)

          let arq_id = param.additional_data.asset_request.arq_ID

          let received = $(event.target).find(".status-received")

          if (param.additional_data.asset_request.arq_ars_ID == 1) {
            received.hide()
          } else {
            received.on("click", function() {
              asset_requests.set_values([arq_id], { arq_ars_ID: "1" }).done(function(_data) {
                received.find("i").removeClass("bell").addClass("green check")
                received.addClass("disabled")

                asset_requests.set_card_data(event.target, param.additional_data)
              })
            })
          }

          let assign = $(event.target).find(".change-values")
          assign.on("click", function() {
            file_transfers.change_values_modal(arq_id)
          })
          
          return 
        }
        default:
          return console.log("Fields were successfully added, callback not handled.")
      }
    })
  },

  change_values_modal(asset_request_id) {
    $.ajax({
      url: "/asset_requests/" + asset_request_id + "/change_values_modal",
    }).done(function(data) {
      $("body").append(data)
      $('.asset-request.change-values.modal').modal({
        autofocus: false,
        closable: false,
        allowMultiple: true,
        onShow() {

          general.fetch_view($('.asset-request.change-values.modal .asset_request.remote.card'))

          api.init()

          let button = $('.asset-request.change-values.modal .set-value.button')
          button.on("click", function() {
            button.addClass("loading");
            asset_requests.set_values(asset_requests.selected_asset_request_ids(asset_request_id), general.collect_parameter('.asset-request.change-values.modal')).done(function(_data) {
              button.removeClass("loading");

              $('.asset-request.change-values.modal').modal("hide")

              general.fetch_view($('.ui.asset_request.link.modal .asset_request.remote.card'))
            }).fail(() => button.removeClass('loading').removeClass('loading green').addClass('red').text("Error"));
          });
        },
        onHidden() {
          $('.asset-request.change-values.modal').remove()
        },
      }).modal("show")
    })
  },

  asset_request_autocompletion() {
    let search_field = $(".link.modal .search.asset-requests")
    search_field.search("destroy")
    
    const url = '/asset_requests/search/import/?q={/query}';
    return search_field.search({
      minCharacters: 2,
      apiSettings: {
        url,
      },
      templates: {
        standard: search.asset_asset_request_search
      },
      onSelect(result, _response) {
        $('.search.asset-requests .add_nested_fields_link').trigger('click', [{
          asset_request: result
        }])

        search_field.search("hide results")
        search_field.search("set value", "")

        search_field.hide()

        $(".disabled.link-request.button").removeClass("disabled")
      }
    })
  },

  link_asset_request(ft_id, arq_id, ingest = false) {
    $.ajax({
      url: `/file_transfers/${ft_id}/link_to_asset_request?asset_request_id=${arq_id}`,
      type: "POST"
    })
    .done(function(_data, _textStatus, jqXHR) {
      if (ingest) {
        $.ajax({
          url: `/file_transfers/${ft_id}/import?asset_request_id=${arq_id}`,
          type: "POST"
        }).done(function(_data, _textStatus, jqXHR) {
          file_transfers.close_link_modal("Asset Import", jqXHR)
        }).fail(function(jqXHR, _textStatus, _errorThrown) {
          console.log(jqXHR)
          $('body').toast({
            title: "Link Asset Request",
            class: jqXHR.statusText == "OK" ? 'success' : 'error',
            message: jqXHR.responseText
          })
          file_transfers.close_link_modal("Asset Import", jqXHR)
        })
      } else {
        file_transfers.close_link_modal("Link Asset Request", jqXHR)
      }
    })
    .fail(function(jqXHR, _textStatus, _errorThrown) {
        console.log(jqXHR)

        $('.ui.asset_request.link.modal .link-request.button').removeClass("loading")

        $('body').toast({
          title: "Link Asset Request",
          class: jqXHR.statusText == "OK" ? 'success' : 'error',
          message: jqXHR.responseText
        })
    })
  },

  close_link_modal(title, jqXHR) {

    $('body').toast({
      title: title,
      class: jqXHR.statusText == "OK" ? 'success' : 'error',
      message: jqXHR.responseText
    })

    $('.ui.asset_request.link.modal .link-request.button').removeClass("loading")
    $('.ui.asset_request.link.modal').modal("hide")
    $(".start-search .primary.button").trigger('click')
  }
};

window.file_transfers = file_transfers
