const delivery_order_avails = {

  init_avail_schedule_dropdowns(component) {
    $(component).find('.ui.avail-schedule-price-type.dropdown').each((_i, item) =>
      $(item).dropdown({
        apiSettings: {
          cache: false,
          url: '/delivery_orders/price_types?q={/query}',
          data: {
            dt_ID: $('#delivery_order_do_dct_ID').val()
          },
          beforeSend(settings) {
            settings.data.dt_ID = $('#delivery_order_do_dct_ID').val();
            return settings;
          }
        },
        templates: {
          menu: dropdownTemplates.search_menu
        }
      }).dropdown('set selected', $(item).val())
    );
  
    $(component).find('.ui.avail-schedule-license-type.dropdown').each((_i, item) =>
      $(item).dropdown({
        apiSettings: {
          cache: false,
          url: '/delivery_orders/license_types?q={/query}',
          data: {
            dt_ID: $('#delivery_order_do_dct_ID').val()
          },
          beforeSend(settings) {
            settings.data.dt_ID = $('#delivery_order_do_dct_ID').val();
            return settings;
          }
        },
        templates: {
          menu: dropdownTemplates.search_menu
        },
        onChange: function(_value) {
          const avail_id = $(this).parents('.nested-fields[data-avail-item-id]').data('avail-item-id')
          const package_id = $(this).parents('.delivery_order_content_package.tab').data('package-id')

          const menu_content = $(`.delivery-order-content-package-menu .content[data-package-id="${package_id}"]`)
          $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`)
          
          const text = $(this).dropdown('get text')
          if (text == "" && typeof(text) === 'undefined') {
            $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.license_type').removeClass('label')
            $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.license_type').text('')

          } else {
            $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.license_type').addClass('label')
            $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.license_type').text(text)
          }
        }
      }).dropdown('set selected', $(item).val())
    );
  
    $(component).find('.ui.avail-schedule-format-profile.dropdown').each((_i, item) =>
      $(item).dropdown({
        apiSettings: {
          cache: false,
          url: '/delivery_orders/format_profiles?q={/query}',
          data: {
            dt_ID: $('#delivery_order_do_dct_ID').val()
          },
          beforeSend(settings) {
            settings.data.dt_ID = $('#delivery_order_do_dct_ID').val();
            return settings;
          }
        },
        templates: {
          menu: dropdownTemplates.search_menu
        },
        onChange: function(_value) {
          const avail_id = $(this).parents('.nested-fields[data-avail-item-id]').data('avail-item-id')
          const package_id = $(this).parents('.delivery_order_content_package.tab').data('package-id')

          const menu_content = $(`.delivery-order-content-package-menu .content[data-package-id="${package_id}"]`)
          
          const text = $(this).dropdown('get text')
          if (text == "" && typeof(text) === 'undefined') {
            $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.format_profile').removeClass('label')
            $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.format_profile').text('')

          } else {
            $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.format_profile').addClass('label')
            $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.format_profile').text(text)
          }
        }
      }).dropdown('set selected', $(item).val())
    );

    $(component).find(".ui.avail_schedule_territory.dropdown").each((_i, item) =>
      $(item).dropdown({
        apiSettings: {
          cache: false,
          url: '/delivery_orders/territories?q={/query}',
          data: {
            c_ID: $("#delivery_order_do_c_ClientContactID").val(),
            dct_ID: $("#delivery_order_do_dct_ID").val()
          },
        },
        templates: {
          menu: dropdownTemplates.territory_search_menu
        },
        onChange(_value, text, _choice) {
          const title = $(item).closest('fieldset').find('.title .text');
          $(title).text(text);
          const field = $(item).closest('fieldset').find('.active.tab');
          $(".avail.list").find(`[data-list-item-id='${field.data("list-item-id")}']`).text(text);

          const avail_id = $(this).parents('.nested-fields[data-avail-item-id]').data('avail-item-id')
          const package_id = $(this).parents('.delivery_order_content_package.tab').data('package-id')

          const menu_content = $(`.delivery-order-content-package-menu .content[data-package-id="${package_id}"]`)
          $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`)
          
          const dropdown_text = $(this).dropdown('get text')
          if (dropdown_text == "" && typeof(dropdown_text) === 'undefined') {
            $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.territory').text('')

          } else {
            $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.territory').text(text)
          }
        

        }
      }).dropdown('set selected', $(item).val())
    );

    $(component).find(".ui.avail_schedule_language.dropdown").each((_i, item) =>
      $(item).dropdown({
        apiSettings: {
          cache: false,
          url: '/delivery_orders/languages?q={/query}',
          data: {
            c_ID: $("#delivery_order_do_c_ClientContactID").val(),
            dct_ID: $("#delivery_order_do_dct_ID").val()
          }
        },
        templates: {
          menu: dropdownTemplates.search_menu
        }
      }).dropdown('set selected', $(item).val())
    );
  },

  display_avail_schedule_fields(namespace = $("#delivery_orders")) {
    if ($('#delivery_order_do_dct_ID').val() !== undefined) {
      if ($('#delivery_order_do_dct_ID').val() !== "") {
        $.ajax({
          url: '/delivery_orders/avail_schedule',
          type: "POST",
          dataType: "json",
          data: {
            dt_ID: $('#delivery_order_do_dct_ID').val(),
            mmc_ID: $('#delivery_order_do_mmc_ID').val()
          },
          success(data, _textStatus, _xhr) {
            if (data['dctasfps_flag'] === true) {
              $(namespace).find('.field.avail-schedule-format-profile').show();
            } else {
              $(namespace).find('.field.avail-schedule-format-profile').hide();
            }
  
            if (data['dctaslts_flag'] === true) {
              $(namespace).find('.avail-schedule-license-type').show();
            } else {
              $(namespace).find('.avail-schedule-license-type').hide();
            }
  
            if (data['dctaspts_flag'] === true) {
              $(namespace).find('.field.avail-schedule-price-type').show();
            } else {
              $(namespace).find('.field.avail-schedule-price-type').hide();
            }
  
            if (data['price_value_flag'] === true) {
              $(namespace).find('.field.price-value').show();
            } else {
              $(namespace).find('.field.price-value').hide();
            }
  
            if (data['optional_price_value_flag'] === true) {
              $(namespace).find('.field.optional-price-value').show();
            } else {
              $(namespace).find('.field.optional-price-value').hide();
            }

            if (data['promotional_type_flag'] === true) {
              $(namespace).find('.field.promotional-release-type').show();
            } else {
              $(namespace).find('.field.promotional-release-type').hide();
            }
  
            if (data['start_date_flag'] === true) {
              $(namespace).find('.field.start-date').show();
              $(namespace).find('.avail_schedule_as_start_date').on('change', function() { 
                const avail_id = $(this).parents('.nested-fields[data-avail-item-id]').data('avail-item-id')
                const package_id = $(this).parents('.delivery_order_content_package.tab').data('package-id')
                const menu_content = $(`.delivery-order-content-package-menu .content[data-package-id="${package_id}"]`)
                
                const start_date = this.value
                const end_date = $(namespace).find('.avail_schedule_as_end_date').val()
  
                const start_date_set = start_date.length > 0
                const end_date_set = end_date.length > 0
                
                switch ([start_date_set, end_date_set].toString()) {
                case 'true,true':
                  $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.start_date').text(start_date)
                  $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.end_date').text(end_date)
                  break
                case 'true,false':
                  $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.start_date').text(start_date)
                  $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.end_date').html('<i class="far fa-infinity"></i>')
                  // all disabled
                  break
                case 'false,true': 
                  $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.start_date').html('<i class="far fa-infinity"></i>')
                  $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.end_date').text(end_date)
                  // all enabled
                  break
                case 'false,false':
                  $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.start_date').text('')
                  $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.end_date').text('')
                  break
                default: 
                }

              })
            } else {
              $(namespace).find('.field.start-date').hide();
            }
  
            if (data['end_date_flag'] === true) {
              $(namespace).find('.field.end-date').show();
              $(namespace).find('.avail_schedule_as_end_date').on('change', function() { 

                const avail_id = $(this).parents('.nested-fields[data-avail-item-id]').data('avail-item-id')
                const package_id = $(this).parents('.delivery_order_content_package.tab').data('package-id')
                const menu_content = $(`.delivery-order-content-package-menu .content[data-package-id="${package_id}"]`)
                
                const end_date = this.value
                const start_date = $(namespace).find('.avail_schedule_as_start_date').val()
  
                const start_date_set = start_date.length > 0
                const end_date_set = end_date.length > 0
                
                switch ([start_date_set, end_date_set].toString()) {
                case 'true,true':
                  $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.start_date').text(start_date)
                  $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.end_date').text(end_date)
                  break
                case 'true,false':
                  $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.start_date').text(start_date)
                  $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.end_date').html('<i class="far fa-infinity"></i>')
                  // all disabled
                  break
                case 'false,true': 
                  $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.start_date').html('<i class="far fa-infinity"></i>')
                  $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.end_date').text(end_date)
                  // all enabled
                  break
                case 'false,false':
                  $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.start_date').text('')
                  $(menu_content).find(`.avail.list .item[data-list-item-id="${avail_id}"]`).find('.end_date').text('')
                  break
                default: 
                }

              })
            } else {
              $(namespace).find('.field.end-date').hide();
            }
          },
  
          error(_jqXHR, _textStatus, errorThrown) {
            console.log(errorThrown);
          }
        });
      } else {
        $(namespace).find('.field.avail-schedule-format-profile').hide();
        $(namespace).find('.avail-schedule-license-type').hide();
        $(namespace).find('.field.avail-schedule-price-type').hide();
        $(namespace).find('.field.price-value').hide();
        $(namespace).find('.field.optional-price-value').hide();
        $(namespace).find('.field.start-date').hide();
        $(namespace).find('.field.end-date').hide();
      }
    }
  },

  init_edit_avails_modal(modal_target) {
    let modal = $(modal_target)
    modal.modal({
      observeChanges: true,
      autofocus: false,
      onShow() {
        delivery_orders.nested_fields_for_modal(modal)
        delivery_order_avails.init_package_avail_schedules(modal)
        modal.find(".clear.all.avails").off('click')
        modal.find(".clear.all.avails").on('click', function() {
          delivery_order_avails.clear_all_avails()
        })
      },
      onHidden() {
        modal.remove()
      },
      onApprove() {
        delivery_order_avails.apply_edit_avails()
        return false
      }
    }).modal("show")
  },

  clear_all_avails() {
    $(".clear.all.avails").addClass("loading")

    let promises = []
    
    $(".delivery_order_content_package").each((_i, do_package) => promises.push(delivery_orders.load_package(do_package)))
    
    $.when.apply($, promises).then( function() {
      const avails = $("#delivery_orders .avail-schedules fieldset")
      $(avails).each((_i, item) =>
        $(item).find(".remove_nested_fields_link").trigger('click')
      )
    }).then( function() {
      $(".clear.all.avails").removeClass("loading")
      delivery_order_avails.avails_recount()
    })
  },

  clone_avail(param, event) {
    if (typeof param.additional_data !== 'undefined') {
      const cloned_form = $(param.additional_data).clone()
      $(':input[id]', event.target).each(function (_i, _element) {
        const correct_id = this.id
        const correct_name = this.name
  
        $(':input[id$=' + this.id.split(/(\d+)/).pop() + ']', cloned_form).each(function (_i, matching_element) {
          const wrong_id = matching_element.id
          matching_element.setAttribute("id", correct_id)
          matching_element.setAttribute("name", correct_name)
          $('label[for='+ wrong_id, cloned_form).each( function (_ii, matching_label) {
            matching_label.setAttribute("for", correct_id)
          })
        })
      })

      cloned_form.attr('class', $(event.target).attr('class'))
      $(event.target).replaceWith(cloned_form)
      $(cloned_form).find(".clone.avail i").addClass("grey").addClass("outline").removeClass("orange")

      delivery_order_avails.init_package_avail_schedules($(cloned_form))
    } else {
      delivery_order_avails.init_package_avail_schedules($(event.target))
    }
  },

  build_avail_list(menu, id = null) {
    //console.log("build_avail_list")
    //console.log(menu)
    const data_list_item_id = id || general.makeid()

    const menu_avail_list = $(menu).find(".avail.list")
    //console.log(menu_avail_list)

    const item = `
    <div class="item" data-list-item-id=${data_list_item_id}>
      <div>
        <span class="territory">No Territory</span>
        <div class="pull-right labels">
          <span class="ui compact mini grey basic format_profile"></span>
          <span class="ui compact mini grey basic license_type"></span>
        </div>
      </div>
      <div class="avail date">
        <span class="start_date"></span> - <span class="end_date"></span>
      </div>
    </div>
    `

    $(menu_avail_list).append(item);
    $(menu).find(".active.tab").each(function(_i, item) {
      if ($(item).attr("data-tab") === "avail_schedule") {
        $(item).attr("data-tab", `avail_schedule-${data_list_item_id}`);
        $(item).attr("data-list-item-id", data_list_item_id);
      }
    });
    //delivery_orders.perform_menu_avails_count(menu);
  },

  init_package_avail_schedules(avail_namespace) {
    calendar.datepicker(avail_namespace)

    delivery_order_avails.init_avail_schedule_dropdowns(avail_namespace);
  
    // could be on click when do_dct or do_dct id change
    // or executed when the element gets displayed
    delivery_order_avails.display_avail_schedule_fields(avail_namespace);

    $(avail_namespace).find(".clone.avail").hover((function() {
      $(this).find("i").removeClass("grey").removeClass("outline").addClass("orange")
    }), function() {
      $(this).find("i").addClass("grey").addClass("outline").removeClass("orange")
    });

    $(avail_namespace).find(".clone.avail.with-tooltip").popup();

    $(avail_namespace).find(".clone.avail").on('click', function() { 
      let avail_space = $(avail_namespace).parent().parent()

      if ($(avail_namespace).is(".modal")) {
        avail_space = avail_namespace
      }

      if ($(avail_namespace).is(".delivery_order_content_package")) {
        avail_space = $(avail_namespace).find(".avails.tab")
      }

      $(avail_space).find(".add_nested_fields_link").trigger('click', [$(this).parent().parent().parent()])
    })
  },

  apply_edit_avails() {
    let promises = []
    $(".edit.all.avails").addClass("loading").addClass("disabled")
    $(".clear.all.avails").addClass("disabled")
    
    $(".delivery_order_content_package").each((_i, do_package) => promises.push(delivery_orders.load_package(do_package)))
    
    $.when.apply($, promises).then( function() {
      const avails_template = $('.edit.avails.modal .avail-schedules fieldset')

      avails_template.each(function(_i, template) {
        $('.avails.add_nested_fields_link').each(function() {
          $(this).trigger('click', [template])
        })
      })
      //delivery_orders.init_package_avail_schedules()
    }).then( function() {
      $(".edit.all.avails").removeClass("loading").removeClass("disabled")
      $(".clear.all.avails").removeClass("disabled")
      $('.edit.avails.modal').modal("hide")
      delivery_order_avails.avails_recount()
    })
  },

  avails_recount() {
    $(".delivery_order_content_package.tab").each(function(_i, item) {
      $(item).data('package-id')

      let avails_count = $(item).find(".avails-list").find('> fieldset[style!="display: none;"]').length

      $(item).find(".menu .item.avails .count").text(avails_count)

      let package_id = $(item).data('package-id')
      let menu_content = $(`.delivery-order-content-package-menu .content[data-package-id="${package_id}"]`)

      $(menu_content).find(".avail-count").text(avails_count)
    })
  },

  rename_avail_nested_fields(fields, package_id, avail_position) {
    fields.each(function(_i, item) {

      $(item).attr("class", "nested_fields nested_delivery_order_delivery_order_content_packages_" + package_id + "_avail_schedules")
      $(item).find("remove_nested_fields_link").attr("data-delete-association-field-name", "delivery_order[delivery_order_content_packages_attributes][" + package_id + "][avail_schedules_attributes][" + avail_position + "][_destroy]")
      $(item).find("input[id]").each(function(_i, input_item) {
        const input_names = ["upw_DatarecordCreatorID", "upw_DatarecordModifierID", "PriceValue", "OptionalPriceValue", "StartDate", "EndDate"]
        input_names.forEach(function(item) {
          if ($(input_item).attr("id").endsWith("_as_" + item)) {
            $(input_item).attr("id", "delivery_order_delivery_order_content_packages_attributes_" + package_id + "_avail_schedules_attributes_" + avail_position + "_as_" + item)
            $(input_item).attr("name", "delivery_order[delivery_order_content_packages_attributes][" + package_id + "][avail_schedules_attributes][" + avail_position + "][as_" + item + "]")
          }
        })
      })

      $(item).find("select").each(function(_i, input_item) {
        const dropdown_names = ["tc_TerritoryID", "lc_StoreLanguageID", "aslt_ID", "asfp_ID", "aspt_ID", "mmcs_ID"]
        dropdown_names.forEach(function(item) {
          if ($(input_item).attr("id").endsWith("_as_" + item)) {
            $(input_item).attr("id", "delivery_order_delivery_order_content_packages_attributes_" + package_id + "_avail_schedules_attributes_" + avail_position + "_as_" + item)
            $(input_item).attr("name", "delivery_order[delivery_order_content_packages_attributes][" + package_id + "][avail_schedules_attributes][" + avail_position + "][as_" + item + "]")
            
            $(input_item).parent().find("select").val($(input_item).parent().find(".item.selected").attr("data-value"))
          }
        })
      })
      avail_position = avail_position + 1    
    })
    return fields
  }

};

window.delivery_order_avails = delivery_order_avails;
