import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['datepicker']
  static values = {
    options: { type: Object, default: { position: 'top left', lastResort: 'top right' } }
  }
  
  connect() {
    if (this.hasDatepickerTarget) {
      this.datepickerTargets.forEach(x => {
        calendar.datepicker2(x, this.optionsValue )
      })
    }
  }
}
