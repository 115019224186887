document.addEventListener("turbo:load", function() {
  if ($("#asset_user_histories.index").length > 0) {
    asset_user_histories.index()
  }

  if ($("#asset_user_histories.edit").length > 0) {
    asset_user_histories.edit()
  }
})

var asset_user_histories = {
  index() {
    search.init("/asset_user_histories")
  },

  edit() {
    $(".dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true})
  },
}

window.asset_user_histories = asset_user_histories
