import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default (props) => {

  const initDropdown = () => {
    const dropdown = $('.ui.actions.pointing.dropdown[data-model-id=' + props.model_id + ']')

    if (dropdown.dropdown("is hidden")) {
      dropdown.dropdown({
        placeholder: false,
        action: "hide",
        onHide : function() {
          $(this).dropdown('clear');
        },
      })
  
      dropdown.dropdown("toggle")

      return
    }
  }

  return(
    <span className="menu pull-right" style={{opacity: props.style.opacity}}>
      <div className="ui actions pointing dropdown top right noselection" data-model-id={props.model_id} onClick={() => initDropdown(props.model_id)}>
        <span className="far fa-ellipsis-h fa-lg fa-fw icon" style={{color: props.style.borderColor}}></span>
        <div className="vertical menu">
          <div className="item" onClick={() => window.open(props.link)}>
            <FontAwesomeIcon icon="share"/>&nbsp; Open
          </div>
          <div className="item" onClick={() => props.showModal(props.model_id, "edit")}>
            <FontAwesomeIcon icon="edit" color="#4A90E2"/>&nbsp; Edit
          </div>
          <div className="divider"></div>
          <div className="item" onClick={() => props.showModal(props.model_id, "relation")}>
            <FontAwesomeIcon icon="sitemap" color="#4A90E2"/>&nbsp; Add Child Relations
          </div>
          <div className="item" onClick={() => props.showModal(props.model_id, "import")}>
            <FontAwesomeIcon icon="file-import" color="#FE9A76"/>&nbsp; Import Relations
          </div>
          <div className="item" onClick={() => props.showModal(props.model_id, "create")}>
            <FontAwesomeIcon icon="plus" color="green"/>&nbsp; Add Node
          </div>
          {/* <div className="item">
            <FontAwesomeIcon icon="minus" color="red"/>&nbsp; Remove Nodes
          </div> */}
        </div>
      </div>
    </span>
  )
}
