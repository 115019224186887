import React, { memo } from 'react';
import { Handle } from 'react-flow-renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default memo((props, { data, isConnectable }) => {
  return (
    <>
    <Handle
      data-parentless={props.parentless}
      type="target"
      position="top"
      isConnectable={isConnectable}
      style={props.style}
      onClick={() => catalog_base_objects.remove_base_object_relation_modal(props.model_id)}
      //children={<FontAwesomeIcon icon={['fas', 'times-circle']} size="xs" color="#000000" transform="up-5 right-1" />}
    />
    </>
  );
});
