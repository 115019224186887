$.fn.search.settings.maxResults = 1000

let asset_project_search_result = null
let queued_project_search_result = null

var search_request = null

var search = {
  init(path) {
    let new_promise = new Promise(function (resolve, _reject) {
      const class_name = path.replace("/", "")
      search.add_pagination_to_actionbar(class_name)
      search.init_search_bar(class_name)
      search.init_keyboard_shortcuts(path)
      if ($("#search_input").length > 0) {
        search.init_reset_search(path)
        search.init_search_form()
        $("#search_input").trigger('focus')
        search.init_search_button(path)
        search.perform_search(path, true)
      }
      resolve({path: path, class_name: class_name})
    })
    new_promise.then(() => {}, () => {})
    return new_promise
  },

  init_search_button(path) {
    $(".start-search .primary.button").removeClass("disabled")

    $(".start-search .primary.button").off('click.search')
    $(".start-search .primary.button").on('click.search', () => {
      //console.log("click.search")
      if ($(".start-search .primary.button").hasClass("loading")) {
        search.cancel(path)
      } else {
        search.perform_search(path)
      }
    })
  },

  init_search_bar(class_name) {
    if ($(".search.bar").length > 0) {
      $('.search.accordion').accordion({
        selector: {
          trigger: '.title'
        }
      })
      $('.search.bar .dropdown.search-field').not('.api').not('.custom').dropdown({ fullTextSearch: true })
      $('.search.bar .dropdown.ng.search-field').not('.api').not('.custom').dropdown({ 
        fullTextSearch: true,
        onShow: function() {
          let default_text = $(this).parent().find(".default.text")

          default_text.css("transform", "translate(-3px, -19px) scale(0.75)")
          default_text.addClass("top-text")
        },
        onHide: function() {
          let default_text = $(this).parent().find(".default.text")

          default_text.css("transform", "translate(0px, 0px) scale(1.0)")
          default_text.removeClass("top-text")
        }
      })

      $('.search.bar .dropdown.filter').dropdown({ fullTextSearch: true })
      $('.search.bar .settings.dropdown').dropdown({  })
      $('.search.bar .ui.checkbox').checkbox()
      api.init()

      search_settings.init(class_name)
      $(".search.bar .search-help").on('click', function() {
        $('.ui.accordion.search.helper').accordion()
        $('.ui.accordion.search.helper').accordion("toggle", 0)
      })
    }
  },

  init_keyboard_shortcuts(path) {
    $(document).off( "keyup.search" )
    $(document).on('keyup.search', function(event) {
      if (event.key == "Enter") {
        if ($("#search_input").is(":focus")) {
          search.perform_search(path)
          return
        }
      }
      if ($("#search_input").length > 0) {
        if (event.ctrlKey || event.metaKey) {
          switch (event.key.toLowerCase()) {
          case 's':
            event.preventDefault()
            search.perform_search(path)
            break
          case 'k':
            event.preventDefault()
            search.reset_search(path)
            break
          }
        }
      }
    })
  },

  init_settings(el) {
    $('#items_per_page .dropdown').dropdown({
      onChange(value, _text, _$selectedItem) {
        return $.when(users.settings("items_per_page." + $(el).data("model"), value)).done(function() {
          search.perform_search("/" + $(el).data("model"));
        });
      }
    });
  },

  init_global_search() {
    $('#main-search-box .remove.icon').off('click')
    $('#main-search-box .remove.icon').on('click', function() {
      //console.log("close")
      search.reset_global_search()
    });

    let search_input = $('#main-search-box input')
    let main_search = $(".menu .main.search")

    search_input.off('focus')
    search_input.on('focus', function() {
      if ($(".global_search").length == 0) {
        let search_settings = $("#global-search-settings")
        search_settings.show()
  
        if ($("#global-search-history").length == 0) {
          search_settings.html('<div class="ui active centered index inline loader"></div>')
          search.search_settings()
        }
      }
    });
    
    if (main_search.length > 0) {
      let delay;
      main_search.off("keyup")
      main_search.on("keyup", function(event) {
        let delay_time = 400
        if (event.keyCode != 13) { delay_time = 1000 }

        if (!main_search.val() || search.check_search_inputs(event)) {
          //search.reset_global_search();
          return;
        } else {
          if (main_search.val() != "") {
            delay((function() {
              search.trigger_global_search()
              return false;
            }), delay_time);
          }
          return false;
        }
      });
      return delay = (function() {
        let timer = 0;
        return function(callback, ms) {
          clearTimeout(timer);
          timer = setTimeout(callback, ms);
        };
      })();
    }
  },

  trigger_global_search() {
    $("#global-search-settings").hide()
    search.global_search_popup()

    $("#main-search-box").find(".search.link.icon").hide();
    $("#main-search-box").find(".remove.icon").show();
    $("#main-search-box .main.search").popup('remove popup');
  },

  global_search_popup() {
    if (!$(".menu .main.search").val()) {
      return
    }
    $("#main-search-box .input").addClass("loading")
    //console.log("loading")
    $.ajax({
      data: {
        search_input: $(".menu .main.search").val(),
      },
      url: '/dashboard/global_search',
      session_id: general.makeid()
    }).done(function(result) {
      $(".menu .main.search").popup({
        className: {
          popup: 'ui popup no-padding',
        },
        delay: {
          show: 200
        },
        position: 'bottom center',
        html: result,
        on: 'manual',
        exclusive: true,
        hoverable: false,
        setFluidWidth: true,
        onShow() {
          $("#main-search-box .input").removeClass("loading")

          search.set_search_history()

          $(".global_search .search.header").each(function(_i, element) {
            search.global_search($(element).data("model"))
          })
        },
        onHidden() {
          search.reset_global_search()
        }
      }).popup("show")
    }).fail(function() {
      popup.html('error');
    });
  },

  search_settings() {
    let search_settings = $("#global-search-settings")
    
    $.ajax({
      url: "/general/global_search_settings",
      success(data, _textStatus, _xhr) {
        search_settings.html(data)

        $("body").off('click')
        $("body").on('click', function(event) {
          const main_search = document.getElementById('main-search-box')
          let main_search_clicked = main_search.contains(event.target)
  
          if (!main_search_clicked) {
            search_settings.hide()
          }
        })

        search_settings.find(".set-history").off('click')
        search_settings.find(".set-history").on('click', function(event) {
          let search_input = $('#main-search-box input')
          search_input.val($(event.target).text())
          search.trigger_global_search()
        })
  
        search_settings.find(".remove-history").off('click')
        search_settings.find(".remove-history").on('click', function(event) {
          const history_name = $(this).parent().find(".set-history").text()
          search.remove_from_search_history(history_name)
          let remove_element = $(this).closest(".history-element").parent()
          remove_element.attr("xyz", "fade").addClass("xyz-out")
          setTimeout(function() {
            remove_element.remove()
          }, 500);
        })
        
      },
      error(_jqXHR, _textStatus, errorThrown) {
        console.log(errorThrown);
      }
    });
  },

  global_search(model) {
    const request_date = Math.round((new Date()).getTime() / 1000)
    $(".menu .main.search").attr("data-request-date", request_date)
    //console.log(request_date)

    let error = '<div style="text-align: center;"><br><span class="ui basic red basic mini label components">Error</span></div>';
    let no_results = '<div style="text-align: center;"><br><span class="ui basic grey mini label components">No results</span></div>';

    $.ajax({
        data: {
          search_input: $(".menu .main.search").val(),
          limit: 3,
          request_date: request_date
        },
        url: "/" + model + "/search"
      }).done(function(data) {
        //console.log(data)
        let result_element = $("." + model + '_results')
        if ($(".menu .main.search").attr("data-request-date") == request_date) {
          if (data.results.length === 0) {
            result_element.html(no_results);
            return
          } else {
            $(`.search.header[data-model=${model}] .pull-right .text`).text(data.count);

            $.ajax({
                data: {
                  model_ids: data.results
                },
                url: "/" + model + "/cards"
              }).done(function(result) {
                if ($(".menu .main.search").attr("data-request-date") == request_date) {
                  result_element.html(result);
                  $("." + model + '_results .ui.active.loader').remove();
                }
              }).fail(function() {
                $("." + model + '_results').html(error);
                //popup.html('error');
            });
            return;
          }
        }
      }).fail(function() {
        $("." + model + '_results').html(error);
        //popup.html('error');
    });
  },

  reset_global_search() {
    //console.log("reset")
    $(".menu .main.search").val('')
    $("#main-search-box .main.search").popup('hide');
    $("#main-search-box .main.search").popup('remove popup');
    $("#main-search-box .main.search").popup('destroy');
    $("#main-search-box").find(".search.link.icon").show();
    $("#main-search-box").find(".remove.icon").hide();
    $(".menu .main.search").trigger("blur")
  },

  set_search_history() {
    const name = $(".menu .main.search").val()
    let search_histroy = $("#global-search-history").data("search-history")
    const index = search_histroy.indexOf(name)

    if (index != 0) {
      search_histroy = search.remove_name_from_history(search_histroy, name)

      search_histroy.unshift(name)
      search_histroy = search_histroy.slice(0, 5)

      $.when(users.settings("global_search.history", search_histroy)).done(function() {
        $("#global-search-history").data("search-history", search_histroy)
        search.search_settings()
      })
    }
  },

  remove_from_search_history(name) {
    let search_histroy = $("#global-search-history").data("search-history")

    search_histroy = search.remove_name_from_history(search_histroy, name)

    $.when(users.settings("global_search.history", search_histroy)).done(function() {
      $("#global-search-history").data("search-history", search_histroy)
    })
  },

  remove_name_from_history(search_histroy, name) {
    const index = search_histroy.indexOf(name);
    if (index > -1) { 
      search_histroy.splice(index, 1);
    }

    return search_histroy
  },

  perform_settings_click(layout, el) {
      $('.settings .layout .icon').removeClass('active');
      $(`.settings .layout .${layout} .icon`).addClass('active')
      if (layout === "default") {
        layout = ""
      } else {
        layout = `_${layout}`
      }

      return $.when(users.settings("layout.assets", layout)).done(function() {
        search.perform_search($(el).data("path"))
      })
    },

  page() {
    if ($(".page.switch").length > 0) {
      return $(".page.switch").val()
    } else {
      return search.parse_query_string(window.location.search.substring(1))["page"]
    }
  },

  collect_search(with_page = false, without_hidden = false) {
    const search_params = {}
    if (with_page) { search_params["page"] = search.page() }

    var search_elements = $('.search-field')
    if (without_hidden) { search_elements =  $('.search-field').not(".invisible") }
    
    search_elements.each(function(_index, element) {
      if ($(element).hasClass('dropdown')) {
        element = $(element).children().first()
      }
      let value = $(element).val()
      if ($(element).parent().hasClass('checkbox')) {
        value = $(element).prop("checked").toString()
      }
      const id = $(element).attr("id");
      if ((value !== "") && (value !== "false") && (value !== undefined) && (value !== null) && (value.length !== 0) && (value != [""])) {
        if (value.constructor === Array) {
          if (value[0] == "") {
            return true
          }
        }
        if ((typeof value) === "string") {
          return search_params[id] = value.trim()
        } else {
          return search_params[id] = value
        }
      }
    })
    return search_params
  },

  init_reset_search(path) {
    $(".search.filter .with-tooltip").popup({ inline: true })
    $(".reset-search").on('click', function() {
      search.reset_search(path)
    })
  },

  clear_search_fields() {
    $(".search-field").not(".invisible").each(function(_i) {
      if (this.tagName == 'INPUT' && this.type == 'checkbox') {
        this.checked = null
        $(this).removeClass('checked')
        $(this).parent('checkbox').removeClass('checked')
      } else if ($(this).hasClass("dropdown")) {
        if (this.tagName === "SELECT") {
          this.selectedIndex = -1
        } else {
          $(this).dropdown('clear')
        }
      } else {
        $(this).val("")
      }
    })
  },

  reset_search(path) {
    search.clear_search_fields()
    $.when(users.settings(`filter.${path.replace("/", "")}`, search.collect_search())).done(function() {
      search.perform_search(path)
    })
  },

  cancel(path) {
    $(".start-search .primary.button").data("request-id", search.request_date())
    search.set_url_params(path.replace("/", ""))
    search.loading(false)
    if ($(`#${path.replace(/^\//, '')} .active.loader`).length > 0) {
      search.show_search_canceled(path)
    }
  },

  request_date() {
    return Math.round((new Date()).getTime() / 1000)
  },

  loading(state = true) {
    if (state) {
      $(".start-search .primary.button").addClass('loading')
    } else {
      $(".start-search .primary.button").removeClass('loading')
    }
  },

  perform_search(path, with_page = false) {
    search.set_url_params(path.replace("/", ""))
    
    const class_name = path.replace("/", "#")
    const request_date = search.request_date()

    $(".start-search .primary.button").data("request-id", request_date)
    $(class_name).data("request-id", request_date)

    search.loading()
    let collect_search = search.collect_search(with_page)
    let search_request_id = { search_request_id: $(".start-search .primary.button").data("request-id")}
    let search_parameter = $.extend(collect_search, search_request_id)
    if(search_request) { search_request.abort() }
    search_request = $.ajax({
      url: path,
      dataType: "script",
      data: search_parameter
    }).done((_data, _textStatus, _xhr) => {
      //console.log("send request done.")
    }).fail((jqXHR, textStatus, errorThrown) => {
      if (errorThrown != 'abort') {
        console.log(errorThrown)
        search.show_error(path)
        search.set_url_params(path.replace("/", ""))
        search.loading(false)
      }
    })
  },

  add_result(name, html, count, request_id = "") {
    //console.log("add result")
    const class_name = name.replace("#", "").split('.')[0];

    if (request_id != $(".start-search .primary.button").data("request-id") && request_id != "") {
      //console.log("canceled")
      return
    }

    if ($("#search-count").length > 0 || $(name).length > 0) {
      $(name).html(html);
      $("#search-count").html(count)
      if (window[class_name] !== undefined) {
        if ((typeof window[class_name].init_index_body) === "function") {
          window[class_name].init_index_body();
        }
      }
      bookmarks.init_action_menu()
      search.set_url_params(name)
      search_settings.save_current_search_filter(class_name, $("#quickmenu .search.name").val(), true, false, false)

      $(`${name}.index .index.sorting.dropdown`).dropdown({ showOnFocus: false })
      $(`${name}.index .index.settings.dropdown`).dropdown({ showOnFocus: false })

      index_select.init()
      context_menu.init()

      general.load_view_from_index($(`${name}.index`))

      if (window[class_name] !== undefined) {
        if ((typeof window[class_name].overwrite_index_body) === "function") {
          window[class_name].overwrite_index_body();
        }
      }
    }
    
    search.add_pagination_to_actionbar(class_name);
    
    general.index_view_settings()

    Turbo.navigator.delegate.adapter.hideVisitProgressBar()
    search.loading(false)
  },

  search_count() {
    return Number($("#search-count").text())
  },

  show_error(path) {
    search.loading(false)
    $(`#${path.replace(/^\//, '')}`).html("<div class=\"ui negative message\"><div class=\"header\">We're sorry, something went wrong.</div><p>Reset all Search Filters or reload the page.</p></div>");
    $("#search-count").html("0");
  },

  show_search_canceled(path) {
    $(`#${path.replace(/^\//, '')}`).html("<div class=\"ui info message\"><div class=\"header\">Search canceled</div><p>Start a new search or reset the search.</p></div>");
    $("#search-count").html("0");
  },

  set_url_params(class_name) {
    const search_params = search.collect_search(true, false)
    const search_params_keys = Object.keys(search_params)

    const search_params_count = search.collect_search(false, true)
    const search_params_keys_count = Object.keys(search_params_count)

    $(".search.bar .reset-search").addClass("hide")
    if (search_params_keys_count !== null) {
      if (search_params_keys_count.length > 0) {
        $(".search.bar .reset-search").removeClass("hide")
        $(".filter .count").text(search_params_keys_count.length)
      }
    }

    const loc = location.href.split('?')

    let string = ""
    let first_flag = true

    for (let key of Array.from(search_params_keys)) {
      var url_part

      if (Array.isArray(search_params[key])) {
        url_part = "";
        const array = search_params[key];
        for (let i = 0; i < array.length; i++) {
          const item = array[i];
          if (i > 0) {
            url_part += "&"
          }
          url_part += `${key}[]=${item}`
        }
        if (array[0] === "") {
          continue
        }
      } else {
        url_part = key + "=" + search_params[key];
      }
      if ((search_params[key] === "") || (search_params[key] === "false") || (search_params[key] === undefined) || (search_params[key] === null)) {
        continue
      }
      if (first_flag) {
        if (url_part !== "") {
          string = string + "?" + url_part
          first_flag = false;
        }
      } else {
        if (url_part !== "") {
          string = string + "&" + url_part
        }
      }
    }

    history.pushState(null, $("title").text(), loc[0] + string)

    return users.settings(`filter.${class_name}`, search_params)
  },

  init_search_form() {
    let settings = $('.filter.settings').data('settings') || {}
    let query_params = search.get_query_params()
    let search_fields = $('.search-field')

    let search_fields_for_search_params = search_fields.toArray().filter( f => 
      ((f.classList?.contains('dropdown') ? $(f).find('select').addBack('select').first().attr('id') : f.id) in query_params) 
    ) 
    window.qp = query_params
    window.fields = search_fields_for_search_params

    if (search_fields_for_search_params.length > 0) {
      search.clear_search_fields()
      search.set_search_field_values(search_fields_for_search_params, query_params)
    } else {
      search.set_search_field_values(search_fields, {...settings, ...query_params})
    }
  },

  set_search_field_values(search_fields, datasource) {
    let new_promise = new Promise(function (resolve, _reject) {
      if (datasource?.constructor === Object) {

        $(search_fields).each(function(index, element) {
          let search_field = $(element)
          var param_value = null//datasource[search_field.children()[0]?.id]

          if (search_field.is('.dropdown')) {

            param_value = datasource[search_field.find('select').addBack('select').first().attr('id')]
            // search_field.find('select').addBack('select').first().val(param_value).trigger('change')
            if (!param_value) { return }
            if (search_field.is('.remote.selection.dropdown')) {
              // allow additions and add missing
              Array().concat(param_value).forEach(function (value, _i) {
                if (!value) { return }
                let select = search_field.find('select')
                let existing_option = select.find(`option[value="${value}"]`)

                if (existing_option.length > 0) {
                  existing_option.attr('selected', '')
                } else {

                  let option = new Option(value, value, false, true)
                  option.classList.add("addition")
                  select[0].add(option)
                }
              })
              search_field.dropdown('setting', 'allowAdditions', true)
              search_field.dropdown("set exactly", param_value)
            } else if (search_field.is('.selection.dropdown')) {
              search_field.dropdown('set exactly', param_value)
            } else {
              search_field.find('select').addBack('select').first().val(param_value).trigger('change')
            }
          } else if (search_field.is(':checkbox')) {
            param_value = datasource[search_field.find(':checkbox').addBack(':checkbox').first().attr('id')]
            let checked = ['true', 'yes', '1'].includes(String(param_value).toLowerCase())
            search_field.prop("checked", checked)
          } else {
            const input_selector = 'input:not([type="button"],[type="submit"],[type="reset"]), textarea'
            param_value = datasource[search_field.find(input_selector).addBack(input_selector).first().attr('id')]
            search_field.val(param_value?.trim())
          }
        })
        resolve()
      } else {
        resolve()
      }
    })
    new_promise.then(() => {}, () => {})
    return new_promise
  },
  
  urldecode(str) {
    return decodeURIComponent((str + ' ').replace(/\+/g, '%20'));
  },

  parse_query_string(query) {
    var vars = query.split("&");
    var query_string = {};
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      var key = decodeURIComponent(pair[0]);
      var value = decodeURIComponent(pair[1]);
      // If first entry with this name
      if (typeof query_string[key] === "undefined") {
        query_string[key] = decodeURIComponent(value);
        // If second entry with this name
      } else if (typeof query_string[key] === "string") {
        var arr = [query_string[key], decodeURIComponent(value)];
        query_string[key] = arr;
        // If third or later entry with this name
      } else {
        query_string[key].push(decodeURIComponent(value));
      }
    }
    return query_string;
  },
  
  get_query_params(query = window.location.search) {
    let params = {}
    new URLSearchParams(query).forEach((value, key) => {
      let decodedKey = decodeURIComponent(key)
      let decodedValue = decodeURIComponent(value)
      if (decodedKey.endsWith("[]")) {
        decodedKey = decodedKey.replace("[]", "")
        params[decodedKey] || (params[decodedKey] = [])
        params[decodedKey].push(decodedValue)
      } else {
        params[decodedKey] = decodedValue
      }
    })
    return params
  },

  getUrlParameter(sParam) {
    const sPageURL = window.location.search.substring(1)
    const sURLVariables = sPageURL.split('&')

    let array = []

    let i = 0
    while (i < sURLVariables.length) {
      const sParameterName = sURLVariables[i].split('=')

      if (sParameterName[0] === sParam) {
        return sParameterName[1]
      }

      if (sParameterName[0] === sParam+"[]") {
        array.push(sParameterName[1])
      }

      i++
    }

    if (array.length > 0) {
      return array
    }
  },

  add_pagination_to_actionbar(path) {
    let set_focus = false;
    if ($(".page.switch").is(":focus")) {
      set_focus = true;
    }
    $(".pagination-element").empty().html($(".pagination.menu"));
    if (set_focus) {
      $(".page.switch").focus();
    }
    search.page_switcher(path);
    search.page_switcher_size();

    $(".pagination-element .item.next, .pagination-element .item.prev").on('click', () => search.pagination_loader());

    if ($(".page.switch").length > 0 ) {
      const page_number = $(".page.switch").val()
      
      let current_parameters = general.get_url_parameter()
      current_parameters["page"] = page_number

      let url = search.add_params_to_url("", current_parameters);
      general.set_url_with_parameter(url)
    }

    $('.total-count.with-tooltip').popup({
      position: 'bottom center',
      delay: {
        show: 800,
        hide: 300
      }
    });
  },

  page_switcher(path) {
    return $(".page.switch").keyup(function(event) {
      search.page_switcher_size();
      if(event.keyCode === 13) {
        let page_number = $(".page.switch").val();
        const total_pages = $(".page.switch").data("total-pages");
        if (page_number > total_pages) {
          $(".page.switch").val(total_pages);
        }
        
        search.perform_search(path, true)
        search.pagination_loader();
      }
    });
  },

  page_switcher_size() {
   if ($(".pagination.menu").length > 0) {
    if ($(".page.switch").length > 0) {
      // $(".pagination-element.pull-right").prev().show();
      const size = $(".page.switch").val().length;
      return $(".page.switch").css("width", 15 + (8 * (size - 1)));
    } else {
      // $(".pagination-element.pull-right").prev().hide();
    }
  }
 },
  
  alternative_pageination_buttons(action) {
    if ($(".page.switch").length > 0) {
      if (action === "keydown") {
        $(".pagination-element .first.page").removeClass("hide");
        $(".pagination-element .last.page").removeClass("hide");
        $(".pagination-element .next.page").addClass("hide");
        $(".pagination-element .prev.page").addClass("hide");
      }
      if (action === "keyup") {
        $(".pagination-element .first.page").addClass("hide");
        $(".pagination-element .last.page").addClass("hide");
        $(".pagination-element .next.page").removeClass("hide");
        return $(".pagination-element .prev.page").removeClass("hide");
      }
    }
  },
  
  pagination_loader() {
    $(".pagination-element .page.item.active.current").addClass("ui active loader");
  },

  check_search_inputs(event) {
    if (event.keyCode === 16) {
      return true;
    } else if (event.keyCode === 17) {
      return true;
    } else if (event.keyCode === 18) {
      return true;
    } else if (event.keyCode === 91) {
      return true;
    } else if (event.keyCode === 93) {
      return true;
    } else if (event.keyCode === 37) {
      return true;
    } else if (event.keyCode === 38) {
      return true;
    } else if (event.keyCode === 39) {
      return true;
    } else if (event.keyCode === 40) {
      return true;
    } else {
      return false;
    }
  },

  asset_project_search(response, _fields, _something, without_all_project_button = false) {
    let html = '';
    if ((response.results.length > 1) && (!without_all_project_button)) {
      html += `<a id='add-all-projects' onClick='window.search.add_all_projects()' class='ui basic fluid button'>Add all ${response.results.length} Projects</a>`;
    }
    $.each(response.results, function(_index, option) {
      html += '<div class="result"><div class="content">';
      html += `<div class="ui pull-right right floated mini grey basic label">${option.p_ProjectType}`;
      if (assets.missingcheck(option.p_ReleaseTerritory, "") !== "") {
        html += ` (${assets.missingcheck(option.p_ReleaseTerritory, "")})`;
      }
      html += '</div>';
      html += `<div class="title">${option.p_ID} - ${option.p_Title}</div>`;
      if (assets.missingcheck(option.p_AdditionalTitle, "") !== "") {
        html += `<div class="description">${assets.missingcheck(option.p_AdditionalTitle)}</div>`;
      }
      html += `<div class="description">${assets.missingcheck(option.platform)}</div>`;
      html += `<div class="description">${option.contact.c_Name}</div>`;
      html += '</div></div>';
    });
    return html;
  },

  queued_project_search(response, _fields, _something, without_all_project_button = false) {
    let html = '';
    if ((response.results.length > 1) && (!without_all_project_button)) {
      html += `<a id='add-all-projects' onClick='window.search.add_all_queued_projects()' class='ui basic fluid button'>Add all ${response.results.length} Projects</a>`;
    }
    $.each(response.results, function(_index, option) {
      html += '<div class="result"><div class="content">';
      html += `<div class="ui pull-right right floated mini grey basic label">${option.p_ProjectType}`;
      if (assets.missingcheck(option.p_ReleaseTerritory, "") !== "") {
        html += ` (${assets.missingcheck(option.p_ReleaseTerritory, "")})`;
      }
      html += '</div>';
      html += `<div class="title">${option.p_ID} - ${option.p_Title}</div>`;
      if (assets.missingcheck(option.p_AdditionalTitle, "") !== "") {
        html += `<div class="description">${assets.missingcheck(option.p_AdditionalTitle)}</div>`;
      }
      html += `<div class="description">${assets.missingcheck(option.platform)}</div>`;
      html += `<div class="description">${option.contact.c_Name}</div>`;
      html += '</div></div>';
    });
    return html;
  },

  multi_asset_project_search(response, fields, something) {
    return search.asset_project_search(response, fields, something, true)
  },

  project_search(response) {
    let html = '';
    $.each(response.results, function(_index, option) {
      html += '<div class="result"><div class="content">';
      html += `<div class="ui pull-right right floated mini grey basic label">${option.p_ProjectType}`;
      if (assets.missingcheck(option.p_ReleaseTerritory, "") !== "") {
        html += ` (${assets.missingcheck(option.p_ReleaseTerritory, "")})`;
      }
      html += '</div>';
      html += `<div class="title">${option.p_ID} - ${option.p_Title}</div>`;
      if (assets.missingcheck(option.p_AdditionalTitle, "") !== "") {
        html += `<div class="description">${assets.missingcheck(option.p_AdditionalTitle)}</div>`;
      }
      html += `<div class="description">${assets.missingcheck(option.platform)}</div>`;
      html += `<div class="description">${option?.contact?.c_Name}</div>`;
      html += '</div></div>';
    });
    return html;
  },

  asset_workflow_search(response) {
    let html = '';
    $.each(response.results, function(_index, option) {
      let color = "grey"
      switch (option.workflow_type.wt_Name) {
        case 'MAM':
          color = "blue"
          break;
        case 'Vantage':
          color = "orange"
          break;
        case 'Vidchecker':
          color = "purple"
          break;
      }

      html += '<div class="result"><div class="content">';
      html += `<div class="ui pull-right right floated mini ${ color } basic label">${option.workflow_type.wt_Name}</div>`;
      html += `<div class="title">${option.wjt_Description}</div>`;
      html += `<div class="description">${option.wjt_WorkflowID}${ option.wjt_LongDescription != "" ? " - " + option.wjt_LongDescription : ""}</div>`;
      html += '</div></div></div>';
    });
    return html;
  },

  asset_order_search(response) {
    let html = '';
    $.each(response.results, function(_index, option) {
      html += '<div class="result"><div class="content">';
      html += `<div class="title">${option.o_ID} - ${option.o_Title}</div>`;
      html += `<div class="description">${assets.missingcheck(option.o_AdditionalTitle)}</div>`;
      html += `<div class="description">${option.contact.c_Name} / ${option.content_partner.c_Name}</div>`;
      html += '</div></div>';
    });
    return html;
  },

  asset_search(response) {
    let html = '';

    if (response.results.length > 1) {
      let asset_ids = []

      $.each(response.results, function(_index, result) {
        asset_ids.push(result.a_ID)
      })
      
      html += `<a id='add-all-projects' onClick='window.search.add_all_assets(this)' data-results='${JSON.stringify(asset_ids)}' class='ui basic fluid button'>Add all ${response.results.length} Assets</a>`;
    }

    $.each(response.results, function(_index, option) {
      html += '<div class="result"><div class="content">';
      html += `<div class="title"><span class='ui grey text'>${option.a_ID}</span> &nbsp;&nbsp;&nbsp; ${option.a_Title}</div>`;
      html += `<div class="description">${assets.missingcheck(option.a_AdditionalTitle)} <span class="ui mini basic label pull-right">${option.contact.c_Name}</span></div>`;
      if (option.spec_types_with_label != "") {
        html += `<div class="description">Spec Types: ${option.spec_types_with_label}</div>`;
      }
      html += '</div></div>';
    });
    return html;
  },

  asset_basic_search(response) {
    let html = '';
    $.each(response.results, function(_index, option) {
      html += '<div class="result"><div class="content">';
      html += `<div class="title"><span class='ui grey text'>${option.a_ID}</span> &nbsp;&nbsp;&nbsp; ${option.a_Title}</div>`;
      html += `<div class="description">${assets.missingcheck(option.a_AdditionalTitle)} <span class="ui mini basic label pull-right">${option.contact.c_Name}</span></div>`;
      if (option.spec_types_with_label != "") {
        html += `<div class="description">Spec Types: ${option.spec_types_with_label}</div>`;
      }
      html += '</div></div>';
    });
    return html;
  },

  asset_search_with_cloning(response) {
    let html = '';
    $.each(response.results, function(_index, option) {
      const result = `
        <div class="result" data-result=${encodeURIComponent(JSON.stringify(option))}>
          <div class="content">
            <div class="ui sixteen column middle aligned grid">
              <div class="${option.a_s_ID == 1 ? "thirteen" : "sixteen" } wide asset_result column" data-action="click->delivery-order#addAsset" data-delivery-order-clone-param='false'>
              <div class="title" tyle="height: 30px;"><span class='ui grey text'>${option.a_ID}</span> &nbsp;&nbsp;&nbsp; ${option.a_Title} ${option.a_s_ID == 2 ? "&nbsp;<div class='ui mini location orange label'>AWAITED</div>" : ""} </div>
                <div class="description" style="height: 30px; margin-top: 12px;">${assets.missingcheck(option.a_AdditionalTitle)} <span class="ui mini basic label pull-right" style="margin-top: 2px;">${option.contact.c_Name}</span></div>
                <div class="description" style="height: 25px;">Spec Types: ${assets.missingcheck(option.spec_types_with_label)} <span class="ui mini basic label pull-right" style="margin-top: 6px;">${assets.missingcheck(option.resolution_format)}</span></div> 
              </div>
              ${option.a_s_ID == 1 ? `<div class='three wide center aligned column'><div class='ui basic right floated orange mini clone into awaited button' data-action='click->delivery-order#addAsset' data-delivery-order-clone-param='true'>Add as Awaited</div></div>` : "" }
            </div>
          </div>
        </div>
      `
      html += result
    });
    return html;
  },

  asset_asset_request_search(response) {
    let html = '';
    $.each(response.results, function(_index, option) {
      let date = "";
      if (option.orders !== undefined) {
        if (option.orders[0] !== undefined) {
          if (option.orders[0].o_DueDate !== undefined) {
            date = ` / Order Due: ${option.orders[0].o_DueDate.replace("T00:00:00.000+01:00", "").replace("T00:00:00.000+02:00", "")}`;
          }
        }
      }
      let title = option.arq_ID
      if (option.arq_Title !== null) {
        title += ` - ${option.arq_Title} (${assets.missingcheck(option.arq_OriginalTitle)})`
      }

      html += '<div class="result"><div class="content">'
      html += `<div class="title">${title}</div>`
      html += `<div class="description" style="color:#000000;">${option.asset_type.aty_AssetType} / ${option.asset_content_type.act_ContentType} / ${option.language_code.lc_FullDescription}${date}</div>`
      html += `<div class="description">${assets.missingcheck(option.arq_AdditionalTitle)}</div>`
      html += `<div class="description">${option.contact.c_Name} / ${option.content_partner.c_Name}</div>`
      if(option.arq_ExternalNotes !== undefined && option.arq_ExternalNotes !== null ) {
        html += `<div class="description" style="color:#000000;">Notes: ${option.arq_ExternalNotes}</div>`
      }
      html += '</div></div>'
    });
    return html;
  },

  recipient_search(response) {
    let html = '';
    $.each(response.results, function(_index, option) {
      var existing = $('.recipient-id').map( function( _i, element ) {
        if ($(element).closest(".nested_notification_type_user_profile_webs").css("display") != "none") { return $(element).val()}
      }).toArray();
      if (existing.includes(option.upw_ID.toString())) {
        // return;
        html += '<div class="result" style="display: none;"><div class="content">';
      } else {
        html += '<div class="result"><div class="content">';
      }
      // html += '<div class="result"><div class="content">';
      html += `<div class="title">${option.upw_ID} - ${option.upw_FullName}</div>`;
      html += `<div class="description">${option.upw_CompanyName}</div>`;
      html += `<div class="description">${assets.missingcheck(option.upw_Email)}</div>`;
      html += '</div></div>';
    });
    return html;
  },

  workflow_parameter_search(response) {
    let html = '';
    $.each(response.results, function(_index, option) {
      html += '<div class="result"><div class="content">';
      html += `<div class="title">${option.wp_Name}</div>`;
      html += `<div class="description">${option.wp_ID}</div>`;
      html += '</div></div>';
    });
    return html;
  },

  workflow_job_template_search(response) {
    let html = '';
    $.each(response.results, function(_index, option) {
      html += '<div class="result"><div class="content">';
      html += `<div class="title">${option.wjt_Description}</div>`;
      html += '<div class="description"></div>';
      html += '</div></div>';
    });
    return html;
  },

  titles_search(response) {
    let html = '';
    $.each(response.results, function(_index, option) {
      html += '<div class="result">';
      let episodic = '';
      let season = '';
      if (option['episodic']) {
        episodic = '<div class="ui pull-right right floated mini blue basic label">Episodic</div>';
        season = ` (Season: ${option.season})`;
      }
      html += `<div class="item" data-value="${option.value}" data-text="${option.name}" data-season-id="${option.ts_id}"><div class="content"><div class="title">${episodic}${option.name}${season}</div><div class="description">${option.english_name} ${option['year']}</div></div></div>`;
      html += '</div>';
    });
    return html;
  },

  min_characters(element) {
    if ($(element).hasClass('syncgroup-master')) {
      return 1;
    }
    if ($(element).hasClass('syncgroup-slave')) {
      return 1;
    }
    return 3
  },

  asset_autocompletion(nodes, params) {
    let url = '/assets/search_dropdown?q={/query}';
    if (params) {
      url = search.add_params_to_url(url, params);
    }
    $(nodes).search({
      minCharacters: search.min_characters(nodes),
      apiSettings: {
        url,
        data: {
          exclude_ids: assets.exclude_asset_ids(nodes)
        },
        beforeSend(settings) {
          settings.data.exclude_ids = assets.exclude_asset_ids(nodes)
          return settings;
        }
      },
      templates: {
        standard: search.asset_search
      },
      onSelect(result, _response) {
        search.add_assets_from_dropdown(this, result)
      }
    })
  },

  add_assets_from_dropdown(element, result) {
    $(element).find('.asset-autocomplete-id').val(parseInt(result.a_ID));
    if ($(element).hasClass('syncgroup-master')) {
      $('.syncgroup-master .add_nested_fields_link').trigger('click', [{
        asset: result
      }])
      $(".ui.search.syncgroup-master").search("set value", "")
      $(".ui.search.syncgroup-master").search("hide results")
      return false
    }
    if ($(element).hasClass('syncgroup-slave')) {
      $('.syncgroup-slave .add_nested_fields_link').trigger('click', [{
        asset: result
      }])
      $(".ui.search.syncgroup-slave").search("set value", "")
      $(".ui.search.syncgroup-slave").search("hide results")
      return false
    }
    if ($(element).hasClass('source-assets')) {
      $('.source-assets .add_nested_fields_link').trigger('click', [{
        asset: result
      }])
      $(".ui.search.source-assets").search("set value", "")
      $(".ui.search.source-assets").search("hide results")
      return false
    }
    if ($(element).hasClass('quicklink-assets')) {
      $('.quicklink-assets .add_nested_fields_link').trigger('click', [{
        asset: result
      }])
      $(".ui.search.quicklink-assets").search("set value", "")
      $(".ui.search.quicklink-assets").search("hide results")
      return false
    }
    if ($(element).hasClass('binge-assets')) {
      $('.binge-assets .add_nested_fields_link').trigger('click', [{
        asset: result
      }])
      $(".ui.search.binge-assets").search("set value", "")
      $(".ui.search.binge-assets").search("hide results")
      return false
    }
    if ($(element).hasClass('qc-request-asset')) {
      qc_requests.update_asset_card(result.a_ID)
      qc_requests.show_qc_element_segment()
      $(".ui.search.qc-request-asset").search("set value", "")
      $(".ui.search.qc-request-asset").search("hide results")
      return false
    }
    if ($(element).hasClass('component-asset')) {
      $('.component-asset .add_nested_fields_link').trigger('click', [{
        asset: result
      }])
      $(element).search("set value", "")
      $(element).search("hide results")
      return false
    }
    if ($(element).hasClass('basket-assets')) {
      baskets.add_items(baskets.current_basket_id(), "Asset", [result.a_ID], $(element).is('.local') ? element : null)
      $(element).search("set value", "")
      $(element).search("hide results")
      return false;
    }
    if ($(element).hasClass('compose-assets')) {
      workflow.add_asset(result.a_ID)
      $(element).search("set value", "")
      $(element).search("hide results")
      return false
    }
  },

  notification_recipient_autocompletion(nodes, params) {
    let url = '/user_profile_webs/recipients?q={/query}';
    if (params) { url = search.add_params_to_url(url, params); }
    return $(nodes).search({
      minCharacters: 0,
      apiSettings: {
        url
      },
      templates: {
        standard: search.recipient_search
      },
      searchOnFocus: 1,
      onSelect(result, _response) {
        if ($(this).hasClass('notification_recipients')) {
          $('.notification_recipients .add_nested_fields_link').trigger('click', [{ upw_ID: result.upw_ID, upw_FullName: result.upw_FullName, upw_Email: result.upw_Email }]);
          $(".ui.search.notification_recipients").search("set value", "");
          $(".ui.search.notification_recipients").search("hide results");
          return false;
        }
      }
    });
  },

  order_autocompletion(nodes) {
    const url = '/orders/search/import/?q={/query}';
    return $(nodes).search({
      minCharacters: 3,
      apiSettings: {
        url,
        data: {
          filter_order: $('#filter_order').prop("checked")
        },
        beforeSend(settings) {
          settings.data.filter_order = $('#filter_order').prop("checked");
          return settings;
        }
      },
      templates: {
        standard: search.asset_order_search
      },
      onSelect(result, _response) {
        return $(this).find('.asset-autocomplete-id').val(parseInt(result.o_ID));
      }
    });
  },

  asset_request_autocompletion(nodes) {
    const url = '/asset_requests/search/import/?q={/query}';
    return $(nodes).search({
      cache: false,
      minCharacters: 3,
      apiSettings: {
        url,
        data: {
          filter: $('#filter_arq').prop("checked")
        },
        beforeSend(settings) {
          settings.data.filter = $('#filter_arq').prop("checked");
          return settings;
        }
      },
      templates: {
        standard: search.asset_asset_request_search
      },
      onSelect(result, _response) {
        return $(this).find('.asset-autocomplete-id').val(parseInt(result.arq_ID));
      }
    });
  },

  asset_project_autocomplete(nodes) {
    const url = '/projects/search_dropdown?q={/query}';
    $(nodes).search({
      minCharacters: 3,
      apiSettings: {
        url,
        data: {
          contact_filter: $('#contact_filter').prop('checked'),
          contact_id: $("#asset_a_c_ID").val(),
          territory_id: $('#search_project_territory').dropdown('get value')
        },
        beforeSend(settings) {
          settings.data.contact_filter = $('#contact_filter').prop('checked');
          settings.data.contact_id = $("#asset_a_c_ID").val();
          settings.data.territory_id = $('#search_project_territory').dropdown('get value')
          return settings;
        }
      },
      templates: {
        standard: search.asset_project_search
      },
      onSelect(result, _response) {
        $('.asset-project .add_nested_fields_link').trigger('click', [{ project: result }]);
        $(".ui.search.asset-project").search("set value", "");
        $(".ui.search.asset-project").search("hide results");
        return false;
      },
      onResults(response) {
        return asset_project_search_result = response.results;
      }
    });
    return $("#contact_filter").change(() => $(".ui.search.asset-project").search("search remote", $(".ui.search.asset-project").search("get value")));
  },

  delivery_note_project_autocomplete(nodes) {
    $(nodes).search({
      minCharacters: 0,
      apiSettings: {
        url: '/delivery_notes/search_queued_projects?q={/query}',
        data: {},
        beforeSend(settings) {
          settings.data.contact_filter = $('#company_filter').prop('checked')
          settings.data.c_id = $('.company.search.dropdown').dropdown('get value')
          settings.data.contact_group_id = $('.contact_group.dropdown').dropdown('get value')
          return settings
        }
      },
      templates: {
        standard: search.queued_project_search
      },
      onSelect(result, _response) {
        $('.queued_project .add_nested_fields_link').trigger('click', [{ project: result }])
        $(".ui.search.queued_project").search("set value", "")
        $(".ui.search.queued_project").search("hide results")
        return false
      },
      onResults(response) {
        queued_project_search_result = response.results
      }
    })
    $("#company_filter").on('change', () => $(".ui.search.queued_project").search("search remote", $(".ui.search.queued_project").search("get value")))
  },

  add_all_assets(element) {
    $.ajax({
      url: "/assets/search_dropdown?q=" + $(element).data("results")
    }).done(function(data) {
      $.each(data.results, function(_index, asset) { 
        search.add_assets_from_dropdown($(element).closest(".ui.search"), asset)
      })
    })
  },

  add_all_projects() {
    if (asset_project_search_result !== null) {
      let i = asset_project_search_result.length;
      while (i--) {
        $('.asset-project .add_nested_fields_link').trigger('click', [{ project: asset_project_search_result[i] }]);
      }

      $(".ui.search.asset-project").search("hide results");
      $(".ui.search.asset-project").search("set value", "");
      return asset_project_search_result = null;
    }
  },

  add_all_queued_projects() {
    if (queued_project_search_result !== null) {
      let i = queued_project_search_result.length
      while (i--) {
        $('.queued_project .add_nested_fields_link').trigger('click', [{ project: queued_project_search_result[i] }])
      }

      $(".ui.search.queued_project").search("hide results")
      $(".ui.search.queued_project").search("set value", "")
      return queued_project_search_result = null
    }
  },

  add_all_title_projects() {
    const t_ID = $('#title_selection').val();
    if (t_ID) {
      const url = `/titles/${t_ID}/projects`;
      $.getJSON( url, {
        contact_filter: $('#contact_filter').prop('checked'),
        contact_id: $("#asset_a_c_ID").val()
      }).done(function( data ) {
        const existing = $.map($('fieldset[style!="display: none;"] .project-id'), function(a) { return [ a.value ] } );
        $.each( data.results, function( _i, item ) {
          if (!existing.includes(item.p_ID.toString())) {
            $('.asset-project .add_nested_fields_link').trigger('click', [{ project: item }]);
          }
        });
      });
    }
    
  },

  project_title_seasons_autocomplete(nodes) {
    const url = '/titles/seasons?q={/query}';
    $(nodes).search({
      minCharacters: 3,
      apiSettings: {
        url,
        data: {
          contact_filter: $('#contact_filter').prop('checked'),
          contact_id: $("#project_p_c_ID").val()
        },
        beforeSend(settings) {
          settings.data.contact_filter = $('#contact_filter').prop('checked');
          settings.data.contact_id = $("#project_p_c_ID").val();
          return settings;
        }
      },
      templates: {
        standard: search.titles_search
      },
      onSelect(result, _response) {
        $('.project-title-seasons .add_nested_fields_link').trigger('click', [{ title: result }]);
        $(".ui.search.project-title-seasons").search("set value", "");
        $(".ui.search.project-title-seasons").search("hide results");
        return false;
      }
    });
    return $("#contact_filter").change(() => $(".ui.search.project-title-seasons").search("search remote", $(".ui.search.project-title-seasons").search("get value")));
  },

  project_assets_autocomplete(nodes) {
    const url = '/assets/search_dropdown?q={/query}';
    $(nodes).search({
      minCharacters: 3,
      apiSettings: {
        url
      },
      templates: {
        standard: search.asset_search
      },
      onSelect(result, _response) {
        if ($(this).closest('.add.assets.modal').length > 0) {
          $(this).closest('.add.assets.modal').find('.add_nested_fields_link').trigger('click', [{asset: result}])
        } else {
          $('.project-assets .add_nested_fields_link').trigger('click', [{ asset: result }]);
        }
        $(".ui.search.project-assets").search("set value", "");
        $(".ui.search.project-assets").search("hide results");
        return false;
      }
    });
  },

  add_params_to_url(url, params) {
    let new_params = new URLSearchParams(params);
    for (let k in params) {
      let v = params[k];
      new_params.set(`${k}`, v);
    }

    if (url.includes("?")) { 
      url = url + "&"
    } else {
      url = url + "?"
    }
    
    return url + new_params.toString();
  },

  datamover_autocompletion(node, params) {
    return $(node).search({
      minCharacters: 3,
      searchDelay: 500,
      apiSettings: {
        url: `${params.autoCompleteUrl}?q={/query}`,
        data: {
          mount: params.mount.dropdown('get value'),
          token: params.token
        },
        beforeSend(settings) {
          settings.data.mount = params.mount.dropdown('get value');
          return settings;
        }
      },
      templates: {
        menu: dropdownTemplates.search_menu
      },
      onSelect(result, _response) {
        $(this).find(".prompt").val(result.value);
        return $(this).find(".prompt").change();
      },
      onResultsAdd(html) {
        if ($(this).parent().hasClass('datamover-destination-search') && ($(this).parent().data('autocomplete') === "off")) {
          return false;
        }
        return html;
      }
    });
  },

  workflow_job_selection(nodes) {
    const awaited = $('#import_into_awaited')?.val()?.length > 0
    const url = '/workflow_job_templates/search?q={query}'
    $(nodes).search({
      minCharacters: 0,
      selectFirstResult: true,
      apiSettings: {
        url,
        method: "post",
        data: {
          asset_ids: $("#workflow_search").data("asset-ids"),
          exclude_workflow_template_ids: workflow_jobs.find_templates_to_exclude(),
          only_include_workflow_template_ids: awaited ? workflow_jobs.find_templates_filling_awaited() : null
        },
        beforeSend(settings) {
          settings.data.asset_ids = $("#workflow_search").data("asset-ids")
          settings.data.exclude_workflow_template_ids = workflow_jobs.find_templates_to_exclude()
          if (awaited) {
            settings.data.only_include_workflow_template_ids = workflow_jobs.find_templates_filling_awaited()
          }
          return settings;
        }
      },
      templates: {
        standard: search.asset_workflow_search
      },
      onResultsOpen() {
        
      },
      onSelect(result, _response) {
        if (result !== undefined && result !== "") {
          workflow.add_workflow_template(result)
        }
        return true;
      }
    });
  }
};

window.search = search;
