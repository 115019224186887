import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = ['code']

  initialize() {
    try {
      hljs.highlightElement(this.element)
    } catch {
      console.log('highlighting failed')
    }
  }
}
