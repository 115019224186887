import { Controller } from "@hotwired/stimulus"
import { get } from '@rails/request.js'

export default class extends Controller {
  static values = {
    tagId: String,
  }

  connect() {
    get(`/asset_tags/${this.tagIdValue}/label`, {
      responseKind: 'turbo-stream'
    })
  }
}
