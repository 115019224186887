class DateHelper {
  static advance(date, by_value, unit, return_string = false, locale = "de-DE") {
    let return_date = null
    switch (unit) {
      case 'day':
      case 'days':
        return_date = new Date(new Date().setDate(date.getDate() + by_value))
        break
      case 'week':
      case 'weeks':
        return_date = new Date(new Date().setDate(date.getDate() + by_value * 7))
        break
      case 'month':
      case 'months':
        return_date = new Date(date.setMonth(date.getMonth() + by_value))
        break
      default:
        break
    }
    return return_string ? return_date.toLocaleDateString(locale) : return_date
  }
  static decrease(date, by_value, unit, return_string = false, locale = "de-DE") { return this.advance(date, by_value * -1, unit, return_string, locale) }
  
  // convenience advance
  static today(return_string = false, locale = "de-DE") { return return_string ? new Date().toLocaleDateString(locale) : new Date() }

  static tomorrow(return_string = false, locale = "de-DE") { return this.advance(new Date(), 1, 'day', return_string, locale) }
  
  static one_week_from_now(return_string = false, locale = "de-DE") { return this.advance(new Date(), 1, 'week', return_string, locale) }
  static two_weeks_from_now(return_string = false, locale = "de-DE") { return this.advance(new Date(), 2, 'week', return_string, locale) }
  
  static one_month_from_now(return_string = false, locale = "de-DE") { return this.advance(new Date(), 1, 'month', return_string, locale) }
  
  // convenience decrease
  static yesterday(return_string = false, locale = "de-DE") { return this.decrease(new Date(), 1, 'day', return_string, locale) }
  
  static one_week_ago(return_string = false, locale = "de-DE") { return this.decrease(new Date(), 1, 'week', return_string, locale) }
  static two_weeks_ago(return_string = false, locale = "de-DE") { return this.decrease(new Date(), 2, 'week', return_string, locale) }
  
  static one_month_ago(return_string = false, locale = "de-DE") { return this.decrease(new Date(), 1, 'month', return_string, locale) }
}

window.DateHelper = DateHelper
