import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["recordType", "referentType", "episodeDestroy"]

  connect() {
    document.querySelector('#catalog_base_objects.edit') && catalog_base_objects.edit()
    catalog_base_objects.init_form($(this.element))
  }

  fetchBaseObjectExtras() {
    fetch(`/catalog_base_objects/26586/base_object_extras?record_type_id=${this.recordTypeTarget.value}&referent_type_id=${this.referentTypeTarget.value}`)
      .then(r => r.text())
      .then(html => Turbo.renderStreamMessage(html))
  }

}
