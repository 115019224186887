document.addEventListener("turbo:load", function() {
      
  if ($("#mam_versions.index").length > 0) {
    search.add_pagination_to_actionbar()
    mam_versions.init("/mam_versions");
  }

  if ($("#mam_versions.edit").length > 0) {
    if ($("#mam_version_release_date").length > 0) {
      calendar.datetimepicker($("#mam_versions"))
      const easyMDE = new EasyMDE({element: document.getElementById('mam_version_release_notes')})
    }
  }
})

var mam_versions = {
  init: function(path) {

    search.perform_search(path)

    var delay = (function() {
      var timer
      timer = 0
      return function(callback, ms) {
        clearTimeout(timer)
        timer = setTimeout(callback, ms)
      }
    })()

    if ($("#search_input").length > 0) {
      search.reset_search(path)
      mam_versions.set_search_form()
      $("#search_input").trigger('focus')
      $("#search_input").on('keyup', function(event) {
        if (search.check_search_inputs(event)) {
          return
        }
        delay((function() {
          search.perform_search(path)
          return false
        }), 200)
        return false
      })
      $(".search-field").change(function() {
        return search.perform_search(path)
      })
    }
  },
  collect_search: function() {
    var search_params
    search_params = {}
    $('.search-field').each(function(index, element) {
      var id, value
      if ($(element).hasClass('dropdown')) {
        element = $(element).children().first()
      }
      value = $(element).val()
      if ($(element).parent().hasClass('checkbox')) {
        value = $(element).prop("checked").toString()
      }
      id = $(element).attr("id")
      return search_params[id] = value
    })
    return search_params
  },
  reset_search: function(path) {
    return $(".reset-search").click(function() {
      $(".search-field").each(function(i) {
        if ($(this).is(':checkbox')) {
          return $(this).prop("checked", false)
        } else if ($(this).hasClass("dropdown")) {
          return $(this).dropdown('clear')
        } else {
          return $(this).val("")
        }
      })
      return search.perform_search(path)
    })
  },
  perform_search: function(path) {
    $("#search_input").parent().addClass('loading')
    return $.ajax({
      url: path,
      dataType: "script",
      data: search.collect_search(),
      complete: function() {},
      success: function(data, textStatus, xhr) {
        return $("#search_input").parent().removeClass('loading')
      },
      error: function(jqXHR, textStatus, errorThrown) {
        return console.log(errorThrown)
      }
    })
  },
  set_search_form: function() {
    $.each($('.search-field'), function(i) {
      var param, ref
      param = search.getUrlParameter(this.id)
      if (param !== void 0 && param !== "") {
        if ($('#' + this.id).is(':checkbox')) {
          param = (ref = param === "true") != null ? ref : {
            "true": false
          }
          return $('#' + this.id).prop("checked", param)
        } else {
          return $('#' + this.id).val(param.replace(/\+/g, ' '))
        }
      }
    })
  },
  getUrlParameter: function(sParam) {
    var i, sPageURL, sParameterName, sURLVariables
    sPageURL = window.location.search.substring(1)
    sURLVariables = sPageURL.split('&')
    i = 0
    while (i < sURLVariables.length) {
      sParameterName = sURLVariables[i].split('=')
      if (sParameterName[0] === sParam) {
        return sParameterName[1]
      }
      i++
    }
  },
  check_search_inputs: function(event) {
    if (event.keyCode === 16) {
      return true
    } else if (event.keyCode === 17) {
      return true
    } else if (event.keyCode === 18) {
      return true
    } else if (event.keyCode === 91) {
      return true
    } else if (event.keyCode === 93) {
      return true
    } else if (event.keyCode === 37) {
      return true
    } else if (event.keyCode === 38) {
      return true
    } else if (event.keyCode === 39) {
      return true
    } else if (event.keyCode === 40) {
      return true
    } else {
      return false
    }
  }
}

window.mam_versions = mam_versions
