document.addEventListener("turbo:load", function() {
  if ($(".tour").length > 0) {
    return tour.init();
  }
});

var tour = {
  init() {
    tour = new (Shepherd.Tour)({
      defaults: {
        classes: 'shepherd-theme-arrows',
        scrollTo: true,
        showCancelLink: true
      }
      });

    tour.addStep('Pagination', {
      title: '<b>Pagination</b>',
      text: 'The Pagination Element is now on to top of the page in the Action Bar',
      attachTo: '.pagination-element bottom',
      advanceOn: '.docs-link click'
    }
    );
    
    return tour.start();
  }
};

window.tour = tour;
