import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'modal',
    'submitButton',
  ]
  connect() {
    //console.log("connected")
    this.init()
  }

  init() {
    $('.destroy-all.modal .count').text($('.index.select.counter').text())
          
    const button = $(this.submitButtonTarget)
    const modal = $(this.modalTarget)

    button.on("click", function() {
      button.addClass("loading");
      $.ajax({
        url: "/" + modal.data("controller-name") + "/destroy_all",
        type: "POST",
        dataType: "json",
        data: {
          model_ids: index_select.selected_ids()
        }
      }).done(function(_data) {
        button.removeClass("loading"); 
        index_select.end_selection()
        modal.modal('hide')
        
        $(".start-search .primary.button").trigger("click")
      }).fail(() => button.removeClass('loading').removeClass('loading green').addClass('red').text("Error"));
    });
  }

  close(event) {
    if (event.detail.success) {
      $(this.modalTarget).modal('hide')
    }
  }
}
