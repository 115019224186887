import NestedForm from 'stimulus-rails-nested-form'

export default class extends NestedForm {

  static targets = [
    "placeholder",
    "target",
    "template",
    "ignore"
  ]

  static values = {
    removeFromDom: { type: Boolean, default: false },
    wrapperSelector: { type: String, default: 'fieldset' },
    identifier: { type: String, default: 'field' },
  }

  connect() {
    this.element.ctrl = this
    super.connect()
    this.checkFields()
  }

  get prepend() {
    if (this.targetTarget.compareDocumentPosition(this.templateTarget) & Node.DOCUMENT_POSITION_PRECEDING) {
      return false
    } else {
      return true
    }
  }

  add (e) {
    e.preventDefault()
    const identifier = this.identifierValue
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString())
   
    if (this.prepend) {
      this.targetTarget.insertAdjacentHTML('beforebegin', content)
    } else {
      this.targetTarget.insertAdjacentHTML('afterend', content)
    }
    
    const event_name = ["added", identifier].filter(Boolean).join(":")
    this.dispatch(event_name)
    this.checkFields()
  }

  remoteAdd(e, template_change_callback) {
    const e_is_event = e instanceof Event
    if (e_is_event) e.preventDefault()
    const identifier_from_params = e_is_event ? e.params.identifier : e?.identifier
    const identifier = identifier_from_params || this.identifierValue

    // template adjustment
    const template_html = this.templateTarget.innerHTML
    var added_index = new Date().getTime().toString()
    var template_content = document.createRange().createContextualFragment(template_html.replace(/NEW_RECORD/g, added_index))
    if (template_change_callback && typeof template_change_callback === 'function') {
      template_content = template_change_callback(template_content)
    }
    // template insertion
    const added_nodes = Array.from(template_content.children)
    const added_element = template_content.firstElementChild
    
    if (this.prepend) {
      this.targetTarget.parentNode.insertBefore(template_content, this.targetTarget)
    } else {
      this.targetTarget.parentNode.insertBefore(template_content, this.targetTarget.nextSibling)
    }
    // event dispatch
    const event_name = this.eventName("added", identifier)

    this.dispatch(event_name, {
      detail: {
        added_index,
        identifier,
        added_element,
        added_nodes,
        event: e_is_event ? e : null,
        additional_data: e_is_event ? e.detail : e,
      }
    })
    
    this.checkFields(identifier)
  }

  remove (e) {
    const e_is_event = e instanceof Event
    if (e_is_event) e.preventDefault()
    const identifier_from_params = e_is_event ? e.params.identifier : e?.identifier
    const identifier = identifier_from_params || this.identifierValue

    const wrapper = e.target.closest(this.wrapperSelectorValue)
    if (wrapper.dataset.newRecord || this.removeFromDomValue) {
      wrapper.remove()
    } else {
      wrapper.style.display = 'none'
      const destroy_input = wrapper.querySelector("input[name*='_destroy']")
      if (destroy_input) destroy_input.value = '1'
    }

    const event_name = this.eventName("removed", identifier)
    this.dispatch(event_name, {
      detail: {
        removed_element: wrapper,
        event: e,
      }
    })

    this.checkFields(identifier)
  }

  checkFields(identifier) {
    let fields = this.visible_fields
    let count = fields?.length || 0
    this.togglePlaceHolder(count)

    const event_name = this.eventName("count-changed", identifier)
    const element = this.element
    this.dispatch(event_name, { detail: { count, element } })
  }

  eventName(event_name, identifier) {
    return [event_name, identifier].filter(Boolean).join(":")
  }

  togglePlaceHolder(field_count) {
    if (this.hasPlaceholderTarget) {
      if (field_count > 0) {
        this.placeholderTarget.style.display = 'none'
      } else {
        this.placeholderTarget.style.display = this.placeholderTarget.dataset.display || 'block'
      }
    }
  }

  get existing_field_data() {
    return this.visible_fields.flatMap(f => {
      const { model, model_id, other_model_identifier } = f.dataset || {}
      return model ? [{ model, model_id, other_model_identifier }] : []
    }) || []
  }

  get visible_fields() {
    return Array.from(this.fields).filter(field => {
      const style = window.getComputedStyle(field)
      return style.display !== 'none' && style.visibility !== 'hidden' && style.opacity !== '0'
    })
  }

  get fields() {
    let fields = Array.from(this.targetTarget.parentNode.querySelectorAll(`:scope > ${this.wrapperSelectorValue}`))
    return fields.filter(field => {
      return !this.placeholderTargets.includes(field) && !this.templateTargets.includes(field) && !this.ignoreTargets.includes(field)
    })
  }
}
