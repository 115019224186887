let content_package_name = {

  init(target, params_id) {
    let name = target.find(`#content_package_name_${params_id}`)
    let cpn_select = target.find('.cpn-select')
    
    name.on('change', () => content_package_name.on_content_package_name_change(target, params_id))
    cpn_select.on('change', () => content_package_name.on_cpn_select_change(target, params_id))
    
    name.trigger('change')
    cpn_select.trigger('change')
  },

  clean_input(input_field) {
    forms.disable("clean_input")
    $.ajax({
      url: "/workflow_jobs/clean_tcp_name",
      type: "GET",
      dataType: "json",
      data: {
        name: $(input_field).val()
      },
      success(data, _textStatus, _xhr) {
        $(input_field).val(data)
        forms.enable("clean_input")
      },
      error(_jqXHR, _textStatus, errorThrown) {
        console.log(errorThrown)
      }
    })
  },

  cut_binary_filename(input_field, output_field, params_id) {
    forms.disable("cut_binary_filename")
    const error_message = $(`#error_message_${params_id}`)
    error_message.addClass('hidden').html("")
    
    $.ajax({
      url: "/workflow_jobs/cut_binary_filename",
      type: "GET",
      dataType: "json",
      data: {
        input: $(input_field).val(),
        filename: output_field.data('binary-filename')
      },
      success(data, _textStatus, _xhr) {
        if (data.length > 0) {
          output_field.val(data)
          output_field.trigger('change')
          forms.enable("cut_binary_filename")
        } else {
          output_field.val("")
          output_field.trigger('change')
          if (error_message.hasClass('hidden')) {
            error_message.append(`<p>Check Content Package Name</p>`).removeClass('hidden')
          }
        }
      },
      error(_jqXHR, _textStatus, errorThrown) {
        console.log(errorThrown);
      }
    });
  },

  on_content_package_name_change(target, params_id) {
    let cpn = $(`#content_package_name_${params_id}`)
  
    cpn.parent('.field').removeClass('warning').find('label .cpn_format.label').remove()
    if ((cpn.val().match(/-/g) || []).length < 2) {
      cpn.parent('.field').addClass('warning').find('label').append(`<span class="ui mini left pointing yellow cpn_format basic label" style="position: relative; top: -1px; margin-right: 4px; margin-left: 10px; background-color:white !important">Content Package Name should contain at least 2 '-' characters!</span>`)
    }

    content_package_name.clean_input(cpn)
    
    if (cpn.val().length > 0 && target.find('[data-binary-filename]').length) {
      content_package_name.cut_binary_filename(cpn, target.find(`#file_name_-_binary_-_SD_${params_id}`), params_id)
      content_package_name.cut_binary_filename(cpn, target.find(`#file_name_-_binary_-_HD_${params_id}`), params_id)
      content_package_name.cut_binary_filename(cpn, target.find(`#file_name_-_binary_-_UHD_${params_id}`), params_id)
    }
  },

  on_cpn_select_change(target, params_id) {
    if ($(`#content_package_name_select_${params_id} option[value!=""]`).length > 0) {
      let cpn = $(`#content_package_name_${params_id}`)
      let cpns = $(`#content_package_name_select_${params_id}`)
      let cpn_string = cpns.val() + $(`#content_package_version_${params_id}`).val()
      target.find('.no-cpn.message').hide()
      target.find('.cpn.fields').show()
      cpn.val(cpn_string)
      cpn.trigger('change')
    }
  }
}

window.content_package_name = content_package_name




