document.addEventListener("turbo:load", function() {
  if ($("#datamovers").length > 0) {
    datamovers.init();
  }
});

var datamovers = {
  init() {
    search.init("/datamovers");
  },

  context_menu() {
    $(".context.menu .datamover.item").on('click', function() {
      patch($(this).data("url"))
    })
  }
};

window.datamovers = datamovers;
