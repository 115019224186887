import { Controller } from "@hotwired/stimulus"

import { get } from '@rails/request.js'

export default class extends Controller {

  timers = {}
  
  static outlets = [
    'avail--calendar'
  ]

  static values = { 
    feedUri: String, 
    startDate: String, 
    endDate: String, 
    mode: String,
  }

  static targets = [
    'modal',
    'submitButton',
    'exchangeButton',
    'dateInput',
  ]

  initialize() {
    this.changed_items = this.availCalendarOutlet.changed_items
  }

  debounce(f, delay = 500) {
    return (...args) => {
      const function_name = f.name;
      if (this.timers[function_name]) clearTimeout(this.timers[function_name])
      this.timers[function_name] = setTimeout(() => f.apply(this, args), delay)
    }
  }

  async connect() {
    this.element[this.identifier] = this
    this.dateInputTargets.forEach(input => this.dateChange(input))
  }

  exchangeDate(event) {
    const button = event.currentTarget
    const input_field = this.dateInputTargets.find(target => target.closest('.field') === button.closest('.field'))
    const original_value = button.dataset.originalValue
    const future_value = button.dataset.futureValue

    if (input_field.value === original_value) {
      input_field.value = future_value
    } else {
      input_field.value = original_value
    }

    this.dateChange(input_field)
  }

  dateChange(event) {
    const input = event instanceof Event ? event.currentTarget : event
    const field = input.closest('.field')
    const button = this.exchangeButtonTargets.find(target => target.closest('.field') === field)
    const original_value = button.dataset.originalValue
    const current_value = input.value

    if (original_value !== current_value) {
      field.classList.add("warning")
    } else {
      field.classList.remove("warning")
    }
  }

  async confirm() {
    console.log('no action needed I guess')
  }

  async cancel() {
    this.availCalendarOutlet?.revert()
  }

  async close({ detail: { success, avail_transactions }}) {
    if (success) {
      this.availCalendarOutlet.updateItems(avail_transactions)
    }
    $(this.modalTarget).modal('hide')
  }
}



