document.addEventListener("turbo:load", function() {
  if ($("#storage_usage_history").length > 0) {
    search.init("/storage_usage_history")
    calendar.datepicker(".search.bar")
  }
})

var storage_usage_history = {
  init_index_body() {
    storage_charts.init()
  }
}
        
var storage_charts = {
  init() {
    if ($("#storage_charts").length > 0) {
      storage_charts.fill_storage_usage_history_chart();
      storage_charts.fill_storage_usage_per_location_chart();
      storage_charts.fill_storage_usage_per_asset_type_chart();
    }

    $('.aws.metrics.button').on("click", function() {
      const button = $(".aws.metrics.button")
      if (button.data('disabled')) return

      button.data('disabled', true)
      button.find('[data-fa-i2svg]').removeClass('fab fa-aws').addClass('fas fa-spinner fa-pulse')
      $.ajax({
        url: "/storage_usage_history/aws_metrics_modal",
        type: "GET"
      }).done( function(html) {
        $('body').append(html);
        $('.ui.aws.metrics.modal').modal({
          autofocus: false,
          onShow() {
            $('.ui.aws.metrics.modal .dropdown').dropdown({
              selectOnKeydown: false,
              onChange(value, _text, _$choice) {
                $('#aws_bucket_selection').addClass('disabled')
                $('.ui.aws.metrics.modal .dimmer').addClass('active')
                
                aws.load_aws_storage_metrics(value).done(function(data) {
                  $('#aws_bucket_selection').removeClass('disabled')
                  $('.ui.aws.metrics.modal .dimmer').removeClass('active')
                  $('.ui.aws.metrics.modal .standard.storage.column .text').text(data.standard_storage || "--")
                  $('.ui.aws.metrics.modal .ia.storage.column .text').text(data.standard_ia_storage || "--")
                });
              }
            })
          },
          onHidden() {
            $('.ui.aws.metrics.modal').remove();
          },
          onApprove() {
          }
        }).modal('show')
        button.find('[data-fa-i2svg]').removeClass('fas fa-spinner fa-pulse').addClass('fab fa-aws')
        button.data('disabled', false)
      })
    })

    $('.dfl.metrics.button').on("click", function() {
      const button = $(".dfl.metrics.button")
      if (button.data('disabled')) return

      button.data('disabled', true)
      button.find('[data-fa-i2svg]').removeClass('fas fa-futbol').addClass('fas fa-spinner fa-pulse')

      $.ajax({
        url: "/storage_usage_history/dfl_metrics_modal",
        type: "GET"
      }).done( function(html) {
        $('body').append(html)
        $('.ui.dfl.metrics.modal').modal({
          autofocus: false,
          onHidden() {
            $('.ui.dfl.metrics.modal').remove()
          },
          onShow() {
            //calendar.datepicker('.ui.dfl.metrics.modal')
          }
        }).modal('show')

        $('.dfl.table tbody td[data-bucket]').each(function(_i, item) {
          const bucket = $(item).data('bucket')
          const date = $(item).parent().data('date')
          $.ajax({
            url: "/aws_metrics/dfl_metrics/",
            type: "GET",
            data: {
              date: date,
              bucket: bucket
            }
          }).done((json) => $(item).html(`${json.average.bytes} (${json.average.human})`))
        })

        button.find('[data-fa-i2svg]').removeClass('fas fa-spinner fa-pulse').addClass('fas fa-futbol')
        button.data('disabled', false)
      })
    })
  },

  fill_storage_usage_history_chart() {
    return $.ajax({
      url: "/charts/storage_usage_by_weeks",
      type: "GET",
      dataType: "json",
      data: search.collect_search(),
      success(data, _textStatus, _xhr) {
        $('#storage_usage_history').find('.dimmer').removeClass('active');
        return charts.storage_usage_history_chart('storage_usage_history_chart', data);
      }
    });
  },

  fill_storage_usage_per_location_chart() {
    return $.ajax({
      url: "/charts/storage_usage_by_location",
      type: "GET",
      dataType: "json",
      data: search.collect_search(),
      success(data, _textStatus, _xhr) {
        $('#storage_usage_location').find('.dimmer').removeClass('active');
        if (data.length === 0) {
          return $('#storage_usage_location').find('.no-data').removeClass('hidden');
        } else {
          return charts.storage_usage_location_chart('storage_usage_per_location_chart', data);
        }
      }
    });
  },

  fill_storage_usage_per_asset_type_chart() {
    return $.ajax({
      url: "/charts/storage_usage_by_asset_type",
      type: "GET",
      dataType: "json",
      data: search.collect_search(),
      success(data, _textStatus, _xhr) {
        $('#storage_usage_asset_type').find('.dimmer').removeClass('active');
        if (data.length === 0) {
          return $('#storage_usage_asset_type').find('.no-data').removeClass('hidden');
        } else {
          return charts.storage_usage_asset_type_chart('storage_usage_per_asset_type_chart', data);
        }
      }
    });
  }
};

window.storage_charts = storage_charts;
window.storage_usage_history = storage_usage_history;
