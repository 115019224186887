document.addEventListener("turbo:load", function() {
  if ($("#asset_spec_types").length > 0) {
    $('.ui.form .dropdown').dropdown({});
    
    search.init("/asset_spec_types");
    
    if (($("#asset_spec_types.edit").length > 0) || ($("#asset_spec_types.new").length > 0)) {
      calendar.datepicker("#asset_spec_types")
    }
  }
});
