document.addEventListener("turbo:load", function() {
  if ($("#deployment_specs").length > 0) {
    search.init("/deployment_specs")
    deployment_specs.init()
  }
})

var deployment_specs = {
  init() {    
  },
}

window.deployment_specs = deployment_specs
