var secondary_audio_01 = {
  init(target, params_id) {
    secondary_audio_01.set_modals(target, params_id)
    return target.find('.ui.accordion').accordion()
  },

  set_modals(target, params_id) {
    const audiolayoutmodal = target.find(".ui.modal.secondary-audio-layout-overview-modal")
    return target.find(".popup.secondary-audio-channel-overview").on('click', function() {
      let secondary_20_l, secondary_20_r
      const mc_header = $(`#secondary_audio_01_-_51_-_language_select_${params_id}`).val()
      const stereo_header = $(`#secondary_audio_01_-_20_-_language_select_${params_id}`).val()
      $('.secondary-audio-layout.content').empty()

      if ($(`#secondary_audio_01_-_51_-_present_${params_id}`).val() === "true") {
        const secondary_51_l = target.find(`#secondary_audio_01_-_51_-_channel_-_l_${params_id}`).val()
        const secondary_51_c = target.find(`#secondary_audio_01_-_51_-_channel_-_c_${params_id}`).val()
        const secondary_51_r = target.find(`#secondary_audio_01_-_51_-_channel_-_r_${params_id}`).val()
        const secondary_51_lfe = target.find(`#secondary_audio_01_-_51_-_channel_-_lfe_${params_id}`).val()
        const secondary_51_ls = target.find(`#secondary_audio_01_-_51_-_channel_-_ls_${params_id}`).val()
        const secondary_51_rs = target.find(`#secondary_audio_01_-_51_-_channel_-_rs_${params_id}`).val()
        secondary_20_l = target.find(`#secondary_audio_01_-_20_-_channel_-_l_${params_id}`).val()
        secondary_20_r = target.find(`#secondary_audio_01_-_20_-_channel_-_r_${params_id}`).val()

        $('.secondary-audio-layout.content').append(
          `<h3>${mc_header}</h3> \
<div class='ui three column grid'> \
<div class='column'><div class='ui massive label'><i class='rotated-left-speaker volume off icon'></i>${secondary_51_l}</div></div> \
<div class='column'><div class='ui massive label'><i class='rotated volume off icon'></i>${secondary_51_c}</div></div> \
<div class='column'><div class='ui massive label'><i class='rotated-right-speaker volume off icon'></i>${secondary_51_r}</div></div> \
</div> \
<div class='ui three column grid'> \
<div class='column'></div> \
<div class='column'><div class='ui massive label'><i class='vertically flipped archive icon'></i>${secondary_51_lfe}</div></div> \
<div class='column'></div> \
</div> \
<div class='ui three column grid'> \
<div class='column'><div class='ui massive label'><i class='rotated-ls-speaker volume off icon'></i>${secondary_51_ls}</div></div> \
<div class='column'></div> \
<div class='column'><div class='ui massive label'><i class='rotated-rs-speaker volume off icon'></i>${secondary_51_rs}</div></div> \
</div> \
<div class='ui divider'></div> \
<h3>${stereo_header}</h3> \
<div class='ui three column grid'> \
<div class='column'><div class='ui massive label'><i class='volume off icon'></i>${secondary_20_l}</div></div> \
<div class='column'></div> \
<div class='column'><div class='ui massive label'><i class='horizontally flipped volume off icon'></i>${secondary_20_r}</div></div> \
</div>`
        )
      } else if ($(`#secondary_audio_01_-_20_-_present_${params_id}`).val() === "true") {
        secondary_20_l = target.find(`#secondary_audio_01_-_20_-_channel_-_l_${params_id}`).val()
        secondary_20_r = target.find(`#secondary_audio_01_-_20_-_channel_-_r_${params_id}`).val()

        $('.secondary-audio-layout.content').append(
          `<h3>${stereo_header}</h3> \
<div class='ui two column grid'> \
<div class='column'><div class='ui massive label'><i class='volume off icon'></i>${secondary_20_l}</div></div> \
<div class='column'><div class='ui massive label'><i class='horizontally flipped volume off icon'></i>${secondary_20_r}</div></div> \
</div>`
        )
      } else {
        return
      }
      audiolayoutmodal.modal()
      return audiolayoutmodal.modal('show')
    })
  },

  set_audio_params(target, params_id) {
    const selected_option = target.find(':selected')
    return (() => {
      const result = []
      for (let prefix of ['secondary_audio_01_-_51_-', 'secondary_audio_01_-_20_-']) {
        if (target.hasClass(prefix)) {
          if (selected_option.val() === "") {
            if ($(`#${prefix.replace(/_-_51/g,'').replace(/_-_20/g,'')}_language_manual_select_${params_id}`).val() === "") {
              $(`#${prefix.replace(/_-_51/g,'').replace(/_-_20/g,'')}_language_manual_select_${params_id}`).parent().dropdown("clear")
            }
            $(`#${prefix}_present_${params_id}`).val(false)
            $(`#${prefix}_path_${params_id}`).val("")
            $(`#${prefix}_framerate_${params_id}`).val("")
            $(`#${prefix}_loudness_${params_id}`).val("")
            if ((prefix === 'secondary_audio_01_-_51_-') || (prefix === 'secondary_audio_-_51_-')) {
              $(`#${prefix}_channel_-_l_${params_id}`).val("")
              $(`#${prefix}_channel_-_r_${params_id}`).val("")
              $(`#${prefix}_channel_-_c_${params_id}`).val("")
              $(`#${prefix}_channel_-_lfe_${params_id}`).val("")
              $(`#${prefix}_channel_-_ls_${params_id}`).val("")
              $(`#${prefix}_channel_-_rs_${params_id}`).val("")
            } else {
              $(`#${prefix}_channel_-_l_${params_id}`).val("")
              $(`#${prefix}_channel_-_r_${params_id}`).val("")
            }
          } else {
            const layout = selected_option.data('layout')
            if (layout !== undefined) {
              $(`#${prefix}_present_${params_id}`).val(true)
              $(`#${prefix}_path_${params_id}`).val(selected_option.data('storage-location'))
              $(`#${prefix}_framerate_${params_id}`).val(selected_option.data('framerate'))
              $(`#${prefix}_loudness_${params_id}`).val(selected_option.data('loudness'))
              $(`#${prefix.replace(/_-_51/g,'').replace(/_-_20/g,'')}_language_manual_select_${params_id}`).dropdown("set selected", `${selected_option.data('iso639_2')} - ${selected_option.data('language')}`)
              if ($(`#${prefix.replace(/_-_51/g,'').replace(/_-_20/g,'')}_language_manual_select_${params_id}`).val() === "") {
                $(`#${prefix.replace(/_-_51/g,'').replace(/_-_20/g,'')}_language_manual_select_${params_id}`).parent().dropdown("clear")
              }
              if (prefix === 'secondary_audio_01_-_51_-') {
                $(`#${prefix}_channel_-_l_${params_id}`).val(layout['Left Front'])
                $(`#${prefix}_channel_-_r_${params_id}`).val(layout['Right Front'])
              } else {
                if (layout !== undefined) {
                  if (selected_option.data('channel_count') === 1) {
                    $(`#${prefix}_channel_-_l_${params_id}`).val(layout['Mono_1'])
                    $(`#${prefix}_channel_-_r_${params_id}`).val(layout['Mono_1'])
                  }
                  else if (selected_option.data('channel_count') === 2) {
                    if ('Mono_1' in layout) {
                      $(`#${prefix}_channel_-_l_${params_id}`).val(layout['Mono_1'])
                      $(`#${prefix}_channel_-_r_${params_id}`).val(layout['Mono_2'])
                    } else {
                      $(`#${prefix}_channel_-_l_${params_id}`).val('LT' in layout ? layout['LT'] : layout['Stereo L'])
                      $(`#${prefix}_channel_-_r_${params_id}`).val('RT' in layout ? layout['RT'] : layout['Stereo R'])
                    }
                  } else {
                    $(`#${prefix}_channel_-_l_${params_id}`).val(`${layout['Left Front']}(0.707),${layout['Center']}(0.5),${layout['Left Surround']}(0.354)`)
                    $(`#${prefix}_channel_-_r_${params_id}`).val(`${layout['Right Front']}(0.707),${layout['Center']}(0.5),${layout['Right Surround']}(0.354)`)
                  }
                }
              }
              if (layout !== undefined) {
                $(`#${prefix}_channel_-_c_${params_id}`).val(layout['Center'])
                $(`#${prefix}_channel_-_lfe_${params_id}`).val(layout['LFE'])
                $(`#${prefix}_channel_-_ls_${params_id}`).val(layout['Left Surround'])
                $(`#${prefix}_channel_-_rs_${params_id}`).val(layout['Right Surround'])
              }
            }
          }
          break
        } else {
          result.push(undefined)
        }
      }
      return result
    })()
  }
}

window.secondary_audio_01 = secondary_audio_01
