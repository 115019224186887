// Entry point for the build script in your package.json

import './vendor/jquery'

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"

import { Application } from "@hotwired/stimulus"
window.Stimulus = Application.start()

import NestedForm from 'stimulus-rails-nested-form'
Stimulus.register('nested-form', NestedForm)

import * as RequestJS from '@rails/request.js'
window.RequestJS = RequestJS
window.get = RequestJS.get
window.post = RequestJS.post
window.patch = RequestJS.patch
window.put = RequestJS.put
window.destroy = RequestJS.destroy

import controllers from "./**/*_controller.js"
controllers.forEach((controller) => {
  Stimulus.register(controller.name.replace("controllers--", ""), controller.module.default)
})

import "./channels/**/*_channel.js"

Rails.start()
ActiveStorage.start()
//Turbo.session.drive = false

import 'fomantic-ui/dist/semantic'

import moment from 'moment'
window.moment = moment

import EasyMDE from 'easymde/dist/easymde.min'
window.EasyMDE = EasyMDE

import Highcharts from 'highcharts'
import More from 'highcharts/highcharts-more'
More(Highcharts)
import SolidGauge from 'highcharts/modules/solid-gauge'
SolidGauge(Highcharts)
window.Highcharts = Highcharts

import hljs from 'highlight.js/lib/core'
import xml from 'highlight.js/lib/languages/xml'
import json from 'highlight.js/lib/languages/json'
window.hljs = hljs
hljs.registerLanguage('xml', xml)
hljs.registerLanguage('json', json)

import '@client-side-validations/client-side-validations'

import MediumEditor from 'medium-editor/dist/js/medium-editor.min'
window.MediumEditor = MediumEditor
import AutoList from 'medium-editor-autolist/dist/autolist.min'
window.AutoList = AutoList

import humanFormat from 'human-format'
window.humanFormat = humanFormat

import sortable from 'html5sortable/dist/html5sortable.es.js'
window.sortable = sortable

import 'jquery-serializeobject'

import 'dragster'
import prettyBytes from 'pretty-bytes'
window.prettyBytes = prettyBytes

import 'cocoon-rails'

import { particlesJS } from 'particles.js/particles'
window.particlesJS = particlesJS

import React from 'react'
window.React = React

import "./components/**/*"

import "./server_rendering"

import "./src/**/*"

import "./vendor/nested_form_fields"
import "./vendor/fomantic_menu"

import "gridstack"

import("./vendor/fontawesome")
import("./video/play")
