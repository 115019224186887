import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'modal',
    'submitButton',
    'includeImageDuplication',
    'contactDropdown',
  ]
  connect() {
    //console.log("connected")
    this.init()
  }

  init() {
    $(this.contactDropdownTarget).dropdown({
      apiSettings: {
        cache: false,
        url: '/contacts.json?type=metadata&q={/query}&search_input={/query}'
      },
      templates: {
        menu: dropdownTemplates.search_menu
      },
    }).dropdown('set selected', $(this.contactDropdownTarget).val())
  }

  close(event) {
    //console.log('close')
    if (event.detail.success) {
      $(this.modalTarget).modal('hide')
    }
  }
}
