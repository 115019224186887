import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  static targets = [
    'episodeSelect'
  ]

  addAllEpisodes() {
    let dropdown = this.episodeSelectTarget
    let optionValues = [...dropdown.options].map(o => o.value)
    $(dropdown).dropdown("set selected", optionValues)
  }
}
