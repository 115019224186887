import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["form", "filePreview", "fileInput", "commonFields", "dropzone", "submit"]

  connect() {
    $(this.formTarget).find('select.default-options.dropdown').dropdown()
  }

  async showPreview(event) {
    if (event.target.files.length > 0) {
      var _URL = window.URL || window.webkitURL
      const formData = new FormData()
      
      this.fileInputTarget.files = event.target.files

      this.filePreviewTarget.innerHTML = ""
      this.dropzoneTarget.style.display = "none"
  
      for (const [index, file] of [...event.target.files].entries()) {
        const template = document.getElementById("image-preview-template")
        const clone = template.content.cloneNode(true)
        let preview = clone.querySelector(".image-preview")
  
        this.updateIndex(preview, index)
        preview.querySelector(".header").textContent = file.name
        this.filePreviewTarget.appendChild(clone)
  
        const img = new Image()
        const objectUrl = _URL.createObjectURL(file)
  
        await this.loadImage(img, objectUrl, preview, file)
        formData.append(`files[]`, file)
        _URL.revokeObjectURL(objectUrl)
      }
      this.submitTarget.classList.remove('disabled')
    }
  }

  async loadImage(img, objectUrl, preview, file) {
    return new Promise((resolve) => {
      img.onload = function () {
        preview.querySelector('.image').href = objectUrl
        preview.querySelector('img').src = objectUrl

        const [filename, extension] = file.name.split(/\.(?=[^\.]+$)/)
        preview.querySelector('span.width').textContent = this.width
        preview.querySelector('span.height').textContent = this.height
        preview.querySelector('span.file-extension').textContent = extension
        preview.querySelector('.header').textContent = file.name
        
        preview.querySelector('input[type="hidden"].width').value = this.width
        preview.querySelector('input[type="hidden"].height').value = this.height
        preview.querySelector('input[type="hidden"].file-extension').value = extension
        preview.querySelector('input[type="hidden"].original-name').value = file.name

        resolve()
      }
      img.src = objectUrl
    })
  }

  updateIndex(element, index) {
    const inputs = element.querySelectorAll('input[type="hidden"]')
    inputs.forEach(input => {
      input.name = input.name.replace(/\[0\]/, `[${index}]`)
      input.id = input.id.replace(/_0_/, `_${index}_`)
    })
  }

  dropzoneTargetConnected(dropzone) {
    dropzone.onclick = () => {
      this.fileInputTarget.click()
    } 

    let counter = 0;

    ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(eventName => {
      dropzone.addEventListener(eventName, function(e) {
        e.preventDefault()
        e.stopPropagation()
      })
    })

    dropzone.addEventListener('dragenter', function() {
      counter++;
      dropzone.classList.add('active');
    })

    dropzone.addEventListener('dragleave', function() {
      counter--;
      if (counter === 0) {
        dropzone.classList.remove('active');
      }
    })

    dropzone.addEventListener('drop', (event) => {
      const accept = this.fileInputTarget.getAttribute('accept').split(',')
      const files = [...event.dataTransfer.files]
      const acceptedFiles = files.filter(file => accept.some(type => file.type.match(type.trim())))

      if (acceptedFiles.length > 0) {
        const dataTransfer = new DataTransfer()
        acceptedFiles.forEach(file => dataTransfer.items.add(file))
        this.fileInputTarget.files = dataTransfer.files
        this.fileInputTarget.dispatchEvent(new Event('change'))
      }
    })
  }
}
