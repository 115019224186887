import { Controller } from "@hotwired/stimulus"
// import { get } from "@rails/request.js"

export default class extends Controller {

  static targets = [
    'importCheckbox',
    'importIntoAwaitedCheckbox'
  ]

  static values = {
    paramsId: String,
  }

  connect() {
    let c = this

    $(c.importCheckboxTarget).checkbox({
      fireOnInit: true,
      onChecked() {
        c.target.find('.title-editorial').show()
        c.target.find('.import.awaited.asset.field').show()
        c.target.find('.content-package-component').hide()
        c.target.find('.content-package.checkbox').checkbox('uncheck')
      },
      onUnchecked() {
        c.target.find('.title-editorial').hide()
        c.target.find('.awaited.asset .checkbox').checkbox('uncheck')
        c.target.find('.import.awaited.asset.field').hide()
      }
    })

    awaited_asset.init(c.target, c.paramsIdValue)
  }

  initialize() {
  }

  get target() {
    return $(this.element.closest("fieldset.nested_workflow_workflow_jobs"))
  }

}
