document.addEventListener("turbo:load", function() {
  if ($("#file_naming_conventions").length > 0) {
    $('.ui.form .dropdown').dropdown({});
    search.init("/file_naming_conventions");
  }

  if ($("#file_naming_convention").length > 0) {
    $('.ui.form .dropdown').dropdown();
    $("#file_naming_convention").on('fields_added.nested_form_fields', function(_event, _param) {
      $(this).find('.ui.dropdown').dropdown();
    });
    calendar.datepicker("#file_naming_convention")
  }
});
