$.fn.calendar.settings.formatter.datetime = 'DD.MM.YYYY HH:mm';
$.fn.calendar.settings.formatter.time = 'HH:mm';
$.fn.calendar.settings.formatter.cellTime = 'HH:mm';

document.addEventListener("turbo:load", () => charts.init());

var calendar = {
  init() {

  },
  
  isValidDate(d) {
    return Object.prototype.toString.call(d) === '[object Date]' && isFinite(d);
  },

  parseDate(text) {
    return window.moment(text, 'DD.MM.YYYY hh:mm').toDate();
  },

  parseDate_WaitAMoment(text) {

    let result = new Date(text);
    if (!this.isValidDate(result)) {
      result = new Date();
    }
    // eslint-disable-next-line no-useless-escape
    const datefinderRegex = /\d{1,}[\.\-\/]\d{1,}[\.\-\/]{0,}\d{0,4}/gm;
    var numberPattern = /\d+/g;

    const foundDateStr = datefinderRegex.exec(text);
    if (foundDateStr) {
      const dateString = foundDateStr[0].match( numberPattern );
      let day
      let month
      let year
      switch(dateString.length) {
        case 3:
          // code block
          day = dateString[0];
          month = dateString[1] - 1;
          year = dateString[2];
          break;
        case 2:
          day = dateString[0]
          month = dateString[1] - 1;
          year = result.getFullYear();
          // code block
          break;
        default:
      }
  
      const yl = 4 - year.length;
      year = String(Math.floor(result.getFullYear() / 1000) * 1000).substring(0,yl) + year ; 
  
      result.setYear(year);
      result.setMonth(month);
      result.setDate(day);
    } 

    return result;
  },

  customParser(text, settings) {
    if (text instanceof Date) {
          return text;
        }
        if (!text) {
          return null;
        }
        text = ('' + text).trim().toLowerCase();
        if (text.length === 0) {
          return null;
        }
        
        var textDate = calendar.parseDate(text);

        if(!isNaN(textDate.getDate())) {
          return textDate;
        }

        var i, j, k;
        var minute = -1, hour = -1, day = -1, month = -1, year = -1;
        var isAm = undefined;
  
        var isTimeOnly = settings.type === 'time';
        var isDateOnly = settings.type.indexOf('time') < 0;
  
        var words = text.split(settings.regExp.dateWords);
        var numbers = text.split(settings.regExp.dateNumbers);
  
        if (!isDateOnly) {
          //am/pm
          isAm = $.inArray(settings.text.am.toLowerCase(), words) >= 0 ? true :
            $.inArray(settings.text.pm.toLowerCase(), words) >= 0 ? false : undefined;
  
          //time with ':'
          for (i = 0; i < numbers.length; i++) {
            var number = numbers[i];
            if (number.indexOf(':') >= 0) {
              if (hour < 0 || minute < 0) {
                var parts = number.split(':');
                for (k = 0; k < Math.min(2, parts.length); k++) {
                  j = parseInt(parts[k]);
                  if (isNaN(j)) {
                    j = 0;
                  }
                  if (k === 0) {
                    hour = j % 24;
                  } else {
                    minute = j % 60;
                  }
                }
              }
              numbers.splice(i, 1);
            }
          }
        }
  
        if (!isTimeOnly) {
          //textual month
          for (i = 0; i < words.length; i++) {
            var word = words[i];
            if (word.length <= 0) {
              continue;
            }
            word = word.substring(0, Math.min(word.length, 3));
            for (j = 0; j < settings.text.months.length; j++) {
              var monthString = settings.text.months[j];
              monthString = monthString.substring(0, Math.min(word.length, Math.min(monthString.length, 3))).toLowerCase();
              if (monthString === word) {
                month = j + 1;
                break;
              }
            }
            if (month >= 0) {
              break;
            }
          }
  
          //year > settings.centuryBreak
          for (i = 0; i < numbers.length; i++) {
            j = parseInt(numbers[i]);
            if (isNaN(j)) {
              continue;
            }
            if (j >= settings.centuryBreak && i === numbers.length-1) {
              if (j <= 99) {
                j += settings.currentCentury - 100;
              }
              year = j;
              numbers.splice(i, 1);
              break;
            }
          }
  
          //numeric month
          if (month < 0) {
            for (i = 0; i < numbers.length; i++) {
              k = i > 1 || settings.monthFirst ? i : i === 1 ? 0 : 1;
              j = parseInt(numbers[k]);
              if (isNaN(j)) {
                continue;
              }
              if (1 <= j && j <= 12) {
                month = j;
                numbers.splice(k, 1);
                break;
              }
            }
          }
  
          //day
          for (i = 0; i < numbers.length; i++) {
            j = parseInt(numbers[i]);
            if (isNaN(j)) {
              continue;
            }
            if (1 <= j && j <= 31) {
              day = j;
              numbers.splice(i, 1);
              break;
            }
          }
  
          //year <= settings.centuryBreak
          if (year < 0) {
            for (i = numbers.length - 1; i >= 0; i--) {
              j = parseInt(numbers[i]);
              if (isNaN(j)) {
                continue;
              }
              if (j <= 99) {
                j += settings.currentCentury;
              }
              year = j;
              numbers.splice(i, 1);
              break;
            }
          }
        }
  
        if (!isDateOnly) {
          //hour
          if (hour < 0) {
            for (i = 0; i < numbers.length; i++) {
              j = parseInt(numbers[i]);
              if (isNaN(j)) {
                continue;
              }
              if (0 <= j && j <= 23) {
                hour = j;
                numbers.splice(i, 1);
                break;
              }
            }
          }
  
          //minute
          if (minute < 0) {
            for (i = 0; i < numbers.length; i++) {
              j = parseInt(numbers[i]);
              if (isNaN(j)) {
                continue;
              }
              if (0 <= j && j <= 59) {
                minute = j;
                numbers.splice(i, 1);
                break;
              }
            }
          }
        }
  
        if (minute < 0 && hour < 0 && day < 0 && month < 0 && year < 0) {
          return null;
        }
  
        if (minute < 0) {
          minute = 0;
        }
        if (hour < 0) {
          hour = 0;
        }
        if (day < 0) {
          day = 1;
        }
        if (month < 0) {
          month = 1;
        }
        if (year < 0) {
          year = new Date().getFullYear();
        }
  
        if (isAm !== undefined) {
          if (isAm) {
            if (hour === 12) {
              hour = 0;
            }
          } else if (hour < 12) {
            hour += 12;
          }
        }
  
        var date = new Date(year, month - 1, day, hour, minute);
        if (date.getMonth() !== month - 1 || date.getFullYear() !== year) {
          //month or year don't match up, switch to last day of the month
          date = new Date(year, month, 0, hour, minute);
        }
        return isNaN(date.getTime()) ? null : date;
  },

  time_formatter(date) {
    if (!date) {
      return '';
    }
    var hour = date.getHours();
    var minute = date.getMinutes();
    var t = "";
    return ("0" + hour).slice(-2) + ':' + (minute < 10 ? '0' : '') + minute + t;
  },

  datepicker(namespace = "", popupOptions = {}, with_formatter = true) {
    $(namespace).find('.calendar.datepicker').calendar({
      type: 'date',
      monthFirst: false,
      selectAdjacentDays: true,
      formatInput: with_formatter,
      popupOptions,
      firstDayOfWeek: 1,
      parser: {
        date: function(text, settings) {
          return calendar.customParser(text,settings);
        }
      },
      formatter: {
        date: function (date, _settings) {
        if (!date) return '';
        var day = ('0' + date.getDate()).slice(-2);
        var month = ('0' + (date.getMonth()+1)).slice(-2);
        var year = date.getFullYear();
        return day + '.' + month + '.' + year;
        }
      }
    })
  },

  datepicker2(namespace = "", options = {}, with_formatter = true) {
    let default_options = {
      type: 'date',
      monthFirst: false,
      selectAdjacentDays: true,
      formatInput: with_formatter,
      // popupOptions,
      firstDayOfWeek: 1,
      parser: {
        date: function(text, settings) {
          return calendar.customParser(text,settings);
        }
      },
      formatter: {
        date: function (date, _settings) {
        if (!date) return '';
        var day = ('0' + date.getDate()).slice(-2);
        var month = ('0' + (date.getMonth()+1)).slice(-2);
        var year = date.getFullYear();
        return day + '.' + month + '.' + year;
        }
      }
    }
    var merged_options = default_options
    if (typeof(options) === 'object') {
      merged_options = {...default_options, ...options}
    }

    $(namespace).find('.calendar.datepicker').calendar(merged_options)
  },


  datetimepicker(target) {
    $(target).find('.calendar.datetimepicker').calendar({
      type: 'datetime',
      monthFirst: false,
      ampm: false,
      selectAdjacentDays: true,
      firstDayOfWeek: 1,
      parser: {
        date: function(text, settings) {
          return calendar.customParser(text,settings);
        }
      },
      formatter: {
        date: function (date, _settings) {
        if (!date) return '';
        var day = ('0' + date.getDate()).slice(-2);
        var month = ('0' + (date.getMonth()+1)).slice(-2);
        var year = date.getFullYear();
        return day + '.' + month + '.' + year;
        },
        time: function (date, _settings, _forCalendar) { return calendar.time_formatter(date) }
      }
    })
  },

  yearpicker() {
    $('.calendar.yearpicker').calendar({
      type: 'year'
    })
  },

  timepicker() {
    $('.calendar.timepicker').calendar({
      type: 'time',
      ampm: false,
      parser: {
        date: function(text, settings) {
          return calendar.customParser(text,settings);
        }
      },
      formatter: {
        time: function (date, _settings, _forCalendar) { return calendar.time_formatter(date) }
      }
    }) 
  },
};

window.calendar = calendar;
