const stereo_audio_convert = {
  init(target, _params_id) {
    return target.find('.ui.accordion').accordion();
  },

  set_audio_params(target, params_id) {
    const selected_option = target.find(':selected');
    const layout = selected_option.data('layout');
    const location = selected_option.data('storage-location');
    if (selected_option.val() === "") {
      $(`#audio_channels_${params_id}`).val(0);
      $(`#audio_channel_l_path_${params_id}`).val(0);
      return $(`#audio_channel_r_path_${params_id}`).val(0);
    } else {
      $(`#audio_mono_${params_id}`).val(selected_option.data('mono'));
      $(`#audio_channels_${params_id}`).val(selected_option.data('channel-count'));
      $(`#audio_framerate_${params_id}`).val(selected_option.data('framerate'));

      if (selected_option.data('channel-count') === 2) {
        $(`#audio_channel_l_path_${params_id}`).val(`${location}` + selected_option.data('filename').L);
        $(`#audio_channel_l_mapping_${params_id}`).val(layout['Stereo L'] || layout['LT'] || layout['Mono_1']);
        $(`#audio_channel_r_path_${params_id}`).val(`${location}` + selected_option.data('filename').R);
        $(`#audio_channel_r_mapping_${params_id}`).val(layout['Stereo R'] || layout['RT'] || layout['Mono_2']);
        $(`#audio_channel_c_path_${params_id}`).val(null);
        $(`#audio_channel_c_mapping_${params_id}`).val(null);
        $(`#audio_channel_lfe_path_${params_id}`).val(null);
        $(`#audio_channel_lfe_mapping_${params_id}`).val(null);
        $(`#audio_channel_ls_path_${params_id}`).val(null);
        $(`#audio_channel_ls_mapping_${params_id}`).val(null);
        $(`#audio_channel_rs_path_${params_id}`).val(null);
        return $(`#audio_channel_rs_mapping_${params_id}`).val(null);
      } else if (selected_option.data('channel-count') === 6) {
        $(`#audio_channel_l_path_${params_id}`).val(`${location}` + selected_option.data('filename').L);
        $(`#audio_channel_l_mapping_${params_id}`).val(`${layout['Left Front']}(0.707),${layout['Center']}(0.5),${layout['Left Surround']}(0.354)`);
        $(`#audio_channel_r_path_${params_id}`).val(`${location}` + selected_option.data('filename').R);
        $(`#audio_channel_r_mapping_${params_id}`).val(`${layout['Right Front']}(0.707),${layout['Center']}(0.5),${layout['Right Surround']}(0.354)`);
        $(`#audio_channel_c_path_${params_id}`).val(`${location}` + selected_option.data('filename').C);
        $(`#audio_channel_c_mapping_${params_id}`).val(layout['Center']);
        $(`#audio_channel_lfe_path_${params_id}`).val(`${location}` + selected_option.data('filename').LFE);
        $(`#audio_channel_lfe_mapping_${params_id}`).val(layout['LFE']);
        $(`#audio_channel_ls_path_${params_id}`).val(`${location}` + selected_option.data('filename').LS);
        $(`#audio_channel_ls_mapping_${params_id}`).val(layout['Left Surround']);
        $(`#audio_channel_rs_path_${params_id}`).val(`${location}` + selected_option.data('filename').RS);
        return $(`#audio_channel_rs_mapping_${params_id}`).val(layout['Right Surround']);
      }
    }
  }
};

window.stereo_audio_convert = stereo_audio_convert;
