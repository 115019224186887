import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["flyout", "pusher"]
  static values = {
    eventNames: { 
      type: Array, default: [
        "change", "show", "hide", "visible", "hidden", "approve", "deny"
      ]
    },
  }

  initialize() {
    const c = this
    // define methods for possible event names
    this.eventNamesValue.forEach((eventName) => {
      this[eventName] = () => {
        const event = new CustomEvent(`flyout:${eventName}`, {
          detail: { element: this.flyoutTarget },
          bubbles: true
        })
        this.flyoutTarget.dispatchEvent(event)
      }
    })
    let params = {}
    this.eventNamesValue.forEach((eventName) => {
      params[`on${eventName.replace(/(^\w)/, (match, p1) => p1.toUpperCase())}`] = this[eventName].bind(this)
    })

    c.flyoutTarget[c.identifier] = c
    $(c.flyoutTarget).flyout({ 
      context: $(c.element),
      ...params,
    })
  }

  onChange() {
    const event = new CustomEvent('flyout:change', {
      detail: { element: this.flyoutTarget },
      bubbles: true
    })
    this.flyoutTarget.dispatchEvent(event)
  }
  onShow() {
    const event = new CustomEvent('flyout:show', {
      detail: { element: this.flyoutTarget },
      bubbles: true
    })
    this.flyoutTarget.dispatchEvent(event)
  }
  onHide() {
    const event = new CustomEvent('flyout:hide', {
      detail: { element: this.flyoutTarget },
      bubbles: true
    })
    this.flyoutTarget.dispatchEvent(event)
  }
  onVisible() {
    const event = new CustomEvent('flyout:visible', {
      detail: { element: this.flyoutTarget },
      bubbles: true
    })
    this.flyoutTarget.dispatchEvent(event)
  }
  onHidden() {
    const event = new CustomEvent('flyout:hidden', {
      detail: { element: this.flyoutTarget },
      bubbles: true
    })
    this.flyoutTarget.dispatchEvent(event)
  }
  onApprove() {
    const event = new CustomEvent('flyout:approve', {
      detail: { element: this.flyoutTarget },
      bubbles: true
    })
    this.flyoutTarget.dispatchEvent(event)
  }
  onDeny() {
    const event = new CustomEvent('flyout:deny', {
      detail: { element: this.flyoutTarget },
      bubbles: true
    })
    this.flyoutTarget.dispatchEvent(event)
  }

  toggle() {
    $(this.flyoutTarget).flyout('toggle')
  }
  
  show() {
    $(this.flyoutTarget).flyout('show')
  }

  hide() {
    $(this.flyoutTarget).flyout('hide')
  }
}
