document.addEventListener("turbo:load", function() {
  if ($("#notification_types").length > 0) {
    //   search.init("/notification_types");
    notification_types.init();
  }
});

$(document).on('fields_added.nested_form_fields.notification_template', function(_event, params) {
  switch (params.object_class) {
    case "notification_template":
      $('.ui.accordion').accordion({
        selector: {
          trigger: '.title .notification-accordion-label'
        }
      })
      break
  }
});

var notification_types = {
  
  init() {
    notification_types.init_form();
    calendar.timepicker();
  },
  init_form() {
    // console.log("init form");
    notification_types.load_remote_cards();
    notification_types.init_dropdowns(); 
    notification_types.init_accordions();
    notification_types.nested_fields();
  },
  nested_fields() {
    $("#notification_types").off("fields_added.nested_form_fields.nested_notification_recipients")
    $("#notification_types").on("fields_added.nested_form_fields.nested_notification_recipients", function(event, param) {
      return notification_types.set_recipient_data(event.target, param.additional_data);
    });
  },
  init_dropdowns() {
    search.notification_recipient_autocompletion(".ui.search.notification_recipients");
    $(".ui.search.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
    $(".search-field.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
    $("#notification_types .default-options.dropdown").not('.remote').dropdown({placeholder: false, fullTextSearch: true});
  },
  init_accordions() {
    $('.ui.accordion').accordion({
      selector: {
        trigger: '.title .notification-accordion-label'
      }
    });
  },
  set_recipient_data(target, data) {
    console.log("set_recipient_data");
    console.log(target);
    if (data !== undefined) {
      $.ajax({
        data: {
          user_profile_web_id: `${data.upw_ID}`,
          nested_field_index: `${$(target).find(".recipient-id-display").data("recipient-index")}`
        },
        url: `/notification_types/card`
      }).done(function(result) {
        console.log("should append data");
        $(target).find(".recipient-id-display").append(result);
        $(target).find(".recipient-id").val(data.upw_ID);
        $(target).find(".dropdown").dropdown({placeholder: false, fullTextSearch: true});
        
      }).fail(function(error) {
        console.log(error.responseText);
      });
    }
  },
  load_remote_cards() {
    console.log("load_remote_cards");
    $('.remote.recipient.card:not([data-recipient-id=""])').each((_i, card) => notification_types.recipient_card($(card).data('recipient-id'), $(card).data('recipient-index'), $(card).data('notification-type-id')).done(function(html) {
      $(card).html(html);
      $(card).find(".dropdown").dropdown({placeholder: false, fullTextSearch: true});
      // $(card).dropdown({placeholder: false, fullTextSearch: true});
      // notification_types.init_dropdowns();
    }));
  },
  recipient_card(upw_id, index, nt_ID) {
    console.log("recipient_card")
    
    return $.ajax({
      url: `/notification_types/card`,
      type: "GET",
      data: {
        user_profile_web_id: `${upw_id}`,
        nested_field_index: `${index}`,
        notification_type_id: `${nt_ID}`
      }
    })
  },
  
  load_email_preview(preview_modal, preview_area, notification_type_id, contact_person_id = null, variant_template = null) {
    $.ajax({
      url: `/notification_types/${notification_type_id}/email_preview`,
      type: "GET",
      data: {
        contact_person_id: contact_person_id,
        model_name: preview_modal.data('model-name'),
        model_id: preview_modal.data('model-id'),
        template: variant_template
      },
      beforeSend() {
        preview_area.html(`
        <div class="ui segment" style="min-height: 150px">
          <div class="ui active inverted dimmer">
            <div class="ui mini text loader">Preparing Preview</div>
          </div>
        </div>`
        )
      }
    }).done(function(data) {
      preview_area.html(data)
      // let email_preview = preview_area.find('.email_preview')
    }).fail(function(_xhr, _textStatus, errorThrown) {
      if (errorThrown != 'abort') { 
        preview_area.html(`<div class="ui visible error message">An unknown error occured during preview creation!</div>`)
        console.log(errorThrown) 
      }
    })
  },

  init_notification_preview_modal(preview_modal) {
    let notification_type_dropdown = preview_modal.find('.preview.notification_type.dropdown').dropdown()
    let contact_person_dropdown = preview_modal.find('.preview.contact_person.search.dropdown').dropdown({ 
      fullTextSearch: false,
      cache: false,
      apiSettings: {
        url: `/contact_persons/for_notification_preview?q={/query}&search_input={/query}`,
        dataType: "json",
        cache: false,
        data: {
          notification_type_id: notification_type_dropdown.dropdown('get value'),
          model_name: preview_modal.data('model-name'),
          model_id: preview_modal.data('model-id')
        },
        beforeSend(settings) {
          settings.data.notification_type_id = notification_type_dropdown.dropdown('get value')
          return settings
        }
      },
      onChange(value, _text, _choice) {
        let notification_type_id = notification_type_dropdown.dropdown('get value')
        let preview_area = preview_modal.find('.preview_area')
        let variant_template = $(`[id$=_template_content][data-notification-type-id=${notification_type_id}]`).val()
        notification_types.load_email_preview(preview_modal, preview_area, notification_type_id, value, variant_template)
      }
        
    })
    contact_person_dropdown.dropdown('queryRemote', '', function() {
      let first_contact_person = contact_person_dropdown.find(".item").first().data('value')
      contact_person_dropdown.dropdown('set exactly', first_contact_person)
    })

    if (preview_modal.data('modelName') === "Notification") { 
      let preview_area = preview_modal.find('.preview_area')
      let notification_type_id = preview_modal.data('notification-type-id')
      notification_types.load_email_preview(preview_modal, preview_area, notification_type_id)

    }
  },
}

window.notification_types = notification_types;
